import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { combineLatest, EMPTY, merge, Observable, of, Subscription } from 'rxjs';
import { delay, distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';
import { PendingRequestsInterceptor } from '../http/pending-requests.interceptor';

@Component({
  selector: 'devapp-spinner',
  template: '<div [class.visible]="visible"></div>',
  styleUrls: ['./spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpinnerComponent implements OnInit, OnDestroy {
  visible = false;

  private navigationInProgress: Observable<boolean>;
  private requestsInProgress: Observable<boolean>;
  private subscription: Subscription;

  constructor(
    private router: Router,
    private requestsInterceptor: PendingRequestsInterceptor,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.navigationInProgress = this.router.events.pipe(
      switchMap((event) => {
        if (event instanceof NavigationStart) {
          return of(true);
        } else if (
          event instanceof NavigationEnd ||
          event instanceof NavigationError ||
          event instanceof NavigationCancel
        ) {
          return of(false);
        }
        return EMPTY;
      }),
      startWith(false),
      distinctUntilChanged(),
    );

    this.requestsInProgress = merge(this.requestsInterceptor.afterRequest, this.requestsInterceptor.beforeRequest).pipe(
      map(() => this.requestsInterceptor.pendingRequests !== 0),
      startWith(false),
      distinctUntilChanged(),
    );

    this.subscription = combineLatest([this.navigationInProgress, this.requestsInProgress])
      .pipe(
        switchMap(([navigation, requests]) => {
          const value = navigation || requests;
          if (value) {
            return of(value);
          } else {
            return of(value).pipe(delay(50));
          }
        }),
        distinctUntilChanged(),
      )
      .subscribe((visible) => {
        this.visible = visible;
        this.cd.detectChanges();
      });
  }

  ngOnDestroy() {
    if (this.subscription != null) {
      this.subscription.unsubscribe();
    }
  }
}
