import { FormatVersion } from './adapter';
import { CompositeControl } from './composite-control';
import { Control } from './control';
import { HeaderPreset } from './controls/header';
import { Page, RootPage } from './page';
import { PageVariation } from './page-variation';
import { ScreenType } from './screen-type';
import { Image } from '../public-api';
import { ScreenTypeIsInUseVisitor } from '../visitor/screen-type-is-in-use-visitor';

export class Application {
  rootPage: RootPage;
  themeOptions: ThemeOptions;
  screenTypes: ScreenType[];

  /**
   * From which format version the application was created (if known).
   */
  formatVersion: FormatVersion | null;

  constructor({
    pages = [],
    themeOptions = {
      brandColor: { background: '#b6000f', hover: '#ff0015', text: '#ffffff' },
      primaryColor: { background: '#869098', hover: '#bfced9', text: '#ffffff' },
      pageColor: { background: '#ffffff', hover: '#b3b3b3', text: '#262626' },
      headerPresets: [
        new HeaderPreset({ id: 'default1', label: 'Header 1', fontSize: '2du', align: 'left' }),
        new HeaderPreset({ id: 'default2', label: 'Header 2', fontSize: '1.5du', align: 'left' }),
        new HeaderPreset({ id: 'default3', label: 'Header 3', fontSize: '1.17du', align: 'left' }),
      ],
    },
    screenTypes = [],
    formatVersion = null,
  }: ApplicationOptions = {}) {
    this.rootPage = new RootPage({ pages });
    this.rootPage.parentApplication = this;
    this.themeOptions = themeOptions;
    this.screenTypes = screenTypes;
    this.formatVersion = formatVersion;
  }

  getPageByID(id: string): Page | null {
    return getPageById(this.rootPage, id);
  }

  getControlById(id: string): Control | null {
    return getControlById(this.rootPage, id);
  }

  getScreenType(id: string): ScreenType | null {
    return this.screenTypes.find((item) => item.id === id) ?? null;
  }

  getImageIds(): string[] {
    return getImageIds(this.rootPage);
  }

  addScreenType(screenType: ScreenType) {
    const exists = this.screenTypes.some((s) => s.id === screenType.id);
    if (exists) {
      throw new Error('Screen type already exists.');
    }

    this.screenTypes.push(screenType);
  }

  removeScreenType(screenType: ScreenType) {
    const visitor = new ScreenTypeIsInUseVisitor(screenType);
    this.rootPage.accept(visitor);
    if (visitor.screenTypeIsInUse()) {
      throw new Error('Cannot remove screen type, because it is used by a page.');
    }

    const index = this.screenTypes.indexOf(screenType);
    if (index !== -1) {
      this.screenTypes.splice(index, 1);
    }
  }
}

export interface ApplicationOptions {
  pages?: Page[];
  themeOptions?: ThemeOptions;
  screenTypes?: ScreenType[];
  formatVersion?: FormatVersion | null;
}

export interface ThemeOptions {
  brandColor: {
    background: string;
    hover: string;
    text: string;
  };
  primaryColor: {
    background: string;
    hover: string;
    text: string;
  };
  pageColor: {
    background: string;
    hover: string;
    text: string;
  };
  headerPresets: HeaderPresetOptions[];
}

export interface HeaderPresetOptions {
  id: string;
  label: string;
  fontSize: string;
  align: 'left' | 'center' | 'right' | 'justify';
}

export function getControlById(node: Page | PageVariation | Control, id: string): Control | null {
  if (node instanceof Control && node.id === id) {
    return node;
  }

  if (node instanceof CompositeControl) {
    for (const control of node.children) {
      const result = getControlById(control, id);
      if (result != null) {
        return result;
      }
    }
  } else if (node instanceof Page) {
    const result = getControlById(node.controlRoot, id);
    if (result != null) {
      return result;
    }

    for (const page of node.children) {
      const result = getControlById(page, id);
      if (result != null) {
        return result;
      }
    }

    for (const variation of node.variations) {
      const result = getControlById(variation, id);
      if (result != null) {
        return result;
      }
    }
  } else if (node instanceof PageVariation) {
    const result = getControlById(node.controlRoot, id);
    if (result != null) {
      return result;
    }
  }

  return null;
}

/**
 * Returns page with give ID from the pages tree.
 *
 * @param page Pages tree root.
 * @param pageId ID of the page to look for.SorS
 * @returns Desired page or null if not found.
 */
export function getPageById(page: Page, pageId: string): Page | null {
  if (page.id === pageId) {
    return page;
  }

  for (const child of page.children) {
    const result = getPageById(child, pageId);
    if (result != null) {
      return result;
    }
  }

  return null;
}

/**
 * Traverses the tree and gathers all the image IDs from
 * the `page` node and its children.
 *
 * @param page The node to start the traversal from
 * @returns The image IDs found
 */
export function getImageIds(page: Page): string[] {
  const ids = new Set<string>();

  const getImageControl = (node: Page | PageVariation | Control) => {
    if (node instanceof Image) {
      ids.add(node.resource);
    }

    if (node instanceof CompositeControl) {
      for (const control of node.children) {
        getImageControl(control);
      }
    } else if (node instanceof Page) {
      getImageControl(node.controlRoot);

      for (const page of node.children) {
        getImageControl(page);
      }

      for (const variation of node.variations) {
        getImageControl(variation);
      }
    } else if (node instanceof PageVariation) {
      getImageControl(node.controlRoot);
    }
  };

  getImageControl(page);

  return Array.from(ids.values());
}
