import { traverseTree } from './validation-utils';
import { Problem, ProblemSeverity, Validatable, ValidationTrigger, Validator } from './validator';
import { Application } from '../../../application/application';
import { CompositeControl } from '../../../application/composite-control';
import { BooleanSignal } from '../../../application/controls/boolean-signal';
import { EnumSignal } from '../../../application/controls/enum-signal';
import { NumericSignal } from '../../../application/controls/numeric-signal';
import { Root } from '../../../application/controls/root';
import { Page } from '../../../application/page';
import { PageVariation } from '../../../application/page-variation';
import { Command, CommandAction } from '../../dev/history/command';
import { AddControlCommand } from '../../dev/history/commands/add-control.command';
import { AddPageVariationCommand } from '../../dev/history/commands/add-page-variation.command';
import { AddPageCommand } from '../../dev/history/commands/add-page.command';
import { EditApplicationCommand } from '../../dev/history/commands/edit-application.command';
import { EditControlPropertyCommand } from '../../dev/history/commands/edit-control-property.command';
import { EditPageCommand } from '../../dev/history/commands/edit-page.command';
import { EditSignalIdentifierCommand } from '../../dev/history/commands/edit-signal-identifier.command';
import { RemoveControlCommand } from '../../dev/history/commands/remove-control.command';
import { RemovePageVariationCommand } from '../../dev/history/commands/remove-page-variation.command';
import { RemovePageCommand } from '../../dev/history/commands/remove-page.command';
import { UpdatePropertyCommand } from '../../dev/history/commands/update-property.command';

export interface Issue extends Problem {
  /**
   * Validator which produced the issue. Used internally.
   */
  validator: Validator;

  /**
   * The node that has the problem.
   */
  object: Validatable;
}

export class ApplicationValidator {
  private issuesMap = new Map<string, Issue[]>();
  private descendantSeverestProblem = new Map<Validatable, ProblemSeverity>();

  constructor(
    private application: Application,
    private validators: Validator[],
  ) {}

  /**
   * Returns the severity of the severest problem in the items subtree.
   * @param item
   * @returns The severity of the severest problem.
   */
  public getSeverestDescendantProblemSeverity(item: Validatable): ProblemSeverity | null {
    return this.descendantSeverestProblem.get(item) ?? null;
  }

  getAllIssues(severity?: ProblemSeverity): Issue[] {
    const issues = Array.from(this.issuesMap.values()).reduce((a, b) => a.concat(b), []);

    return severity != null ? issues.filter((issue) => issue.severity === severity) : issues;
  }

  getIssues(item: Validatable, severity?: ProblemSeverity, subObject?: string): Issue[] {
    let issues = this.issuesMap.get(item.id);

    if (issues == null) {
      return [];
    }

    if (subObject != null) {
      issues = issues.filter((issue) => issue.subObjects.includes(subObject));
    }
    if (severity != null) {
      issues = issues.filter((issue) => issue.severity === severity);
    }

    return issues;
  }

  public runAllValidators() {
    this.issuesMap.clear();
    this.validateApplication([
      'nodeProperties',
      'childrenProperties',
      'controlHierarchy',
      'dashboardHierarchy',
      'signals',
    ]);
    this.refreshDescendantMap();
  }

  private getHighestSeverity(issues: Issue[]): ProblemSeverity {
    for (const issue of issues) {
      if (issue.severity === 'error') {
        return 'error';
      }
    }
    return 'warning';
  }

  private refreshDescendantMap() {
    this.descendantSeverestProblem.clear();

    const setEntry = (node: Validatable, severity: ProblemSeverity) => {
      if (this.descendantSeverestProblem.has(node)) {
        if (this.descendantSeverestProblem.get(node) === 'warning' && severity === 'error') {
          this.descendantSeverestProblem.set(node, severity);
        }
      } else {
        this.descendantSeverestProblem.set(node, severity);
      }
    };

    this.issuesMap.forEach((issues: Issue[], _key: string) => {
      const severestProblem = this.getHighestSeverity(issues);

      let node = issues[0].object;
      while (node != null) {
        setEntry(node, severestProblem);
        node =
          node instanceof Root ? node.parentContainer : node instanceof PageVariation ? node.parentPage : node.parent;
      }
    });
  }

  private validateApplication(triggers: ValidationTrigger[]) {
    this.application.rootPage.children.forEach((page) => {
      traverseTree(page, (item) => {
        this.validateNode(item, triggers);
      });
    });
  }

  /**
   * @param node
   * @param triggers
   */
  private validateNode(node: Validatable, triggers: ValidationTrigger[]) {
    if (node instanceof Root) {
      // Optimization, lets just skip the Root.
      return;
    }

    const relevantValidators = this.validators.filter((validator) => {
      return validator.isRelevant(node, triggers);
    });

    relevantValidators.forEach((validator: Validator) => {
      this.clearIssues(node, validator);

      const problems = validator.validate(node, this.application);
      if (problems != null && problems.length > 0) {
        this.addIssues(node, validator, problems);
      }
    });
  }

  private clearIssues(item: Validatable, validator?: Validator) {
    if (this.issuesMap.has(item.id)) {
      const remainingIssues = this.issuesMap.get(item.id)!.filter((issue) => {
        return validator != null && issue.validator !== validator;
      });
      if (remainingIssues.length === 0) {
        this.issuesMap.delete(item.id);
      } else {
        this.issuesMap.set(item.id, remainingIssues);
      }
    }
  }

  private addIssues(node: Validatable, validator: Validator, problems: Problem[]) {
    const newIssues: Issue[] = problems.map((problem) => {
      return {
        severity: problem.severity,
        shortDescription: problem.shortDescription,
        longDescription: problem.longDescription,
        subObjects: problem.subObjects,
        validator,
        object: node,
      };
    });

    if (this.issuesMap.has(node.id)) {
      this.issuesMap.get(node.id)!.push(...newIssues);
    } else {
      this.issuesMap.set(node.id, newIssues);
    }
  }

  private isPropertyUpdateCommand(command: Command): Validatable | null {
    if (command instanceof EditControlPropertyCommand) {
      return command.data.control;
    } else if (command instanceof EditPageCommand) {
      return command.data.page;
    } else if (command instanceof UpdatePropertyCommand) {
      return command.data.property.parentNode;
    } else if (command instanceof AddControlCommand || command instanceof RemoveControlCommand) {
      // "Child count" is part of nodeProperties-trigger.
      const parentContainer = command.data.parentControl.parentRoot.parentContainer;
      const parentControl = command.data.parentControl;
      if (parentControl instanceof Root) {
        return parentContainer;
      } else {
        return parentControl;
      }
    } else if (command instanceof EditApplicationCommand) {
      return command.data.app.rootPage;
    }
    return null;
  }

  private isDeleteCommand(command: Command): Validatable | null {
    if (command instanceof RemoveControlCommand) {
      return command.data.removedControl;
    } else if (command instanceof RemovePageCommand) {
      return command.data.removedPage;
    } else if (command instanceof RemovePageVariationCommand) {
      return command.data.removedVariation;
    }
    return null;
  }

  private isCreateCommand(command: Command): Validatable | null {
    if (command instanceof AddPageCommand) {
      return command.data.page;
    } else if (command instanceof AddControlCommand) {
      return command.data.control;
    } else if (command instanceof AddPageVariationCommand) {
      return command.data.variation;
    }
    return null;
  }

  private isSignalCommand(command: Command): boolean {
    return command instanceof EditSignalIdentifierCommand;
  }

  private isPageHierarchyCommand(command: Command): boolean {
    return command instanceof RemovePageCommand || command instanceof AddPageCommand;
  }

  private isControlHierarchyCommand(command: Command): Page | PageVariation | null {
    if (command instanceof RemoveControlCommand || command instanceof AddControlCommand) {
      return command.data.parentControl.parentRoot.parentContainer;
    }
    return null;
  }

  private clearIssuesRecursive(root: Validatable) {
    traverseTree(root, (item) => {
      this.clearIssues(item);
    });
    this.refreshDescendantMap();
  }

  processCommand(command: Command, action: CommandAction) {
    const applicationWideTriggers: ValidationTrigger[] = [];
    const nodesToValidate: Map<Validatable, ValidationTrigger[]> = new Map<Validatable, ValidationTrigger[]>();

    const addNode = (item: Validatable, trigger: ValidationTrigger) => {
      if (nodesToValidate.has(item)) {
        if (!nodesToValidate.get(item)!.includes(trigger)) {
          nodesToValidate.get(item)!.push(trigger);
        }
      } else {
        nodesToValidate.set(item, [trigger]);
      }
    };

    const nodeUpdated = this.isPropertyUpdateCommand(command);
    if (nodeUpdated != null) {
      if (action === CommandAction.postApply || action === CommandAction.postUnapply) {
        if (
          // These are theme and signal properties, which are used throughout the whole app.
          nodeUpdated === this.application.rootPage ||
          nodeUpdated instanceof EnumSignal ||
          nodeUpdated instanceof NumericSignal ||
          nodeUpdated instanceof BooleanSignal
        ) {
          applicationWideTriggers.push('applicationWide');
        } else {
          addNode(nodeUpdated, 'nodeProperties');
          const parent = nodeUpdated instanceof PageVariation ? null : nodeUpdated.parent;
          if (parent != null) {
            addNode(parent, 'childrenProperties');
            if (parent instanceof Root && parent.parentContainer instanceof PageVariation) {
              addNode(parent.parentContainer.parentPage, 'childrenProperties');
            }
          }
          if (nodeUpdated instanceof CompositeControl) {
            nodeUpdated.children.forEach((child) => addNode(child, 'parentProperties'));
          }
          if (nodeUpdated instanceof PageVariation) {
            nodeUpdated.parentPage.variations.forEach((child) => addNode(child, 'nodeProperties'));
          }
        }
      }
    }

    const nodeCreated = this.isCreateCommand(command);
    if (nodeCreated != null) {
      if (action === CommandAction.postApply) {
        traverseTree(nodeCreated, (item) => {
          addNode(item, 'nodeProperties');
        });
        if (nodeCreated instanceof PageVariation) {
          nodeCreated.parentPage.variations.forEach((child) => addNode(child, 'nodeProperties'));
        }
      } else if (action === CommandAction.preUnapply) {
        this.clearIssuesRecursive(nodeCreated);
      } else if (action === CommandAction.postUnapply) {
        if (command instanceof AddPageVariationCommand) {
          command.data.parentPage.variations.forEach((child) => addNode(child, 'nodeProperties'));
        }
      }
    }

    const nodeDeleted = this.isDeleteCommand(command);
    if (nodeDeleted != null) {
      if (action === CommandAction.preApply) {
        this.clearIssuesRecursive(nodeDeleted);
      } else if (action === CommandAction.postApply) {
        if (command instanceof RemovePageVariationCommand) {
          command.data.parentPage.variations.forEach((child) => addNode(child, 'nodeProperties'));
        }
      } else if (action === CommandAction.postUnapply) {
        traverseTree(nodeDeleted, (item) => {
          addNode(item, 'nodeProperties');
        });
        if (nodeDeleted instanceof PageVariation) {
          nodeDeleted.parentPage.variations.forEach((child) => addNode(child, 'nodeProperties'));
        }
      }
    }

    const updatedPage = this.isControlHierarchyCommand(command);
    if (updatedPage != null) {
      if (action === CommandAction.postApply || action === CommandAction.postUnapply) {
        traverseTree(updatedPage, (item) => {
          addNode(item, 'controlHierarchy');
        });
      }
    }

    if (action === CommandAction.postApply) {
      if (this.isPageHierarchyCommand(command)) {
        applicationWideTriggers.push('dashboardHierarchy');
      }

      if (this.isSignalCommand(command)) {
        applicationWideTriggers.push('signals');
      }
    }

    nodesToValidate.forEach((triggers: ValidationTrigger[], node: Validatable) => {
      this.validateNode(node, triggers);
    });

    if (applicationWideTriggers.length > 0) {
      this.validateApplication(applicationWideTriggers);
    }

    if (applicationWideTriggers.length > 0 || nodesToValidate.size > 0) {
      this.refreshDescendantMap();
    }
  }
}
