import { Validatable } from './validator';
import { CompositeControl } from '../../../application/composite-control';
import { Page } from '../../../application/page';
import { PageVariation } from '../../../application/page-variation';

/**
 * Visits all descendants (and the root) and calls the provided functions.
 *
 * @param node The root to start the traversal from.
 * @param callback The function to call for every node.
 */
export function traverseTree(node: Validatable, callback: (item: Validatable) => void) {
  callback(node);
  if (node instanceof CompositeControl) {
    node.children.forEach((child) => traverseTree(child, callback));
  } else if (node instanceof Page) {
    traverseTree(node.controlRoot, callback);
    node.children.forEach((child) => traverseTree(child, callback));
    node.variations.forEach((child) => traverseTree(child, callback));
  } else if (node instanceof PageVariation) {
    traverseTree(node.controlRoot, callback);
  }
}
