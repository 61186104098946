import { ControlValidator } from './control.validator';
import { BooleanSignal } from '../../../../application/controls/boolean-signal';
import { Problem, Validatable, ValidationTrigger } from '../validator';

export class BooleanSignalValidator extends ControlValidator {
  public isRelevant(node: Validatable, triggers: ValidationTrigger[]): boolean {
    return (
      node instanceof BooleanSignal &&
      (triggers.includes('nodeProperties') || triggers.includes('signals') || triggers.includes('applicationWide'))
    );
  }

  public validate(control: BooleanSignal): Problem[] {
    return [
      ...this.validateControl(control),
      ...this.validateSignalId(control.signalId, control.humanReadableName, 'signalId', this.systemIndex),
      ...this.checkSignalTypeIsBool(control),
    ];
  }

  checkSignalTypeIsBool(control: BooleanSignal): Problem[] {
    if (control.signalId.signalType != null && control.signalId.signalType !== 'BOOL') {
      return [
        {
          severity: 'warning',
          shortDescription: 'Boolean Signal has non-boolean signal selected.',
          longDescription:
            'Boolean Signal has a non-boolean signal selected for it. Please only use boolean signals for Boolean Signal controls.',
          subObjects: ['signalId'],
        },
      ];
    }
    return [];
  }
}
