<div class="table-responsive">
  <table
    class="table table-layout-fixed"
    [class.table-no-rows-border]="!rowsBorder"
    [class.table-striped-odd]="strips === 'odd'"
    [class.table-striped-even]="strips === 'even'"
    [class.table-one-line-overflow]="ensureOneLine"
  >
    <ng-content></ng-content>
  </table>
</div>
