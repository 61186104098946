export type FormErrorTemplate = (context: { label: string; [key: string]: any }) => string;

/**
 * Error descriptor specifies how error with given `id` should be presented
 * to the user.
 */
export interface FormErrorDescriptor {
  id: string;
  template: FormErrorTemplate;
}

/**
 * Default error descriptors specify error messages for all basic form error
 * types. They are used by {@link FormErrorMessagePipe} to format error
 * messages. All common error types should a have item in this list. If error,
 * which is not in this list happens {@link unexpectedErrorDescriptor} will be
 * used to display it to user.
 */
export const defaultErrorDescriptors: FormErrorDescriptor[] = [
  {
    id: 'required',
    template: ({ label }) => `${label} is required.`,
  },
  {
    id: 'minlength',
    template: ({ label, requiredLength }) => `${label} must be at least ${requiredLength} characters long.`,
  },
  {
    id: 'maxlength',
    template: ({ label, requiredLength }) => `${label} must be at most ${requiredLength} characters long.`,
  },
  {
    id: 'email',
    template: ({ label }) => `${label} is not a well-formed email address.`,
  },
  {
    id: 'color',
    template: () => 'Invalid color. Please, specify color in RGB (e.g. #ff0000 or #00ff00).',
  },
  {
    id: 'wholeNumber',
    template: ({ label }) => `${label} must be a whole number.`,
  },
  {
    id: 'floatNumber',
    template: ({ label }) => `${label} must be a float number.`,
  },
  {
    id: 'fileType',
    template: ({ label, type }) => `${label} must be any of filetypes: ${type}.`,
  },
  {
    id: 'fileSize',
    template: ({ label, range }) => `${label} must have a file size between ${range} MB.`,
  },
  {
    id: 'illegalCharacter',
    template: ({ label }) => `${label} contains an illegal character.`,
  },
  {
    id: 'onlyOneValueInSet',
    template: ({ label }) => `${label} has just one entry. Please use "Equal To" or "Not Equal To" instead.`,
  },
  {
    id: 'formattedIncorrectly',
    template: ({ label }) => `${label} is formatted incorrectly.`,
  },
  {
    id: 'F000002',
    template: ({ label }) => `${label} is required.`,
  },
  {
    id: 'F000003',
    template: ({ label }) => `${label} is required.`,
  },
  {
    id: 'F000004',
    template: ({ label, min, max }) => `${label} length must be between ${min} and ${max} characters.`,
  },
  {
    id: 'F000005',
    template: ({ label }) => `${label} is not a well-formed email address.`,
  },
  {
    id: 'F000101',
    template: () => 'Firmware name must be unique. Provided name is already used by another firmware in the package.',
  },
  {
    id: 'F000102',
    template: () => 'System name must be unique. Provided name is already used by another system in the group.',
  },
  {
    id: 'F000103',
    template: () => 'Package name must be unique. Provided name is already used by another package in the group.',
  },
  {
    id: 'F000104',
    template: () =>
      'Application name must be unique. Provided name is already used by another application in the package.',
  },
  {
    id: 'F000105',
    template: () =>
      'Name and version pair must be unique. Provided name and version pair is already ' +
      'used by another package release in the group.',
  },
  {
    id: 'F000117',
    template: () => 'Group name must be unique. Provided name is already used by another group.',
  },
  {
    id: 'F000202',
    template: () => 'Package does not exist.',
  },
  {
    id: 'F000203',
    template: () => 'Role does not exist.',
  },
  {
    id: 'F000205',
    template: () => 'System does not exist.',
  },
  {
    id: 'F000207',
    template: () => 'Medium does not exist.',
  },
  {
    id: 'F000208',
    template: () => 'System is already used by another package.',
  },
  {
    id: 'F000211',
    template: () => 'Invalid medium type.',
  },
  {
    id: 'F000212',
    template: () => 'Uploaded image format is not supported. Should be JPEG, PNG, GIF or SVG file.',
  },
  {
    id: 'F000213',
    template: () => 'Firmware binary format is not supported. Should be MLHX file.',
  },
  {
    id: 'F000215',
    template: () => 'Group does not exist.',
  },
  {
    id: 'F000302',
    template: () => 'Using @-character in group name is prohibited.',
  },
  {
    id: 'F000303',
    template: () => 'Document name must be unique. Provided name is already used by another document in the package.',
  },
  {
    id: 'F000304',
    template: () => 'Document binary format is not supported. Should be Word, Excel, PowerPoint or PDF file.',
  },
  {
    id: 'R000005',
    template: () => 'Form contains invalid data. Fix errors and try again.',
  },
];

/**
 * Error descriptor to be used if there is not more concrete descriptor
 * provided.
 */
export const unexpectedErrorDescriptor: FormErrorDescriptor = {
  id: '',
  template: ({ label }) => `${label} has invalid value.`,
};
