import { Property } from './property';
import { parseIntSafe } from '../utils/number';

/**
 * Contains specification of a screen type.
 */
export class ScreenType {
  id: string;

  readonly name = new Property<string>(null, { guard: (raw) => (raw !== '' ? raw : 'Unnamed') });

  readonly minWidth = new Property<string, string, number>(null, {
    guard: (raw) => (/^[0-9]+du$/.test(raw) ? raw : '0du'),
    parse: (safe) => parseIntSafe(safe.slice(0, -2)),
  });

  readonly orientation = new Property<Orientation>(null);

  constructor({ id, name = 'Unnamed', minWidth = '0du', orientation = 'any' }: ScreenOptions) {
    this.id = id;
    this.name.set(name);
    this.minWidth.set(minWidth);
    this.orientation.set(orientation);
  }

  matches(width: number, height: number) {
    return (
      width >= this.minWidth.parsed &&
      ((this.orientation.parsed === 'landscape' && width > height) ||
        (this.orientation.parsed === 'portrait' && width <= height) ||
        this.orientation.parsed === 'any')
    );
  }
}

export interface ScreenOptions {
  id: string;
  name?: string;
  minWidth?: string;
  orientation?: Orientation;
}

export type Orientation = 'landscape' | 'portrait' | 'any';
