import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { FormErrorDescriptor } from '../validation/error-descriptors';

let nextId = 0;

/**
 * Component represents a "frame" for the control. This frame includes layout,
 * label with required indicator and logic for error visualization.
 *
 * For common controls there are dedicated components, which when nested
 * inside frame will automatically get control assigned to the frame and some
 * other parameters (depending on the control type). See
 * {@link FormControlTextComponent}, {@link FormControlFileComponent},
 * {@link FormControlSelectComponent}, etc.
 *
 * In cases when more complex control is needed, the implementation can be
 * done in place. It's especially convenient, when re-usability is not a
 * concern - no need to create extra component.
 *
 * Also it's possible to use frame without control. For example when there is a
 * need to display some static data in the same structure as the rest of the
 * form. In such cases use this component, but don't set `control` property.
 */
@Component({
  selector: 'devapp-form-control-frame',
  templateUrl: './form-control-frame.component.html',
  styleUrls: ['./form-control-frame.component.scss'],
})
export class FormControlFrameComponent<T extends AbstractControl = AbstractControl> {
  /**
   * Control to be framed.
   *
   * Frame can be used to represent arbitrary data in the same structure as
   * form controls. In such cases this property should be skipped.
   */
  @Input()
  control: T;

  /**
   * Human-readable label to display.
   */
  @Input()
  label = '';

  /**
   * Allows to customize labels width.
   * Any valid value for CSS width is accepted.
   */
  @Input()
  labelWidth = '128px';

  /**
   * Enable stacked form ( label on the top )
   */
  @Input()
  stacked = false;

  /**
   * If control is required. Defines if 'required' indicator should be
   * displayed next to the label.
   */
  @Input()
  required = false;

  /**
   * ID (https://developer.mozilla.org/en/docs/Web/API/Element/id) of the
   * input element. It is used as `for` attribute of <label> to focus input
   * on the label click. By default value is generated and actual control
   * should use generated value, but it can also be customized if needed.
   *
   * Generated example:
   *
   * <devapp-form-control-frame #control [control]="myControl" [label]="'Name'">
   *   <input type="text" class="form-control" [id]="control.inputId" [formControl]="myControl">
   * </devapp-form-control-frame>
   *
   * Custom example:
   *
   * <devapp-form-control-frame [control]="myControl" inputId="customId" [label]="'Name'">
   *   <input type="text" class="form-control" id="customId" [formControl]="myControl">
   * </devapp-form-control-frame>
   */
  @Input()
  inputId = `control-${nextId++}`;

  /**
   * Custom error descriptors. Allows to customize default error messages (@see
   * {@link defaultErrorDescriptors}) for this control. Error descriptor
   * specified here will always take precedence over default ones if their ids
   * match.
   */
  @Input()
  errorDescriptors: FormErrorDescriptor[];

  get isErrorVisible(): boolean {
    return this.control != null && this.control.dirty && this.control.invalid;
  }
}
