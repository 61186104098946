import { Control } from '../../../../application/control';
import { VisibilityCondition } from '../../../../application/controls/visibility';
import {
  ArraySignalIdentifier,
  ClientDeviceSignalSource,
  EcuSignalSource,
  SignalIdentifier,
} from '../../../../application/signal-identifier';
import { SystemIndex } from '../../../dev/system-index';
import { Problem, Validator } from '../validator';

export abstract class ControlValidator extends Validator {
  systemIndex: SystemIndex;

  constructor(systemIndex: SystemIndex) {
    super();
    this.systemIndex = systemIndex;
  }

  protected validateControl(control: Control): Problem[] {
    const allProblems: Problem[] = [];
    const controlName = control.humanReadableName;

    let problem: Problem | null = this.checkDimension(control.height, controlName, 'height');
    if (problem != null) {
      allProblems.push(problem);
    }

    problem = this.checkDimension(control.width, controlName, 'width');
    if (problem != null) {
      allProblems.push(problem);
    }

    problem = this.checkMargin(control.margin.top, control.marginTop, controlName, 'margin.top', 'top');
    if (problem != null) {
      allProblems.push(problem);
    }

    problem = this.checkMargin(control.margin.bottom, control.marginBottom, controlName, 'margin.bottom', 'bottom');
    if (problem != null) {
      allProblems.push(problem);
    }

    problem = this.checkMargin(control.margin.left, control.marginLeft, controlName, 'margin.left', 'left');
    if (problem != null) {
      allProblems.push(problem);
    }

    problem = this.checkMargin(control.margin.right, control.marginRight, controlName, 'margin.right', 'right');
    if (problem != null) {
      allProblems.push(problem);
    }

    allProblems.push(
      ...this.checkVisibility(control.visibility.conditions, control.visibility.aggregator, controlName),
    );

    return allProblems;
  }

  private checkMargin(
    value: string,
    safeValue: string,
    controlName: string,
    propertyName: string,
    marginType: string,
  ): Problem | null {
    if (value !== safeValue) {
      return {
        severity: 'error',
        shortDescription: `${controlName} ${marginType} margin is invalid.`,
        longDescription:
          `${controlName} ${marginType} margin is invalid. ` +
          `The value should be a number with a 'du' suffix, for example '2du'.`,
        subObjects: [`${propertyName}`],
      };
    }
    return null;
  }

  private incorrectDimensionMessage(controlName: string, propertyName: string): Problem {
    return {
      severity: 'error',
      shortDescription: `${controlName} ${propertyName} is invalid.`,
      longDescription:
        `${controlName} ${propertyName} is invalid. ` +
        `Examples of valid values: 'Match parent', 'Wrap content', '10du', '2we'.`,
      subObjects: [`${propertyName}`],
    };
  }

  private checkDimension(value: string, controlName: string, propertyName: string): Problem | null {
    const numberRegExp = /^[1-9]\d*$/;

    if (value == null || value.length === 0) {
      return {
        severity: 'error',
        shortDescription: `${controlName} ${propertyName} can not be empty.`,
        longDescription: `${controlName} ${propertyName} can not be empty. Please specify a value.`,
        subObjects: [`${propertyName}`],
      };
    } else if (value === 'matchParent') {
      // ok
    } else if (value === 'wrapContent') {
      // ok
    } else if (value.endsWith('du')) {
      if (!numberRegExp.test(value.substring(0, value.indexOf('du')))) {
        return this.incorrectDimensionMessage(controlName, propertyName);
      }
    } else if (value.includes('we')) {
      if (!numberRegExp.test(value.substring(0, value.indexOf('we')))) {
        return this.incorrectDimensionMessage(controlName, propertyName);
      }
    } else {
      return this.incorrectDimensionMessage(controlName, propertyName);
    }
    return null;
  }

  private checkVisibility(conditions: VisibilityCondition[], aggregator: 'or' | 'and', controlName: string): Problem[] {
    const problems: Problem[] = [];

    if (aggregator == null) {
      return [
        {
          severity: 'error',
          shortDescription: `${controlName} visibility aggregator can not be empty.`,
          longDescription: `${controlName} visibility aggregator can not be empty. Please specify a value.`,
          subObjects: ['visibility'],
        },
      ];
    } else if (aggregator !== 'or' && aggregator !== 'and') {
      return [
        {
          severity: 'error',
          shortDescription: `${controlName} visibility aggregator has an invalid value.`,
          longDescription: `${controlName} visibility aggregator has an invalid value. Please specify a correct value.`,
          subObjects: ['visibility'],
        },
      ];
    }

    conditions.forEach((condition) => {
      if (condition.operator == null || condition.operator === 'none') {
        problems.push({
          severity: 'error',
          shortDescription: `${controlName} visibility operator can not be empty.`,
          longDescription: `${controlName} visibility operator can not be empty. Please specify a value.`,
          subObjects: ['visibility'],
        });
        return;
      }

      problems.push(...this.validateSignalId(condition.reference, controlName, 'visibility', this.systemIndex));

      if (
        condition.operator !== 'contains' &&
        condition.operator !== 'doesNotContain' &&
        condition.operator !== 'equalTo' &&
        condition.operator !== 'greaterThan' &&
        condition.operator !== 'greaterThanOrEqualTo' &&
        condition.operator !== 'inSet' &&
        condition.operator !== 'lessThan' &&
        condition.operator !== 'lessThanOrEqualTo' &&
        condition.operator !== 'notEqualTo' &&
        condition.operator !== 'notInSet'
      ) {
        problems.push({
          severity: 'error',
          shortDescription: `${controlName} visibility operator has an invalid value.`,
          longDescription: `${controlName} visibility operator has an invalid value. Please specify a correct value.`,
          subObjects: ['visibility'],
        });
        return;
      }

      if (condition.operator === 'inSet' || condition.operator === 'notInSet') {
        if (typeof condition.comparator !== 'string') {
          problems.push({
            severity: 'error',
            shortDescription: `${controlName} visibility comparator has an invalid value.`,
            longDescription: `${controlName} visibility comparator has an invalid value. Please specify a correct value.`,
            subObjects: ['visibility'],
          });
          return;
        } else {
          if (condition.comparator.length === 0) {
            problems.push({
              severity: 'error',
              shortDescription: `${controlName} visibility comparator set has no values.`,
              longDescription: `${controlName} visibility comparator set has no values. Please specify a value.`,
              subObjects: ['visibility'],
            });
            return;
          }

          if (/^[\d,:-]+$/.test(condition.comparator) === false) {
            problems.push({
              severity: 'error',
              shortDescription: `${controlName} visibility comparator set has illegal characters.`,
              longDescription: `${controlName} visibility comparator set has illegal characters. Please remove the illegal characters.`,
              subObjects: ['visibility'],
            });
            return;
          }

          if (/^-*\d+,*$/.test(condition.comparator) === true) {
            problems.push({
              severity: 'error',
              shortDescription: `${controlName} visibility comparator set is set to only one value.`,
              longDescription: `${controlName} visibility comparator set is set to only one value. Please change the operator to not be In Set or Not In Set.`,
              subObjects: ['visibility'],
            });
            return;
          }

          if (
            /^(?:(?:-*\d+,)|(?:-*\d+:-*\d*,*))(?:(?:-*\d+,*)|(?:-*\d+:-*\d*,*))+$/.test(condition.comparator) === false
          ) {
            problems.push({
              severity: 'error',
              shortDescription: `${controlName} visibility comparator set has improper formatting.`,
              longDescription: `${controlName} visibility comparator set has improper formatting. Please make sure it is in the format from:to,value.`,
              subObjects: ['visibility'],
            });
            return;
          }

          if (
            condition.comparator.split(',').some((value) => {
              if (value.includes(':')) {
                const range = value.split(':');
                const min = parseInt(range[0]);
                const max = parseInt(range[1]);

                return isNaN(min) || isNaN(max) || min >= max;
              }
              return isNaN(parseInt(value));
            })
          ) {
            problems.push({
              severity: 'error',
              shortDescription: `${controlName} visibility comparator set has invalid values.`,
              longDescription: `${controlName} visibility comparator set has invalid values. Please specify correct values.`,
              subObjects: ['visibility'],
            });
            return;
          }
        }
      }

      if (
        condition.reference.signalType.startsWith('STRING') &&
        condition.operator !== 'contains' &&
        condition.operator !== 'doesNotContain' &&
        condition.operator !== 'equalTo' &&
        condition.operator !== 'notEqualTo'
      ) {
        {
          problems.push({
            severity: 'error',
            shortDescription: `${controlName} visibility operator cannot be used on this signal type.`,
            longDescription: `${controlName} visibility operator cannot be used on this signal type. Please specify another one.`,
            subObjects: ['visibility'],
          });
          return;
        }
      }

      if (condition.comparator instanceof SignalIdentifier) {
        if (
          condition.comparator.signalType.startsWith('STRING') &&
          condition.operator !== 'contains' &&
          condition.operator !== 'doesNotContain' &&
          condition.operator !== 'equalTo' &&
          condition.operator !== 'notEqualTo'
        ) {
          {
            problems.push({
              severity: 'error',
              shortDescription: `${controlName} visibility operator cannot be used on this signal type.`,
              longDescription: `${controlName} visibility operator cannot be used on this signal type. Please specify another one.`,
              subObjects: ['visibility'],
            });
            return;
          }
        }

        problems.push(...this.validateSignalId(condition.comparator, controlName, 'visibility', this.systemIndex));

        if (
          (condition.reference.signalType.startsWith('STRING') &&
            !condition.comparator.signalType.startsWith('STRING')) ||
          (!condition.reference.signalType.startsWith('STRING') && condition.comparator.signalType.startsWith('STRING'))
        ) {
          problems.push({
            severity: 'error',
            shortDescription: `${controlName} visibility comparator and reference are of different signal types.`,
            longDescription: `${controlName} visibility comparator and reference are of different signal types. Please specify a different signal for comparison.`,
            subObjects: ['visibility'],
          });
          return;
        }
      } else if (condition.reference.signalType.startsWith('STRING') && typeof condition.comparator != 'string') {
        problems.push({
          severity: 'error',
          shortDescription: `${controlName} visibility comparator and reference value are of different types.`,
          longDescription: `${controlName} visibility comparator and reference value are of different types. Please specify a different value for comparison.`,
          subObjects: ['visibility'],
        });
        return;
      }
    });

    return problems;
  }

  protected validateSignalId(
    signalId: SignalIdentifier,
    humanReadableName: string,
    subObject: string,
    systemIndex: SystemIndex,
  ): Problem[] {
    if (
      signalId.source == null ||
      (signalId.source instanceof EcuSignalSource &&
        (signalId.source.ecuId == null || signalId.source.signalName == null)) ||
      (signalId.source instanceof ClientDeviceSignalSource && signalId.source.signalName == null)
    ) {
      return [
        {
          severity: 'error',
          shortDescription: `${humanReadableName} has no signal specified.`,
          longDescription:
            `${humanReadableName} has no signal specified. ` +
            `Please choose a signal using ${humanReadableName} Properties pane.`,
          subObjects: [subObject],
        },
      ];
    }

    const signalSpecification = systemIndex.getSignalSpecification(signalId);
    if (signalSpecification == null) {
      return [
        {
          severity: 'error',
          shortDescription: `${humanReadableName} references non-existing signal.`,
          longDescription:
            `${humanReadableName} references non-existing signal. ` +
            `Please choose a correct signal using ${humanReadableName} Properties pane.`,
          subObjects: [subObject],
        },
      ];
    }

    if (signalSpecification.dimensions != null) {
      if (
        !(
          signalId instanceof ArraySignalIdentifier &&
          signalId.index != null &&
          signalSpecification.dimensions[0] <= signalId.index &&
          signalId.index <= signalSpecification.dimensions[1]
        )
      ) {
        return [
          {
            severity: 'error',
            shortDescription: `${humanReadableName} references non-existing signal.`,
            longDescription:
              `${humanReadableName} references non-existing signal. Array index is out of range. ` +
              `Please choose valid array index using ${humanReadableName} Properties pane.`,
            subObjects: [subObject],
          },
        ];
      }
    }

    const problems: Problem[] = [];
    if (
      signalId.scale.multiplier !== signalId.scale.safeMultiplier ||
      signalId.scale.offset !== signalId.scale.safeOffset
    ) {
      problems.push({
        severity: 'error',
        shortDescription: `${humanReadableName} has incorrectly configured scale for the linked signal.`,
        longDescription: `${humanReadableName} has incorrectly configured scale for the linked signal.`,
        subObjects: [subObject],
      });
    }

    if (signalId.formatter.fractionDigits !== signalId.formatter.safeFractionDigits) {
      problems.push({
        severity: 'error',
        shortDescription: `${humanReadableName} has incorrectly configured format for the linked signal.`,
        longDescription: `${humanReadableName} has incorrectly configured format for the linked signal.`,
        subObjects: [subObject],
      });
    }

    return problems;
  }
}
