import { ControlOptions } from '../control';
import { SignalIdentifier } from '../signal-identifier';
import { SimpleControl } from '../simple-control';

export class Text extends SimpleControl {
  text: string;
  align: TextAlign;
  signals: SignalBinding[];

  readonly minimumWidth = 1;
  readonly minimumHeight = 1;

  readonly humanReadableName = 'Text';

  constructor({ text = 'Text', align = 'left', signals = [], ...options }: TextOptions) {
    super(options);

    this.text = text;
    this.align = align;
    this.signals = signals;
  }

  getUsedSignalIDs(): string[] {
    return [];
  }

  getSignalPlaceholders(): string[] {
    const matches = this.text.match(/%\w+\$/g);
    return matches != null ? matches.map((s) => s.slice(1, -1)) : [];
  }
}

export interface TextOptions extends ControlOptions {
  text?: string;
  align?: TextAlign;
  signals?: SignalBinding[];
}

export class SignalBinding {
  signalId: SignalIdentifier;
  variableName: string;

  constructor(options: SignalBindingOptions) {
    this.signalId = options.signalId;
    this.variableName = options.variableName;
  }

  formatValue(value: number): string {
    const scaledValue = this.signalId.scale.apply(value);

    const option = this.signalId.options.find((option) => option.value === scaledValue);
    if (option != null) {
      return option.name;
    } else {
      const formattedValue = this.signalId.formatter.format(scaledValue);
      return this.signalId.unit === '' ? formattedValue : `${formattedValue}${this.signalId.unit}`;
    }
  }
}

export interface SignalBindingOptions {
  signalId: SignalIdentifier;
  variableName: string;
}

export type TextAlign = 'left' | 'center' | 'right' | 'justify';
