import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ToastComponent } from './toast/toast.component';
import { ToastContainerComponent } from './toast-container/toast-container.component';

@NgModule({
  imports: [CommonModule, FontAwesomeModule],
  declarations: [ToastContainerComponent, ToastComponent],
  exports: [ToastContainerComponent],
})
export class ToasterModule {}
