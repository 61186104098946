import { ClientDeviceSignalType } from '../host/dev/client-device';

export type SignalType = 'BOOL' | 'U8' | 'U16' | 'U32' | 'S8' | 'S16' | 'S32' | `STRING[${number}]`;
export class SignalIdentifier {
  /**
   * Type of the signal.
   *
   * Note that signal type will always be null in applications older than
   * 0.2.9. Always take this into account when using this property from
   * the STAppRunner. DevApp will populate missing types as part of DevHost
   * initialization.
   * TODO: Remove the above disclaimer once support for <0.2.9 is dropped
   */
  signalType: SignalType | null;
  unit: string;
  options: SignalOption[];
  scale: SignalScale;
  formatter: SignalFormatter;
  source: EcuSignalSource | ClientDeviceSignalSource;
  /**
   * We need to be able to check that values are within range without
   * actually changing the scale values, so use a temporary SignalScale
   * for live checks.
   */
  private temporaryScale = new SignalScale();

  constructor({
    signalType = null,
    unit = '',
    options = [],
    scale = new SignalScale(),
    formatter = new SignalFormatter(),
    source = null,
  }: SignalIdentifierOptions = {}) {
    this.signalType = signalType;
    this.unit = unit;
    this.options = options;
    this.scale = scale;
    this.formatter = formatter;
    this.source = source;
  }

  asString(): string | null {
    if (this.source == null) {
      return null;
    }

    if (this.source instanceof EcuSignalSource) {
      if (this.source.signalName != null) {
        if (this.source.ecuId != null) {
          return `${this.source.ecuId}.${this.source.signalName}`;
        } else if (this.source.ecuFullAddress != null) {
          return `${this.source.ecuFullAddress}.${this.source.signalName}`;
        }
      }
    } else if (this.source instanceof ClientDeviceSignalSource) {
      if (this.source.signalName != null) {
        return `client-device.${this.source.signalName}`;
      }
    }
    return null;
  }

  getCommonName(): string | null {
    return this.asString();
  }

  getRange(multiplier?: number, offset?: number): number[] {
    this.temporaryScale.multiplier = multiplier ?? this.scale.multiplier;
    this.temporaryScale.offset = offset ?? this.scale.offset;

    switch (this.signalType) {
      case 'U8':
        return [this.temporaryScale.apply(0), this.temporaryScale.apply(255)];
      case 'U16':
        return [this.temporaryScale.apply(0), this.temporaryScale.apply(65535)];
      case 'U32':
        return [this.temporaryScale.apply(0), this.temporaryScale.apply(4294967295)];
      case 'S8':
        return [this.temporaryScale.apply(-128), this.temporaryScale.apply(127)];
      case 'S16':
        return [this.temporaryScale.apply(-32768), this.temporaryScale.apply(32767)];
      case 'S32':
        return [this.temporaryScale.apply(-2147483648), this.temporaryScale.apply(2147483647)];
      case 'BOOL':
        return [this.temporaryScale.apply(0), this.temporaryScale.apply(1)];
      default:
        return [0, 0];
    }
  }

  getStringLength(): number | null {
    if (this.signalType == null) {
      return null;
    }

    if (this.signalType.startsWith('STRING')) {
      const match = this.signalType.match(/\[(\d+)]/);
      if (match != null) {
        return parseInt(match[1], 10);
      }
    }
    return null;
  }
}

export interface SignalIdentifierOptions {
  signalType?: SignalType | null;
  unit?: string;
  options?: SignalOption[];
  scale?: SignalScale;
  formatter?: SignalFormatter;
  source?: EcuSignalSource | ClientDeviceSignalSource;
}

export class ArraySignalIdentifier extends SignalIdentifier {
  index: number | null;

  constructor({ index = 0, ...options }: ArraySignalIdentifierOptions) {
    super(options);
    this.index = index;
  }

  override asString() {
    const baseString = super.asString();

    if (this.index == null || baseString == null) {
      return null;
    }

    return baseString.replace('[x]', `[${this.index}]`);
  }

  override getCommonName(): string | null {
    return super.asString();
  }
}

export interface ArraySignalIdentifierOptions extends SignalIdentifierOptions {
  index: number | null;
}

export interface SignalOption {
  name: string;
  value: number;
}

export class SignalScale {
  multiplier: number;
  offset: number;

  get safeMultiplier() {
    return typeof this.multiplier === 'number' && !isNaN(this.multiplier) ? this.multiplier : 1;
  }

  get safeOffset() {
    return typeof this.offset === 'number' && !isNaN(this.offset) ? this.offset : 0;
  }

  constructor({ multiplier = 1, offset = 0 }: SignalScaleOptions = {}) {
    this.multiplier = multiplier;
    this.offset = offset;
  }

  apply(value: number) {
    return value * this.safeMultiplier + this.safeOffset;
  }

  /**
   * Unapplies scale and rounds value to nearest integer.
   *
   * ECUs can only work with integer values and float math is not precise, so
   * we always round result.
   *
   * TODO: Rounding can produce unexpected results, with some values. Come up with better approach.
   *
   * @param value - Original signal value.
   * @returns Scaled signal value.
   */
  unapply(value: number) {
    return Math.round((value - this.safeOffset) / this.safeMultiplier);
  }
}

export interface SignalScaleOptions {
  multiplier?: number;
  offset?: number;
}

export type FractionLimiting = 'round' | 'truncate';

export class SignalFormatter {
  fractionDigits: number;
  fractionLimiting: FractionLimiting;

  get safeFractionDigits() {
    return Number.isInteger(this.fractionDigits) && this.fractionDigits >= 0 ? this.fractionDigits : 0;
  }

  constructor({ fractionDigits = 0, fractionLimiting = 'round' }: SignalFormatterOptions = {}) {
    this.fractionDigits = fractionDigits;
    this.fractionLimiting = fractionLimiting;
  }

  format(value: number | string): string {
    if (typeof value === 'string') {
      return value;
    }

    // Number.toFixed() method always rounds value. The code below ensures that
    // number always rounds to the smaller, thus leading to the truncate
    // behavior.
    if (this.fractionLimiting === 'truncate') {
      const tmp = (value * 10 ** this.safeFractionDigits) % 1;
      if (tmp >= 0.5) {
        value -= 0.5 / 10 ** this.safeFractionDigits;
      } else if (tmp <= -0.5) {
        value += 0.5 / 10 ** this.safeFractionDigits;
      }
    }

    return value.toFixed(this.safeFractionDigits);
  }
}

export interface SignalFormatterOptions {
  fractionDigits?: number;
  fractionLimiting?: FractionLimiting;
}

export abstract class SignalSource {
  signalName: string | null;
  constructor({ signalName = null }: SignalSourceOptions = {}) {
    this.signalName = signalName;
  }
}

export interface SignalSourceOptions {
  signalName?: string | null;
}

export class EcuSignalSource extends SignalSource {
  /** @deprecated - this is kept to support applications older than 0.2.4 */
  ecuFullAddress: number | null;
  ecuId: string | null;
  constructor({ ecuFullAddress = null, ecuId = null, ...options }: EcuSignalSourceOptions = {}) {
    super(options);
    this.ecuFullAddress = ecuFullAddress;
    this.ecuId = ecuId;
  }
}

export interface EcuSignalSourceOptions extends SignalSourceOptions {
  /** @deprecated */
  ecuFullAddress?: number | null;
  ecuId?: string | null;
}

export class ClientDeviceSignalSource extends SignalSource {
  override signalName: ClientDeviceSignalType | null;
  constructor(options: ClientDeviceSignalSourceOptions) {
    super(options);
  }
}

export interface ClientDeviceSignalSourceOptions extends SignalSourceOptions {
  signalName?: ClientDeviceSignalType;
}
