import { ControlVisitor } from './control-visitor';
import { Control } from '../application/control';
import { BarChart } from '../application/controls/bar-chart';
import { BooleanSignal } from '../application/controls/boolean-signal';
import { Button, SetPulseButtonAction } from '../application/controls/button';
import { EnumSignal } from '../application/controls/enum-signal';
import { Gauge } from '../application/controls/gauge';
import { LineChart } from '../application/controls/line-chart';
import { NumericSignal } from '../application/controls/numeric-signal';
import { StringSignal } from '../application/controls/string-signal';
import { Text } from '../application/controls/text';
import { SignalIdentifier } from '../application/signal-identifier';
import { SystemIndex } from '../host/dev/system-index';

export class MigrateSignalTypeVisitor extends ControlVisitor {
  constructor(private systemIndex: SystemIndex) {
    super();
  }

  visitControl(control: Control): void {
    if (control instanceof LineChart) {
      control.lines.forEach((line) => this.migrate(line.signalId));
    } else if (control instanceof BarChart) {
      control.bars.forEach((bar) => this.migrate(bar.signalId));
    } else if (control instanceof BooleanSignal) {
      this.migrate(control.signalId);
    } else if (control instanceof EnumSignal) {
      this.migrate(control.signalId);
    } else if (control instanceof Gauge) {
      this.migrate(control.signalId);
    } else if (control instanceof NumericSignal) {
      this.migrate(control.signalId);
    } else if (control instanceof Button && control.action instanceof SetPulseButtonAction) {
      this.migrate(control.action.signalId);
    } else if (control instanceof Text) {
      for (const link of control.signals) {
        this.migrate(link.signalId);
      }
    } else if (control instanceof StringSignal) {
      this.migrate(control.signalId);
    }
  }

  private migrate(signalId: SignalIdentifier) {
    const specification = this.systemIndex.getSignalSpecification(signalId);
    if (specification != null) {
      signalId.signalType = specification.type;
    }
  }
}
