import { CompositeControl } from '../../../../application/composite-control';
import { Control } from '../../../../application/control';
import { Command } from '../command';

export class AddControlCommand implements Command {
  constructor(public data: Data) {}

  apply(): void {
    this.data.parentControl.insert(this.data.control, this.data.position);
  }

  unapply(): void {
    this.data.parentControl.remove(this.data.position);
  }
}

interface Data {
  parentControl: CompositeControl;
  control: Control;
  position: number;
}
