export type ResponseErrorCode =
  | 'R000001' // Invalid credentials provided.
  | 'R000002' // Internal server error.
  | 'R000003' // Operation is forbidden.
  | 'R000004' // Request contains empty body.
  | 'R000005' // Validation failed.
  | 'R000006' // Entity not found.
  | 'R000007' // Offset is larger than total count.
  | 'R000009' // Field operator in filter parameter is not correct.
  | 'R000102' // Only one Owner is allowed for a group and this group already has one.
  | 'R000206' // Current user is not allowed to assign role with same or higher tier than their own.
  | 'R000401' // Unable to delete system. It is used by the package.
  | 'R000408' // Unable to create package release. Package doesn't have any contents (applications, firmware).
  | 'R000409' // Unable to create package release.
  // Package contents (applications, firmware) haven't changed since previous release.
  | 'R000410' // Unable to create package matching. System specification is not valid.
  | 'R000414' // The user is already a member of this group.
  | 'R000415' // User is not allowed to be Owner in more than one group.
  | 'R000419' // Requires active Professional license.
  | 'R000420' // Current user does not have a valid Professional license.
  | 'R000422' // Updating this application will overwrite changes made elsewhere.
  | 'R000423' // Only one Owner is allowed for a group and this group already has invitation for an Owner.
  | 'R000424' // The user is already invited to this group.
  | 'R000425' // Resource was removed and no longer available. Migrate to the latest API.
  | 'R000426' // Private groups can not have members.
  | 'R000427' // Private groups can not have sub-groups.
  | 'R000428' // Group owner is above content limits imposed by their PLUS+1 licenses.
  | 'R000429' // Group owner will exceed content limits imposed by their PLUS+1 licenses after this change.
  | 'R000430' // Group does not have owner.
  | 'R000431'; // Group is archived.

/**
 * Error messages map, which maps response error code into message, which are
 * displayed to the user.
 */
export const responseErrorMessages = new Map<ResponseErrorCode, string>();
responseErrorMessages.set('R000001', 'Invalid credentials provided.');
responseErrorMessages.set('R000003', 'Permission denied.');
responseErrorMessages.set('R000005', 'Validation failed.');
responseErrorMessages.set('R000006', 'Item not found.');
responseErrorMessages.set('R000102', 'Only one Owner is allowed for a group and this group already has one.');
responseErrorMessages.set('R000401', 'Unable to delete system. It is used by the package.');
responseErrorMessages.set('R000408', 'Release should have at least one application or firmware included.');
responseErrorMessages.set('R000409', 'Release contents is identical to the previous release.');
responseErrorMessages.set('R000414', 'The user is already a member of this group.');
responseErrorMessages.set('R000415', 'Selected user does not have an available group ownership.');
responseErrorMessages.set('R000419', 'Only a user with a valid Professional License can be assigned this role.');
responseErrorMessages.set(
  'R000423',
  'Only one owner is allowed for a group and this group already has a pending invitation for an owner.',
);
responseErrorMessages.set('R000424', 'The user is already invited to this group.');
responseErrorMessages.set(
  'R000429',
  'The group will exceed content limits after this change. ' +
    'Please contact your group owner to purchase more resources.',
);

/**
 * Default error message, which is used, when API returned unexpected code or
 * when there is no specific message for the error set in
 * {@link responseErrorMessages}.
 */
export const UNEXPECTED_ERROR_MESSAGE =
  'Unexpected error happened. Please try again later, if error persists contact your system provider.';
