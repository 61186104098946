import { ControlValidator } from './control.validator';
import { LineChart } from '../../../../application/controls/line-chart';
import { Problem, Validatable, ValidationTrigger } from '../validator';

export class LineChartValidator extends ControlValidator {
  public isRelevant(node: Validatable, triggers: ValidationTrigger[]): boolean {
    return (
      node instanceof LineChart &&
      (triggers.includes('nodeProperties') || triggers.includes('signals') || triggers.includes('applicationWide'))
    );
  }

  public validate(chart: LineChart): Problem[] {
    const allProblems: Problem[] = super.validateControl(chart);

    let problem = this.validateMinMax(chart);
    if (problem != null) {
      allProblems.push(problem);
    }

    allProblems.push(...this.validateLines(chart));

    problem = this.validateNumberOfValues(chart);
    if (problem != null) {
      allProblems.push(problem);
    }

    return allProblems;
  }

  private validateLines(chart: LineChart): Problem[] {
    if (chart.lines.length === 0) {
      return [
        {
          severity: 'warning',
          shortDescription: 'Line Chart has no signals.',
          longDescription:
            "Line Chart does not have associated signals and won't display any data. " +
            'Consider attaching some signals using Lines property in the Line Chart Properties pane.',
          subObjects: ['lines'],
        },
      ];
    } else {
      for (const line of chart.lines) {
        const problems = this.validateSignalId(line.signalId, chart.humanReadableName, 'lines', this.systemIndex);

        if (problems.length > 0) {
          return problems;
        }
      }
    }
    return [];
  }

  private validateMinMax(chart: LineChart): Problem | null {
    if (typeof chart.minimum !== 'number') {
      return {
        severity: 'error',
        shortDescription: 'Line Chart minimum is not a valid number.',
        longDescription:
          'Line Chart minimum is not a valid number.' +
          'Please specify a valid number using Minimum property in the Line Chart Properties pane.',
        subObjects: ['minimum'],
      };
    } else if (typeof chart.maximum !== 'number') {
      return {
        severity: 'error',
        shortDescription: 'Line Chart maximum is not a valid number.',
        longDescription:
          'Line Chart maximum is not a valid number.' +
          'Please specify a valid number using Maximum property in the Line Chart Properties pane.',
        subObjects: ['maximum'],
      };
    } else if (chart.minimum >= chart.maximum) {
      return {
        severity: 'error',
        shortDescription: 'Line Chart minimum is greater than maximum.',
        longDescription:
          'Line Chart minimum is greater than maximum. ' +
          'Please ensure that that Maximum property is strictly greater than Minimum ' +
          'in the Line Chart Properties pane.',
        subObjects: ['minimum', 'maximum'],
      };
    }
    return null;
  }

  private validateNumberOfValues(chart: LineChart): Problem | null {
    if (chart.valuesCount !== chart.valuesCountSafe) {
      return {
        severity: 'error',
        shortDescription: 'Line Chart number of values is invalid.',
        longDescription:
          `Line Chart number of values is invalid. ` +
          `Please specify an integer between ${LineChart.minValuePoints} and ${LineChart.maxValuePoints}.`,
        subObjects: ['valuesCount'],
      };
    }
    return null;
  }
}
