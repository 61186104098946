import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormErrorDescriptor } from '../validation/error-descriptors';

/**
 * Component to display errors for the whole form (as opposed to specific field error).
 */
@Component({
  selector: 'devapp-form-errors',
  templateUrl: './form-errors.component.html',
  styleUrls: ['./form-errors.component.scss'],
})
export class FormErrorsComponent {
  /**
   * Form instance to check for errors.
   */
  @Input()
  form: FormGroup;

  /**
   * Custom error descriptors. Allows to customize default error messages (@see
   * {@link defaultErrorDescriptors}). Error descriptors specified here will
   * always take precedence over default ones if their ids match.
   */
  @Input()
  errorDescriptors: FormErrorDescriptor[];

  get isErrorVisible(): boolean {
    return this.form.errors != null;
  }
}
