import { Observable, Subscription } from 'rxjs';
import { ChangesManager } from './history/changes-manager';
import { CommandEvent } from './history/command';
import { CommandManager } from './history/command-manager';
import { SystemIndex } from './system-index';
import { ApplicationAdapter } from '../../application/adapter';
import { Application } from '../../application/application';
import { SignalIdentifier } from '../../application/signal-identifier';
import { SystemMatchingCriteria } from '../../system-matching-criteria/system-matching-criteria';
import { getDefaultEcuLabel } from '../../system-matching-criteria/utils';
import { SignalSpecification, SystemSpecification } from '../../system-specification/system-specification';
import { MigrateEcuIdVisitor } from '../../visitor/migrate-ecu-id.visitor';
import { MigrateSignalTypeVisitor } from '../../visitor/migrate-signal-type.visitor';
import { ApplicationValidator } from '../shared/validation/application-validator';
import { BarChartValidator } from '../shared/validation/validators/bar-chart.validator';
import { BooleanSignalValidator } from '../shared/validation/validators/boolean-signal.validator';
import { ButtonValidator } from '../shared/validation/validators/button.validator';
import { CrossAxisWeightValidator } from '../shared/validation/validators/cross-axis-weight.validator';
import { EnumSignalValidator } from '../shared/validation/validators/enum-signal.validator';
import { GaugeValidator } from '../shared/validation/validators/gauge.validator';
import { GridLayoutValidator } from '../shared/validation/validators/grid-layout.validator';
import { HeaderValidator } from '../shared/validation/validators/header.validator';
import { HintValidator } from '../shared/validation/validators/hint.validator';
import { ImageValidator } from '../shared/validation/validators/image.validator';
import { LabelValidator } from '../shared/validation/validators/label.validator';
import { LineChartValidator } from '../shared/validation/validators/line-chart.validator';
import { NumericSignalValidator } from '../shared/validation/validators/numeric-signal.validator';
import { PageVariationValidator } from '../shared/validation/validators/page-variation.validator';
import { PageValidator } from '../shared/validation/validators/page.validator';
import { StackLayoutValidator } from '../shared/validation/validators/stack-layout.validator';
import { StringSignalValidator } from '../shared/validation/validators/string-signal.validator';
import { TextValidator } from '../shared/validation/validators/text.validator';

export class DevHost {
  private _commandManger = new CommandManager();
  private _validator: ApplicationValidator;
  private _systemIndex: SystemIndex;
  private _changeManager: ChangesManager;
  private _adapter: ApplicationAdapter;

  private _subscription = new Subscription();

  static create(
    application: Application,
    systemSpecification: SystemSpecification,
    systemMatchingCriteria: SystemMatchingCriteria,
  ): DevHost {
    return new DevHost(application, systemSpecification, systemMatchingCriteria);
  }

  get application(): Application {
    return this._application;
  }

  get systemSpecification(): SystemSpecification {
    return this._systemSpecification;
  }

  get systemMatchingCriteria(): SystemMatchingCriteria {
    return this._systemMatchingCriteria;
  }

  get systemIndex(): SystemIndex {
    return this._systemIndex;
  }

  get commands(): Observable<CommandEvent> {
    return this._commandManger.commands;
  }

  get applicationChanges(): Observable<void> {
    return this._commandManger.changes;
  }

  get canUndoApplicationChange(): boolean {
    return this._commandManger.canUndo;
  }

  get canRedoApplicationChange(): boolean {
    return this._commandManger.canRedo;
  }

  /**
   * TODO: encapsulate and expose dedicated methods/data
   */
  get validator(): ApplicationValidator {
    return this._validator;
  }

  get changeManager(): ChangesManager {
    return this._changeManager;
  }

  private constructor(
    private _application: Application,
    private _systemSpecification: SystemSpecification,
    private _systemMatchingCriteria: SystemMatchingCriteria,
  ) {
    this._systemIndex = new SystemIndex(this._systemSpecification, this._systemMatchingCriteria);

    // Updates applications older than 0.2.4 to use ecuId instead of ecuFullAddress.
    this._application.rootPage.accept(new MigrateEcuIdVisitor(this._systemIndex));
    // Updates applications older than 0.2.9 to have signalType values.
    this._application.rootPage.accept(new MigrateSignalTypeVisitor(this._systemIndex));

    this._validator = new ApplicationValidator(this._application, [
      new BarChartValidator(this._systemIndex),
      new BooleanSignalValidator(this._systemIndex),
      new ButtonValidator(this._systemIndex),
      new PageValidator(),
      new EnumSignalValidator(this._systemIndex),
      new GaugeValidator(this._systemIndex),
      new HintValidator(this._systemIndex),
      new ImageValidator(this._systemIndex),
      new LabelValidator(this._systemIndex),
      new LineChartValidator(this._systemIndex),
      new NumericSignalValidator(this._systemIndex),
      new StackLayoutValidator(this._systemIndex),
      new TextValidator(this._systemIndex),
      new GridLayoutValidator(this._systemIndex),
      new CrossAxisWeightValidator(this._systemIndex),
      new HeaderValidator(this._systemIndex),
      new PageVariationValidator(),
      new StringSignalValidator(this._systemIndex),
    ]);
    this._adapter = new ApplicationAdapter();
    this._changeManager = new ChangesManager(this._commandManger, this.application, this._adapter);

    this._validator.runAllValidators();
    this._subscription.add(
      this._commandManger.commands.subscribe((event) => this._validator.processCommand(event.command, event.action)),
    );
  }

  redoApplicationChange() {
    this._commandManger.redo();
  }

  undoApplicationChange() {
    this._commandManger.undo();
  }

  isValidSignalIdentifier(signalId: SignalIdentifier): boolean {
    return this.getSignalSpecification(signalId) != null;
  }

  getSignalSpecification(signalId: SignalIdentifier): SignalSpecification | null {
    return this._systemIndex.getSignalSpecification(signalId);
  }

  getEcuOptions(
    signalFilter: (value: SignalSpecification) => boolean = () => true,
  ): Array<{ ecuId: string; label: string; signals: SignalSpecification[] }> {
    return this._systemMatchingCriteria.ecus.map((ecu) => {
      // every ecuId must have corresponding criteria and specification when system matched
      const ecuSpecification = this._systemIndex.getEcuSpecification(ecu.ecuId)!;
      const ecuCriteria = this._systemIndex.getEcuCriteria(ecu.ecuId)!;

      return {
        ecuId: ecu.ecuId,
        label: getDefaultEcuLabel(ecuCriteria),
        signals: ecuSpecification.signals.filter(signalFilter),
      };
    });
  }

  dispose() {
    this._subscription.unsubscribe();
  }
}
