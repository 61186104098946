import { Page } from '../../../../application/page';
import { PageVariation } from '../../../../application/page-variation';
import { Command } from '../command';

export class AddPageVariationCommand implements Command {
  constructor(public data: Data) {}

  apply(): void {
    this.data.parentPage.addPageVariation(this.data.variation, this.data.position);
  }

  unapply(): void {
    this.data.parentPage.removeVariation(this.data.variation);
  }
}

interface Data {
  variation: PageVariation;
  parentPage: Page;
  position: number;
}
