import { FormGroup } from '@angular/forms';
import { ValidationError } from '../../../core/http/error';

/**
 * Transforms errors from the ValidationError received from the API and sets
 * these errors on the form to be displayed to user.
 *
 * By default it assumes that form fields are named the same as API fields. This
 * can be customised by passing `customFieldMapping` argument, which overrides
 * how API field name is transformed into form field name.
 *
 * @param form - Form to receive errors.
 * @param error - Object to get errors from.
 * @param options - Extra options.
 */
export function addErrorsFromResponse(form: FormGroup, error: ValidationError, options?: Options) {
  for (const [apiFieldName, apiFieldErrors] of Object.entries(error.fieldErrors)) {
    const formFieldName = options?.customFieldMapping?.[apiFieldName] ?? apiFieldName;
    const formFieldErrors = apiFieldErrors.reduce((obj: { [key: string]: any }, item) => {
      obj[item.code] = item.context || true;
      return obj;
    }, {});

    form.controls[formFieldName].setErrors(formFieldErrors);
  }

  // add form error (from response error code)
  form.setErrors({ [error.code]: true });
}

export interface Options {
  /**
   * Customise how API field name is mapped into form field name.
   */
  customFieldMapping?: { [apiFieldName: string]: string | null };
}
