import { ApplicationAdapterV0_1_0 } from './adapter-v0-1-0';
import { AdapterContract } from './contract';
import { A0_1_2 } from './v0-1-2';
import { array } from '../../utils/utils';
import { Application } from '../application';
import { Control } from '../control';
import { GridLayout, GridLayoutCell, GridLayoutRow } from '../controls/grid-layout';
import { Image } from '../controls/image';
import { StackLayout } from '../controls/stack-layout';
import { Page } from '../page';

/**
 * This is the only place, where different versions of ST application format
 * are converted into same set of entities, which should be used by ST
 * applications consumers.
 */
export class ApplicationAdapterV0_1_2 implements AdapterContract {
  private baseAdapter = new ApplicationAdapterV0_1_0();
  private rowId = 1;
  private colId = 1;

  createApplication(declaration: A0_1_2.ApplicationDeclaration): Application {
    return new Application({
      pages: declaration.dashboards.map((item) => this.createPage(item, declaration.system)),
      // Intentionally using a different format because we don't support
      // serialisation for this format anymore.
      formatVersion: '0.2.1',
    });
  }

  createPage(declaration: A0_1_2.DashboardDeclaration, system: A0_1_2.SystemDeclaration = { ecus: [] }): Page {
    const pages =
      declaration.dashboards != null ? declaration.dashboards.map((child) => this.createPage(child, system)) : [];

    const controls = declaration.controlRoot != null ? [this.createControl(declaration.controlRoot, system)] : [];

    return new Page({
      id: declaration.id,
      name: declaration.name,
      pages,
      controls,
    });
  }

  createControl(data: A0_1_2.AnyControlDeclaration, system: A0_1_2.SystemDeclaration = { ecus: [] }): Control {
    switch (data.type) {
      case 'BarChart':
        return this.baseAdapter.createBarChartControl(data, system);
      case 'BooleanSignal':
        return this.baseAdapter.createBooleanSignalControl(data, system);
      case 'Button':
        return this.baseAdapter.createButtonControl(data);
      case 'EnumSignal':
        return this.baseAdapter.createEnumSignalControl(data, system);
      case 'Gauge':
        return this.baseAdapter.createGaugeControl(data, system);
      case 'GridLayout':
        return this.createGridLayoutControl(data, system);
      case 'Hint':
        return this.baseAdapter.createHintControl(data);
      case 'Image':
        return this.createImageControl(data);
      case 'Label':
        return this.baseAdapter.createLabelControl(data);
      case 'LineChart':
        return this.baseAdapter.createLineChartControl(data, system);
      case 'NumericSignal':
        return this.baseAdapter.createNumericSignalControl(data, system);
      case 'StackLayout':
        return this.createStackLayoutControl(data, system);
      case 'Text':
        return this.baseAdapter.createTextControl(data);
      default:
        throw new Error(`Unsupported control type: ${(data as any).type}.`);
    }
  }

  createImageControl({ margin, ...declaration }: A0_1_2.ImageControlDeclaration): Image {
    return new Image({ margin: this.createMargins(margin), ...declaration });
  }

  createStackLayoutControl(
    { type: _, margin, ...declaration }: A0_1_2.StackLayoutControlDeclaration,
    system: A0_1_2.SystemDeclaration,
  ): StackLayout {
    return new StackLayout({
      ...declaration,
      margin: this.createMargins(margin),
      children: declaration.children.map((item) => this.createControl(item, system)),
    });
  }

  createGridLayoutControl(
    { type: _, rows, spacing, margin, ...declaration }: A0_1_2.GridLayoutControlDeclaration,
    system: A0_1_2.SystemDeclaration,
  ): GridLayout {
    if (rows == null) {
      rows = Math.ceil(declaration.children.length / declaration.columns);
    }

    const children = array(
      rows,
      (rowIndex) =>
        new GridLayoutRow({
          id: `grid-layout-row-${this.rowId++}`,
          children: array(declaration.columns, (columnIndex) => {
            const control = declaration.children[rowIndex * declaration.columns + columnIndex];
            return new GridLayoutCell({
              id: `grid-layout-cell-${this.colId++}`,
              children: control != null ? [this.createControl(control, system)] : [],
            });
          }),
        }),
    );

    return new GridLayout({ ...declaration, margin: this.createMargins(margin), spacing: `${spacing}du`, children });
  }

  createMargins(value: A0_1_2.ControlDeclaration.Margins = {}) {
    const { top = '0du', bottom = '0du', left = '0du', right = '0du' } = value;

    return {
      top: top === '0' ? `${top}du` : top,
      bottom: bottom === '0' ? `${bottom}du` : bottom,
      left: left === '0' ? `${left}du` : left,
      right: right === '0' ? `${right}du` : right,
    };
  }

  serializeControl(_: Control): A0_1_2.AnyControlDeclaration {
    throw new Error('Serializing to this format version is not supported.');
  }

  serializeApplication(_: Application): A0_1_2.ApplicationDeclaration {
    throw new Error('Serializing to this format version is not supported.');
  }

  serializePage(_: Page): A0_1_2.DashboardDeclaration {
    throw new Error('Serializing to this format version is not supported.');
  }
}
