import { Component, Host, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FormControlFrameComponent } from '../form-control-frame/form-control-frame.component';

@Component({
  selector: 'devapp-form-control-text',
  templateUrl: './form-control-text.component.html',
})
export class FormControlTextComponent {
  @Input()
  placeholder: string;

  // FIXME: value is set dynamically and Angular always picks default value accessor (does not work for number)
  @Input()
  type: 'text' | 'number' | 'email' | 'password' = 'text';

  constructor(@Host() public frame: FormControlFrameComponent<FormControl>) {}
}
