import { ControlValidator } from './control.validator';
import { StackLayout } from '../../../../application/controls/stack-layout';
import { Problem, Validatable, ValidationTrigger } from '../validator';

export class StackLayoutValidator extends ControlValidator {
  public isRelevant(node: Validatable, triggers: ValidationTrigger[]): boolean {
    return node instanceof StackLayout && (triggers.includes('nodeProperties') || triggers.includes('applicationWide'));
  }

  public validate(control: StackLayout): Problem[] {
    const allProblems: Problem[] = super.validateControl(control);

    if (control.children.length === 0) {
      allProblems.push({
        severity: 'warning',
        shortDescription: 'Stack Layout is empty.',
        longDescription: 'A Stack Layout without any children is not visible and can be safely removed.',
        subObjects: [],
      });
    }

    return allProblems;
  }
}
