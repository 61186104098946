export const formatVersion = '0.2.5';

export interface ApplicationDeclaration {
  formatVersion: typeof formatVersion;
  pages: PageDeclaration[];
  themeOptions: ThemeDeclaration;
}

export interface ThemeDeclaration {
  brandColor: {
    background: string;
  };
  primaryColor: {
    background: string;
  };
  pageColor: {
    background: string;
  };
}

export interface PageDeclaration {
  id: string;
  name: string;
  pages?: PageDeclaration[];
  controls?: AnyControlDeclaration[];
}

export interface ControlDeclaration {
  id: string;

  width?: string;
  height?: string;
  margin?: ControlDeclaration.Margins;
}

export namespace ControlDeclaration {
  export interface Margins {
    top?: string;
    bottom?: string;
    right?: string;
    left?: string;
  }
}

export interface SignalFormatDeclaration {
  fractionDigits: number;
  fractionLimiting: 'round' | 'truncate';
}

export interface SignalScaleDeclaration {
  multiplier: number;
  offset: number;
}

export interface BaseSignalIdentifierDeclaration {
  ecuId: string | null;
  signalName: string | null;
  unit?: string;
  options?: SignalOptionDeclaration[];
  scale?: SignalScaleDeclaration;
  format?: SignalFormatDeclaration;
}

export interface SignalIdentifierDeclaration extends BaseSignalIdentifierDeclaration {
  type: 'SignalIdentifier';
}

export interface ArraySignalIdentifierDeclaration extends BaseSignalIdentifierDeclaration {
  type: 'ArraySignalIdentifier';
  index: number | null;
}

export interface SignalOptionDeclaration {
  name: string;
  value: number;
}

export type AnySignalIdentifierDeclaration = SignalIdentifierDeclaration | ArraySignalIdentifierDeclaration;

export interface CompositeControlDeclaration extends ControlDeclaration {
  children: AnyControlDeclaration[];
}

export interface LabelControlDeclaration extends ControlDeclaration {
  type: 'Label';
  text: string;
  align: 'left' | 'center' | 'right';
}

export interface TextControlDeclaration extends ControlDeclaration {
  type: 'Text';
  text: string;
  align: 'left' | 'center' | 'right' | 'justify';
}

export interface HintControlDeclaration extends ControlDeclaration {
  type: 'Hint';
  title: string;
  text: string;
  align: 'left' | 'center' | 'right' | 'justify';
}

export interface ButtonControlDeclaration extends ControlDeclaration {
  type: 'Button';
  text: string;
  action: AnyButtonActionDeclaration;
}

export interface WriteSignalsActionDeclaration {
  type: 'writeSignals';
}

export interface NavigatePageActionDeclaration {
  type: 'navigatePage';
  pageId: string | null;
}

export interface SetPulseActionDeclaration {
  type: 'setPulse';
  signalId: AnySignalIdentifierDeclaration;
}

export type AnyButtonActionDeclaration =
  | WriteSignalsActionDeclaration
  | NavigatePageActionDeclaration
  | SetPulseActionDeclaration;

export interface ImageControlDeclaration extends ControlDeclaration {
  type: 'Image';
  resource: string | null;
  intrinsicAspectRatio: number | null;
}

export interface StackLayoutControlDeclaration extends CompositeControlDeclaration {
  type: 'StackLayout';
  direction: 'topToBottom' | 'leftToRight';
  children: AnyControlDeclaration[];
}

export interface GridLayoutControlDeclaration extends CompositeControlDeclaration {
  type: 'GridLayout';
  children: GridLayoutRowControlDeclaration[];
  columns: number;
  spacing: number;
}

export interface GridLayoutRowControlDeclaration extends CompositeControlDeclaration {
  type: 'GridLayoutRow';
  children: GridLayoutCellControlDeclaration[];
}

export interface GridLayoutCellControlDeclaration extends CompositeControlDeclaration {
  type: 'GridLayoutCell';
}

export interface NumericSignalControlDeclaration extends ControlDeclaration {
  type: 'NumericSignal';
  readonly: boolean;
  standalone: boolean;
  signalID: AnySignalIdentifierDeclaration;
}

export interface EnumSignalControlDeclaration extends ControlDeclaration {
  type: 'EnumSignal';
  readonly: boolean;
  standalone: boolean;
  signalID: AnySignalIdentifierDeclaration;
}

export interface BooleanSignalControlDeclaration extends ControlDeclaration {
  type: 'BooleanSignal';
  readonly: boolean;
  standalone: boolean;
  signalID: AnySignalIdentifierDeclaration;
}

export interface GaugeControlDeclaration extends ControlDeclaration {
  type: 'Gauge';
  minimum: number;
  maximum: number;
  areas: GaugeControlDeclaration.Area[];
  signalID: AnySignalIdentifierDeclaration;
  valueForegroundColor: string;
  useThemeColor: boolean;
}

export namespace GaugeControlDeclaration {
  export interface Area {
    range: [number, number];
    color: string;
  }
}

export interface LineChartControlDeclaration extends ControlDeclaration {
  type: 'LineChart';
  minimum: number;
  maximum: number;
  valuesCount: number;
  lines: LineChartControlDeclaration.Line[];
}

export namespace LineChartControlDeclaration {
  export interface Line {
    label: string;
    color: string;
    signalID: AnySignalIdentifierDeclaration;
  }
}

export interface BarChartControlDeclaration extends ControlDeclaration {
  type: 'BarChart';
  minimum: number;
  maximum: number;
  direction: 'right' | 'left' | 'top' | 'bottom';
  bars: BarChartControlDeclaration.Bar[];
}

export namespace BarChartControlDeclaration {
  export interface Bar {
    label: string;
    color: string;
    signalID: AnySignalIdentifierDeclaration;
  }
}

export type AnyControlDeclaration =
  | LabelControlDeclaration
  | TextControlDeclaration
  | HintControlDeclaration
  | ButtonControlDeclaration
  | ImageControlDeclaration
  | StackLayoutControlDeclaration
  | GridLayoutControlDeclaration
  | GridLayoutRowControlDeclaration
  | GridLayoutCellControlDeclaration
  | NumericSignalControlDeclaration
  | EnumSignalControlDeclaration
  | BooleanSignalControlDeclaration
  | GaugeControlDeclaration
  | LineChartControlDeclaration
  | BarChartControlDeclaration;
