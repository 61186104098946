import { ControlVisitor } from './control-visitor';
import { Control } from '../application/control';
import { BarChart } from '../application/controls/bar-chart';
import { BooleanSignal } from '../application/controls/boolean-signal';
import { Button, SetPulseButtonAction } from '../application/controls/button';
import { EnumSignal } from '../application/controls/enum-signal';
import { Gauge } from '../application/controls/gauge';
import { LineChart } from '../application/controls/line-chart';
import { NumericSignal } from '../application/controls/numeric-signal';
import { EcuSignalSource, SignalIdentifier } from '../application/signal-identifier';
import { SystemIndex } from '../host/dev/system-index';

export class MigrateEcuIdVisitor extends ControlVisitor {
  constructor(private systemIndex: SystemIndex) {
    super();
  }

  visitControl(control: Control): void {
    if (control instanceof LineChart) {
      control.lines.forEach((line) => this.migrate(line.signalId));
    } else if (control instanceof BarChart) {
      control.bars.forEach((bar) => this.migrate(bar.signalId));
    } else if (control instanceof BooleanSignal) {
      this.migrate(control.signalId);
    } else if (control instanceof EnumSignal) {
      this.migrate(control.signalId);
    } else if (control instanceof Gauge) {
      this.migrate(control.signalId);
    } else if (control instanceof NumericSignal) {
      this.migrate(control.signalId);
    } else if (control instanceof Button && control.action instanceof SetPulseButtonAction) {
      this.migrate(control.action.signalId);
    }
  }

  private migrate(signalId: SignalIdentifier) {
    if (signalId.source instanceof EcuSignalSource) {
      if (signalId.source.ecuFullAddress != null && signalId.source.ecuId == null) {
        signalId.source.ecuId = this.systemIndex.getEcuId(signalId.source.ecuFullAddress);
      }
      signalId.source.ecuFullAddress = null;
    }
  }
}
