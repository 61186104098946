import { Application } from './application';
import { Control } from './control';
import { Page } from './page';
import { PageVariation } from './page-variation';
import { ScreenType } from './screen-type';
import { ApplicationAdapter as ApplicationAdapterV0_2_10 } from './versions/0.2.10/adapter';
import { ApplicationAdapter as ApplicationAdapterV0_2_11 } from './versions/0.2.11/adapter';
import { ApplicationAdapter as ApplicationAdapterV0_2_12 } from './versions/0.2.12/adapter';
import { ApplicationAdapter as ApplicationAdapterV0_2_13 } from './versions/0.2.13/adapter';
import { ApplicationAdapter as ApplicationAdapterV0_2_14 } from './versions/0.2.14/adapter';
import { ApplicationAdapter as ApplicationAdapterV0_2_5 } from './versions/0.2.5/adapter';
import { ApplicationAdapter as ApplicationAdapterV0_2_6 } from './versions/0.2.6/adapter';
import { ApplicationAdapter as ApplicationAdapterV0_2_7 } from './versions/0.2.7/adapter';
import { ApplicationAdapter as ApplicationAdapterV0_2_8 } from './versions/0.2.8/adapter';
import { ApplicationAdapter as ApplicationAdapterV0_2_9 } from './versions/0.2.9/adapter';
import { ApplicationAdapterV0_1_0 } from './versions/adapter-v0-1-0';
import { ApplicationAdapterV0_1_1 } from './versions/adapter-v0-1-1';
import { ApplicationAdapterV0_1_2 } from './versions/adapter-v0-1-2';
import { ApplicationAdapterV0_2_0 } from './versions/adapter-v0-2-0';
import { ApplicationAdapterV0_2_1 } from './versions/adapter-v0-2-1';
import { ApplicationAdapterV0_2_2 } from './versions/adapter-v0-2-2';
import { ApplicationAdapterV0_2_3 } from './versions/adapter-v0-2-3';
import { ApplicationAdapterV0_2_4 } from './versions/adapter-v0-2-4';
import { AdapterContract } from './versions/contract';
import { ApplicationAdapter as ApplicationAdapterLatest } from './versions/latest/adapter';
import { formatVersion as versionLatest } from './versions/latest/schema';

export type FormatVersion =
  | '0.1.0'
  | '0.1.1'
  | '0.1.2'
  | '0.2.0'
  | '0.2.1'
  | '0.2.2'
  | '0.2.3'
  | '0.2.4'
  | '0.2.5'
  | '0.2.6'
  | '0.2.7'
  | '0.2.8'
  | '0.2.9'
  | '0.2.10'
  | '0.2.11'
  | '0.2.12'
  | '0.2.13'
  | '0.2.14'
  | typeof versionLatest;

/**
 * This is the only place, where different versions of ST application format
 * are converted into same set of entities, which should be used by ST
 * applications consumers.
 */
export class ApplicationAdapter {
  private readonly latest: ApplicationAdapterLatest;
  private readonly versions: Record<FormatVersion, AdapterContract>;

  constructor() {
    this.latest = new ApplicationAdapterLatest();
    this.versions = {
      '0.1.0': new ApplicationAdapterV0_1_0(),
      '0.1.1': new ApplicationAdapterV0_1_1(),
      '0.1.2': new ApplicationAdapterV0_1_2(),
      '0.2.0': new ApplicationAdapterV0_2_0(),
      '0.2.1': new ApplicationAdapterV0_2_1(),
      '0.2.2': new ApplicationAdapterV0_2_2(),
      '0.2.3': new ApplicationAdapterV0_2_3(),
      '0.2.4': new ApplicationAdapterV0_2_4(),
      '0.2.5': new ApplicationAdapterV0_2_5(),
      '0.2.6': new ApplicationAdapterV0_2_6(),
      '0.2.7': new ApplicationAdapterV0_2_7(),
      '0.2.8': new ApplicationAdapterV0_2_8(),
      '0.2.9': new ApplicationAdapterV0_2_9(),
      '0.2.10': new ApplicationAdapterV0_2_10(),
      '0.2.11': new ApplicationAdapterV0_2_11(),
      '0.2.12': new ApplicationAdapterV0_2_12(),
      '0.2.13': new ApplicationAdapterV0_2_13(),
      '0.2.14': new ApplicationAdapterV0_2_14(),
      [versionLatest]: new ApplicationAdapterLatest(),
    };
  }

  createApplication(data: any): Application {
    if (data.formatVersion == null) {
      throw new Error('ST application version is not set. Unable to load.');
    }

    // TODO: Add runtime validation (using JSON Schema?)

    return this.getAdapter(data.formatVersion).createApplication(data);
  }

  createPage(data: any, extras: { screenTypesMap: Map<string, ScreenType> }): Page {
    return this.latest.createPage(data, extras.screenTypesMap);
  }

  createPageVariation(data: any, extras: { screenTypesMap: Map<string, ScreenType> }): PageVariation {
    return this.latest.createPageVariation(data, extras.screenTypesMap);
  }

  createControl(data: any): Control {
    return this.latest.createControl(data);
  }

  createScreenType(data: any): ScreenType {
    return this.latest.createScreenType(data);
  }

  serializeApplication(app: Application, useLatestFormat = true): unknown {
    const adapter = !useLatestFormat && app.formatVersion != null ? this.getAdapter(app.formatVersion) : this.latest;
    return adapter.serializeApplication(app);
  }

  serializePageVariation(variation: PageVariation): unknown {
    return this.latest.serializePageVariation(variation);
  }

  serializePage(page: Page): unknown {
    return this.latest.serializePage(page);
  }

  serializeControl(control: Control): unknown {
    return this.latest.serializeControl(control);
  }

  serializeScreenType(screenType: ScreenType): unknown {
    return this.latest.serializeScreenType(screenType);
  }

  convertControl(control: Control, to: 'BooleanSignal' | 'EnumSignal' | 'NumericSignal' | 'LineChart' | 'BarChart') {
    return this.latest.convertControl(control, to);
  }

  private getAdapter(format: FormatVersion) {
    const adapter = this.versions[format];
    if (adapter == null) {
      throw new Error(
        'ST application was build with newer version of the Editor, than Runner supports. ' +
          'Please update PLUS+1 Service Tool application to be able to use this ST application. ' +
          `Editor version used is ${format}. Latest supported version is ${versionLatest}.`,
      );
    }
    return adapter;
  }
}
