<div class="form-control-frame-group" [class.form-control-frame-group-horizontal]="!stacked">
  <label
    [htmlFor]="inputId"
    [style.minWidth]="!stacked ? labelWidth : null"
    [style.maxWidth]="!stacked ? labelWidth : null"
    [class.col-form-label]="!stacked"
    [class.me-3]="!stacked"
  >
    {{ label }} <span *ngIf="required" class="field-required">*</span>
  </label>

  <div [class.col-form-content]="!stacked">
    <ng-content></ng-content>

    <div class="invalid-feedback d-block" *ngIf="isErrorVisible">
      <div *ngFor="let errorKey of control.errors | devappKeys">
        {{ control.getError(errorKey) | devappFormErrorMessage: errorKey : label : errorDescriptors }}
      </div>
    </div>
  </div>
</div>
