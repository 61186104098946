import { Component, Input } from '@angular/core';

/**
 * Component to render table of data.
 *
 * It doesn't add much functionality to <table>, only simplifies markup and
 * by default ensures that every row takes only one line.
 *
 * Example:
 *
 * <devapp-table>
 *   <thead>
 *   <tr>
 *     <th>ID</th>
 *     <th>Name</th>
 *   </tr>
 *   </thead>
 *   <tbody>
 *   <tr *ngFor="let item of items">
 *     <td>{{ item.id }}</td>
 *     <td>{{ item.name }}</td>
 *   </tr>
 *   </tbody>
 * </devapp-table>
 */
@Component({
  selector: 'devapp-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent {
  @Input()
  ensureOneLine = true;
  @Input()
  strips: 'odd' | 'even';
  @Input()
  rowsBorder = true;
}
