import SystemDeclaration = S0_1_0.SystemDeclaration;
import EcuDeclaration = S0_1_0.EcuDeclaration;
import SignalDeclaration = S0_1_0.SignalDeclaration;
import { AdapterContract } from './contract';
import {
  cleanUndefinedProperties,
  parseDimensions,
  parseOptionalBoolean,
  parseOptionalInt,
  serializeDimensions,
  serializeOptionalBoolean,
} from './utils';
import { S0_1_0 } from './v0-1-0';
import {
  EcuSpecification,
  NodeType,
  ProgrammableStatus,
  Protocol,
  SignalOption,
  SignalSpecification,
  SignalType,
  SystemSpecification,
} from '../system-specification';

export class SystemSpecificationAdapterV0_1_0 implements AdapterContract {
  serialize(systemSpecification: SystemSpecification): SystemDeclaration {
    return {
      formatVersion: '0.1.0',
      ecus: systemSpecification.ecus.map((sysSpec) => this.serializeECU(sysSpec)),
    };
  }

  private serializeECU(ecuSpecification: EcuSpecification): EcuDeclaration {
    return cleanUndefinedProperties({
      'Application ID': ecuSpecification.applicationID,
      'Full Address': ecuSpecification.fullAddress.toString(),
      'Net Address': ecuSpecification.netAddress.toString(),
      'Node Address': ecuSpecification.nodeAddress.toString(),
      Protocol: ecuSpecification.protocol,
      'Application Type': ecuSpecification.applicationType,
      'Application Version': ecuSpecification.applicationVersion,
      'Tool Version': ecuSpecification.toolVersion,
      OS: ecuSpecification.os,
      'Compile Time': ecuSpecification.compileTime,
      'Bootloader Version': ecuSpecification.bootloaderVersion,
      'Serial Number': ecuSpecification.serialNumber,
      'Manufacturing Date': ecuSpecification.manufacturingDate,
      'System ID': ecuSpecification.systemID,
      'Part Number 0': ecuSpecification.partNumber0,
      'Part Number Revision 0': ecuSpecification.partNumberRevision0,
      'Part Number 1': ecuSpecification.partNumber1,
      'Part Number Revision 1': ecuSpecification.partNumberRevision1,
      'Part Number 2': ecuSpecification.partNumber2,
      EAN: ecuSpecification.ean,
      'Application Release Status': serializeOptionalBoolean(
        ecuSpecification.isApplicationReleased,
        'Released',
        'Not Released',
      ),
      'SIL2 Certified': serializeOptionalBoolean(ecuSpecification.isSIL2Certified, 'Certified', 'Not Certified'),
      'Node Type': ecuSpecification.nodeType,
      'Tool Name': ecuSpecification.toolName,
      'Programmable Status': ecuSpecification.programmableStatus,
      'ECU History': serializeOptionalBoolean(ecuSpecification.isHistorySupported, 'Supported', 'Not Supported'),
      'Diagnostic Data Filename': ecuSpecification.diagnosticDataFilename,
      'Diagnostic Data ID': ecuSpecification.diagnosticDataId,
      'Diagnostic Data Binary': ecuSpecification.diagnosticDataBinary,
      Signals: ecuSpecification.signals.map((signal) => this.serializeSignal(signal)),
    });
  }

  private serializeSignal(signal: SignalSpecification): SignalDeclaration {
    return cleanUndefinedProperties({
      'Parameter Name': signal.name,
      'Parameter Type': signal.type,
      Writeable: serializeOptionalBoolean(signal.writable, 'True', 'False') as any,
      'Parameter Dimension': serializeDimensions(signal.dimensions, signal.name),
      'Parameter Read Access Level': signal.readAccessLevel,
      'Parameter Write Access Level': signal.writeAccessLevel,
      'Parameter Options': signal.options.join(','),
    });
  }

  parse(declaration: SystemDeclaration): SystemSpecification {
    return new SystemSpecification({
      formatVersion: declaration.formatVersion,
      ecus: declaration.ecus.map((ecu) => this.parseECU(ecu)),
    });
  }

  private parseECU(declaration: EcuDeclaration): EcuSpecification {
    const mandatoryProperties = ['Full Address', 'Net Address', 'Node Address', 'Protocol'];

    for (const mandatoryProperty of mandatoryProperties) {
      if (!declaration.hasOwnProperty(mandatoryProperty)) {
        throw new Error(`Mandatory property ${mandatoryProperty} is missing from ECU declaration.`);
      }
    }

    return new EcuSpecification({
      fullAddress: parseInt(declaration['Full Address'], 10),
      netAddress: parseInt(declaration['Net Address'], 10),
      nodeAddress: parseInt(declaration['Node Address'], 10),
      protocol: declaration.Protocol as Protocol,
      applicationID: declaration['Application ID'],
      applicationType: declaration['Application Type'],
      applicationVersion: declaration['Application Version'],
      toolVersion: declaration['Tool Version'],
      os: declaration.OS,
      compileTime: declaration['Compile Time'],
      bootloaderVersion: declaration['Bootloader Version'],
      serialNumber: declaration['Serial Number'],
      manufacturingDate: declaration['Manufacturing Date'],
      systemID: declaration['System ID'],
      partNumber0: declaration['Part Number 0'],
      partNumberRevision0: declaration['Part Number Revision 0'],
      partNumber1: declaration['Part Number 1'],
      partNumberRevision1: declaration['Part Number Revision 1'],
      partNumber2: declaration['Part Number 2'],
      ean: declaration.EAN,
      isApplicationReleased: declaration['Application Release Status'] === 'Released',
      isSIL2Certified: declaration['SIL2 Certified'] === 'Certified',
      nodeType: declaration['Node Type'] as NodeType,
      toolName: declaration['Tool Name'],
      programmableStatus: declaration['Programmable Status'] as ProgrammableStatus,
      isHistorySupported: parseOptionalBoolean(declaration['ECU History'], 'Supported', 'Not Supported'),
      diagnosticDataFilename: declaration['Diagnostic Data Filename'],
      diagnosticDataId: declaration['Diagnostic Data ID'],
      diagnosticDataBinary: declaration['Diagnostic Data Binary'],
      signals: declaration.Signals.map((signal) => this.parseSignal(signal)),
    });
  }

  private parseSignal(declaration: SignalDeclaration): SignalSpecification {
    return new SignalSpecification({
      name: declaration['Parameter Name'],
      type: declaration['Parameter Type'] as SignalType,
      writable: parseOptionalBoolean(declaration.Writeable.toString(), 'True', 'False', false),
      readAccessLevel: parseOptionalInt(declaration['Parameter Read Access Level'] as any, 9),
      writeAccessLevel: parseOptionalInt(declaration['Parameter Write Access Level'] as any, 9),
      options: declaration['Parameter Options'].split(',').filter((option) => option !== '') as SignalOption[],
      dimensions: parseDimensions(declaration['Parameter Dimension'], declaration['Parameter Name']),
    });
  }
}
