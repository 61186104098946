import { Application } from './application';
import { Control } from './control';
import { Root } from './controls/root';
import { PageVariation } from './page-variation';
import { PageVisitor } from '../visitor/page-visitor';

export class Page {
  backgroundImage: string | null;
  backgroundImageRepeat: 'repeat' | 'repeat-x' | 'repeat-y' | 'no-repeat' | 'space' | 'round';
  backgroundImageSize: 'auto' | 'cover' | 'contain';

  id: string;
  name: string;
  children: Page[] = [];
  variations: PageVariation[] = [];

  controlRoot: Root;

  parent: Page | null = null;

  get safeName() {
    return this.name !== '' ? this.name : 'Unnamed';
  }

  constructor({
    id,
    name = '',
    pages = [],
    controls = [],
    variations = [],
    backgroundImage = null,
    backgroundImageRepeat = 'repeat',
    backgroundImageSize = 'auto',
  }: PageOptions) {
    this.id = id;
    this.name = name;
    this.backgroundImage = backgroundImage;
    this.backgroundImageRepeat = backgroundImageRepeat;
    this.backgroundImageSize = backgroundImageSize;

    this.controlRoot = new Root({ id: `root-${id}`, parentContainer: this });

    pages.forEach((child) => this.add(child));
    controls.forEach((control) => this.controlRoot.add(control));
    variations.forEach((variation) => this.addPageVariation(variation));
  }

  accept<T extends PageVisitor>(visitor: T): T {
    visitor.visitPage(this);
    this.children.forEach((child) => child.accept(visitor));
    return visitor;
  }

  add(page: Page): void {
    this.insert(page, this.children.length);
  }

  /**
   * Inserts a child into the page at a specific position.
   * If the page is already a child of the parent, then the rootPage
   * will be moved to the specified position.
   * @param child The Page to insert as child.
   * @param index An index specifying at which position the child should be
   *              inserted.
   */
  insert(child: Page, index: number) {
    if (child.parent != null) {
      child.parent.remove(child);
    }
    this.children.splice(index, 0, child);
    child.parent = this;
  }

  /**
   * Removes a sub Page. If the child page isn't a direct child to the
   * page then nothing will happen.
   * @param child The Page to remove as child.
   */
  remove(child: Page) {
    const index = this.children.indexOf(child);
    if (index < 0) {
      throw new Error('Page is not a child of the Page.');
    }
    this.children.splice(index, 1);
    child.parent = null;
  }

  /**
   * Adds a page variation at a specific position.
   *
   * If the passed variation is already a child of the page, then it will be
   * moved to the specified position.
   *
   * @param variation The page variation to add to the page.
   * @param index An index specifying at which position the variation should
   *              be added.
   */
  addPageVariation(variation: PageVariation, index: number = this.variations.length) {
    if (variation.parentPage != null) {
      variation.parentPage.removeVariation(variation);
    }
    this.variations.splice(index, 0, variation);
    variation.parentPage = this;
  }

  insertPageVariation(variation: PageVariation, index: number) {
    this.addPageVariation(variation, index);
  }

  /**
   * Removes a page variation.
   *
   * @param variation The page variation to remove from the page.
   */
  removeVariation(variation: PageVariation) {
    const index = this.variations.indexOf(variation);
    if (index < 0) {
      throw new Error('Page variation is not a child of the page.');
    }
    this.variations.splice(index, 1);
    variation.parentPage = null;
  }
}

export interface PageOptions {
  id: string;
  name?: string;
  pages?: Page[];
  controls?: Control[];
  variations?: PageVariation[];
  backgroundImage?: string;
  backgroundImageRepeat?: 'repeat' | 'repeat-x' | 'repeat-y' | 'no-repeat' | 'space' | 'round';
  backgroundImageSize?: 'auto' | 'cover' | 'contain';
}

export class RootPage extends Page {
  parentApplication: Application | null;

  constructor({ pages }: RootPageOptions) {
    super({ id: '', name: '', pages });
  }
}

export interface RootPageOptions {
  pages?: Page[];
}
