import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'devapp-pagination',
  templateUrl: './pagination.component.html',
})
export class PaginationComponent {
  @Input()
  totalItems: number;

  @Input()
  itemsPerPage: number;

  @Input()
  activePage: number;

  @Output()
  readonly pageChanged = new EventEmitter<number>();
}
