import SystemMatchingCriteriaDeclaration = M0_2_0.SystemMatchingCriteriaDeclaration;
import EcuMatchingCriteriaDeclaration = M0_2_0.EcuMatchingCriteriaDeclaration;
import PropertyCriterionDeclaration = M0_2_0.PropertyCriterionDeclaration;
import { AdapterContract } from './contract';
import { M0_2_0 } from './v0-2-0';
import { EcuMatchingCriteria, PropertyCriterion, SystemMatchingCriteria } from '../system-matching-criteria';

export class SystemMatchingCriteriaAdapterV0_2_0 implements AdapterContract {
  parse(declaration: SystemMatchingCriteriaDeclaration): SystemMatchingCriteria {
    return new SystemMatchingCriteria({
      nextEcuId: declaration.nextEcuId,
      ecus: declaration.ecus.map((ecu) => this.createEcuMatchingCriteriaV0_2_0(ecu)),
    });
  }

  serialize(criteria: SystemMatchingCriteria): SystemMatchingCriteriaDeclaration {
    return {
      formatVersion: '0.2.0',
      nextEcuId: criteria.nextEcuId,
      ecus: criteria.ecus.map((ecu) => this.serializeEcuMatchingCriteria(ecu)),
    };
  }

  private createEcuMatchingCriteriaV0_2_0(declaration: EcuMatchingCriteriaDeclaration): EcuMatchingCriteria {
    return new EcuMatchingCriteria({
      ecuId: declaration.ecuId,
      criteria: declaration.criteria.map((prop) => this.createPropertyCriterionV0_2_0(prop)),
    });
  }

  private createPropertyCriterionV0_2_0(declaration: PropertyCriterionDeclaration): PropertyCriterion {
    return new PropertyCriterion({ name: declaration.name, toMatch: declaration.toMatch });
  }

  private serializeEcuMatchingCriteria(ecu: EcuMatchingCriteria): EcuMatchingCriteriaDeclaration {
    return {
      enabled: true,
      ecuId: ecu.ecuId,
      criteria: ecu.criteria.map((prop) => this.serializePropertyCriterionDeclaration(prop)),
    };
  }

  private serializePropertyCriterionDeclaration(prop: PropertyCriterion): PropertyCriterionDeclaration {
    return {
      enabled: true,
      name: prop.name,
      toMatch: prop.toMatch,
    };
  }
}
