import { ControlOptions } from '../control';
import { SignalIdentifier } from '../signal-identifier';
import { SimpleControl } from '../simple-control';

export class StringSignal extends SimpleControl {
  readonly: boolean;
  standalone: boolean;
  signalId: SignalIdentifier;

  readonly minimumHeight = 2.5;

  get minimumWidth() {
    return this.readonly ? 2.5 : 7.5;
  }

  readonly humanReadableName = 'String Signal';

  constructor({
    readonly = false,
    standalone = true,
    signalId = new SignalIdentifier(),
    ...options
  }: StringSignalOptions) {
    super(options);

    this.readonly = readonly;
    this.standalone = standalone;
    this.signalId = signalId;
  }
}

export interface StringSignalOptions extends ControlOptions {
  readonly?: boolean;
  standalone?: boolean;
  signalId?: SignalIdentifier;
}
