import { EcuSpecification } from '../system-specification/system-specification';

export type PropertyName =
  | 'Net Address'
  | 'Node Address'
  | 'Protocol'
  | 'Diagnostic Data ID'
  | 'System ID'
  | 'Application ID'
  | 'Application Type'
  | 'Application Version'
  | 'Tool Version'
  | 'OS'
  | 'Compile Time'
  | 'Manufacturing Date'
  | 'Bootloader Version'
  | 'Serial Number'
  | 'Part Number 0'
  | 'Part Number Revision 0'
  | 'Part Number 1'
  | 'Part Number Revision 1'
  | 'Part Number 2'
  | 'EAN';

export class PropertyCriterion {
  name: PropertyName;
  toMatch: string;

  constructor({ name, toMatch }: PropertyCriterionOptions) {
    this.name = name;
    this.toMatch = toMatch;
  }

  getValue(ecu: EcuSpecification): string | undefined {
    switch (this.name) {
      case 'Net Address':
        return ecu.netAddress.toString();
      case 'Node Address':
        return ecu.nodeAddress.toString();
      case 'Protocol':
        return ecu.protocol;
      case 'Diagnostic Data ID':
        return ecu.diagnosticDataId;
      case 'System ID':
        return ecu.systemID;
      case 'Application ID':
        // For UDS Application ID is present in the system specification JSON,
        // but not available, when system reads ECU information in RunApp.
        // Here we emulate this behavior to make sure matching will work
        // identically at design time in DevApp and runtime in Sleipner/RunApp.
        return ecu.protocol === 'UDS' ? undefined : ecu.applicationID;
      case 'Application Type':
        return ecu.applicationType;
      case 'Application Version':
        return ecu.applicationVersion;
      case 'Tool Version':
        return ecu.toolVersion;
      case 'OS':
        return ecu.os;
      case 'Compile Time':
        return ecu.compileTime;
      case 'Manufacturing Date':
        return ecu.manufacturingDate;
      case 'Bootloader Version':
        return ecu.bootloaderVersion;
      case 'Serial Number':
        return ecu.serialNumber;
      case 'Part Number 0':
        return ecu.partNumber0;
      case 'Part Number Revision 0':
        return ecu.partNumberRevision0;
      case 'Part Number 1':
        return ecu.partNumber1;
      case 'Part Number Revision 1':
        return ecu.partNumberRevision1;
      case 'Part Number 2':
        return ecu.partNumber2;
      case 'EAN':
        return ecu.ean;
    }
  }

  matches(ecu: EcuSpecification) {
    return this.getValue(ecu) === this.toMatch;
  }
}

export interface PropertyCriterionOptions {
  name: PropertyName;
  toMatch: string;
}

export class EcuMatchingCriteria {
  ecuId: string;
  criteria: PropertyCriterion[];

  constructor({ ecuId, criteria = [] }: EcuMatchingCriteriaOptions) {
    this.criteria = criteria;
    this.ecuId = ecuId;
  }
}

export interface EcuMatchingCriteriaOptions {
  ecuId: string;
  criteria?: PropertyCriterion[];
}

export class SystemMatchingCriteria {
  ecus: EcuMatchingCriteria[];
  nextEcuId: number;

  constructor({ ecus = [], nextEcuId }: SystemMatchingCriteriaOptions) {
    this.ecus = ecus;
    this.nextEcuId = nextEcuId;
  }

  getNewEcuId() {
    return `ecu${this.nextEcuId++}`;
  }
}

export interface SystemMatchingCriteriaOptions {
  nextEcuId: number;
  ecus?: EcuMatchingCriteria[];
}
