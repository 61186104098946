import { AdapterContract } from './contract';
import { A0_2_3 } from './v0-2-3';
import { parseIntSafe } from '../../utils/number';
import { Application } from '../application';
import { Control } from '../control';
import { Area } from '../controls/area';
import { Bar, BarChart } from '../controls/bar-chart';
import { BooleanSignal } from '../controls/boolean-signal';
import {
  Button,
  ButtonAction,
  NavigatePageButtonAction,
  SetPulseButtonAction,
  WriteSignalsButtonAction,
} from '../controls/button';
import { EnumSignal } from '../controls/enum-signal';
import { Gauge } from '../controls/gauge';
import { GridLayout, GridLayoutCell, GridLayoutRow } from '../controls/grid-layout';
import { Hint } from '../controls/hint';
import { Image } from '../controls/image';
import { Label } from '../controls/label';
import { Line, LineChart } from '../controls/line-chart';
import { NumericSignal } from '../controls/numeric-signal';
import { StackLayout } from '../controls/stack-layout';
import { Text } from '../controls/text';
import { Page } from '../page';
import {
  ArraySignalIdentifier,
  EcuSignalSource,
  SignalFormatter,
  SignalIdentifier,
  SignalScale,
} from '../signal-identifier';

export class ApplicationAdapterV0_2_3 implements AdapterContract {
  createApplication(declaration: A0_2_3.ApplicationDeclaration): Application {
    return new Application({
      pages: declaration.pages.map((item) => this.createPage(item)),
      formatVersion: '0.2.3',
    });
  }

  createPage(declaration: A0_2_3.PageDeclaration): Page {
    const pages = declaration.pages != null ? declaration.pages.map((child) => this.createPage(child)) : [];

    const controls = declaration.controls != null ? declaration.controls.map((item) => this.createControl(item)) : [];

    return new Page({
      id: declaration.id,
      name: declaration.name,
      pages,
      controls,
    });
  }

  createControl(data: A0_2_3.AnyControlDeclaration): Control {
    switch (data.type) {
      case 'BarChart':
        return this.createBarChartControl(data);
      case 'BooleanSignal':
        return this.createBooleanSignalControl(data);
      case 'Button':
        return this.createButtonControl(data);
      case 'EnumSignal':
        return this.createEnumSignalControl(data);
      case 'Gauge':
        return this.createGaugeControl(data);
      case 'GridLayout':
        return this.createGridLayoutControl(data);
      case 'GridLayoutRow':
        return this.createGridLayoutRowControl(data);
      case 'GridLayoutCell':
        return this.createGridLayoutCellControl(data);
      case 'Hint':
        return this.createHintControl(data);
      case 'Image':
        return this.createImageControl(data);
      case 'Label':
        return this.createLabelControl(data);
      case 'LineChart':
        return this.createLineChartControl(data);
      case 'NumericSignal':
        return this.createNumericSignalControl(data);
      case 'StackLayout':
        return this.createStackLayoutControl(data);
      case 'Text':
        return this.createTextControl(data);
      default:
        throw new Error(`Unsupported control type: ${(data as any).type}.`);
    }
  }

  createSignalIdentifier(data: A0_2_3.AnySignalIdentifierDeclaration): SignalIdentifier {
    switch (data.type) {
      case 'SignalIdentifier':
        return new SignalIdentifier({
          unit: data.unit,
          options: data.options,
          scale: data.scale != null ? this.createSignalScale(data.scale) : undefined,
          formatter: data.format != null ? this.createSignalFormat(data.format) : undefined,
          source:
            data.ecuFullAddress != null && data.signalName != null
              ? new EcuSignalSource({ ecuFullAddress: data.ecuFullAddress, signalName: data.signalName })
              : null,
        });
      case 'ArraySignalIdentifier':
        return new ArraySignalIdentifier({
          index: data.index,
          unit: data.unit,
          options: data.options,
          scale: data.scale != null ? this.createSignalScale(data.scale) : undefined,
          formatter: data.format != null ? this.createSignalFormat(data.format) : undefined,
          source:
            data.ecuFullAddress != null && data.signalName != null
              ? new EcuSignalSource({ ecuFullAddress: data.ecuFullAddress, signalName: data.signalName })
              : null,
        });
      default:
        throw new Error(`Unsupported signal identifier type: ${(data as any).type}.`);
    }
  }

  createSignalScale(data: A0_2_3.SignalScaleDeclaration) {
    return new SignalScale(data);
  }

  createSignalFormat(data: A0_2_3.SignalFormatDeclaration) {
    return new SignalFormatter(data);
  }

  createLabelControl({ margin, ...declaration }: A0_2_3.LabelControlDeclaration): Label {
    return new Label({ margin: this.createMargins(margin), ...declaration });
  }

  createTextControl({ margin, ...declaration }: A0_2_3.TextControlDeclaration): Text {
    return new Text({ margin: this.createMargins(margin), ...declaration });
  }

  createHintControl({ margin, ...declaration }: A0_2_3.HintControlDeclaration): Hint {
    return new Hint({ margin: this.createMargins(margin), ...declaration });
  }

  createLine({ signalID, ...declaration }: A0_2_3.LineChartControlDeclaration.Line): Line {
    return new Line({ ...declaration, signalId: this.createSignalIdentifier(signalID) });
  }

  createLineChartControl({ type: _, margin, ...declaration }: A0_2_3.LineChartControlDeclaration): LineChart {
    return new LineChart({
      ...declaration,
      margin: this.createMargins(margin),
      lines: declaration.lines.map((line) => this.createLine(line)),
    });
  }

  createArea(declaration: A0_2_3.GaugeControlDeclaration.Area): Area {
    return new Area(declaration);
  }

  createGaugeControl({ type: _, signalID, margin, ...declaration }: A0_2_3.GaugeControlDeclaration): Gauge {
    return new Gauge({
      ...declaration,
      margin: this.createMargins(margin),
      signalId: this.createSignalIdentifier(signalID),
      areas: declaration.areas.map((area) => this.createArea(area)),
    });
  }

  createNumericSignalControl({
    signalID,
    type: _,
    margin,
    ...rest
  }: A0_2_3.NumericSignalControlDeclaration): NumericSignal {
    return new NumericSignal({
      margin: this.createMargins(margin),
      signalId: this.createSignalIdentifier(signalID),
      ...rest,
    });
  }

  createEnumSignalControl({ signalID, type: _, margin, ...rest }: A0_2_3.EnumSignalControlDeclaration): EnumSignal {
    return new EnumSignal({
      margin: this.createMargins(margin),
      signalId: this.createSignalIdentifier(signalID),
      ...rest,
    });
  }

  createBooleanSignalControl({
    signalID,
    type: _,
    margin,
    ...rest
  }: A0_2_3.BooleanSignalControlDeclaration): BooleanSignal {
    return new BooleanSignal({
      margin: this.createMargins(margin),
      signalId: this.createSignalIdentifier(signalID),
      ...rest,
    });
  }

  createBar({ signalID, ...declaration }: A0_2_3.BarChartControlDeclaration.Bar): Bar {
    return new Bar({ ...declaration, signalId: this.createSignalIdentifier(signalID) });
  }

  createBarChartControl({ type: _, margin, ...declaration }: A0_2_3.BarChartControlDeclaration): BarChart {
    return new BarChart({
      ...declaration,
      margin: this.createMargins(margin),
      bars: declaration.bars.map((bar) => this.createBar(bar)),
    });
  }

  createButtonControl({ type: _, action, margin, ...options }: A0_2_3.ButtonControlDeclaration): Button {
    return new Button({ margin: this.createMargins(margin), action: this.createButtonAction(action), ...options });
  }

  createButtonAction(declaration: A0_2_3.AnyButtonActionDeclaration): ButtonAction {
    switch (declaration.type) {
      case 'navigatePage':
        return new NavigatePageButtonAction({ pageId: declaration.pageId });
      case 'writeSignals':
        return new WriteSignalsButtonAction();
      case 'setPulse':
        return new SetPulseButtonAction({ signalId: this.createSignalIdentifier(declaration.signalId) });
    }
  }

  createImageControl({ margin, ...declaration }: A0_2_3.ImageControlDeclaration): Image {
    return new Image({ margin: this.createMargins(margin), ...declaration });
  }

  createStackLayoutControl({
    type: _,
    children,
    margin,
    ...options
  }: A0_2_3.StackLayoutControlDeclaration): StackLayout {
    return new StackLayout({
      ...options,
      margin: this.createMargins(margin),
      children: children.map((item) => this.createControl(item)),
    });
  }

  createGridLayoutControl({
    type: _,
    spacing,
    children,
    margin,
    ...options
  }: A0_2_3.GridLayoutControlDeclaration): GridLayout {
    return new GridLayout({
      ...options,
      spacing: `${spacing}du`,
      margin: this.createMargins(margin),
      children: children.map((item) => this.createControl(item) as GridLayoutRow),
    });
  }

  createGridLayoutRowControl({
    type: _,
    children,
    margin,
    ...options
  }: A0_2_3.GridLayoutRowControlDeclaration): GridLayoutRow {
    return new GridLayoutRow({
      ...options,
      margin: this.createMargins(margin),
      children: children.map((item) => this.createControl(item) as GridLayoutCell),
    });
  }

  createGridLayoutCellControl({ type: _, children, margin, ...options }: A0_2_3.GridLayoutCellControlDeclaration) {
    return new GridLayoutCell({
      ...options,
      margin: this.createMargins(margin),
      children: children.map((item) => this.createControl(item)),
    });
  }

  createMargins(value: A0_2_3.ControlDeclaration.Margins = {}) {
    const { top = '0du', bottom = '0du', left = '0du', right = '0du' } = value;

    return {
      top: top === '0' ? `${top}du` : top,
      bottom: bottom === '0' ? `${bottom}du` : bottom,
      left: left === '0' ? `${left}du` : left,
      right: right === '0' ? `${right}du` : right,
    };
  }

  serializeControl(control: Control): A0_2_3.AnyControlDeclaration {
    let json;

    if (control instanceof BarChart) {
      json = this.serializeBarChart(control);
    } else if (control instanceof BooleanSignal) {
      json = this.serializeBooleanSignal(control);
    } else if (control instanceof Button) {
      json = this.serializeButton(control);
    } else if (control instanceof EnumSignal) {
      json = this.serializeEnumSignal(control);
    } else if (control instanceof Gauge) {
      json = this.serializeGauge(control);
    } else if (control instanceof GridLayout) {
      json = this.serializeGridLayout(control);
    } else if (control instanceof GridLayoutRow) {
      json = this.serializeGridLayoutRow(control);
    } else if (control instanceof GridLayoutCell) {
      json = this.serializeGridLayoutCell(control);
    } else if (control instanceof Hint) {
      json = this.serializeHint(control);
    } else if (control instanceof Image) {
      json = this.serializeImage(control);
    } else if (control instanceof Label) {
      json = this.serializeLabel(control);
    } else if (control instanceof LineChart) {
      json = this.serializeLineChart(control);
    } else if (control instanceof NumericSignal) {
      json = this.serializeNumericSignal(control);
    } else if (control instanceof StackLayout) {
      json = this.serializeStackLayout(control);
    } else if (control instanceof Text) {
      json = this.serializeText(control);
    } else {
      throw new Error(`Unknown control type: ${control.constructor.name}.`);
    }

    return {
      ...json,
      ...(control.width !== control.fallbackWidth ? { width: control.width } : {}),
      ...(control.height !== control.fallbackHeight ? { height: control.height } : {}),
      ...(control.margin.top !== '0du' ||
      control.margin.bottom !== '0du' ||
      control.margin.right !== '0du' ||
      control.margin.left !== '0du'
        ? {
            margin: {
              top: control.margin.top === '0du' ? '0' : control.margin.top,
              bottom: control.margin.bottom === '0du' ? '0' : control.margin.bottom,
              right: control.margin.right === '0du' ? '0' : control.margin.right,
              left: control.margin.left === '0du' ? '0' : control.margin.left,
            },
          }
        : {}),
    };
  }

  serializeSignalIdentifier(signal: SignalIdentifier): A0_2_3.AnySignalIdentifierDeclaration {
    if (signal instanceof ArraySignalIdentifier) {
      return {
        type: 'ArraySignalIdentifier',
        ecuFullAddress: signal.source instanceof EcuSignalSource ? signal.source.ecuFullAddress : null,
        signalName: signal.source instanceof EcuSignalSource ? signal.source.signalName : null,
        index: signal.index,
        ...(signal.unit === '' ? {} : { unit: signal.unit }),
        ...(signal.options.length === 0 ? {} : { options: signal.options }),
        ...(signal.scale.multiplier !== 1 || signal.scale.offset !== 0
          ? { scale: this.serializeSignalScale(signal.scale) }
          : {}),
        ...(signal.formatter.fractionDigits !== 0 || signal.formatter.fractionLimiting !== 'round'
          ? { format: this.serializeSignalFormat(signal.formatter) }
          : {}),
      };
    } else {
      return {
        type: 'SignalIdentifier',
        ecuFullAddress: signal.source instanceof EcuSignalSource ? signal.source.ecuFullAddress : null,
        signalName: signal.source instanceof EcuSignalSource ? signal.source.signalName : null,
        ...(signal.unit === '' ? {} : { unit: signal.unit }),
        ...(signal.options.length === 0 ? {} : { options: signal.options }),
        ...(signal.scale.multiplier !== 1 || signal.scale.offset !== 0
          ? { scale: this.serializeSignalScale(signal.scale) }
          : {}),
        ...(signal.formatter.fractionDigits !== 0 || signal.formatter.fractionLimiting !== 'round'
          ? { format: this.serializeSignalFormat(signal.formatter) }
          : {}),
      };
    }
  }

  serializeSignalScale(scale: SignalScale): A0_2_3.SignalScaleDeclaration {
    return { multiplier: scale.multiplier, offset: scale.offset };
  }

  serializeSignalFormat(format: SignalFormatter): A0_2_3.SignalFormatDeclaration {
    return { fractionDigits: format.fractionDigits, fractionLimiting: format.fractionLimiting };
  }

  serializeText(control: Text): A0_2_3.TextControlDeclaration {
    return {
      id: control.id,
      type: 'Text',
      text: control.text,
      align: control.align,
    };
  }

  serializeLabel(label: Label): A0_2_3.LabelControlDeclaration {
    return {
      id: label.id,
      type: 'Label',
      text: label.text,
      align: label.align,
    };
  }

  serializeHint(control: Hint): A0_2_3.HintControlDeclaration {
    return {
      id: control.id,
      type: 'Hint',
      title: control.title,
      text: control.text,
      align: control.align,
    };
  }

  serializeNumericSignal(control: NumericSignal): A0_2_3.NumericSignalControlDeclaration {
    return {
      id: control.id,
      type: 'NumericSignal',
      readonly: control.readonly,
      standalone: control.standalone,
      signalID: this.serializeSignalIdentifier(control.signalId),
    };
  }

  serializeLine(line: Line): A0_2_3.LineChartControlDeclaration.Line {
    return {
      label: line.label,
      color: line.color,
      signalID: this.serializeSignalIdentifier(line.signalId),
    };
  }

  serializeLineChart(control: LineChart): A0_2_3.LineChartControlDeclaration {
    return {
      id: control.id,
      type: 'LineChart',
      minimum: control.minimum,
      maximum: control.maximum,
      valuesCount: control.valuesCount,
      lines: control.lines.map((line) => this.serializeLine(line)),
    };
  }

  serializeEnumSignal(control: EnumSignal): A0_2_3.EnumSignalControlDeclaration {
    return {
      id: control.id,
      type: 'EnumSignal',
      readonly: control.readonly,
      standalone: control.standalone,
      signalID: this.serializeSignalIdentifier(control.signalId),
    };
  }

  serializeBar(bar: Bar): A0_2_3.BarChartControlDeclaration.Bar {
    return {
      label: bar.label,
      color: bar.color,
      signalID: this.serializeSignalIdentifier(bar.signalId),
    };
  }

  serializeBarChart(control: BarChart): A0_2_3.BarChartControlDeclaration {
    return {
      id: control.id,
      type: 'BarChart',
      minimum: control.minimum,
      maximum: control.maximum,
      direction: control.direction,
      bars: control.bars.map((bar) => this.serializeBar(bar)),
    };
  }

  serializeBooleanSignal(control: BooleanSignal): A0_2_3.BooleanSignalControlDeclaration {
    return {
      id: control.id,
      type: 'BooleanSignal',
      readonly: control.readonly,
      standalone: control.standalone,
      signalID: this.serializeSignalIdentifier(control.signalId),
    };
  }

  serializeApplication(app: Application): A0_2_3.ApplicationDeclaration {
    const pages = app.rootPage.children.map((page) => this.serializePage(page));

    return {
      formatVersion: '0.2.3',
      pages,
    };
  }

  serializePage(page: Page): A0_2_3.PageDeclaration {
    const declaration: A0_2_3.PageDeclaration = {
      id: page.id,
      name: page.name,
    };

    if (page.controlRoot.children.length !== 0) {
      declaration.controls = page.controlRoot.children.map((child) => this.serializeControl(child));
    }

    if (page.children.length !== 0) {
      declaration.pages = page.children.map((pge) => this.serializePage(pge));
    }

    return declaration;
  }

  serializeStackLayout(stackLayout: StackLayout): A0_2_3.StackLayoutControlDeclaration {
    const children = stackLayout.children.map((child) => this.serializeControl(child));

    return {
      id: stackLayout.id,
      type: 'StackLayout',
      direction: stackLayout.direction,
      children,
    };
  }

  serializeGridLayout(control: GridLayout): A0_2_3.GridLayoutControlDeclaration {
    return {
      id: control.id,
      type: 'GridLayout',
      columns: control.columns,
      spacing: parseIntSafe(control.spacing.slice(0, -2)),
      children: control.children.map((item) => this.serializeControl(item) as A0_2_3.GridLayoutRowControlDeclaration),
    };
  }

  serializeGridLayoutRow(control: GridLayoutRow): A0_2_3.GridLayoutRowControlDeclaration {
    return {
      id: control.id,
      type: 'GridLayoutRow',
      children: control.children.map((item) => this.serializeControl(item) as A0_2_3.GridLayoutCellControlDeclaration),
    };
  }

  serializeGridLayoutCell(control: GridLayoutCell): A0_2_3.GridLayoutCellControlDeclaration {
    return {
      id: control.id,
      type: 'GridLayoutCell',
      children: control.children.map((item) => this.serializeControl(item)),
    };
  }

  serializeImage(control: Image): A0_2_3.ImageControlDeclaration {
    return {
      id: control.id,
      type: 'Image',
      resource: control.resource,
      intrinsicAspectRatio: control.intrinsicAspectRatio,
    };
  }

  serializeArea(area: Area): A0_2_3.GaugeControlDeclaration.Area {
    return {
      range: area.range,
      color: area.color,
    };
  }

  serializeGauge(control: Gauge): A0_2_3.GaugeControlDeclaration {
    return {
      id: control.id,
      type: 'Gauge',
      minimum: control.minimum,
      maximum: control.maximum,
      areas: control.areas.map((area) => this.serializeArea(area)),
      signalID: this.serializeSignalIdentifier(control.signalId),
      valueForegroundColor: control.valueForegroundColor,
    };
  }

  serializeButton(control: Button): A0_2_3.ButtonControlDeclaration {
    return {
      id: control.id,
      type: 'Button',
      text: control.text,
      action: this.serializeButtonAction(control.action),
    };
  }

  serializeButtonAction(action: ButtonAction): A0_2_3.AnyButtonActionDeclaration {
    if (action instanceof WriteSignalsButtonAction) {
      return {
        type: 'writeSignals',
      };
    } else if (action instanceof NavigatePageButtonAction) {
      return {
        type: 'navigatePage',
        pageId: action.pageId,
      };
    } else if (action instanceof SetPulseButtonAction) {
      return {
        type: 'setPulse',
        signalId: this.serializeSignalIdentifier(action.signalId),
      };
    } else {
      throw new Error(`Unexpected button action: ${action}.`);
    }
  }
}
