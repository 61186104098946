import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ComboBoxComponent } from './combo-box.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, FontAwesomeModule],
  declarations: [ComboBoxComponent],
  exports: [ComboBoxComponent],
})
export class ComboBoxModule {}
