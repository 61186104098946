import { PageVariationVisitor } from './page-variation-visitor';
import { Control } from '../application/control';
import { Page } from '../application/page';
import { PageVariation } from '../application/page-variation';

/**
 * Implement this interface and use {@link Control.accept},
 * {@link PageVariation.accept} or {@link Page.accept} to conveniently traverse
 * controls.
 */
export abstract class ControlVisitor extends PageVariationVisitor {
  abstract visitControl(control: Control): void;

  override visitPage(page: Page): void {
    page.controlRoot.accept(this);
    super.visitPage(page);
  }

  visitPageVariation(variation: PageVariation): void {
    variation.controlRoot.accept(this);
  }
}
