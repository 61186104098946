import { Component, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons/faInfoCircle';
import { environment } from '../../../../environments/environment';
import { Toast } from '../toast';
import { ToasterService } from '../toaster.service';

@Component({
  selector: 'devapp-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent implements OnInit, OnDestroy {
  @Input()
  public toast: Toast;

  public visible = false;

  isAnimated = environment.animations;

  private dismissTimeout: any;

  public constructor(
    private service: ToasterService,
    private zone: NgZone,
  ) {}

  public ngOnInit() {
    setTimeout(() => (this.visible = true), 0);
    this.startTimeout();
  }

  public ngOnDestroy() {
    if (this.toast.onDismiss != null) {
      this.toast.onDismiss(this.toast);
    }
  }

  public startTimeout() {
    if (this.toast.dismissOnTimeout) {
      this.zone.runOutsideAngular(() => {
        // Schedule timeout outside of Angular Zone to avoid E2E tests waiting for toast to disappear.
        this.dismissTimeout = setTimeout(() => this.zone.run(() => this.dismiss()), this.toast.timeout);
      });
    }
  }

  public cancelTimeout() {
    clearTimeout(this.dismissTimeout);
  }

  public dismiss() {
    this.visible = false;
    setTimeout(() => this.service.dismiss(this.toast.id), this.isAnimated ? 300 : 0);
  }

  public onMouseEnter() {
    this.cancelTimeout();
  }

  public onMouseLeave() {
    this.startTimeout();
  }

  public onToastClick() {
    if (this.toast.dismissOnClick) {
      this.dismiss();
    }
  }

  public onDismissClick(event: MouseEvent) {
    event.stopPropagation();

    this.dismiss();
  }

  get icon(): IconDefinition {
    let toastClassName = this.toast.className;
    if (toastClassName === 'danger') {
      return faExclamationTriangle;
    } else if (toastClassName === 'success') {
      return faCheckCircle;
    } else if (toastClassName === 'warning' || toastClassName === 'info') {
      return faInfoCircle;
    }
    return null;
  }
}
