import { Observable, Subject } from 'rxjs';
import { Command, CommandAction, CommandEvent } from './command';

export class CommandManager {
  /**
   * Observable, which emits multiple events for every command being applied
   * or unapplied.
   *
   * This should be used to perform some actions at particular stage of command
   * application. This allows much greater flexibility than {@link changes}.
   */
  public commands: Observable<CommandEvent>;

  /**
   * Observable, which emits after commands step is applied or unapplied.
   *
   * This should be used to do something after all changes from commands step
   * have been applied/unapplied.
   */
  public changes: Observable<void>;

  /**
   * History consists of steps, which can be applied and unapplied.
   * Every commands step consists of one or more commands.
   */
  private history: Command[][] = [];
  private historyPosition = 0;

  private commandsSubject = new Subject<CommandEvent>();
  private changesSubject = new Subject<void>();

  public get canUndo(): boolean {
    return this.historyPosition > 0;
  }

  public get canRedo(): boolean {
    return this.historyPosition < this.history.length;
  }

  public constructor() {
    this.commands = this.commandsSubject.asObservable();
    this.changes = this.changesSubject.asObservable();
  }

  /**
   * Applies command and adds it to commands log.
   *
   * @param command - Command to apply.
   */
  applyCommand(command: Command) {
    this.applyCommands([command]);
  }

  /**
   * Applies multiple commands as a single step in commands log.
   *
   * @param commands - Commands to apply.
   */
  applyCommands(commands: Command[]) {
    if (commands.length < 1) {
      throw new Error('Expected at least 1 command to apply.');
    }

    this.history.splice(this.historyPosition, this.history.length - this.historyPosition, commands);
    this.redo();
  }

  redo() {
    if (!this.canRedo) {
      throw new Error('No actions to redo.');
    }

    const commands = this.history[this.historyPosition];
    commands.forEach((command) => {
      this.commandsSubject.next({ command, action: CommandAction.preApply });
      command.apply();
      this.commandsSubject.next({ command, action: CommandAction.postApply });
    });
    this.historyPosition += 1;

    this.changesSubject.next();
  }

  undo() {
    if (!this.canUndo) {
      throw new Error('No actions to undo.');
    }

    const commands = this.history[this.historyPosition - 1];
    [...commands].reverse().forEach((command) => {
      this.commandsSubject.next({ command, action: CommandAction.preUnapply });
      command.unapply();
      this.commandsSubject.next({ command, action: CommandAction.postUnapply });
    });
    this.historyPosition -= 1;

    this.changesSubject.next();
  }
}
