import { ControlValidator } from './control.validator';
import { GridLayout } from '../../../../application/controls/grid-layout';
import { Problem, Validatable, ValidationTrigger } from '../validator';

export class GridLayoutValidator extends ControlValidator {
  public isRelevant(node: Validatable, triggers: ValidationTrigger[]): boolean {
    return node instanceof GridLayout && (triggers.includes('nodeProperties') || triggers.includes('applicationWide'));
  }

  public validate(grid: GridLayout): Problem[] {
    const allProblems: Problem[] = super.validateControl(grid);

    if (grid.spacing !== grid.safeSpacing) {
      allProblems.push({
        severity: 'error',
        shortDescription: 'Grid Layout spacing is invalid.',
        longDescription:
          `Grid Layout spacing is invalid. ` +
          `Please specify an integer in the range [0, 25] with a 'du' suffix, for example '2du'.`,
        subObjects: ['spacing'],
      });
    }

    if (grid.columns !== grid.safeColumns) {
      allProblems.push({
        severity: 'error',
        shortDescription: 'Grid Layout columns value is invalid.',
        longDescription: 'Grid Layout columns value is invalid. Please specify an integer in the range [1, 25].',
        subObjects: ['columns'],
      });
    }

    if (grid.rows !== grid.safeRows) {
      allProblems.push({
        severity: 'error',
        shortDescription: 'Grid Layout rows value is invalid.',
        longDescription: 'Grid Layout rows value is invalid. Please specify an integer in the range [1, 25].',
        subObjects: ['rows'],
      });
    }

    if (grid.children.length === 0) {
      allProblems.push({
        severity: 'warning',
        shortDescription: 'Grid Layout is empty.',
        longDescription: 'A Grid Layout without any children is not visible and can be safely removed.',
        subObjects: [],
      });
    }

    return allProblems;
  }
}
