<nav class="navbar navbar-dark navbar-expand">
  <div class="container-fluid px-0">
    <a class="navbar-brand" routerLink="/">PLUS+1 Service Tool</a>

    <div class="navbar-nav me-auto">
      <a *ngIf="permissions.canListPackages" class="nav-link" routerLink="/packages" routerLinkActive="active">
        Packages
      </a>

      <a *ngIf="permissions.canListDevices" class="nav-link" routerLink="/devices" routerLinkActive="active">Devices</a>

      <div ngbDropdown class="nav-item" *ngIf="canSeeAdminMenu">
        <a ngbDropdownToggle class="nav-link">Administration</a>

        <div ngbDropdownMenu>
          <a *ngIf="permissions.canListSystems" class="dropdown-item" routerLink="/systems" routerLinkActive="current">
            Systems
          </a>
          <div *ngIf="permissions.canListUsers || permissions.canListChildGroups" class="dropdown-divider"></div>
          <a *ngIf="permissions.canListUsers" class="dropdown-item" routerLink="/users" routerLinkActive="current">
            Users
          </a>
          <a
            *ngIf="permissions.canListChildGroups"
            class="dropdown-item"
            routerLink="/groups"
            routerLinkActive="current"
          >
            Groups
          </a>
        </div>
      </div>
    </div>

    <div class="navbar-nav">
      <div *ngIf="currentUser$ | async as user" ngbDropdown class="nav-item profile-dropdown" [autoClose]="'inside'">
        <div class="nav-link profile" ngbDropdownToggle>
          <div class="profile-text">
            <strong>{{ user.fullName }}</strong>
            <ng-container *ngIf="currentMembership$ | async as membership">
              <ng-container *ngIf="membership != null">
                <br />
                <small>{{ membership.group.name }}</small>
              </ng-container>
            </ng-container>
          </div>
          <fa-icon [icon]="icons.faUserCircle" size="lg"></fa-icon>
        </div>
        <div ngbDropdownMenu>
          <ng-container *ngIf="recentGroups$ | async as recentGroups">
            <ng-container *ngIf="currentMembership$ | async as currentMembership">
              <h6 class="dropdown-header">CHOOSE GROUP</h6>
              <button
                *ngFor="let group of recentGroups"
                type="button"
                class="dropdown-item"
                [class.current]="group.id === currentMembership.groupId"
                (click)="setCurrentMembership(group)"
              >
                {{ group.name }}
              </button>
              <button *ngIf="canViewAllGroups$ | async" type="button" class="dropdown-item" (click)="seeAllGroups()">
                View all groups
              </button>
              <div class="dropdown-divider"></div>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="canCreateGroup$ | async">
            <button type="button" class="dropdown-item" (click)="createGroup()">Create group</button>
            <div class="dropdown-divider"></div>
          </ng-container>

          <a class="dropdown-item" href="/docs" target="_blank">
            Documentation
            <small>
              <fa-icon [icon]="icons.faExternalLink"> </fa-icon>
            </small>
          </a>
          <a class="dropdown-item" routerLink="/what-is-new" routerLinkActive="current">What's new</a>
          <a class="dropdown-item" routerLink="/mobile-applications" routerLinkActive="current">Mobile applications</a>
          <a class="dropdown-item" [href]="serviceSupportUrl$ | async" target="_blank">Support</a>
          <div class="dropdown-divider"></div>

          <a class="dropdown-item" [routerLink]="['/profile', user.id]" routerLinkActive="current">Profile</a>
          <a class="dropdown-item" routerLink="/profile/groups" routerLinkActive="current">Groups</a>
          <a class="dropdown-item" routerLink="/profile/invitations" routerLinkActive="current">Invitations</a>
          <a class="dropdown-item" routerLink="/licenses/manage" routerLinkActive="current">Licenses</a>
          <a class="dropdown-item" href="javascript:CookieConsent.renew()">Cookies</a>
          <button type="button" class="dropdown-item" (click)="logout()">Log out</button>
        </div>
      </div>
    </div>
  </div>
</nav>

<devapp-spinner></devapp-spinner>

<main>
  <devapp-breadcrumb></devapp-breadcrumb>
  <router-outlet></router-outlet>
</main>

<footer class="d-flex flex-row justify-content-between">
  <p class="text-muted mb-0">Danfoss &copy; 2016-{{ currentYear }}</p>
  <p class="text-muted mb-0">Version: {{ version }}</p>
</footer>
