import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbAlertModule, NgbDropdownModule, NgbPaginationModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { DetailsRowComponent } from './details-row/details-row.component';
import { FormsModule as ExtendedFormsModule } from './forms/forms.module';
import { ItemDetailLinkComponent } from './item-detail-link/item-detail-link.component';
import { LayoutModule } from './layout/layout.module';
import { ListingComponent } from './listing/listing.component';
import { PaginationComponent } from './pagination/pagination.component';
import { CellActionButtonComponent } from './table/cell-action-button.component';
import { CellActionLinkComponent } from './table/cell-action-link.component';
import { ActionsCellDirective } from './table/table-cell-actions.directive';
import { TableRowLinkDirective } from './table/table-row-link.directive';
import { TableComponent } from './table/table.component';
import { TextConfirmationModalComponent } from './text-confirmation-modal/text-confirmation-modal.component';

@NgModule({
  imports: [CommonModule, RouterModule, FormsModule, ExtendedFormsModule, NgbPaginationModule, FontAwesomeModule],
  declarations: [
    ConfirmationModalComponent,
    TextConfirmationModalComponent,

    PaginationComponent,

    TableComponent,
    ListingComponent,
    TableRowLinkDirective,
    ActionsCellDirective,
    CellActionLinkComponent,
    CellActionButtonComponent,

    DetailsRowComponent,

    ItemDetailLinkComponent,
  ],
  exports: [
    CommonModule,
    LayoutModule,
    RouterModule,
    ExtendedFormsModule,

    NgbAlertModule,
    NgbDropdownModule,
    NgbTooltipModule,

    ConfirmationModalComponent,
    TextConfirmationModalComponent,

    PaginationComponent,

    TableComponent,
    ListingComponent,
    TableRowLinkDirective,
    ActionsCellDirective,
    CellActionLinkComponent,
    CellActionButtonComponent,

    DetailsRowComponent,

    ItemDetailLinkComponent,

    FontAwesomeModule,
  ],
})
export class SharedModule {}
