import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

/**
 * This component is a hack to force router to rebuild activated route from
 * scratch. The idea is to navigate to this route and pass desired URL as a
 * query parameter. Router will destroy active route completely and render this
 * component. But before rendering is complete it will redirect back to the
 * desired route, thus rebuilding desired route from scratch. This approach
 * produces some flickering, but it is better than reloading whole page.
 */
@Component({
  selector: 'devapp-redirect',
  template: '',
})
export class RedirectComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    const url = this.route.snapshot.queryParamMap.has('to') ? this.route.snapshot.queryParamMap.get('to') : '/';
    this.router.navigateByUrl(url);
  }
}
