import { EcuDeclaration, formatVersion, SignalAccessLevel, SignalDeclaration, SystemDeclaration } from './schema';
import { EcuSpecification, SignalOption, SignalSpecification, SystemSpecification } from '../../system-specification';
import { AdapterContract } from '../contract';
import {
  cleanUndefinedProperties,
  parseDimensions,
  parseOptionalBoolean,
  parseOptionalInt,
  serializeDimensions,
  serializeOptionalBoolean,
} from '../utils';

export class SystemSpecificationAdapter implements AdapterContract {
  public serialize(systemSpecification: SystemSpecification): SystemDeclaration {
    return {
      formatVersion,
      ecus: systemSpecification.ecus.map((sysSpec) => this.serializeECU(sysSpec)),
    };
  }

  private serializeECU(ecuSpecification: EcuSpecification): EcuDeclaration {
    const result: EcuDeclaration = {
      'Full Address': ecuSpecification.fullAddress.toString(),
      'Net Address': ecuSpecification.netAddress.toString(),
      'Node Address': ecuSpecification.nodeAddress.toString(),
      Protocol: ecuSpecification.protocol,
      'System ID': ecuSpecification.systemID,
      'Application ID': ecuSpecification.applicationID,
      'Tool Key': ecuSpecification.toolKey,
      'Application Type': ecuSpecification.applicationType,
      'Application Version': ecuSpecification.applicationVersion,
      'Application Release Status': ecuSpecification.isApplicationReleased ? 'Released' : 'Not Released',
      'Node Type': ecuSpecification.nodeType,
      'Tool Name': ecuSpecification.toolName,
      'Tool Version': ecuSpecification.toolVersion,
      OS: ecuSpecification.os,
      'Compile Time': ecuSpecification.compileTime,
      'Bootloader Version': ecuSpecification.bootloaderVersion,
      EAN: ecuSpecification.ean,
      'Serial Number': ecuSpecification.serialNumber,
      'Manufacturing Date': ecuSpecification.manufacturingDate,
      'Part Number 0': ecuSpecification.partNumber0,
      'Part Number Revision 0': ecuSpecification.partNumberRevision0,
      'Part Number 1': ecuSpecification.partNumber1,
      'Part Number Revision 1': ecuSpecification.partNumberRevision1,
      'Part Number 2': ecuSpecification.partNumber2,
      'Programmable Status': ecuSpecification.programmableStatus,
      'SIL2 Certified': ecuSpecification.isSIL2Certified ? 'Certified' : 'Not Certified',
      'ECU History': serializeOptionalBoolean(ecuSpecification.isHistorySupported, 'Supported', 'Not Supported'),
      'Diagnostic Data ID': ecuSpecification.diagnosticDataId!, // TODO: Remove when support for 0.1.0 is dropped
      'Diagnostic Data Filename': ecuSpecification.diagnosticDataFilename,
      'Diagnostic Data Binary': ecuSpecification.diagnosticDataBinary,
      Signals: ecuSpecification.signals.map((signal) => this.serializeSignal(signal)),
    };

    cleanUndefinedProperties(result);

    return result;
  }

  private serializeSignal(signal: SignalSpecification): SignalDeclaration {
    const result: SignalDeclaration = {
      'Parameter Name': signal.name,
      'Parameter Type': signal.type,
      Writeable: serializeOptionalBoolean(signal.writable, 'True', 'False'),
      'Parameter Dimension': serializeDimensions(signal.dimensions, signal.name),
      'Parameter Read Access Level': signal.readAccessLevel.toString() as SignalAccessLevel,
      'Parameter Write Access Level': signal.writeAccessLevel.toString() as SignalAccessLevel,
      'Parameter Options': signal.options.length > 0 ? signal.options.join(',') : undefined,
    };

    cleanUndefinedProperties(result);

    return result;
  }

  parse(declaration: SystemDeclaration): SystemSpecification {
    return new SystemSpecification({
      formatVersion: declaration.formatVersion,
      ecus: declaration.ecus.map((ecu) => this.parseECU(ecu)),
    });
  }

  private parseECU(declaration: EcuDeclaration): EcuSpecification {
    const baseMandatoryProperties = ['Full Address', 'Net Address', 'Node Address', 'Protocol', 'Diagnostic Data ID'];

    const mandatoryProperties =
      declaration.Protocol === 'PLUS+1' ? [...baseMandatoryProperties, 'Application ID'] : baseMandatoryProperties;

    for (const mandatoryProperty of mandatoryProperties) {
      if (!declaration.hasOwnProperty(mandatoryProperty)) {
        throw new Error(`Mandatory property ${mandatoryProperty} is missing from ECU declaration.`);
      }
    }

    return new EcuSpecification({
      fullAddress: parseInt(declaration['Full Address'], 10),
      netAddress: parseInt(declaration['Net Address'], 10),
      nodeAddress: parseInt(declaration['Node Address'], 10),
      protocol: declaration.Protocol,
      applicationID: declaration['Application ID'],
      toolKey: declaration['Tool Key'],
      applicationType: declaration['Application Type'],
      applicationVersion: declaration['Application Version'],
      toolVersion: declaration['Tool Version'],
      os: declaration.OS,
      compileTime: declaration['Compile Time'],
      bootloaderVersion: declaration['Bootloader Version'],
      serialNumber: declaration['Serial Number'],
      manufacturingDate: declaration['Manufacturing Date'],
      systemID: declaration['System ID'],
      partNumber0: declaration['Part Number 0'],
      partNumberRevision0: declaration['Part Number Revision 0'],
      partNumber1: declaration['Part Number 1'],
      partNumberRevision1: declaration['Part Number Revision 1'],
      partNumber2: declaration['Part Number 2'],
      ean: declaration.EAN,
      isApplicationReleased: declaration['Application Release Status'] === 'Released',
      isSIL2Certified: declaration['SIL2 Certified'] === 'Certified',
      nodeType: declaration['Node Type'],
      toolName: declaration['Tool Name'],
      programmableStatus: declaration['Programmable Status'],
      isHistorySupported: parseOptionalBoolean(declaration['ECU History'], 'Supported', 'Not Supported'),
      diagnosticDataFilename: declaration['Diagnostic Data Filename'],
      diagnosticDataId: declaration['Diagnostic Data ID'],
      diagnosticDataBinary: declaration['Diagnostic Data Binary'],
      signals: declaration.Signals.map((signal) => this.parseSignal(signal)),
    });
  }

  private parseSignal(declaration: SignalDeclaration): SignalSpecification {
    const po = declaration['Parameter Options'];
    const options: SignalOption[] =
      po !== undefined ? (po.split(',').filter((option) => option !== '') as SignalOption[]) : [];

    return new SignalSpecification({
      name: declaration['Parameter Name'],
      type: declaration['Parameter Type'],
      writable: parseOptionalBoolean(declaration.Writeable, 'True', 'False', false),
      readAccessLevel: parseOptionalInt(declaration['Parameter Read Access Level'], 9),
      writeAccessLevel: parseOptionalInt(declaration['Parameter Write Access Level'], 9),
      options,
      dimensions: parseDimensions(declaration['Parameter Dimension'], declaration['Parameter Name']),
    });
  }
}
