import { AdapterContract } from './contract';
import { A0_1_0 } from './v0-1-0';
import { array } from '../../utils/utils';
import { Application } from '../application';
import { Control } from '../control';
import { Area } from '../controls/area';
import { Bar, BarChart } from '../controls/bar-chart';
import { BooleanSignal } from '../controls/boolean-signal';
import { Button, ButtonAction, NavigatePageButtonAction, WriteSignalsButtonAction } from '../controls/button';
import { EnumSignal } from '../controls/enum-signal';
import { Gauge } from '../controls/gauge';
import { GridLayout, GridLayoutCell, GridLayoutRow } from '../controls/grid-layout';
import { Hint } from '../controls/hint';
import { Image } from '../controls/image';
import { Label } from '../controls/label';
import { Line, LineChart } from '../controls/line-chart';
import { NumericSignal } from '../controls/numeric-signal';
import { StackLayout } from '../controls/stack-layout';
import { Text } from '../controls/text';
import { Page } from '../page';
import { EcuSignalSource, SignalIdentifier } from '../signal-identifier';
/**
 * This is the only place, where different versions of ST application format
 * are converted into same set of entities, which should be used by ST
 * applications consumers.
 */
export class ApplicationAdapterV0_1_0 implements AdapterContract {
  private rowId = 1;
  private colId = 1;

  createApplication(declaration: A0_1_0.ApplicationDeclaration): Application {
    return new Application({
      pages: declaration.dashboards.map((item) => this.createPage(item, declaration.system)),
      // Intentionally using a different format because we don't support
      // serialisation for this format anymore.
      formatVersion: '0.2.1',
    });
  }

  createPage(declaration: A0_1_0.DashboardDeclaration, system: A0_1_0.SystemDeclaration = { ecus: [] }): Page {
    const pages =
      declaration.dashboards != null ? declaration.dashboards.map((child) => this.createPage(child, system)) : [];

    const controls = declaration.controlRoot != null ? [this.createControl(declaration.controlRoot, system)] : [];

    return new Page({
      id: declaration.id,
      name: declaration.name,
      pages,
      controls,
    });
  }

  createControl(data: A0_1_0.AnyControlDeclaration, system: A0_1_0.SystemDeclaration = { ecus: [] }): Control {
    switch (data.type) {
      case 'BarChart':
        return this.createBarChartControl(data, system);
      case 'BooleanSignal':
        return this.createBooleanSignalControl(data, system);
      case 'Button':
        return this.createButtonControl(data);
      case 'EnumSignal':
        return this.createEnumSignalControl(data, system);
      case 'Gauge':
        return this.createGaugeControl(data, system);
      case 'GridLayout':
        return this.createGridLayoutControl(data, system);
      case 'Hint':
        return this.createHintControl(data);
      case 'Image':
        return this.createImageControl(data);
      case 'Label':
        return this.createLabelControl(data);
      case 'LineChart':
        return this.createLineChartControl(data, system);
      case 'NumericSignal':
        return this.createNumericSignalControl(data, system);
      case 'StackLayout':
        return this.createStackLayoutControl(data, system);
      case 'Text':
        return this.createTextControl(data);
      default:
        throw new Error(`Unsupported control type: ${(data as any).type}.`);
    }
  }

  createSignalIdentifier(value: string | null, system: A0_1_0.SystemDeclaration): SignalIdentifier {
    if (value == null || value === '') {
      return new SignalIdentifier();
    }

    try {
      const components = value.split('.');
      const [ecuFullAddress, signalName] = [components.shift(), components.join('.')];
      const signalId = new SignalIdentifier({
        source: new EcuSignalSource({ ecuFullAddress: parseInt(ecuFullAddress!, 10), signalName }),
      });

      for (const ecu of system.ecus) {
        if (ecu.id === ecuFullAddress) {
          for (const sm of ecu.signalMappings) {
            const signal = sm.signals[0];
            if (signal.id === signalName) {
              signalId.unit = signal.unit != null ? signal.unit : '';
              signalId.options = signal.options != null ? signal.options : [];

              break;
            }
          }

          break;
        }
      }

      return signalId;
    } catch (_) {
      return new SignalIdentifier();
    }
  }

  createButtonControl({ type: _, action, actionArgs, margin, ...options }: A0_1_0.ButtonControlDeclaration): Button {
    return new Button({
      margin: this.createMargins(margin),
      ...options,
      action: this.createButtonAction(options.id, action, actionArgs),
    });
  }

  createButtonAction(controlId: string, action: 'writeSignals' | 'navigateDashboard', actionArgs: any[]): ButtonAction {
    if (action === 'navigateDashboard' && actionArgs.length !== 1) {
      throw new Error(
        `Button with ID '${controlId}' specifies action 'navigateDashboard'. ` +
          `'actionArgs' property should contain exactly one element - page ID to navigate, ` +
          `but ${actionArgs.length} elements was provided.`,
      );
    }

    switch (action) {
      case 'writeSignals':
        return new WriteSignalsButtonAction();
      case 'navigateDashboard':
        return new NavigatePageButtonAction({ pageId: actionArgs[0] });
    }
  }

  createLabelControl({ margin, ...declaration }: A0_1_0.LabelControlDeclaration): Label {
    return new Label({ margin: this.createMargins(margin), ...declaration });
  }

  createTextControl({ margin, ...declaration }: A0_1_0.TextControlDeclaration): Text {
    return new Text({ margin: this.createMargins(margin), ...declaration });
  }

  createHintControl({ margin, ...declaration }: A0_1_0.HintControlDeclaration): Hint {
    return new Hint({ margin: this.createMargins(margin), ...declaration });
  }

  createImageControl({ margin, ...declaration }: A0_1_0.ImageControlDeclaration): Image {
    return new Image({ margin: this.createMargins(margin), ...declaration });
  }

  createStackLayoutControl(
    { type: _, margin, ...declaration }: A0_1_0.StackLayoutControlDeclaration,
    system: A0_1_0.SystemDeclaration,
  ): StackLayout {
    return new StackLayout({
      ...declaration,
      margin: this.createMargins(margin),
      children: declaration.children.map((item) => this.createControl(item, system)),
    });
  }

  createGridLayoutControl(
    { type: _1, rows, spacing, margin, ...declaration }: A0_1_0.GridLayoutControlDeclaration,
    system: A0_1_0.SystemDeclaration,
  ): GridLayout {
    if (rows == null) {
      rows = Math.ceil(declaration.children.length / declaration.columns);
    }

    const children = array(
      rows,
      (rowIndex) =>
        new GridLayoutRow({
          id: `grid-layout-row-${this.rowId++}`,
          children: array(declaration.columns, (columnIndex) => {
            const control = declaration.children[rowIndex * declaration.columns + columnIndex];
            return new GridLayoutCell({
              id: `grid-layout-cell-${this.colId++}`,
              children: control != null ? [this.createControl(control, system)] : [],
            });
          }),
        }),
    );

    return new GridLayout({ ...declaration, margin: this.createMargins(margin), spacing: `${spacing}du`, children });
  }

  createNumericSignalControl(
    { signalID, type: _, margin, ...rest }: A0_1_0.NumericSignalControlDeclaration,
    system: A0_1_0.SystemDeclaration,
  ): NumericSignal {
    return new NumericSignal({
      margin: this.createMargins(margin),
      signalId: this.createSignalIdentifier(signalID, system),
      ...rest,
    });
  }

  createEnumSignalControl(
    { signalID, type: _, margin, ...rest }: A0_1_0.EnumSignalControlDeclaration,
    system: A0_1_0.SystemDeclaration,
  ): EnumSignal {
    return new EnumSignal({
      margin: this.createMargins(margin),
      signalId: this.createSignalIdentifier(signalID, system),
      ...rest,
    });
  }

  createBooleanSignalControl(
    { signalID, type: _, margin, ...rest }: A0_1_0.BooleanSignalControlDeclaration,
    system: A0_1_0.SystemDeclaration,
  ): BooleanSignal {
    return new BooleanSignal({
      margin: this.createMargins(margin),
      signalId: this.createSignalIdentifier(signalID, system),
      ...rest,
    });
  }

  createLine(
    { signalID, ...declaration }: A0_1_0.LineChartControlDeclaration.Line,
    system: A0_1_0.SystemDeclaration,
  ): Line {
    return new Line({ ...declaration, signalId: this.createSignalIdentifier(signalID, system) });
  }

  createLineChartControl(
    { type: _, margin, ...declaration }: A0_1_0.LineChartControlDeclaration,
    system: A0_1_0.SystemDeclaration,
  ): LineChart {
    return new LineChart({
      ...declaration,
      margin: this.createMargins(margin),
      lines: declaration.lines.map((line) => this.createLine(line, system)),
    });
  }

  createBar(
    { signalID, ...declaration }: A0_1_0.BarChartControlDeclaration.Bar,
    system: A0_1_0.SystemDeclaration,
  ): Bar {
    return new Bar({ ...declaration, signalId: this.createSignalIdentifier(signalID, system) });
  }

  createBarChartControl(
    { type: _, margin, ...declaration }: A0_1_0.BarChartControlDeclaration,
    system: A0_1_0.SystemDeclaration,
  ): BarChart {
    return new BarChart({
      ...declaration,
      margin: this.createMargins(margin),
      bars: declaration.bars.map((bar) => this.createBar(bar, system)),
    });
  }

  createArea(declaration: A0_1_0.GaugeControlDeclaration.Area): Area {
    return new Area(declaration);
  }

  createGaugeControl(
    { type: _, signalID, margin, ...declaration }: A0_1_0.GaugeControlDeclaration,
    system: A0_1_0.SystemDeclaration,
  ): Gauge {
    return new Gauge({
      ...declaration,
      margin: this.createMargins(margin),
      signalId: this.createSignalIdentifier(signalID, system),
      areas: declaration.areas.map((area) => this.createArea(area)),
    });
  }

  createMargins(value: A0_1_0.ControlDeclaration.Margins = {}) {
    const { top = '0du', bottom = '0du', left = '0du', right = '0du' } = value;

    return {
      top: top === '0' ? `${top}du` : top,
      bottom: bottom === '0' ? `${bottom}du` : bottom,
      left: left === '0' ? `${left}du` : left,
      right: right === '0' ? `${right}du` : right,
    };
  }

  serializeControl(_: Control): A0_1_0.AnyControlDeclaration {
    throw new Error('Serializing to this format version is not supported.');
  }

  serializeApplication(_: Application): A0_1_0.ApplicationDeclaration {
    throw new Error('Serializing to this format version is not supported.');
  }

  serializePage(_: Page): A0_1_0.DashboardDeclaration {
    throw new Error('Serializing to this format version is not supported.');
  }
}
