import { SystemMatchingCriteria } from './system-matching-criteria';
import { SystemMatchingCriteriaAdapterV0_1_0 } from './versions/adapter-v0-1-0';
import { SystemMatchingCriteriaAdapterV0_2_0 } from './versions/adapter-v0-2-0';
import { AdapterContract } from './versions/contract';

export type FormatVersion = '0.1.0' | '0.2.0';
export const latestVersion = '0.2.0';

export class SystemMatchingCriteriaAdapter {
  private versions = new Map<FormatVersion, AdapterContract>();

  constructor() {
    this.versions.set('0.1.0', new SystemMatchingCriteriaAdapterV0_1_0());
    this.versions.set('0.2.0', new SystemMatchingCriteriaAdapterV0_2_0());
  }

  parse(data: object): SystemMatchingCriteria {
    if ((data as any).formatVersion == null) {
      throw new Error('System Matching Criteria version is not set. Unable to load.');
    }

    return this.getAdapter((data as any).formatVersion).parse(data);
  }

  serialize(matchingCriteria: SystemMatchingCriteria, format: FormatVersion = latestVersion): object {
    return this.getAdapter(format).serialize(matchingCriteria);
  }

  private getAdapter(format: FormatVersion) {
    const adapter = this.versions.get(format);
    if (adapter == null) {
      throw new Error(
        'System Matching Criteria format version is not supported. ' +
          `Matching Criteria version is ${format}. Latest supported version is ${latestVersion}.`,
      );
    }
    return adapter;
  }
}
