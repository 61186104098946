import { ControlValidator } from './control.validator';
import { Application } from '../../../../application/application';
import { Control } from '../../../../application/control';
import { BooleanSignal } from '../../../../application/controls/boolean-signal';
import {
  Button,
  NavigatePageButtonAction,
  SetPulseButtonAction,
  WriteSignalsButtonAction,
} from '../../../../application/controls/button';
import { EnumSignal } from '../../../../application/controls/enum-signal';
import { NumericSignal } from '../../../../application/controls/numeric-signal';
import { Root } from '../../../../application/controls/root';
import { StringSignal } from '../../../../application/controls/string-signal';
import { PageVariation } from '../../../../application/page-variation';
import { ControlVisitor } from '../../../../visitor/control-visitor';
import { Problem, Validatable, ValidationTrigger } from '../validator';

export class ButtonValidator extends ControlValidator {
  isRelevant(node: Validatable, triggers: ValidationTrigger[]): boolean {
    // TODO: Consider splitting this validator to optimize.
    return (
      node instanceof Button &&
      (triggers.includes('nodeProperties') ||
        triggers.includes('dashboardHierarchy') ||
        triggers.includes('controlHierarchy') ||
        triggers.includes('applicationWide'))
    );
  }

  validate(button: Button, application: Application): Problem[] {
    const problems = this.validateControl(button);

    if (button.action instanceof NavigatePageButtonAction) {
      problems.push(...this.validateNavigatePageAction(button.action, application));
    } else if (button.action instanceof WriteSignalsButtonAction) {
      problems.push(...this.validateWriteSignalsAction(button.parentRoot));
    } else {
      problems.push(...this.validateSetPulseAction(button.action));
    }

    return problems;
  }

  private hasSignalWritingControls(root: Root): boolean {
    return root.accept(new HasSignalVisitor()).foundSignalControl;
  }

  private validateWriteSignalsAction(root: Root): Problem[] {
    // If the button has action "write signals" there should be at least one
    // signal-writing control.
    if (!this.hasSignalWritingControls(root)) {
      const container = root.parentContainer instanceof PageVariation ? 'page variation' : 'page';
      return [
        {
          severity: 'warning',
          shortDescription: `Button has write signals action but the ${container} has no signal writing controls.`,
          longDescription:
            `Button has write signals action but the ${container} has no signal writing controls. ` +
            `Consider adding controls for writing signals to the ${container} or remove the button.`,
          subObjects: ['action'],
        },
      ];
    }

    return [];
  }

  private validateNavigatePageAction(action: NavigatePageButtonAction, application: Application): Problem[] {
    if (action.pageId == null || action.pageId === '') {
      return [
        {
          severity: 'warning',
          shortDescription: 'Page to navigate to is not set.',
          longDescription:
            'Page to navigate to is not set. ' +
            'Please specify a page to navigate to using Action property in the Button Properties pane.',
          subObjects: ['action'],
        },
      ];
    } else if (application.getPageByID(action.pageId) == null) {
      return [
        {
          severity: 'error',
          shortDescription: `Button navigates to a non existing page.`,
          longDescription:
            'Button navigates to a non existing page. This page has probably been removed. ' +
            'Please specify a valid page using Action property in the Button Properties pane.',
          subObjects: ['action'],
        },
      ];
    }

    return [];
  }

  private validateSetPulseAction(action: SetPulseButtonAction): Problem[] {
    const problems = this.validateSignalId(action.signalId, 'Set Pulse action', 'action', this.systemIndex);

    if (problems.length > 0) {
      return problems;
    }

    const signalSpecification = this.systemIndex.getSignalSpecification(action.signalId);
    if (signalSpecification != null && !signalSpecification.options.includes('SETPULSE')) {
      return [
        {
          severity: 'error',
          shortDescription: 'Set Pulse action references signal, which does not support it.',
          longDescription:
            'Set Pulse action references signal, which is not supported. ' +
            'Please choose signal, which supports Set Pulse action using Action property ' +
            'in the Button Properties pane.',
          subObjects: ['action'],
        },
      ];
    }

    return [];
  }
}

class HasSignalVisitor extends ControlVisitor {
  foundSignalControl = false;

  visitControl(control: Control) {
    this.foundSignalControl =
      this.foundSignalControl ||
      ((control instanceof BooleanSignal ||
        control instanceof EnumSignal ||
        control instanceof NumericSignal ||
        control instanceof StringSignal) &&
        !control.standalone &&
        !control.readonly);
  }
}
