import isAfter from 'date-fns/isAfter';
import { License } from '../core/data/license.service';

export function hasProfessionalLicense(licenses: License[]) {
  const now = new Date();
  return licenses.some((license) => {
    return (
      (license.type === 'ProfessionalLicense' || license.type === 'TestLicense') && isAfter(license.expiresOn, now)
    );
  });
}

export function hasCloudAddon(licenses: License[]) {
  const now = new Date();
  return licenses.some(
    (license) =>
      ['BasicAddOn', 'ProfessionalAddOn', 'EnterpriseAddOn'].includes(license.type) && isAfter(license.expiresOn, now),
  );
}

export function getAvailableStorage(licenses: License[]) {
  const now = new Date();
  return licenses
    .filter((license) => !isAfter(now, license.expiresOn))
    .map((license) => license.storage)
    .reduce((a, b) => a + b, 0);
}

export function getAvailableGroups(licenses: License[]) {
  const now = new Date();
  let groupCount: number = licenses
    .filter((license) => !isAfter(now, license.expiresOn))
    .map((license) => license.groups)
    .reduce((a, b) => a + b, 0);
  return groupCount > 5 ? 5 : groupCount;
}
