import { Page } from '../../../../application/page';
import { Command } from '../command';

export class EditPageCommand implements Command {
  constructor(public data: Data) {}

  apply(): void {
    this.data.page.name = this.data.newName;
  }

  unapply(): void {
    this.data.page.name = this.data.oldName;
  }
}

interface Data {
  page: Page;
  oldName: string;
  newName: string;
}
