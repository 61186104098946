import { Pipe, PipeTransform } from '@angular/core';
import { defaultErrorDescriptors, FormErrorDescriptor, unexpectedErrorDescriptor } from './error-descriptors';

@Pipe({
  name: 'devappFormErrorMessage',
})
export class FormErrorMessagePipe implements PipeTransform {
  private errorMessages = new Map<string, FormErrorDescriptor>();

  constructor() {
    defaultErrorDescriptors.forEach((item) => this.errorMessages.set(item.id, item));
  }

  transform(context: any, id: string, label: string, customMessages?: FormErrorDescriptor[]): string {
    let descriptor: FormErrorDescriptor;
    if (customMessages != null) {
      descriptor = customMessages.find((item) => item.id === id);
    }

    if (descriptor == null) {
      descriptor = this.errorMessages.get(id);
    }

    if (descriptor == null) {
      descriptor = unexpectedErrorDescriptor;
    }

    return descriptor.template({ label, ...(context === true ? undefined : context) });
  }
}
