/**
 * Creates array of given length.
 *
 * This array can be iterated.
 * See https://stackoverflow.com/a/41246860/1377864
 *
 * @param length - Length of the array.
 * @param itemGenerator? - Optional function to generate array items.
 * @return Array of given length.
 */
export function array<T>(length: number, itemGenerator?: (index: number) => T): T[] {
  const value = Array(length).fill(undefined);
  if (itemGenerator != null) {
    return value.map((_, index) => itemGenerator(index));
  }
  return value;
}
