import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { FormControlCheckboxComponent } from './form-control-checkbox/form-control-checkbox.component';
import { FormControlComboBoxComponent } from './form-control-combo-box/form-control-combo-box.component';
import { FileValueAccessor } from './form-control-file/file-value-accessor';
import { FormControlFileComponent } from './form-control-file/form-control-file.component';
import { FormControlFrameComponent } from './form-control-frame/form-control-frame.component';
import { FormControlSelectComponent } from './form-control-select/form-control-select.component';
import { FormControlTextComponent } from './form-control-text/form-control-text.component';
import { FormControlTextareaComponent } from './form-control-textarea/form-control-text.component';
import { FormErrorsComponent } from './form-errors/form-errors.component';
import { SubmitDirective } from './submit.directive';
import { FormErrorMessagePipe } from './validation/form-error-message.pipe';
import { ComboBoxModule } from '../combo-box/combo-box.module';
import { KeysPipe } from '../common/keys.pipe';

@NgModule({
  imports: [ComboBoxModule, CommonModule, NgbAlertModule, ReactiveFormsModule],
  declarations: [
    SubmitDirective,
    FormControlCheckboxComponent,
    FormControlComboBoxComponent,
    FormControlFileComponent,
    FormControlFrameComponent,
    FormControlTextComponent,
    FormControlTextareaComponent,
    FormControlSelectComponent,
    FormErrorsComponent,
    FormErrorMessagePipe,

    FileValueAccessor,
    KeysPipe,
  ],
  exports: [
    ReactiveFormsModule,

    SubmitDirective,
    FormControlCheckboxComponent,
    FormControlComboBoxComponent,
    FormControlFileComponent,
    FormControlFrameComponent,
    FormControlTextComponent,
    FormControlTextareaComponent,
    FormControlSelectComponent,
    FormErrorsComponent,
    FormErrorMessagePipe,
  ],
})
export class FormsModule {}
