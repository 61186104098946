import { ControlValidator } from './control.validator';
import { Application } from '../../../../application/application';
import { Header } from '../../../../application/controls/header';
import { Problem, Validatable, ValidationTrigger } from '../validator';

export class HeaderValidator extends ControlValidator {
  isRelevant(node: Validatable, triggers: ValidationTrigger[]): boolean {
    return node instanceof Header && (triggers.includes('applicationWide') || triggers.includes('nodeProperties'));
  }

  validate(header: Header, application: Application): Problem[] {
    const problems = this.validateControl(header);
    problems.push(...this.validateSelectedPreset(header, application));
    return problems;
  }

  validateSelectedPreset(header: Header, application: Application): Problem[] {
    if (application.themeOptions.headerPresets.length === 0) {
      return [
        {
          severity: 'error',
          shortDescription: 'No header presets are defined.',
          longDescription: 'No header presets are defined. Please create a preset in the application theme properties.',
          subObjects: ['preset'],
        },
      ];
    }
    if (
      !application.themeOptions.headerPresets.some((hp) => {
        return hp.id === header.preset;
      })
    ) {
      return [
        {
          severity: 'error',
          shortDescription: 'Header preset is not set.',
          longDescription:
            'Header preset is not set. ' +
            'Please specify a preset to use for the header using the Preset property in the Header properties pane.',
          subObjects: ['preset'],
        },
      ];
    }

    return [];
  }
}
