import { ControlOptions } from '../control';
import { SimpleControl } from '../simple-control';

export class Header extends SimpleControl {
  text: string;
  preset: string;

  readonly minimumWidth = 1;
  readonly minimumHeight = 1;

  readonly humanReadableName = 'Header';

  constructor({ text = 'Header', preset = null, ...options }: HeaderOptions) {
    super(options);

    this.text = text;
    this.preset = preset;
  }
}

export interface HeaderOptions extends ControlOptions {
  text?: string;
  preset?: string;
}

export class HeaderPreset {
  id: string;
  label: string;
  fontSize: string;
  align: 'left' | 'center' | 'right' | 'justify';

  constructor(options: HeaderPresetOptions) {
    this.id = options.id;
    this.label = options.label;
    this.fontSize = options.fontSize;
    this.align = options.align;
  }
}

export interface HeaderPresetOptions {
  id: string;
  label: string;
  fontSize: string;
  align: 'left' | 'center' | 'right' | 'justify';
}
