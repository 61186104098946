import { CompositeControl, CompositeControlOptions } from '../composite-control';
import { Page } from '../page';
import { PageVariation } from '../page-variation';

export class Root extends CompositeControl {
  readonly minimumWidth = 0;
  readonly minimumHeight = 0;

  readonly humanReadableName = 'Root';

  parentContainer: Page | PageVariation | null;

  override get parentRoot(): Root {
    return this;
  }

  constructor({ parentContainer = null, ...options }: RootOptions) {
    super(options);

    this.parentContainer = parentContainer;
  }
}

export interface RootOptions extends CompositeControlOptions {
  parentContainer?: Page | PageVariation | null;
}
