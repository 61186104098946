import { Injectable } from '@angular/core';

export type RoleName = 'Owner' | 'Admin' | 'Developer' | 'Technician' | 'Owner Common Group';

export const OWNER_ROLE_ID = 1;
export const ADMIN_ROLE_ID = 2;
export const DEVELOPER_ROLE_ID = 3;
export const TECHNICIAN_ROLE_ID = 4;
export const OWNER_COMMON_GROUP_ROLE_ID = 5;

/**
 * Utilities for working with user roles.
 */
@Injectable({
  providedIn: 'root',
})
export class RolesMappingService {
  private roles: Array<{ id: number; name: RoleName }> = [
    { id: TECHNICIAN_ROLE_ID, name: 'Technician' },
    { id: DEVELOPER_ROLE_ID, name: 'Developer' },
    { id: ADMIN_ROLE_ID, name: 'Admin' },
    { id: OWNER_ROLE_ID, name: 'Owner' },
    { id: OWNER_COMMON_GROUP_ROLE_ID, name: 'Owner Common Group' },
  ];
  private roleIdsMap = new Map<RoleName, number>(this.roles.map((role) => [role.name, role.id]));
  private roleNamesMap = new Map<number, RoleName>(this.roles.map((role) => [role.id, role.name]));

  /**
   * Role ID by role name.
   *
   * @param name - role name
   * @returns role ID
   */
  getRoleId(name: RoleName): number {
    return this.roleIdsMap.get(name);
  }

  /**
   * Role name by role ID.
   *
   * @param id - role ID
   * @return role name
   */
  getRoleName(id: number): RoleName {
    return this.roleNamesMap.get(id) as RoleName;
  }

  /**
   * List of role ID and label pairs to be used in selects.
   *
   * @returns role options
   */
  getRoleOptions(): Array<[number, RoleName]> {
    return this.roles.map((item) => [item.id, this.getRoleName(item.id)]);
  }
}
