import { ControlValidator } from './control.validator';
import { Text } from '../../../../application/controls/text';
import { Problem, Validatable, ValidationTrigger } from '../validator';

export class TextValidator extends ControlValidator {
  public isRelevant(node: Validatable, triggers: ValidationTrigger[]): boolean {
    return node instanceof Text && (triggers.includes('nodeProperties') || triggers.includes('applicationWide'));
  }

  public validate(text: Text): Problem[] {
    return [...this.validateControl(text), ...this.validateText(text), ...this.validateBindings(text)];
  }

  private validateText(text: Text): Problem[] {
    if (text.text.length === 0) {
      return [
        {
          severity: 'warning',
          shortDescription: 'Text has no text.',
          longDescription: 'A Text control without a text is not visible and can be safely removed.',
          subObjects: ['text'],
        },
      ];
    }

    return [];
  }

  private validateBindings(control: Text): Problem[] {
    const placeholders = control.getSignalPlaceholders();
    const bindings = control.signals.map((item) => item.variableName);

    const missingPlaceholders = Array.from(
      new Set(bindings.filter((binding) => !placeholders.includes(binding)).map((item) => `%${item}$`)),
    );
    const missingBindings = Array.from(new Set(placeholders.filter((marker) => !bindings.includes(marker))));
    const missingSignals = control.signals
      .map((signal) => this.validateSignalId(signal.signalId, control.humanReadableName, 'signals', this.systemIndex))
      .reduce((accumulator, value) => accumulator.concat(value), []);

    return [
      ...(missingPlaceholders.length !== 0
        ? [
            {
              severity: 'warning' as const,
              shortDescription: 'Missing signal placeholders.',
              longDescription:
                'Some configured signal bindings are missing placeholders in the text. Please add the missing ' +
                'placeholders or remove the unused signal bindings. ' +
                `Missing placeholder${missingPlaceholders.length > 1 ? 's' : ''}: ${missingPlaceholders.join(', ')}.`,
              subObjects: ['text'],
            },
          ]
        : []),
      ...(missingBindings.length !== 0
        ? [
            {
              severity: 'error' as const,
              shortDescription: 'Missing signal bindings.',
              longDescription:
                'Some placeholders in the text are missing the corresponding signal binding. Please add the ' +
                'missing signal bindings or remove the placeholders. ' +
                `Missing signal binding${missingPlaceholders.length > 1 ? 's' : ''}: ${missingBindings.join(', ')}.`,
              subObjects: ['text'],
            },
          ]
        : []),
      // There might be several errors/warnings when having multiple signals, but we only need to show the first to the user.
      ...(missingSignals.length !== 0 ? [missingSignals[0]] : []),
    ];
  }
}
