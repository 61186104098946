import { ControlValidator } from './control.validator';
import { Control } from '../../../../application/control';
import { StackLayout } from '../../../../application/controls/stack-layout';
import { Problem, Validatable, ValidationTrigger } from '../validator';

export class CrossAxisWeightValidator extends ControlValidator {
  isRelevant(node: Validatable, triggers?: ValidationTrigger[]): boolean {
    return (
      (node.parent instanceof StackLayout && triggers!.includes('parentProperties')) ||
      triggers!.includes('nodeProperties') ||
      triggers!.includes('applicationWide')
    );
  }

  validate(control: Control): Problem[] {
    const problem = this.validateXsDims(control);
    if (problem != null) {
      return [problem];
    }

    return [];
  }

  private validateXsDims(control: Control): Problem | null {
    // TODO: Add validation for all containers, not only Stack Layouts (Grid Cell, Root, etc)
    if (control.parent instanceof StackLayout) {
      if (control.parent.direction === 'topToBottom' && control.widthDim.endsWith('we')) {
        return {
          severity: 'error',
          shortDescription: 'The "Width" value cannot be in "we" unit.',
          longDescription:
            'The "Width" value cannot be in "we" unit, because parent layout has incorrect direction. ' +
            'Did you mean to use Match parent?',
          subObjects: ['width'],
        };
      }
      if (control.parent.direction === 'leftToRight' && control.heightDim.endsWith('we')) {
        return {
          severity: 'error',
          shortDescription: 'The "Height" value cannot be in "we" unit.',
          longDescription:
            'The "Height" value cannot be in "we" unit, because parent layout has incorrect direction. ' +
            'Did you mean to use Match parent?',
          subObjects: ['height'],
        };
      }
    }
    return null;
  }
}
