import { Page } from '../../../../application/page';
import { Command } from '../command';

export class AddPageCommand implements Command {
  constructor(public data: Data) {}

  apply(): void {
    this.data.parentPage.insert(this.data.page, this.data.position);
  }

  unapply(): void {
    this.data.parentPage.remove(this.data.page);
  }
}

interface Data {
  page: Page;
  parentPage: Page;
  position: number;
}
