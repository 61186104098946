export const formatVersion = '0.4.0';

export type NodeType =
  | 'Controller'
  | 'Display'
  | 'Firmware'
  | 'I/O Module'
  | 'Joystick'
  | 'Valve'
  | 'Sensor'
  | 'Inverter'
  | 'Other';

export type ProgrammableStatus =
  | 'Not programmable'
  | 'PLUS+C programmable'
  | 'PLUS+1 GUIDE programmable'
  | 'Other programmable';

export interface SystemDeclaration {
  formatVersion: typeof formatVersion;
  ecus: EcuDeclaration[];
}

export interface EcuDeclaration {
  'Full Address': string;
  'Net Address': string;
  'Node Address': string;
  Protocol: 'UDS' | 'PLUS+1';
  'Diagnostic Data ID': string;
  'Application Release Status': 'Released' | 'Not Released';
  'SIL2 Certified': 'Certified' | 'Not Certified';

  'System ID'?: string;
  'Application ID'?: string; // This is required only if Protocol is PLUS+1
  'Tool Key'?: string;
  'Application Type'?: string;
  'Application Version'?: string;
  'Node Type'?: NodeType;
  'Tool Name'?: string;
  'Tool Version'?: string;
  OS?: string;
  'Compile Time'?: string;
  'Programmable Status'?: ProgrammableStatus;
  'Bootloader Version'?: string;
  'Serial Number'?: string;
  'Manufacturing Date'?: string;
  'ECU History'?: 'Supported' | 'Not Supported';
  'Diagnostic Data Binary'?: string;
  'Part Number 0'?: string;
  'Part Number Revision 0'?: string;
  'Part Number 1'?: string;
  'Part Number Revision 1'?: string;
  'Part Number 2'?: string;
  EAN?: string;
  'Diagnostic Data Filename'?: string;

  Signals: SignalDeclaration[];
}

export interface SignalDeclaration {
  'Parameter Name': string;
  'Parameter Type': 'BOOL' | 'U8' | 'U16' | 'U32' | 'S8' | 'S16' | 'S32' | `STRING[${number}]`;
  Writeable?: 'True' | 'False';
  'Parameter Dimension'?: string;
  'Parameter Read Access Level'?: SignalAccessLevel;
  'Parameter Write Access Level'?: SignalAccessLevel;
  'Parameter Options'?: string;
}

export type SignalAccessLevel = '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9';
