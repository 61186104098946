import { ControlOptions } from '../control';
import { SimpleControl } from '../simple-control';

export class Image extends SimpleControl {
  resource: string | null;
  override intrinsicAspectRatio: number | null;

  readonly minimumWidth = 1;
  readonly minimumHeight = 1;

  readonly humanReadableName = 'Image';

  constructor({ resource = null, intrinsicAspectRatio = null, ...options }: ImageOptions) {
    super(options);

    this.resource = resource;
    this.intrinsicAspectRatio = intrinsicAspectRatio;
  }
}

export interface ImageOptions extends ControlOptions {
  resource?: string | null;
  intrinsicAspectRatio?: number | null;
}
