import { Environment } from './types';

// In production builds this properties are set in index.html from environment variables.
// Check Dockerfile for more details.
function getConfigValue(name: keyof Environment) {
  const value = (window as any).config[name] as string;
  if (value.endsWith('PLACEHOLDER') || value === '') {
    throw new Error(`Configuration property ${name} is not set in the index.html.`);
  }
  return value;
}

const environmentName = getConfigValue('environmentName');
if (
  environmentName !== 'local' &&
  environmentName !== 'dev' &&
  environmentName !== 'test' &&
  environmentName !== 'prod' &&
  environmentName !== 'e2e'
) {
  throw new Error(`Invalid environment name: ${environmentName}.`);
}

export const environment: Environment = {
  production: true,
  environmentName,
  devappBaseUrl: getConfigValue('devappBaseUrl'),
  sleipnerBaseUrl: getConfigValue('sleipnerBaseUrl'),
  fileHandlerBaseUrl: getConfigValue('fileHandlerBaseUrl'),
  systemApiBaseUrl: getConfigValue('systemApiBaseUrl'),
  dipBaseUrl: getConfigValue('dipBaseUrl'),
  dipClientId: getConfigValue('dipClientId'),
  version: getConfigValue('version'),
  animations: getConfigValue('environmentName') !== 'e2e',
};
