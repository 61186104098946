import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbConfig } from '@ng-bootstrap/ng-bootstrap';
import { AppComponent } from './app.component';
import { routes } from './app.routes';
import { CoreModule } from './core/core.module';
import { ConfigurablePreloadingStrategy } from './core/preloading-strategy';
import { TechnicianGuard } from './technician.guard';
import { environment } from '../environments/environment';

@NgModule({
  imports: [CoreModule, RouterModule.forRoot(routes, { preloadingStrategy: ConfigurablePreloadingStrategy })],
  declarations: [AppComponent],
  providers: [TechnicianGuard],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(ngbConfig: NgbConfig) {
    ngbConfig.animation = environment.animations;
  }
}
