import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { PermissionsService } from './permissions.service';
import { ToasterService } from '../toaster/toaster.service';

/**
 * Guard to check permissions.
 *
 * It uses trick from http://stackoverflow.com/a/39872345/1377864 to pass in
 * parameters, so usage is not very straightforward. See example below:
 *
 * const routes: Routes = [
 *   {
 *     path: 'users',
 *     component: UserListComponent,
 *     canActivate: [ PermissionGuard ],
 *     data: { permissionChecker: permissionChecker((permissions) => permissions.canListUsers },
 *   },
 * ];
 */
@Injectable()
export class PermissionGuard {
  constructor(
    private permissions: PermissionsService,
    private router: Router,
    private toaster: ToasterService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    // TODO: Use Object Spread operator to return this parameter from permissionChecker() function
    // so no need to write permissionChecker key in all routes. Once migrated to TS 2.1
    let checker: PermissionGuardParams = route.data['permissionChecker'];
    if (checker(this.permissions)) {
      return true;
    } else {
      this.toaster.warning(
        `You've been redirected as you don't have access to the previous page in the selected group.`,
      );
      this.router.navigate(['/']);
      return false;
    }
  }
}

export type PermissionGuardParams = (permissions: PermissionsService) => boolean;

export function permissionChecker(func: (permissions: PermissionsService) => boolean): PermissionGuardParams {
  return func;
}
