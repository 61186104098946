import { CompositeControl, CompositeControlOptions } from '../composite-control';

export class GridLayout extends CompositeControl {
  override children!: GridLayoutRow[]; // Narrows type inherited from CompositeControl.
  spacing: string;

  readonly minimumWidth = 1;
  readonly minimumHeight = 1;

  columns: number;
  rows: number;

  get safeSpacing(): string {
    // Regex will also validate that number is in [0, 25] range to avoid extra parsing.
    return /^(:?[0-9]|1[0-9]|2[0-5])du$/.test(this.spacing) ? this.spacing : '1du';
  }

  get safeColumns(): number {
    return typeof this.columns === 'number' && this.columns > 0 && this.columns <= 25 ? this.columns : 2;
  }

  get safeRows(): number {
    return typeof this.rows === 'number' && this.rows > 0 && this.rows <= 25 ? this.rows : 2;
  }

  readonly humanReadableName = 'Grid Layout';

  constructor({ spacing = '1du', columns = 2, children = [], ...options }: GridLayoutOptions) {
    super(options);

    this.spacing = spacing;
    this.columns = columns;
    this.rows = children.length;

    children.forEach((row) => {
      this.add(row);
    });
  }

  override insert(child: GridLayoutRow, index: number) {
    this.assertCellsAmount(child);
    super.insert(child, index);
  }

  private assertCellsAmount(row: GridLayoutRow) {
    if (row.children.length !== this.safeColumns) {
      throw new Error(`Row expected to have exactly ${this.safeColumns} children, but got ${row.children.length}.`);
    }
  }
}

export interface GridLayoutOptions extends CompositeControlOptions {
  children?: GridLayoutRow[];
  columns?: number;
  spacing?: string;
}

export class GridLayoutRow extends CompositeControl {
  override children!: GridLayoutCell[]; // Narrows type inherited from CompositeControl.

  readonly minimumWidth = 1;
  readonly minimumHeight = 1;

  readonly humanReadableName = 'Grid Layout Row';

  constructor(options: GridLayoutRowOptions) {
    super(options);
  }
}

export interface GridLayoutRowOptions extends CompositeControlOptions {
  children?: GridLayoutCell[];
}

export class GridLayoutCell extends CompositeControl {
  readonly minimumWidth = 1;
  readonly minimumHeight = 1;

  readonly humanReadableName = 'Grid Layout Cell';

  constructor(options: CompositeControlOptions) {
    super(options);
  }
}
