<div class="modal-header">
  <h4 class="modal-title">Choose group</h4>
  <button type="button" class="btn-close" (click)="hideModal()"></button>
</div>
<div class="modal-body">
  <devapp-listing
    [data]="data"
    [entityName]="'groups'"
    [shouldNavigate]="false"
    (dataRequest)="reloadGroups($event.page, $event.itemsPerPage, $event.search)"
  >
    <thead>
      <tr>
        <th width="50">Id</th>
        <th>Name</th>
        <th>Action</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of data?.value?.records" [class.current]="selectedGroupId === item.group.id">
        <td>{{ item.group.id }}</td>
        <td>{{ item.group.name }}</td>
        <td devappTableCellActions>
          <devapp-cell-action-button
            *ngIf="selectedGroupId !== item.group.id"
            [title]="'Select Group'"
            [text]="'Select'"
            [danger]="false"
            (click)="selectGroup(item.group)"
          ></devapp-cell-action-button>
          <span *ngIf="selectedGroupId === item.group.id">SELECTED</span>
        </td>
      </tr>
    </tbody>
  </devapp-listing>
</div>
