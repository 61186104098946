import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import parseJSON from 'date-fns/parseJSON';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Page } from './shared/page';
import { BaseService } from './shared/service';

export interface User {
  id: number;
  email: string;
  fullName: string;
  created: Date;
  updated: Date;
}

export interface SelfUser extends User {
  usedGroups: number;
  usedStorage: number;
}

@Injectable({ providedIn: 'root' })
export class UserService extends BaseService<User> {
  constructor(private client: HttpClient) {
    super();
  }

  read(id: number): Observable<User> {
    return this.client.get(`/v2/users/${id}`).pipe(map((data) => this.parse(data)));
  }

  readSelf(): Observable<SelfUser> {
    return this.client.get(`/v2/users/self`).pipe(map((data) => this.parseSelf(data)));
  }

  protected override parsePage(data: any): Page<User> {
    return {
      limit: data._metadata.limit,
      offset: data._metadata.offset,
      totalCount: data._metadata.totalCount,
      records: data.records.map((item: any) => this.parse(item)),
    };
  }

  protected parse(data: any): User {
    return {
      id: data.id,
      email: data.email,
      fullName: data.fullName,
      created: parseJSON(data.created),
      updated: parseJSON(data.updated),
    };
  }

  protected parseSelf(data: any) {
    return {
      ...this.parse(data),
      usedGroups: data.usedGroups,
      usedStorage: data.usedStorage,
    };
  }
}
