import { EdmondsKarp } from './edmonds-karp';
import { EcuMatchingCriteria, SystemMatchingCriteria } from './system-matching-criteria';
import { EcuSpecification, SystemSpecification } from '../system-specification/system-specification';

/**
 * System Matcher can check if given system specification fits to given criteria.
 */
export class SystemMatcher {
  idToSpecification = new Map<string, EcuSpecification>();
  idToCriteria = new Map<string, EcuMatchingCriteria>();
  fullAddressToId = new Map<number, string>();

  matches(specification: SystemSpecification, criteria: SystemMatchingCriteria): boolean {
    // It's definitely not a match, when there are more slots, than candidates.
    if (criteria.ecus.length > specification.ecus.length) {
      return false;
    }

    const flowGraphVertexCount = criteria.ecus.length + specification.ecus.length + 2;
    const flow = new EdmondsKarp(flowGraphVertexCount);

    const sourceVertex = specification.ecus.length + criteria.ecus.length;
    const sinkVertex = specification.ecus.length + criteria.ecus.length + 1;

    for (let i = 0; i < specification.ecus.length; i++) {
      const specVertex = i;
      flow.addEdge(sourceVertex, specVertex);
    }

    let exactMatch = true;
    for (let i = 0; i < criteria.ecus.length; i++) {
      const ecuCriteria = criteria.ecus[i];
      const criteriaVertex = specification.ecus.length + i;

      let matchingECUSpecificationsCount = 0;
      for (let j = 0; j < specification.ecus.length; j++) {
        const ecuSpecification = specification.ecus[j];
        const specVertex = j;

        if (this.ecuMatches(ecuSpecification, ecuCriteria)) {
          flow.addEdge(specVertex, criteriaVertex);
          matchingECUSpecificationsCount++;
        }
      }

      // If no ECU specifications match one of ECU matching criteria, than it is definitely not a match.
      if (matchingECUSpecificationsCount === 0) {
        return false;
      }

      if (matchingECUSpecificationsCount !== 1) {
        exactMatch = false;
      }

      flow.addEdge(criteriaVertex, sinkVertex);
    }

    const flowHypothesis = flow.clone();
    const flowCount = flowHypothesis.computeMaxFlow(sourceVertex, sinkVertex);

    // This means that not all ECU matching criteria items received matching ECU specifications.
    if (flowCount !== criteria.ecus.length) {
      return false;
    }

    // If there was exactly 1 candidate matching every available slot
    // there is no need to check different paths, because there is only
    // one arrangement is possible. This should significantly optimize
    // a common case.
    if (exactMatch) {
      this.buildMapping(flowHypothesis, specification, criteria);
      return true;
    }

    const userEdges = flowHypothesis.getUsedEdges();

    for (const usedEdge of userEdges) {
      const flowWithoutTakenPath = flow.clone();
      flowWithoutTakenPath.removeEdge(usedEdge[0], usedEdge[1]);
      const flowWithoutTakenPathCount = flowWithoutTakenPath.computeMaxFlow(sourceVertex, sinkVertex);

      // This means that there is more than one arrangement of ECU specifications, which satisfies matching
      // criteria. Such situation is considered a no-match.
      if (flowWithoutTakenPathCount === criteria.ecus.length) {
        return false;
      }
    }

    this.buildMapping(flowHypothesis, specification, criteria);
    return true;
  }

  private ecuMatches(specification: EcuSpecification, criteria: EcuMatchingCriteria): boolean {
    return criteria.criteria.every((criterion) => criterion.matches(specification));
  }

  private buildMapping(flow: EdmondsKarp, specification: SystemSpecification, criteria: SystemMatchingCriteria) {
    for (const usedEdge of flow.getUsedEdges()) {
      const ecuSpecification = specification.ecus[usedEdge[0]];
      const ecuCriteria = criteria.ecus[usedEdge[1] - specification.ecus.length];

      this.idToSpecification.set(ecuCriteria.ecuId, ecuSpecification);
      this.idToCriteria.set(ecuCriteria.ecuId, ecuCriteria);
      this.fullAddressToId.set(ecuSpecification.fullAddress, ecuCriteria.ecuId);
    }
  }
}

export function systemMatches(specification: SystemSpecification, criteria: SystemMatchingCriteria): SystemMatchResult {
  const matcher = new SystemMatcher();
  const matches = matcher.matches(specification, criteria);
  if (matches) {
    return {
      matches: true,
      idToSpecification: matcher.idToSpecification,
      idToCriteria: matcher.idToCriteria,
      fullAddressToId: matcher.fullAddressToId,
    };
  } else {
    return {
      matches: false,
    };
  }
}

export interface SystemMatch {
  matches: true;
  idToSpecification: Map<string, EcuSpecification>;
  idToCriteria: Map<string, EcuMatchingCriteria>;
  fullAddressToId: Map<number, string>;
}

export interface SystemNoMatch {
  matches: false;
}

export type SystemMatchResult = SystemMatch | SystemNoMatch;
