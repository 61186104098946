import { AdapterContract } from './contract';
import { A0_2_2 } from './v0-2-2';
import { parseIntSafe } from '../../utils/number';
import { Application } from '../application';
import { Control } from '../control';
import { Area } from '../controls/area';
import { Bar, BarChart } from '../controls/bar-chart';
import { BooleanSignal } from '../controls/boolean-signal';
import {
  Button,
  ButtonAction,
  NavigatePageButtonAction,
  SetPulseButtonAction,
  WriteSignalsButtonAction,
} from '../controls/button';
import { EnumSignal } from '../controls/enum-signal';
import { Gauge } from '../controls/gauge';
import { GridLayout, GridLayoutCell, GridLayoutRow } from '../controls/grid-layout';
import { Hint } from '../controls/hint';
import { Image } from '../controls/image';
import { Label } from '../controls/label';
import { Line, LineChart } from '../controls/line-chart';
import { NumericSignal } from '../controls/numeric-signal';
import { StackLayout } from '../controls/stack-layout';
import { Text } from '../controls/text';
import { Page } from '../page';
import { ArraySignalIdentifier, EcuSignalSource, SignalIdentifier, SignalOption } from '../signal-identifier';

export class ApplicationAdapterV0_2_2 implements AdapterContract {
  createApplication(declaration: A0_2_2.ApplicationDeclaration): Application {
    return new Application({
      pages: declaration.pages.map((item) => this.createPage(item, declaration.system)),
      formatVersion: '0.2.2',
    });
  }

  createPage(declaration: A0_2_2.PageDeclaration, system: A0_2_2.SystemDeclaration = { ecus: [] }): Page {
    const pages = declaration.pages != null ? declaration.pages.map((child) => this.createPage(child, system)) : [];

    const controls =
      declaration.controls != null ? declaration.controls.map((item) => this.createControl(item, system)) : [];

    return new Page({
      id: declaration.id,
      name: declaration.name,
      pages,
      controls,
    });
  }

  createControl(data: A0_2_2.AnyControlDeclaration, system: A0_2_2.SystemDeclaration = { ecus: [] }): Control {
    switch (data.type) {
      case 'BarChart':
        return this.createBarChartControl(data, system);
      case 'BooleanSignal':
        return this.createBooleanSignalControl(data, system);
      case 'Button':
        return this.createButtonControl(data, system);
      case 'EnumSignal':
        return this.createEnumSignalControl(data, system);
      case 'Gauge':
        return this.createGaugeControl(data, system);
      case 'GridLayout':
        return this.createGridLayoutControl(data, system);
      case 'GridLayoutRow':
        return this.createGridLayoutRowControl(data, system);
      case 'GridLayoutCell':
        return this.createGridLayoutCellControl(data, system);
      case 'Hint':
        return this.createHintControl(data);
      case 'Image':
        return this.createImageControl(data);
      case 'Label':
        return this.createLabelControl(data);
      case 'LineChart':
        return this.createLineChartControl(data, system);
      case 'NumericSignal':
        return this.createNumericSignalControl(data, system);
      case 'StackLayout':
        return this.createStackLayoutControl(data, system);
      case 'Text':
        return this.createTextControl(data);
      default:
        throw new Error(`Unsupported control type: ${(data as any).type}.`);
    }
  }

  createSignalIdentifier(
    data: A0_2_2.AnySignalIdentifierDeclaration | null,
    system: A0_2_2.SystemDeclaration,
  ): SignalIdentifier {
    if (data == null) {
      return new SignalIdentifier();
    }

    let unit = '';
    let options: SignalOption[] = [];

    for (const ecu of system.ecus) {
      if (ecu.fullAddress === data.ecuFullAddress) {
        for (const signal of ecu.signals) {
          if (signal.name === data.signalName) {
            unit = signal.unit != null ? signal.unit : '';
            options = signal.options != null ? signal.options : [];

            break;
          }
        }

        break;
      }
    }

    switch (data.type) {
      case 'SignalIdentifier':
        return new SignalIdentifier({
          unit,
          options,
          source: new EcuSignalSource({ ecuFullAddress: data.ecuFullAddress, signalName: data.signalName }),
        });
      case 'ArraySignalIdentifier':
        return new ArraySignalIdentifier({
          index: data.index,
          unit,
          options,
          source: new EcuSignalSource({ ecuFullAddress: data.ecuFullAddress, signalName: data.signalName }),
        });
      default:
        throw new Error(`Unsupported signal identifier type: ${(data as any).type}.`);
    }
  }

  createLabelControl({ margin, ...declaration }: A0_2_2.LabelControlDeclaration): Label {
    return new Label({ margin: this.createMargins(margin), ...declaration });
  }

  createTextControl({ margin, ...declaration }: A0_2_2.TextControlDeclaration): Text {
    return new Text({ margin: this.createMargins(margin), ...declaration });
  }

  createHintControl({ margin, ...declaration }: A0_2_2.HintControlDeclaration): Hint {
    return new Hint({ margin: this.createMargins(margin), ...declaration });
  }

  createLine(
    { signalID, ...declaration }: A0_2_2.LineChartControlDeclaration.Line,
    system: A0_2_2.SystemDeclaration,
  ): Line {
    return new Line({ ...declaration, signalId: this.createSignalIdentifier(signalID, system) });
  }

  createLineChartControl(
    { type: _, margin, ...declaration }: A0_2_2.LineChartControlDeclaration,
    system: A0_2_2.SystemDeclaration,
  ): LineChart {
    return new LineChart({
      ...declaration,
      margin: this.createMargins(margin),
      lines: declaration.lines.map((line) => this.createLine(line, system)),
    });
  }

  createArea(declaration: A0_2_2.GaugeControlDeclaration.Area): Area {
    return new Area(declaration);
  }

  createGaugeControl(
    { type: _, signalID, margin, ...declaration }: A0_2_2.GaugeControlDeclaration,
    system: A0_2_2.SystemDeclaration,
  ): Gauge {
    return new Gauge({
      ...declaration,
      margin: this.createMargins(margin),
      signalId: this.createSignalIdentifier(signalID, system),
      areas: declaration.areas.map((area) => this.createArea(area)),
    });
  }

  createNumericSignalControl(
    { signalID, type: _, margin, ...rest }: A0_2_2.NumericSignalControlDeclaration,
    system: A0_2_2.SystemDeclaration,
  ): NumericSignal {
    return new NumericSignal({
      margin: this.createMargins(margin),
      signalId: this.createSignalIdentifier(signalID, system),
      ...rest,
    });
  }

  createEnumSignalControl(
    { signalID, type: _, margin, ...rest }: A0_2_2.EnumSignalControlDeclaration,
    system: A0_2_2.SystemDeclaration,
  ): EnumSignal {
    return new EnumSignal({
      margin: this.createMargins(margin),
      signalId: this.createSignalIdentifier(signalID, system),
      ...rest,
    });
  }

  createBooleanSignalControl(
    { signalID, type: _, margin, ...rest }: A0_2_2.BooleanSignalControlDeclaration,
    system: A0_2_2.SystemDeclaration,
  ): BooleanSignal {
    return new BooleanSignal({
      margin: this.createMargins(margin),
      signalId: this.createSignalIdentifier(signalID, system),
      ...rest,
    });
  }

  createBar(
    { signalID, ...declaration }: A0_2_2.BarChartControlDeclaration.Bar,
    system: A0_2_2.SystemDeclaration,
  ): Bar {
    return new Bar({ ...declaration, signalId: this.createSignalIdentifier(signalID, system) });
  }

  createBarChartControl(
    { type: _, margin, ...declaration }: A0_2_2.BarChartControlDeclaration,
    system: A0_2_2.SystemDeclaration,
  ): BarChart {
    return new BarChart({
      ...declaration,
      margin: this.createMargins(margin),
      bars: declaration.bars.map((bar) => this.createBar(bar, system)),
    });
  }

  createButtonControl(
    { type: _, action, margin, ...options }: A0_2_2.ButtonControlDeclaration,
    system: A0_2_2.SystemDeclaration,
  ): Button {
    return new Button({
      margin: this.createMargins(margin),
      action: this.createButtonAction(action, system),
      ...options,
    });
  }

  createButtonAction(declaration: A0_2_2.AnyButtonActionDeclaration, system: A0_2_2.SystemDeclaration): ButtonAction {
    switch (declaration.type) {
      case 'navigatePage':
        return new NavigatePageButtonAction({ pageId: declaration.pageId });
      case 'writeSignals':
        return new WriteSignalsButtonAction();
      case 'setPulse':
        return new SetPulseButtonAction({ signalId: this.createSignalIdentifier(declaration.signalId, system) });
    }
  }

  createImageControl({ margin, ...declaration }: A0_2_2.ImageControlDeclaration): Image {
    return new Image({ margin: this.createMargins(margin), ...declaration });
  }

  createStackLayoutControl(
    { type: _, children, margin, ...options }: A0_2_2.StackLayoutControlDeclaration,
    system: A0_2_2.SystemDeclaration,
  ): StackLayout {
    return new StackLayout({
      ...options,
      margin: this.createMargins(margin),
      children: children.map((item) => this.createControl(item, system)),
    });
  }

  createGridLayoutControl(
    { type: _, children, spacing, margin, ...options }: A0_2_2.GridLayoutControlDeclaration,
    system: A0_2_2.SystemDeclaration,
  ): GridLayout {
    return new GridLayout({
      ...options,
      margin: this.createMargins(margin),
      spacing: `${spacing}du`,
      children: children.map((item) => this.createControl(item, system) as GridLayoutRow),
    });
  }

  createGridLayoutRowControl(
    { type: _, children, margin, ...options }: A0_2_2.GridLayoutRowControlDeclaration,
    system: A0_2_2.SystemDeclaration,
  ): GridLayoutRow {
    return new GridLayoutRow({
      ...options,
      margin: this.createMargins(margin),
      children: children.map((item) => this.createControl(item, system) as GridLayoutCell),
    });
  }

  createGridLayoutCellControl(
    { type: _, children, margin, ...options }: A0_2_2.GridLayoutCellControlDeclaration,
    system: A0_2_2.SystemDeclaration,
  ) {
    return new GridLayoutCell({
      ...options,
      margin: this.createMargins(margin),
      children: children.map((item) => this.createControl(item, system)),
    });
  }

  createMargins(value: A0_2_2.ControlDeclaration.Margins = {}) {
    const { top = '0du', bottom = '0du', left = '0du', right = '0du' } = value;

    return {
      top: top === '0' ? `${top}du` : top,
      bottom: bottom === '0' ? `${bottom}du` : bottom,
      left: left === '0' ? `${left}du` : left,
      right: right === '0' ? `${right}du` : right,
    };
  }

  serializeControl(control: Control): A0_2_2.AnyControlDeclaration {
    let json;

    if (control instanceof BarChart) {
      json = this.serializeBarChart(control);
    } else if (control instanceof BooleanSignal) {
      json = this.serializeBooleanSignal(control);
    } else if (control instanceof Button) {
      json = this.serializeButton(control);
    } else if (control instanceof EnumSignal) {
      json = this.serializeEnumSignal(control);
    } else if (control instanceof Gauge) {
      json = this.serializeGauge(control);
    } else if (control instanceof GridLayout) {
      json = this.serializeGridLayout(control);
    } else if (control instanceof GridLayoutRow) {
      json = this.serializeGridLayoutRow(control);
    } else if (control instanceof GridLayoutCell) {
      json = this.serializeGridLayoutCell(control);
    } else if (control instanceof Hint) {
      json = this.serializeHint(control);
    } else if (control instanceof Image) {
      json = this.serializeImage(control);
    } else if (control instanceof Label) {
      json = this.serializeLabel(control);
    } else if (control instanceof LineChart) {
      json = this.serializeLineChart(control);
    } else if (control instanceof NumericSignal) {
      json = this.serializeNumericSignal(control);
    } else if (control instanceof StackLayout) {
      json = this.serializeStackLayout(control);
    } else if (control instanceof Text) {
      json = this.serializeText(control);
    } else {
      throw new Error(`Unknown control type: ${control.constructor.name}.`);
    }

    return {
      ...json,
      width: control.width,
      height: control.height,
      margin: {
        top: control.margin.top === '0du' ? '0' : control.margin.top,
        bottom: control.margin.bottom === '0du' ? '0' : control.margin.bottom,
        right: control.margin.right === '0du' ? '0' : control.margin.right,
        left: control.margin.left === '0du' ? '0' : control.margin.left,
      },
    };
  }

  serializeSignalIdentifier(signal: SignalIdentifier): A0_2_2.AnySignalIdentifierDeclaration | null {
    if (!(signal.source instanceof EcuSignalSource)) {
      return null;
    }

    if (signal.source.ecuFullAddress == null) {
      return null;
    }

    if (signal instanceof ArraySignalIdentifier) {
      return {
        type: 'ArraySignalIdentifier',
        ecuFullAddress: signal.source.ecuFullAddress!,
        signalName: signal.source.signalName!,
        index: signal.index!,
      };
    } else {
      return {
        type: 'SignalIdentifier',
        ecuFullAddress: signal.source.ecuFullAddress!,
        signalName: signal.source.signalName!,
      };
    }
  }

  serializeText(control: Text): A0_2_2.TextControlDeclaration {
    return {
      id: control.id,
      type: 'Text',
      text: control.text,
      align: control.align,
    };
  }

  serializeLabel(label: Label): A0_2_2.LabelControlDeclaration {
    return {
      id: label.id,
      type: 'Label',
      text: label.text,
      align: label.align,
    };
  }

  serializeHint(control: Hint): A0_2_2.HintControlDeclaration {
    return {
      id: control.id,
      type: 'Hint',
      title: control.title,
      text: control.text,
      align: control.align,
    };
  }

  serializeNumericSignal(control: NumericSignal): A0_2_2.NumericSignalControlDeclaration {
    return {
      id: control.id,
      type: 'NumericSignal',
      readonly: control.readonly,
      standalone: control.standalone,
      signalID: this.serializeSignalIdentifier(control.signalId),
    };
  }

  serializeLine(line: Line): A0_2_2.LineChartControlDeclaration.Line {
    return {
      label: line.label,
      color: line.color,
      signalID: this.serializeSignalIdentifier(line.signalId),
    };
  }

  serializeLineChart(control: LineChart): A0_2_2.LineChartControlDeclaration {
    return {
      id: control.id,
      type: 'LineChart',
      minimum: control.minimum,
      maximum: control.maximum,
      valuesCount: control.valuesCount,
      lines: control.lines.map((line) => this.serializeLine(line)),
    };
  }

  serializeEnumSignal(control: EnumSignal): A0_2_2.EnumSignalControlDeclaration {
    return {
      id: control.id,
      type: 'EnumSignal',
      readonly: control.readonly,
      standalone: control.standalone,
      signalID: this.serializeSignalIdentifier(control.signalId),
    };
  }

  serializeBar(bar: Bar): A0_2_2.BarChartControlDeclaration.Bar {
    return {
      label: bar.label,
      color: bar.color,
      signalID: this.serializeSignalIdentifier(bar.signalId),
    };
  }

  serializeBarChart(control: BarChart): A0_2_2.BarChartControlDeclaration {
    return {
      id: control.id,
      type: 'BarChart',
      minimum: control.minimum,
      maximum: control.maximum,
      direction: control.direction,
      bars: control.bars.map((bar) => this.serializeBar(bar)),
    };
  }

  serializeBooleanSignal(control: BooleanSignal): A0_2_2.BooleanSignalControlDeclaration {
    return {
      id: control.id,
      type: 'BooleanSignal',
      readonly: control.readonly,
      standalone: control.standalone,
      signalID: this.serializeSignalIdentifier(control.signalId),
    };
  }

  serializeApplication(app: Application): A0_2_2.ApplicationDeclaration {
    const pages = app.rootPage.children.map((page) => this.serializePage(page));

    return {
      formatVersion: '0.2.2',
      pages,
      system: { ecus: [] },
    };
  }

  serializePage(page: Page): A0_2_2.PageDeclaration {
    const declaration: A0_2_2.PageDeclaration = {
      id: page.id,
      name: page.name,
    };

    if (page.controlRoot.children.length !== 0) {
      declaration.controls = page.controlRoot.children.map((child) => this.serializeControl(child));
    }

    if (page.children.length !== 0) {
      declaration.pages = page.children.map((pge) => this.serializePage(pge));
    }

    return declaration;
  }

  serializeStackLayout(stackLayout: StackLayout): A0_2_2.StackLayoutControlDeclaration {
    const children = stackLayout.children.map((child) => this.serializeControl(child));

    return {
      id: stackLayout.id,
      type: 'StackLayout',
      direction: stackLayout.direction,
      children,
    };
  }

  serializeGridLayout(control: GridLayout): A0_2_2.GridLayoutControlDeclaration {
    return {
      id: control.id,
      type: 'GridLayout',
      columns: control.columns,
      spacing: parseIntSafe(control.spacing.slice(0, -2)),
      children: control.children.map((item) => this.serializeControl(item) as A0_2_2.GridLayoutRowControlDeclaration),
    };
  }

  serializeGridLayoutRow(control: GridLayoutRow): A0_2_2.GridLayoutRowControlDeclaration {
    return {
      id: control.id,
      type: 'GridLayoutRow',
      children: control.children.map((item) => this.serializeControl(item) as A0_2_2.GridLayoutCellControlDeclaration),
    };
  }

  serializeGridLayoutCell(control: GridLayoutCell): A0_2_2.GridLayoutCellControlDeclaration {
    return {
      id: control.id,
      type: 'GridLayoutCell',
      children: control.children.map((item) => this.serializeControl(item)),
    };
  }

  serializeImage(control: Image): A0_2_2.ImageControlDeclaration {
    return {
      id: control.id,
      type: 'Image',
      resource: control.resource,
      intrinsicAspectRatio: control.intrinsicAspectRatio,
    };
  }

  serializeArea(area: Area): A0_2_2.GaugeControlDeclaration.Area {
    return {
      range: area.range,
      color: area.color,
    };
  }

  serializeGauge(control: Gauge): A0_2_2.GaugeControlDeclaration {
    return {
      id: control.id,
      type: 'Gauge',
      minimum: control.minimum,
      maximum: control.maximum,
      areas: control.areas.map((area) => this.serializeArea(area)),
      signalID: this.serializeSignalIdentifier(control.signalId),
      valueForegroundColor: control.valueForegroundColor,
    };
  }

  serializeButton(control: Button): A0_2_2.ButtonControlDeclaration {
    return {
      id: control.id,
      type: 'Button',
      text: control.text,
      action: this.serializeButtonAction(control.action),
    };
  }

  serializeButtonAction(action: ButtonAction): A0_2_2.AnyButtonActionDeclaration {
    if (action instanceof WriteSignalsButtonAction) {
      return {
        type: 'writeSignals',
      };
    } else if (action instanceof NavigatePageButtonAction) {
      return {
        type: 'navigatePage',
        pageId: action.pageId,
      };
    } else if (action instanceof SetPulseButtonAction) {
      return {
        type: 'setPulse',
        signalId: this.serializeSignalIdentifier(action.signalId),
      };
    } else {
      throw new Error(`Unexpected button action: ${action}.`);
    }
  }
}
