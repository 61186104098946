import { ControlVisitor } from './control-visitor';
import { Control } from '../application/control';
import { Button, NavigatePageButtonAction } from '../application/controls/button';
import { BulkChangesManager } from '../host/dev/history/changes-manager';

export class UpdateButtonLinksVisitor extends ControlVisitor {
  pageOldToNewIdMap = new Map();
  batchAction: BulkChangesManager;

  constructor(batchAction: BulkChangesManager, pageOldToNewIdMap: Map<string, string>) {
    super();
    this.pageOldToNewIdMap = pageOldToNewIdMap;
    this.batchAction = batchAction;
  }

  visitControl(control: Control): void {
    if (control instanceof Button && control.action instanceof NavigatePageButtonAction) {
      const pageId = this.pageOldToNewIdMap.get(control.action.pageId);
      if (pageId != null) {
        const navigateAction = new NavigatePageButtonAction({ pageId });
        this.batchAction.editControlProperty(control, navigateAction, 'action');
      }
    }
  }
}
