import { CompositeControl } from './composite-control';
import { Root } from './controls/root';
import { VisibilityCondition } from './controls/visibility';
import { ControlVisitor } from '../visitor/control-visitor';

export abstract class Control {
  id: string;
  width: string;
  height: string;
  margin: {
    top: string;
    bottom: string;
    left: string;
    right: string;
  };
  visibility: {
    conditions: VisibilityCondition[];
    aggregator: 'or' | 'and';
  };
  parent: CompositeControl | null = null;

  readonly fallbackWidth: string = 'wrapContent';
  readonly fallbackHeight: string = 'wrapContent';
  readonly intrinsicAspectRatio: number | null = null; // aspectRatio = width / height

  /**
   * Minimum width for control (in du).
   */
  abstract get minimumWidth(): number;

  /**
   * Minimum width for control (in du).
   */
  abstract get minimumHeight(): number;

  abstract get humanReadableName(): string;

  get parentRoot(): Root | null {
    if (this.parent == null) {
      return null;
    }

    return this.parent.parentRoot;
  }

  get widthDim() {
    return this._getSafeDimension(this.width, this.minimumWidth, this.fallbackWidth);
  }

  get heightDim() {
    return this._getSafeDimension(this.height, this.minimumHeight, this.fallbackHeight);
  }

  get marginLeft() {
    return this._getSafeMargin(this.margin.left);
  }

  get marginRight() {
    return this._getSafeMargin(this.margin.right);
  }

  get marginTop() {
    return this._getSafeMargin(this.margin.top);
  }

  get marginBottom() {
    return this._getSafeMargin(this.margin.bottom);
  }

  protected constructor({
    id,
    width = 'wrapContent',
    height = 'wrapContent',
    margin = {},
    visibility = {},
  }: ControlOptions) {
    const { top = '0du', bottom = '0du', left = '0du', right = '0du' } = margin;
    const { conditions = [], aggregator = 'or' } = visibility;

    this.id = id;
    this.width = width;
    this.height = height;
    this.margin = { top, bottom, left, right };
    this.visibility = { conditions, aggregator };
  }

  abstract accept<T extends ControlVisitor>(visitor: T): T;

  private _getSafeMargin(margin: string) {
    return /^[0-9]+du$/.test(margin) ? margin : '0du';
  }

  private _getSafeDimension(value: string, minimum: number, fallback: string) {
    if (value === 'matchParent' || value === 'wrapContent' || /^[1-9][0-9]*(du|we)$/.test(value)) {
      if (value.endsWith('du')) {
        const intValue = parseInt(value, 10);
        return intValue <= minimum ? `${minimum}du` : `${intValue}du`;
      }

      return value;
    }

    return fallback;
  }
}

export interface ControlOptions {
  id: string;
  width?: string;
  height?: string;
  margin?: {
    top?: string;
    bottom?: string;
    left?: string;
    right?: string;
  };
  visibility?: {
    conditions?: VisibilityCondition[];
    aggregator?: 'or' | 'and';
  };
}
