import { ControlValidator } from './control.validator';
import { BarChart } from '../../../../application/controls/bar-chart';
import { Problem, Validatable, ValidationTrigger } from '../validator';

export class BarChartValidator extends ControlValidator {
  public isRelevant(node: Validatable, triggers: ValidationTrigger[]): boolean {
    return (
      node instanceof BarChart &&
      (triggers.includes('nodeProperties') || triggers.includes('signals') || triggers.includes('applicationWide'))
    );
  }

  public validate(chart: BarChart): Problem[] {
    const allProblems: Problem[] = super.validateControl(chart);

    const problem = this.validateMinMax(chart);
    if (problem != null) {
      allProblems.push(problem);
    }

    allProblems.push(...this.validateBars(chart));

    return allProblems;
  }

  private validateBars(chart: BarChart): Problem[] {
    if (chart.bars.length === 0) {
      return [
        {
          severity: 'warning',
          shortDescription: 'Bar Chart has no signals.',
          longDescription:
            "Bar Chart does not have any associated signals and won't display any data. " +
            'Consider attaching some signals using Bars property in the Bar Chart Properties pane.',
          subObjects: ['bars'],
        },
      ];
    } else {
      for (const bar of chart.bars) {
        const problems = this.validateSignalId(bar.signalId, chart.humanReadableName, 'bars', this.systemIndex);
        if (problems.length > 0) {
          return problems;
        }
      }
    }
    return [];
  }

  private validateMinMax(chart: BarChart): Problem | null {
    if (typeof chart.minimum !== 'number') {
      return {
        severity: 'error',
        shortDescription: 'Bar Chart minimum is not a valid number.',
        longDescription:
          'Bar Chart minimum is not a valid number. ' +
          'Please specify a valid number using Minimum property in the Bar Chart Properties pane.',
        subObjects: ['minimum'],
      };
    } else if (typeof chart.maximum !== 'number') {
      return {
        severity: 'error',
        shortDescription: 'Bar Chart maximum is not a valid number.',
        longDescription:
          'Bar Chart maximum is not a valid number. ' +
          'Please specify a valid number using Maximum property in the Bar Chart Properties pane.',
        subObjects: ['maximum'],
      };
    } else if (chart.minimum >= chart.maximum) {
      return {
        severity: 'error',
        shortDescription: 'Bar Chart minimum is greater than maximum.',
        longDescription:
          'Bar Chart minimum is greater than maximum. ' +
          'Please ensure that that Maximum property is strictly greater than Minimum in the Bar Chart Properties pane.',
        subObjects: ['minimum', 'maximum'],
      };
    }
    return null;
  }
}
