export class Area {
  color: string;
  range: [number, number];
  warning?: boolean;

  constructor(options: AreaOptions) {
    this.color = options.color;
    this.range = options.range;
    this.warning = options.warning;
  }
}

export interface AreaOptions {
  range: [number, number];
  color: string;
  warning?: boolean;
}
