import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { merge, Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { BreadcrumbService } from './breadcrumb.service';

@Component({
  selector: 'devapp-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbComponent implements OnInit {
  breadcrumbs$: Observable<Breadcrumb[]>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private service: BreadcrumbService,
  ) {}

  ngOnInit() {
    const navigationEnd = this.router.events.pipe(filter((event) => event instanceof NavigationEnd));

    this.breadcrumbs$ = merge(navigationEnd, this.service.invalidated).pipe(
      map(() => this.makeBreadcrumbs(this.route.root)),
    );
  }

  private makeBreadcrumbs(route: ActivatedRoute, currentUrl = ''): Breadcrumb[] {
    if (route == null) {
      return [];
    }

    const nextRoute = route.children.find((child) => child.outlet === 'primary');
    const url = currentUrl + '/' + route.snapshot.url.map((segment) => segment.path).join('/');

    if (route.snapshot.data['breadcrumb'] != null) {
      const value = route.snapshot.data['breadcrumb'];
      const label: Observable<string> = value instanceof Function ? value(route) : of(value);

      return [{ label, url }, ...this.makeBreadcrumbs(nextRoute, url)];
    }

    return this.makeBreadcrumbs(nextRoute, url);
  }
}

interface Breadcrumb {
  label: Observable<string>;
  url: string;
}
