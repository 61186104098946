import { PageVariationVisitor } from './page-variation-visitor';
import { PageVariation } from '../application/page-variation';
import { ScreenType } from '../application/screen-type';
import { BulkChangesManager } from '../host/dev/history/changes-manager';

export class UpdateScreenTypesVisitor extends PageVariationVisitor {
  constructor(
    private batchAction: BulkChangesManager,
    private existingScreenTypes: ScreenType[],
    private oldToNewScreenTypeMap: Map<ScreenType, ScreenType>,
  ) {
    super();
  }

  visitPageVariation(variation: PageVariation): void {
    if (variation.screenType.raw != null) {
      if (!this.oldToNewScreenTypeMap.has(variation.screenType.raw)) {
        const existingScreenType = this.existingScreenTypes.find(
          (st) =>
            st.minWidth.raw === variation.screenType.raw.minWidth.raw &&
            st.name.raw === variation.screenType.raw.name.raw &&
            st.orientation.raw === variation.screenType.raw.orientation.raw,
        );
        if (existingScreenType != null) {
          this.oldToNewScreenTypeMap.set(variation.screenType.raw, existingScreenType);
        } else {
          variation.screenType.raw.id = this.batchAction.getNewId('screen-type');
          this.batchAction.addScreenType(variation.screenType.raw);
          this.oldToNewScreenTypeMap.set(variation.screenType.raw, variation.screenType.raw);
        }
      }

      variation.screenType.set(this.oldToNewScreenTypeMap.get(variation.screenType.raw));
    }
  }
}
