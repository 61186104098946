import { PageVariation } from '../../../../application/page-variation';
import { Problem, Validatable, ValidationTrigger, Validator } from '../validator';

export class PageVariationValidator extends Validator {
  isRelevant(node: Validatable, triggers: ValidationTrigger[]): boolean {
    return (
      node instanceof PageVariation && (triggers.includes('nodeProperties') || triggers.includes('applicationWide'))
    );
  }

  validate(variation: PageVariation): Problem[] {
    const allProblems: Problem[] = [];

    const problem1 = this.validateHasControls(variation);
    if (problem1 != null) {
      allProblems.push(problem1);
    }

    const problem2 = this.validateHasScreenType(variation);
    if (problem2 != null) {
      allProblems.push(problem2);
    }

    const problem3 = this.validateDuplicateScreenTypes(variation);
    if (problem3 != null) {
      allProblems.push(problem3);
    }

    return allProblems;
  }

  private validateHasControls(variation: PageVariation): Problem | null {
    if (variation.controlRoot.children.length === 0) {
      return {
        severity: 'warning',
        shortDescription: 'Page Variation has no content.',
        longDescription: 'Page Variation has no content. Consider removing empty page variation.',
        subObjects: [],
      };
    }
    return null;
  }

  private validateHasScreenType(variation: PageVariation): Problem | null {
    if (variation.screenType.raw == null) {
      return {
        severity: 'error',
        shortDescription: 'Page Variation has no screen type.',
        longDescription: 'Page Variation has no screen type. Please assign one.',
        subObjects: ['screenType'],
      };
    }
    return null;
  }

  private validateDuplicateScreenTypes(variation: PageVariation): Problem | null {
    if (
      variation.screenType.raw != null &&
      variation.parentPage != null &&
      variation.parentPage.variations.some((v) => v !== variation && v.screenType.raw === variation.screenType.raw)
    ) {
      return {
        severity: 'error',
        shortDescription: 'Multiple Page Variations have the same screen type.',
        longDescription:
          'Multiple Page Variations have the same screen type. ' +
          'This is likely a mistake as only the last one will ever be displayed.',
        subObjects: ['screenType'],
      };
    }
    return null;
  }
}
