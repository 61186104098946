import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import parseJSON from 'date-fns/parseJSON';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Page } from './shared/page';
import { BaseService } from './shared/service';

export type LicenseType =
  | 'ProfessionalLicense'
  | 'BasicAddOn'
  | 'ProfessionalAddOn'
  | 'EnterpriseAddOn'
  | 'TestLicense';

export interface License {
  id: number;
  userId: number;
  licenseId: string;
  type: LicenseType;
  name: string;
  storage: number;
  groups: number;
  expiresOn: Date;
  created: Date;
  updated: Date;
}

@Injectable({ providedIn: 'root' })
export class LicenseService extends BaseService<License> {
  constructor(private client: HttpClient) {
    super();
  }

  list(params: ListOptions = {}): Observable<Page<License>> {
    return this.client
      .get(`/v2/licenses`, { params: this.buildParams(params) })
      .pipe(map((data: any) => this.parsePage(data)));
  }

  protected parse(data: any): License {
    return {
      id: data.id,
      userId: data.userId,
      licenseId: data.licenseId,
      type: data.type,
      name: data.name,
      storage: data.storage,
      groups: data.groups,
      expiresOn: parseJSON(data.expiresOn),
      created: parseJSON(data.created),
      updated: parseJSON(data.updated),
    };
  }
}

interface ListOptions {
  limit?: number;
  offset?: number;
  userId?: number;
}
