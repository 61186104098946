import { AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ArraySignalIdentifier, DevHost, SignalIdentifier } from '@plus1tools/duit';
import { uniqBy } from 'lodash-es';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Page } from '../core/data/shared/page';

export function getValueForKeyPath(object: any, keyPath: string): any {
  if (object == null || keyPath == null) {
    return null;
  }

  if (!keyPath.includes('.')) {
    return object[keyPath];
  }

  let chain = keyPath.split('.');
  let firstKey = chain.shift();
  let shiftedKeyPath = chain.join('.');

  return getValueForKeyPath(object[firstKey], shiftedKeyPath);
}

export class CustomValidators {
  static color(control: AbstractControl): ValidationErrors {
    return !/^#([a-f0-9]{3}|[a-f0-9]{6})$/.test(control.value) ? { color: true } : null;
  }

  static range(control: FormArray<FormControl<number>>): ValidationErrors {
    return control.at(0).value > control.at(1).value ? { range: true } : null;
  }

  static signalIdentifier(host: DevHost): ValidatorFn {
    return (control: AbstractControl) => {
      if (control.value == null || control.value.length === 0) {
        return null;
      }

      const specification = host.getSignalSpecification(control.value);

      if (specification == null) {
        return { invalidSignalID: true };
      }

      if (control.value instanceof ArraySignalIdentifier) {
        const index = control.value.index;
        if (!(index != null && specification.dimensions[0] <= index && index <= specification.dimensions[1])) {
          return { invalidSignalID: true };
        }
      }

      return null;
    };
  }

  static floatNumber(control: AbstractControl) {
    if (control.value == null || control.value.length === 0) {
      return null;
    }

    const value = parseFloat(control.value);

    return isNaN(value) ? { floatNumber: true } : null;
  }

  static wholeNumber(control: AbstractControl) {
    if (control.value == null || control.value.length === 0) {
      return null;
    }

    const value = parseFloat(control.value);

    return !Number.isInteger(value) || value < 0 ? { wholeNumber: true } : null;
  }

  static fontSize(control: AbstractControl): ValidationErrors {
    return !/^([0-9]*\.[0-9]+|[0-9]+)(du)?$/.test(control.value) ? { fontSize: true } : null;
  }

  static alphaNumeric(control: AbstractControl) {
    if (control.value == null || control.value.length === 0) {
      return null;
    }

    return !/^\w+$/.test(control.value) ? { alphaNumeric: true } : null;
  }

  static fileType(type: string | string[]): ValidatorFn {
    return (control: AbstractControl) => {
      if (control.value == null || control.value.length === 0) {
        return null;
      }

      const types = typeof type === 'string' ? [type] : type;

      const result = types.some((t) => {
        return control.value.name.toLowerCase().endsWith('.' + t.toLowerCase());
      });

      return !result ? { fileType: { type: types.join(', ') } } : null;
    };
  }

  static fileSize(max: number, min = 0): ValidatorFn {
    return (control: AbstractControl) => {
      if (control.value == null || control.value.length === 0) {
        return null;
      }

      if (control.value.size >= min && control.value.size <= max) {
        return null;
      }

      return { fileSize: { range: `${min} and ${max / 1000 / 1000 /** MB */}` } };
    };
  }

  static withinSignalRange(signalId: SignalIdentifier): ValidatorFn {
    return (form: FormGroup | FormArray): { [key: string]: any } => {
      if (signalId == null) {
        return null;
      }

      // EnumSignal configuration options
      if (form instanceof FormGroup && form.get('options') != null) {
        const range = signalId.getRange(parseFloat(form.get('multiplier').value), parseFloat(form.get('offset').value));
        const options: [{ name: string; value: number }] = form.get('options').value;

        return options.every((o) => {
          return o.value != null && o.value >= range[0] && o.value <= range[1];
        })
          ? null
          : { withinSignalRange: true };

        // Array with two numbers as the range
      } else if (form instanceof FormArray) {
        const range = signalId.getRange();
        return form.at(0).value >= range[0] && form.at(1).value <= range[1] ? null : { withinSignalRange: true };
      } else {
        return null;
      }
    };
  }

  static noDuplicates(array: FormArray): ValidationErrors {
    if (array.length < 2) {
      return null;
    }

    if (array.controls.some((control) => control.valid == false)) {
      return null;
    }

    // This is a naïve comparison. Preferrably, a deep comparison should be made.
    if (array.length !== uniqBy(array.value, JSON.stringify).length) {
      return { duplicates: true };
    }

    return null;
  }
}

export function loadAll<T>(
  loader: (offset: number, limit: number) => Observable<Page<T>>,
  batchSize = 50,
  offset = 0,
): Observable<T[]> {
  return loader(offset, batchSize).pipe(
    switchMap(({ records, totalCount }) => {
      if (offset + batchSize < totalCount) {
        return loadAll(loader, batchSize, offset + batchSize).pipe(
          map((remainingRecords) => [...records, ...remainingRecords]),
        );
      } else {
        return of(records);
      }
    }),
  );
}
