import { Directive, HostListener } from '@angular/core';

/**
 * Directive to fix padding for cell, which contains actions specified by:
 *  - CellActionLinkComponent
 *  - CellActionButtonComponent
 */
@Directive({ selector: '[devappTableCellActions]' })
export class ActionsCellDirective {
  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    event.stopPropagation();
  }
}
