import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faExternalLink } from '@fortawesome/pro-regular-svg-icons/faExternalLink';
import { faUserCircle } from '@fortawesome/pro-solid-svg-icons/faUserCircle';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { hasProfessionalLicense } from '../../licenses/utils';
import { Group } from '../data/group.service';
import { Membership } from '../data/membership.service';
import { User } from '../data/user.service';
import { ListGroupsModalComponent } from '../list-groups-modal.component';
import { ModalsService } from '../modals/modals.service';
import { NewRootGroupModalComponent } from '../new-root-group-modal.component';
import { PermissionsService } from '../security/permissions.service';
import { MAX_RECENT_GROUPS_COUNT, Session } from '../security/session.service';

@Component({
  selector: 'devapp-layout',
  styleUrls: ['./layout.component.scss'],
  templateUrl: './layout.component.html',
})
export class LayoutComponent implements OnInit {
  icons = { faUserCircle, faExternalLink };
  currentYear: number;
  version: string;
  currentUser$: Observable<User>;
  memberships$: Observable<Membership[]>;
  currentMembership$: Observable<Membership | null>;
  canCreateGroup$: Observable<boolean>;
  canViewAllGroups$: Observable<boolean>;
  recentGroups$: Observable<Group[]>;
  serviceSupportUrl$: Observable<string>;

  get canSeeAdminMenu() {
    return this.permissions.canListChildGroups || this.permissions.canListUsers || this.permissions.canListSystems;
  }

  constructor(
    public permissions: PermissionsService,
    private session: Session,
    private router: Router,
    private modals: ModalsService,
  ) {}

  ngOnInit() {
    this.version = environment.version;
    this.currentYear = new Date().getFullYear();
    this.currentUser$ = this.session.user$;
    this.currentMembership$ = this.session.membership$;
    this.memberships$ = this.session.memberships$;
    this.recentGroups$ = this.session.memberships$.pipe(
      map((memberships) => memberships.slice(0, MAX_RECENT_GROUPS_COUNT).map((m) => m.group)),
    );
    this.canViewAllGroups$ = this.session.memberships$.pipe(
      map((memberships) => memberships.length > MAX_RECENT_GROUPS_COUNT),
    );

    this.canCreateGroup$ = this.session.licenses$.pipe(map((licenses) => hasProfessionalLicense(licenses)));

    this.serviceSupportUrl$ = this.currentUser$.pipe(
      map(
        (data) =>
          'https://www.danfoss.com/en/campaigns/dps/plusplus1-support-form/?plus1_product=mobilesev&plus1_email=' +
          data.email,
      ),
    );
  }

  setCurrentMembership(group: Group) {
    if (this.session.membership.groupId !== group.id) {
      this.session.setCurrentGroupId(group.id).subscribe(() =>
        this.router.navigate(['/redirect'], {
          queryParams: { to: this.router.url },
          skipLocationChange: true,
        }),
      );
    }
  }

  createGroup() {
    this.modals.show(NewRootGroupModalComponent);
  }

  seeAllGroups() {
    this.modals.show(ListGroupsModalComponent);
  }

  logout() {
    this.session.logout();
  }
}
