import { Area } from './area';
import { ControlOptions } from '../control';
import { SignalIdentifier } from '../signal-identifier';
import { SimpleControl } from '../simple-control';

export class NumericSignal extends SimpleControl {
  readonly: boolean;
  standalone: boolean;
  signalId: SignalIdentifier;
  areas: Area[];

  readonly minimumHeight = 2.5;

  get minimumWidth() {
    return this.readonly ? 2.5 : 7.5;
  }

  // A numeric signal doesn't really have a min and max,
  // so use infinity for its validation.
  get minimum(): number {
    return Number.NEGATIVE_INFINITY;
  }

  get maximum(): number {
    return Number.POSITIVE_INFINITY;
  }

  readonly humanReadableName = 'Numeric Signal';

  constructor({
    readonly = false,
    standalone = true,
    signalId = new SignalIdentifier(),
    areas = [],
    ...options
  }: NumericSignalOptions) {
    super(options);

    this.readonly = readonly;
    this.standalone = standalone;
    this.signalId = signalId;
    this.areas = areas;
  }
}

export interface NumericSignalOptions extends ControlOptions {
  readonly?: boolean;
  standalone?: boolean;
  signalId?: SignalIdentifier;
  areas?: Area[];
}
