import { Injectable } from '@angular/core';
import { DEVELOPER_ROLE_ID, RoleName, RolesMappingService, TECHNICIAN_ROLE_ID } from './roles-mapping.service';
import { Session } from './session.service';
import { Group } from '../data/group.service';
import { Invitation } from '../data/invitation.service';
import { Membership } from '../data/membership.service';
import { User } from '../data/user.service';

/**
 * High-level service for checking permissions.
 *
 * It's recommended to inject it as public property into component and use in
 * templates directly without proxying through component's methods, i.e.
 *
 * class MyComponent {
 *   constructor(public permissions: PermissionsService) {
 *   }
 * }
 *
 * and in the template:
 *
 * <div *ngIf="permissions.canListUsers">Users list</div>
 */
@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  constructor(
    private session: Session,
    private rolesMapping: RolesMappingService,
  ) {}

  get canListUsers() {
    return (
      this.hasAnyRole(this.session.membership, 'Owner Common Group', 'Owner', 'Admin', 'Developer') &&
      !this.isPrivateGroup(this.session.membership.group)
    );
  }

  get canListInvitations() {
    return (
      this.hasAnyRole(this.session.membership, 'Owner Common Group', 'Owner', 'Admin') &&
      !this.isPrivateGroup(this.session.membership.group)
    );
  }

  get canCreateInvitation() {
    return (
      this.hasAnyRole(this.session.membership, 'Owner Common Group', 'Owner', 'Admin') &&
      !this.isPrivateGroup(this.session.membership.group)
    );
  }

  get canListChildGroups() {
    return (
      this.hasAnyRole(this.session.membership, 'Owner Common Group', 'Owner') &&
      !this.isPrivateGroup(this.session.membership.group)
    );
  }

  get canCreateChildGroup() {
    return (
      this.hasAnyRole(this.session.membership, 'Owner Common Group', 'Owner') &&
      !this.isPrivateGroup(this.session.membership.group)
    );
  }

  get canListSystems() {
    return this.hasAnyRole(this.session.membership, 'Owner Common Group', 'Owner', 'Admin', 'Developer');
  }

  get canCreateSystem() {
    return this.hasAnyRole(this.session.membership, 'Owner Common Group', 'Owner', 'Admin', 'Developer');
  }

  get canListPackages() {
    return this.hasAnyRole(this.session.membership, 'Owner Common Group', 'Owner', 'Admin', 'Developer');
  }

  get canListDevices() {
    return (
      this.hasAnyRole(this.session.membership, 'Owner Common Group', 'Owner', 'Admin', 'Developer') &&
      ['yaroslav.admin@softwerk.se', 'sheikzayed@danfoss.com', 'fbjorn@danfoss.com'].includes(this.session.user.email)
    );
  }

  get canCreatePackage() {
    return this.hasAnyRole(this.session.membership, 'Owner Common Group', 'Owner', 'Admin', 'Developer');
  }

  get canCreateCommonGroup() {
    return [
      'akelemen@danfoss.com',
      'dusko.susa@danfoss.com',
      'fbjorn@danfoss.com',
      'holesen@danfoss.com',
      'jkarlsson@danfoss.com',
      'jlindholm@danfoss.com',
      'mohamed.salam@danfoss.com',
      'rlbehning@danfoss.com',
      'tpetersen@danfoss.com',
      'rebecca.wallander@danfoss.com',
      'marcus.cvjeticanin@softwerk.se',
      'ram.hamid@danfoss.com',
      'sheikzayed@danfoss.com',
      'oscar.lesshammar@softwerk.se',
      'mohamed.salam@danfoss.com',
      'mbuchholz@danfoss.com',
    ].includes(this.session.user.email);
  }

  get canReleaseCommonGroupPackage() {
    return this.hasAnyRole(this.session.membership, 'Owner', 'Owner Common Group');
  }

  canDeleteInvitation(invitation: Invitation) {
    if (this.session.membership == null || this.isPrivateGroup(this.session.membership.group)) {
      return false;
    }

    // Owner can delete any invitation in the same group.
    if (
      this.session.membership.groupId === invitation.groupId &&
      this.hasAnyRole(this.session.membership, 'Owner Common Group', 'Owner')
    ) {
      return true;
    }

    // Admin can delete any Developer or Technician invitation in the same group.
    if (
      this.session.membership.groupId === invitation.groupId &&
      this.hasAnyRole(this.session.membership, 'Admin') &&
      [DEVELOPER_ROLE_ID, TECHNICIAN_ROLE_ID].includes(invitation.roleId)
    ) {
      return true;
    }

    return false;
  }

  canUpdateUser(user: User) {
    return this.session.user.id === user.id;
  }

  canUpdateGroup(group: Group) {
    if (group.parentId != null) {
      return this.session.memberships.some(
        (m) => this.hasAnyRole(m, 'Owner Common Group', 'Owner') && m.groupId === group.parentId,
      );
    } else {
      return (
        !this.isPrivateGroup(group) &&
        this.session.memberships.some(
          (m) => this.hasAnyRole(m, 'Owner Common Group', 'Owner') && m.groupId === group.id,
        )
      );
    }
  }

  canLeaveGroup(group: Group) {
    return (
      !this.isPrivateGroup(group) &&
      this.session.memberships.some(
        group.parentId != null
          ? (m) =>
              this.hasAnyRole(m, 'Owner Common Group', 'Owner', 'Admin', 'Developer', 'Technician') &&
              m.groupId === group.id
          : (m) => this.hasAnyRole(m, 'Admin', 'Developer', 'Technician') && m.groupId === group.id,
      )
    );
  }

  canDeleteGroup(group: Group) {
    if (group.parentId != null) {
      return this.session.memberships.some(
        (m) => this.hasAnyRole(m, 'Owner Common Group', 'Owner') && m.groupId === group.parentId,
      );
    } else {
      return (
        !this.isPrivateGroup(group) &&
        this.session.memberships.some(
          (m) => this.hasAnyRole(m, 'Owner Common Group', 'Owner') && m.groupId === group.id,
        )
      );
    }
  }

  canAssignGroupOwner(group: Group) {
    return (
      group.parentId != null &&
      this.session.memberships.some(
        (m) => this.hasAnyRole(m, 'Owner Common Group', 'Owner') && m.groupId === group.parentId,
      )
    );
  }

  canUpdateMembership(membership: Membership) {
    if (membership == null || this.session.membership == null) {
      return false;
    }

    // Owner can update any membership in the same group except their own.
    if (
      this.session.membership.groupId === membership.groupId &&
      this.hasAnyRole(this.session.membership, 'Owner Common Group', 'Owner') &&
      this.session.membership.userId !== membership.userId
    ) {
      return true;
    }

    // Admin can update any Developer or Technician membership in the same group.
    if (
      this.session.membership.groupId === membership.groupId &&
      this.hasAnyRole(this.session.membership, 'Admin') &&
      this.hasAnyRole(membership, 'Developer', 'Technician')
    ) {
      return true;
    }

    return false;
  }

  canDeleteMembership(membership: Membership) {
    if (membership == null || this.session.membership == null) {
      return false;
    }

    // Owner can delete any membership in the same group except their own.
    if (
      this.session.membership.groupId === membership.groupId &&
      this.hasAnyRole(this.session.membership, 'Owner Common Group', 'Owner') &&
      this.session.membership.userId !== membership.userId
    ) {
      return true;
    }

    // Admin can delete any Developer or Technician membership in the same group.
    if (
      this.session.membership.groupId === membership.groupId &&
      this.hasAnyRole(this.session.membership, 'Admin') &&
      this.hasAnyRole(membership, 'Developer', 'Technician')
    ) {
      return true;
    }

    return false;
  }

  /**
   * Checks whether membership has any of the roles.
   *
   * @param membership - membership to check
   * @param roles - list of roles to look for
   * @return whether user has one of the roles
   */
  private hasAnyRole(membership: Membership, ...roles: RoleName[]): boolean {
    if (membership == null) {
      return false;
    }

    return roles.includes(this.rolesMapping.getRoleName(membership.roleId));
  }

  private isPrivateGroup(group: Group) {
    return group.name.includes('@');
  }
}
