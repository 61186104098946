import { ControlValidator } from './control.validator';
import { Image } from '../../../../application/controls/image';
import { Problem, Validatable, ValidationTrigger } from '../validator';

export class ImageValidator extends ControlValidator {
  public isRelevant(node: Validatable, triggers: ValidationTrigger[]): boolean {
    return node instanceof Image && (triggers.includes('nodeProperties') || triggers.includes('applicationWide'));
  }

  public validate(image: Image): Problem[] {
    const allProblems: Problem[] = super.validateControl(image);

    const problem = this.validateSource(image);
    if (problem != null) {
      allProblems.push(problem);
    }
    return allProblems;
  }

  private validateSource(image: Image): Problem | null {
    if (image.resource == null) {
      return {
        severity: 'warning',
        shortDescription: 'Image control does not have image source.',
        longDescription:
          'Image control does not have image source. ' +
          'Please provide an image to display using Image property in the Image Properties pane.',
        subObjects: ['resource'],
      };
    }
    return null;
  }
}
