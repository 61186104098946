/**
 * Safely parse string into integer number and throw error if string does not
 * contain a valid integer number.
 *
 * This handles caveat of the native method that "10bla" is parsed into 10
 * without errors.
 *
 * See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/parseInt
 */
export const parseIntSafe = (value: string, radix = 10) => {
  if (/^(0|-?[1-9]\d*)$/.test(value)) {
    return parseInt(value, radix);
  }
  throw new Error(`Not an integer: '${value}'`);
};
