import { Component, Host, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FormControlFrameComponent } from '../form-control-frame/form-control-frame.component';

@Component({
  selector: 'devapp-form-control-checkbox',
  templateUrl: './form-control-checkbox.component.html',
  styleUrls: ['./form-control-checkbox.component.scss'],
})
export class FormControlCheckboxComponent {
  @Input()
  label: string;

  constructor(@Host() public frame: FormControlFrameComponent<FormControl>) {}
}
