import { Control, ControlOptions } from './control';
import { ControlVisitor } from '../visitor/control-visitor';

/**
 * Base class for any control, which can not contain other controls.
 */
export abstract class SimpleControl extends Control {
  constructor(options: ControlOptions) {
    super(options);
  }

  accept<T extends ControlVisitor>(visitor: T): T {
    visitor.visitControl(this);
    return visitor;
  }
}
