import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'devappKeys',
})
export class KeysPipe implements PipeTransform {
  transform(value: { [key: string]: any } | null): string[] {
    return value != null ? Object.keys(value) : [];
  }
}
