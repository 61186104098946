import { array } from '../utils/utils';

/**
 * Slightly simplified and tweaked version of Edmonds-Karp algorithm.
 * See https://en.wikipedia.org/wiki/Ford%E2%80%93Fulkerson_algorithm for reference implementation.
 * This implementation assumes that weight of all edges is 1.
 * This implementation tracks taken paths.
 */
export class EdmondsKarp {
  private graph: number[][];
  private size: number;
  private originalEdges: number[][];

  getUsedEdges(): number[][] {
    return this.originalEdges.filter((item) => this.graph[item[0]][item[1]] === 0);
  }

  constructor(size: number) {
    this.graph = array(size, () => array(size, () => 0));
    this.size = size;
    this.originalEdges = [];
  }

  addEdge(v1: number, v2: number) {
    if (v1 >= this.size || v2 >= this.size || v1 < 0 || v2 < 0) {
      throw new Error('Invalid vertex.');
    }

    this.graph[v1][v2] = 1;

    if (v1 !== this.size - 2 && v1 !== this.size - 1 && v2 !== this.size - 2 && v2 !== this.size - 1) {
      this.originalEdges.push([v1, v2]);
    }
  }

  removeEdge(v1: number, v2: number) {
    if (v1 >= this.size || v2 >= this.size || v1 < 0 || v2 < 0) {
      throw new Error('Invalid vertex.');
    }

    this.graph[v1][v2] = 0;

    this.originalEdges.filter((item) => item[0] !== v1 || item[1] !== v2);
  }

  private BFS(source: number, target: number, parent: number[]): boolean {
    // Mark all the vertices as not visited (array is filled with false by default)
    const visited = array(this.size, () => false);

    // Create a queue for BFS
    const queue: number[] = [];

    // Mark the source node as visited and enqueue it
    queue.push(source);
    visited[source] = true;

    // Standard BFS Loop
    while (queue.length > 0) {
      const u = queue.shift()!;

      // Get all adjacent vertices's of the dequeued vertex u
      // If a adjacent has not been visited, then mark it
      // visited and enqueue it
      for (let i = 0; i < this.size; i++) {
        if (!visited[i] && this.graph[u][i] > 0) {
          queue.push(i);
          visited[i] = true;
          parent[i] = u;
        }
      }
    }

    // If we reached sink in BFS starting from source, then return
    // true, else false
    return visited[target];
  }

  computeMaxFlow(source: number, sink: number): number {
    // This array is filled by BFS and to store path
    const parent = array(this.size, () => -1);

    let maxFlow = 0; // There is no flow initially

    // Augment the flow while there is path from source to sink
    while (this.BFS(source, sink, parent)) {
      // Every possible path has flow 1
      maxFlow += 1;

      // update residual capacities of the edges and reverse edges
      // along the path
      let v = sink;
      while (v !== source) {
        const u = parent[v];
        this.graph[u][v] -= 1;
        this.graph[v][u] += 1;
        v = parent[v];
      }
    }

    return maxFlow;
  }

  clone(): EdmondsKarp {
    const clone = new EdmondsKarp(this.size);
    clone.graph = [...this.graph.map((item) => [...item])];
    clone.originalEdges = [...this.originalEdges.map((item) => [...item])];
    return clone;
  }
}
