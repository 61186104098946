import { CompositeControl, CompositeControlOptions } from '../composite-control';
import { Control } from '../control';

export class StackLayout extends CompositeControl {
  direction: StackLayoutDirection;

  readonly minimumWidth = 1;
  readonly minimumHeight = 1;

  readonly humanReadableName = 'Stack Layout';

  constructor({ direction = 'topToBottom', ...options }: StackLayoutOptions) {
    super(options);

    this.direction = direction;
  }
}

export interface StackLayoutOptions extends CompositeControlOptions {
  direction?: StackLayoutDirection;
  children?: Control[];
}

export type StackLayoutDirection = 'topToBottom' | 'leftToRight';
