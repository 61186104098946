import { Injectable, Injector, Type } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MODAL_OPTIONS } from './modal-component';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'any' })
export class ModalsService {
  constructor(
    private router: Router,
    private modal: NgbModal,
    private injector: Injector,
  ) {}

  show<T>(modal: Type<any>, options?: T) {
    let childInjector = Injector.create({
      providers: [{ provide: MODAL_OPTIONS, useValue: options }],
      parent: this.injector,
    });

    const ref = this.modal.open(modal, {
      injector: childInjector,
      size: 'lg',
      animation: environment.animations,
    });

    const subscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationStart))
      .subscribe(() => ref.dismiss());

    ref.close = this.wrapCloseOrDismiss(ref, ref.close, subscription);
    ref.dismiss = this.wrapCloseOrDismiss(ref, ref.dismiss, subscription);

    return ref;
  }

  private wrapCloseOrDismiss(
    ref: NgbModalRef,
    f: (this: NgbModalRef, arg: any) => void,
    navigationSubscription: Subscription,
  ): (arg: any) => void {
    return (arg) => {
      navigationSubscription.unsubscribe();

      f.call(ref, arg);
    };
  }
}
