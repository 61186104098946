import { ControlValidator } from './control.validator';
import { NumericSignal } from '../../../../application/controls/numeric-signal';
import { Problem, Validatable, ValidationTrigger } from '../validator';

export class NumericSignalValidator extends ControlValidator {
  public isRelevant(node: Validatable, triggers: ValidationTrigger[]): boolean {
    return (
      node instanceof NumericSignal &&
      (triggers.includes('nodeProperties') || triggers.includes('signals') || triggers.includes('applicationWide'))
    );
  }

  public validate(control: NumericSignal): Problem[] {
    return [
      ...this.validateControl(control),
      ...this.validateSignalId(control.signalId, control.humanReadableName, 'signalId', this.systemIndex),
    ];
  }
}
