import { ControlValidator } from './control.validator';
import { Label } from '../../../../application/controls/label';
import { Problem, Validatable, ValidationTrigger } from '../validator';

export class LabelValidator extends ControlValidator {
  public isRelevant(node: Validatable, triggers: ValidationTrigger[]): boolean {
    return node instanceof Label && (triggers.includes('nodeProperties') || triggers.includes('applicationWide'));
  }

  public validate(label: Label): Problem[] {
    const allProblems: Problem[] = super.validateControl(label);

    const textProblem = this.validateText(label);
    if (textProblem != null) {
      allProblems.push(textProblem);
    }
    return allProblems;
  }

  private validateText(label: Label): Problem | null {
    if (label.text == null || label.text.length === 0) {
      return {
        severity: 'warning',
        shortDescription: 'Label has no text.',
        longDescription: 'A Label control without any text is not visible and can be safely removed.',
        subObjects: ['text'],
      };
    }
    return null;
  }
}
