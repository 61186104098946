import { Injectable } from '@angular/core';
import { Auth0Client } from '@auth0/auth0-spa-js';
import { combineLatest, from, NEVER, Observable, of } from 'rxjs';
import { catchError, shareReplay, switchMap } from 'rxjs/operators';
import { IdpService } from './idp.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class DIPService extends IdpService {
  private auth0Client$ = from(
    (async () => {
      const auth0 = new Auth0Client({
        domain: new URL(environment.dipBaseUrl).host,
        client_id: environment.dipClientId,
        redirect_uri: `${window.location.origin}/app/callback`,
        audience: 'https://api.plus1cloud.danfoss.com',
        cacheLocation: 'localstorage',
      });
      // Workaround as in the local environment we don't have HTTPS support, but
      // the auth0-spa-js library does not support customising the protocol
      // through regular options.
      if (environment.dipBaseUrl.startsWith('http://')) {
        (auth0 as any).domainUrl = (auth0 as any).domainUrl.replace('https://', 'http://');
        (auth0 as any).tokenIssuer = (auth0 as any).tokenIssuer.replace('https://', 'http://');
      }
      await auth0.checkSession();
      return auth0;
    })(),
  ).pipe(shareReplay(1));

  private isAuthenticated$ = this.auth0Client$.pipe(switchMap((client) => from(client.isAuthenticated())));
  private handleRedirectCallback$ = this.auth0Client$.pipe(
    switchMap((client) => from(client.handleRedirectCallback())),
  );

  initialize(): Observable<string | null> {
    return this.handleAuthCallback().pipe(
      switchMap(([loggedIn, targetRoute]) => {
        if (loggedIn) {
          return of(targetRoute);
        } else {
          // Skip application base URL from the redirect path.
          this.login(window.location.pathname.substring('/app'.length));
          return NEVER;
        }
      }),
    );
  }

  getToken(): Observable<string> {
    return this.auth0Client$.pipe(switchMap((client) => from(client.getTokenSilently())));
  }

  logout() {
    this.auth0Client$.subscribe((client) => client.logout({ returnTo: `${window.location.origin}/app` }));
  }

  openProfile() {
    window.open(`${environment.dipBaseUrl.replace('accounts', 'my')}/edit-profile`);
  }

  private login(redirectPath = '/') {
    const shouldShowSignUpForm =
      redirectPath.startsWith('/onboarding') ||
      new URLSearchParams(window.location.search.substring(1))?.get('signup')?.toLowerCase() === 'true';
    const email = new URLSearchParams(window.location.search.substring(1)).get('email');

    this.auth0Client$.subscribe((client) => {
      return client.loginWithRedirect({
        appState: { target: redirectPath },
        ...(shouldShowSignUpForm ? { signup: true } : {}),
        ...(email != null ? { login_hint: email } : {}),
      });
    });
  }

  private handleAuthCallback(): Observable<[boolean, string]> {
    const params = window.location.search;
    if (params.includes('code=') && params.includes('state=')) {
      return this.handleRedirectCallback$.pipe(
        switchMap((cbRes) => {
          const targetRoute = cbRes.appState && cbRes.appState.target ? cbRes.appState.target : '/';
          return combineLatest([this.isAuthenticated$, of(targetRoute)]);
        }),
        // This usually happens if user reloads /app/callback page and Auth0
        // fails because of invalid state.
        catchError((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
          this.login('/');
          return NEVER;
        }),
      );
    } else {
      return combineLatest([this.isAuthenticated$, of(null)]);
    }
  }
}
