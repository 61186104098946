import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import parseJSON from 'date-fns/parseJSON';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Page } from './shared/page';
import { BaseService } from './shared/service';

export interface Membership {
  id: number;
  userId: number;
  roleId: number;
  groupId: number;
  created: Date;
  updated: Date;
  user: {
    id: number;
    email: string;
    fullName: string;
    created: Date;
    updated: Date;
  };
  group: {
    id: number;
    name: string;
    parentId: number | null;
    isArchived: boolean;
    created: Date;
    updated: Date;
    type: 'REGULAR' | 'COMMON';
  };
}

@Injectable({ providedIn: 'root' })
export class MembershipService extends BaseService<Membership> {
  constructor(private client: HttpClient) {
    super();
  }

  list(params: ListOptions = {}): Observable<Page<Membership>> {
    return this.client
      .get(`/v2/memberships`, { params: this.buildParams(params) })
      .pipe(map((data: any) => this.parsePage(data)));
  }

  listByGroup(groupId: number, params: ListOptions = {}): Observable<Page<Membership>> {
    return this.client
      .get(`/v2/groups/${groupId}/memberships`, { params: this.buildParams(params) })
      .pipe(map((data: any) => this.parsePage(data)));
  }

  read(id: number): Observable<Membership> {
    return this.client.get(`/v2/memberships/${id}`).pipe(map((data: any) => this.parse(data)));
  }

  update(id: number, data: Partial<Pick<Membership, 'roleId'>>): Observable<Membership> {
    return this.client.patch(`/v2/memberships/${id}`, data).pipe(map((result: any) => this.parse(result)));
  }

  delete(id: number): Observable<void> {
    return this.client.delete(`/v2/memberships/${id}`).pipe(map(() => void 0));
  }

  protected parse(data: any): Membership {
    return {
      id: data.id,
      userId: data.userId,
      roleId: data.roleId,
      groupId: data.groupId,
      created: parseJSON(data.created),
      updated: parseJSON(data.updated),
      user: {
        id: data.user.id,
        email: data.user.email,
        fullName: data.user.fullName,
        created: parseJSON(data.user.created),
        updated: parseJSON(data.user.updated),
      },
      group: {
        id: data.group.id,
        name: data.group.name,
        parentId: data.group.parentId,
        isArchived: data.group.isArchived,
        created: parseJSON(data.group.created),
        updated: parseJSON(data.group.updated),
        type: data.group.type,
      },
    };
  }
}

interface ListOptions {
  limit?: number;
  offset?: number;
  search?: string;
  sort?: 'groupNameAsc' | 'createdDesc' | 'updatedDesc';
  userId?: number;
  groupId?: number;
  roleId?: number | number[];
}
