import SystemMatchingCriteriaDeclaration = M0_1_0.SystemMatchingCriteriaDeclaration;
import EcuMatchingCriteriaDeclaration = M0_1_0.EcuMatchingCriteriaDeclaration;
import PropertyCriterionDeclaration = M0_1_0.PropertyCriterionDeclaration;
import { AdapterContract } from './contract';
import { M0_1_0 } from './v0-1-0';
import { EcuMatchingCriteria, PropertyCriterion, SystemMatchingCriteria } from '../system-matching-criteria';

export class SystemMatchingCriteriaAdapterV0_1_0 implements AdapterContract {
  parse(declaration: SystemMatchingCriteriaDeclaration): SystemMatchingCriteria {
    const result = new SystemMatchingCriteria({ nextEcuId: 1 });
    result.ecus = declaration.ecus
      .filter((ecu) => ecu.enabled)
      .map((ecu) => this.createEcuMatchingCriteriaV0_1_0(ecu, result.getNewEcuId()));
    return result;
  }

  serialize(criteria: SystemMatchingCriteria): SystemMatchingCriteriaDeclaration {
    return {
      formatVersion: '0.1.0',
      ecus: criteria.ecus.map((ecu) => this.serializeEcuMatchingCriteria(ecu)),
    };
  }

  private createEcuMatchingCriteriaV0_1_0(
    declaration: EcuMatchingCriteriaDeclaration,
    ecuId: string,
  ): EcuMatchingCriteria {
    return new EcuMatchingCriteria({
      ecuId,
      criteria: declaration.criteria
        .filter((prop) => prop.enabled)
        .map((prop) => this.createPropertyCriterionV0_1_0(prop)),
    });
  }

  private createPropertyCriterionV0_1_0(declaration: PropertyCriterionDeclaration): PropertyCriterion {
    return new PropertyCriterion({
      name: declaration.name,
      toMatch: declaration.toMatch != null ? declaration.toMatch.toString() : '',
    });
  }

  private serializeEcuMatchingCriteria(ecu: EcuMatchingCriteria): EcuMatchingCriteriaDeclaration {
    return {
      enabled: true,
      criteria: ecu.criteria.map((prop) => this.serializePropertyCriterionDeclaration(prop)),
    };
  }

  private serializePropertyCriterionDeclaration(prop: PropertyCriterion): PropertyCriterionDeclaration {
    return {
      enabled: true,
      name: prop.name,
      toMatch: prop.toMatch,
    };
  }
}
