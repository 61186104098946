<form *ngIf="searchable" role="form" class="form-horizontal">
  <div class="mb-3">
    <div class="col-md-3 clear-left-padding">
      <input
        #searchInput
        type="text"
        class="form-control"
        placeholder="Search..."
        (input)="search(searchInput.value)"
      />
    </div>
  </div>
</form>
<devapp-table>
  <ng-content select="thead"></ng-content>

  <ng-content select="tbody" *ngIf="rowsVisible; else messages"></ng-content>

  <ng-template #messages>
    <tbody class="listing-messages">
      <tr *ngIf="loadingVisible" class="listing-message-loading">
        <td [attr.colspan]="columns" class="text-center text-muted">
          <fa-icon [spin]="true" [fixedWidth]="true" [icon]="icons.faSpinner"></fa-icon> Loading...
        </td>
      </tr>

      <tr *ngIf="emptyVisible" class="listing-message-empty">
        <td [attr.colspan]="columns" class="text-center text-muted">No {{ entityName }} found.</td>
      </tr>

      <tr *ngIf="errorVisible" class="listing-message-error">
        <td [attr.colspan]="columns" class="text-center text-danger">
          Unable to load {{ entityName }}. <a href="" (click)="retry($event)">Retry?</a>
        </td>
      </tr>
    </tbody>
  </ng-template>
</devapp-table>

<devapp-pagination
  *ngIf="paginationVisible"
  [totalItems]="totalItems"
  [itemsPerPage]="itemsPerPage"
  [activePage]="activePage"
  (pageChanged)="navigatePage($event)"
>
</devapp-pagination>
