import { ControlVisitor } from './control-visitor';
import { Control } from '../application/control';
import { Page } from '../application/page';

export class UniqueIdVisitor extends ControlVisitor {
  readonly ids = new Set<string>();
  readonly notUniqueIds: Control[] = [];

  override visitPage(page: Page): void {
    if (this.ids.has(page.id)) {
      throw new Error(`The page id is already in use: ${page.id}.`);
    }

    this.ids.add(page.id);

    super.visitPage(page);
  }

  visitControl(control: Control): void {
    if (this.ids.has(control.id)) {
      this.notUniqueIds.push(control);
    } else {
      this.ids.add(control.id);
    }
  }

  getAllIds() {
    return this.ids;
  }

  getAllControlsWithNonUniqueIds() {
    return this.notUniqueIds;
  }
}
