import { FormatVersion } from './adapter';
import { parseLength } from './versions/utils';

export type SignalOption = 'APP' | 'SYS' | 'ALIAS' | 'CHECKPOINT' | 'SETVALUE' | 'SETPULSE';

export type SignalType = 'BOOL' | 'U8' | 'U16' | 'U32' | 'S8' | 'S16' | 'S32' | `STRING[${number}]`;

export type Protocol = 'UDS' | 'PLUS+1';

export type NodeType =
  | 'Controller'
  | 'Display'
  | 'Firmware'
  | 'I/O Module'
  | 'Joystick'
  | 'Valve'
  | 'Sensor'
  | 'Inverter'
  | 'Other';

export type ProgrammableStatus =
  | 'Not programmable'
  | 'PLUS+C programmable'
  | 'PLUS+1 GUIDE programmable'
  | 'Other programmable';

export class SignalSpecification {
  name: string;
  type: SignalType;
  writable: boolean;
  readAccessLevel: number; // 0-9
  writeAccessLevel: number; // 0-9
  options: SignalOption[];
  dimensions?: [number, number];
  length?: number;

  constructor({
    name,
    type,
    writable = false,
    readAccessLevel = 9,
    writeAccessLevel = 9,
    options = [],
    dimensions,
  }: SignalSpecification) {
    this.name = name;
    this.type = type;
    this.writable = writable;
    this.readAccessLevel = readAccessLevel;
    this.writeAccessLevel = writeAccessLevel;
    this.options = options;
    this.dimensions = dimensions;
    this.length = parseLength(type);
  }
}

export class EcuSpecification {
  fullAddress: number;
  netAddress: number;
  nodeAddress: number;
  protocol: Protocol;
  isApplicationReleased: boolean;
  isSIL2Certified: boolean;
  applicationID?: string;
  toolKey?: string;
  diagnosticDataId?: string; // TODO: remove optional, when support for 0.1.0 version is dropped
  systemID?: string;
  applicationType?: string;
  applicationVersion?: string;
  nodeType?: NodeType;
  toolName?: string;
  toolVersion?: string;
  os?: string;
  compileTime?: string;
  bootloaderVersion?: string;
  ean?: string;
  serialNumber?: string;
  manufacturingDate?: string;
  partNumber0?: string;
  partNumberRevision0?: string;
  partNumber1?: string;
  partNumberRevision1?: string;
  partNumber2?: string;
  programmableStatus?: ProgrammableStatus;
  isHistorySupported?: boolean;
  diagnosticDataFilename?: string;
  diagnosticDataBinary?: string;
  signals: SignalSpecification[];

  constructor({
    fullAddress,
    netAddress,
    nodeAddress,
    protocol,
    applicationID,
    toolKey,
    diagnosticDataId,
    isApplicationReleased,
    systemID,
    applicationType,
    applicationVersion,
    toolVersion,
    os,
    compileTime,
    bootloaderVersion,
    serialNumber,
    manufacturingDate,
    partNumber0,
    partNumberRevision0,
    partNumber1,
    partNumberRevision1,
    partNumber2,
    ean,
    isSIL2Certified,
    nodeType,
    toolName,
    programmableStatus,
    isHistorySupported,
    diagnosticDataFilename,
    diagnosticDataBinary,
    signals = [],
  }: EcuSpecificationOptions) {
    this.fullAddress = fullAddress;
    this.netAddress = netAddress;
    this.nodeAddress = nodeAddress;
    this.protocol = protocol;
    this.applicationID = applicationID;
    this.toolKey = toolKey;
    this.diagnosticDataId = diagnosticDataId;
    this.isApplicationReleased = isApplicationReleased;
    this.systemID = systemID;
    this.applicationType = applicationType;
    this.applicationVersion = applicationVersion;
    this.toolVersion = toolVersion;
    this.os = os;
    this.compileTime = compileTime;
    this.bootloaderVersion = bootloaderVersion;
    this.serialNumber = serialNumber;
    this.manufacturingDate = manufacturingDate;
    this.partNumber0 = partNumber0;
    this.partNumberRevision0 = partNumberRevision0;
    this.partNumber1 = partNumber1;
    this.partNumberRevision1 = partNumberRevision1;
    this.partNumber2 = partNumber2;
    this.ean = ean;
    this.isSIL2Certified = isSIL2Certified;
    this.nodeType = nodeType;
    this.toolName = toolName;
    this.programmableStatus = programmableStatus;
    this.isHistorySupported = isHistorySupported;
    this.diagnosticDataFilename = diagnosticDataFilename;
    this.diagnosticDataBinary = diagnosticDataBinary;
    this.signals = signals;
  }
}

export interface EcuSpecificationOptions {
  fullAddress: number;
  netAddress: number;
  nodeAddress: number;
  protocol: Protocol;
  isApplicationReleased: boolean;
  isSIL2Certified: boolean;
  applicationID?: string;
  toolKey?: string;
  diagnosticDataId?: string; // TODO: remove optional, when support for 0.1.0 version is dropped
  systemID?: string;
  applicationType?: string;
  applicationVersion?: string;
  nodeType?: NodeType;
  toolName?: string;
  toolVersion?: string;
  os?: string;
  compileTime?: string;
  bootloaderVersion?: string;
  ean?: string;
  serialNumber?: string;
  manufacturingDate?: string;
  partNumber0?: string;
  partNumberRevision0?: string;
  partNumber1?: string;
  partNumberRevision1?: string;
  partNumber2?: string;
  programmableStatus?: ProgrammableStatus;
  isHistorySupported?: boolean;
  diagnosticDataFilename?: string;
  diagnosticDataBinary?: string;
  signals?: SignalSpecification[];
}

export interface SystemSpecificationOptions {
  formatVersion: FormatVersion;
  ecus?: EcuSpecification[];
}

export class SystemSpecification {
  formatVersion: FormatVersion;
  ecus: EcuSpecification[];

  constructor({ formatVersion, ecus = [] }: SystemSpecificationOptions) {
    this.formatVersion = formatVersion;
    this.ecus = ecus;
  }
}
