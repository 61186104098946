import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Injectable()
export class PendingRequestsInterceptor implements HttpInterceptor {
  private _pendingRequests = 0;
  private _beforeRequest = new Subject<void>();
  private _afterRequest = new Subject<void>();

  readonly beforeRequest = this._beforeRequest.asObservable();
  readonly afterRequest = this._afterRequest.asObservable();

  get pendingRequests(): number {
    return this._pendingRequests;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.onBeforeRequest();
    return next.handle(request).pipe(finalize(() => this.onAfterRequest()));
  }

  private onBeforeRequest() {
    this._pendingRequests += 1;
    this._beforeRequest.next();
  }

  private onAfterRequest() {
    this._pendingRequests -= 1;
    this._afterRequest.next();
  }
}
