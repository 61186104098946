import { ControlValidator } from './control.validator';
import { Gauge } from '../../../../application/controls/gauge';
import { Problem, Validatable, ValidationTrigger } from '../validator';

export class GaugeValidator extends ControlValidator {
  public isRelevant(node: Validatable, triggers: ValidationTrigger[]): boolean {
    return (
      node instanceof Gauge &&
      (triggers.includes('nodeProperties') || triggers.includes('signals') || triggers.includes('applicationWide'))
    );
  }

  public validate(gauge: Gauge): Problem[] {
    const allProblems: Problem[] = super.validateControl(gauge);

    let problem: Problem | null;
    if (Number.isInteger(gauge.minimum) && Number.isInteger(gauge.maximum)) {
      problem = this.validateMinMax(gauge);
      if (problem != null) {
        allProblems.push(problem);
      }
    }

    problem = this.validateMinIsInt(gauge);
    if (problem != null) {
      allProblems.push(problem);
    }

    problem = this.validateMaxIsInt(gauge);
    if (problem != null) {
      allProblems.push(problem);
    }

    allProblems.push(...this.validateSignalId(gauge.signalId, gauge.humanReadableName, 'signalId', this.systemIndex));

    problem = this.validateAreas(gauge);
    if (problem != null) {
      allProblems.push(problem);
    }

    return allProblems;
  }

  private validateAreas(gauge: Gauge): Problem | null {
    const ranges: number[] = [];
    for (const area of gauge.areas) {
      ranges.push(area.range[0], area.range[1]);
    }
    const maxRange = Math.max.apply(Math, ranges);
    const minRange = Math.min.apply(Math, ranges);

    if (maxRange > gauge.maximum || minRange < gauge.minimum) {
      return {
        severity: 'error',
        shortDescription: 'Area is outside of minimum - maximum interval.',
        longDescription:
          'Area is outside of minimum - maximum interval. ' +
          'Please ensure that all configured areas are inside the [Minimum, Maximum] interval.',
        subObjects: ['areas'],
      };
    }
    return null;
  }

  private validateMinMax(gauge: Gauge): Problem | null {
    if (gauge.minimum > gauge.maximum) {
      return {
        severity: 'error',
        shortDescription: 'Gauge minimum is greater than maximum.',
        longDescription:
          'Gauge minimum is greater than maximum. ' +
          'Please ensure that Minimum property is strictly less than Maximum property ' +
          'in the Enum Signal Properties pane.',
        subObjects: ['minimum', 'maximum'],
      };
    }
    return null;
  }

  private validateMinIsInt(gauge: Gauge): Problem | null {
    if (!Number.isInteger(gauge.minimum)) {
      return {
        severity: 'error',
        shortDescription: 'Gauge minimum value is not a valid integer.',
        longDescription:
          'Gauge minimum value is not a valid integer. ' +
          'Please ensure that Minimum property in the Enum Signal Properties pane is a valid integer number.',
        subObjects: ['minimum'],
      };
    }
    return null;
  }

  private validateMaxIsInt(gauge: Gauge): Problem | null {
    if (!Number.isInteger(gauge.maximum)) {
      return {
        severity: 'error',
        shortDescription: 'Gauge maximum value is not a valid integer.',
        longDescription:
          'Gauge maximum value is not a valid integer. ' +
          'Please ensure that Maximum property in the Enum Signal Properties pane is a valid integer number.',
        subObjects: ['maximum'],
      };
    }
    return null;
  }
}
