import { PageVisitor } from './page-visitor';
import { Page } from '../application/page';
import { PageVariation } from '../application/page-variation';

/**
 * Implement this interface and use {@link PageVariation.accept} or
 * {@link Page.accept} to conveniently traverse page variations.
 */
export abstract class PageVariationVisitor implements PageVisitor {
  abstract visitPageVariation(variation: PageVariation): void;

  visitPage(page: Page): void {
    page.variations.forEach((v) => v.accept(this));
  }
}
