import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, of } from 'rxjs';

/**
 * Preloading strategy, which preloads module if it has preload = true in the
 * data property.
 */
@Injectable()
export class ConfigurablePreloadingStrategy implements PreloadingStrategy {
  preload(route: Route, load: () => Observable<any>): Observable<any> {
    return route.data != null && route.data['preload'] ? load() : of(null);
  }
}

export const preloadModule = { preload: true };
