import { Observable } from 'rxjs';

export abstract class IdpService {
  abstract initialize(): Observable<string | null>;

  abstract getToken(): Observable<string>;

  abstract logout(): void;

  abstract openProfile(): void;
}
