import { Application, ThemeOptions } from '../../../../application/application';
import { Command } from '../command';

export class EditApplicationCommand implements Command {
  constructor(public data: Data) {}

  apply(): void {
    this.data.app.themeOptions = this.data.newThemeOptions;
  }

  unapply(): void {
    this.data.app.themeOptions = this.data.oldThemeOptions;
  }
}

interface Data {
  app: Application;
  oldThemeOptions: ThemeOptions;
  newThemeOptions: ThemeOptions;
}
