import { Area } from './area';
import { ControlOptions } from '../control';
import { SignalIdentifier } from '../signal-identifier';
import { SimpleControl } from '../simple-control';

export class Gauge extends SimpleControl {
  minimum: number;
  maximum: number;
  signalId: SignalIdentifier;
  areas: Area[];
  valueForegroundColor: string;
  useThemeColor: boolean;

  override readonly intrinsicAspectRatio = 1.78;
  readonly minimumWidth = 7;
  readonly minimumHeight = this.minimumWidth / this.intrinsicAspectRatio;

  get min() {
    return this.minimum == null || this.minimum >= this.maximum ? 0 : this.minimum;
  }

  get max() {
    return this.maximum == null || this.maximum <= this.minimum ? 100 : this.maximum;
  }

  get safeAreas(): Area[] {
    return this.areas.filter((area) => this.min <= area.range[0] && area.range[1] <= this.max);
  }

  readonly humanReadableName = 'Gauge';

  constructor({
    minimum = 0,
    maximum = 100,
    signalId = new SignalIdentifier(),
    areas = [],
    valueForegroundColor = '#cccccc',
    useThemeColor = false,
    ...options
  }: GaugeOptions) {
    super(options);

    this.minimum = minimum;
    this.maximum = maximum;
    this.signalId = signalId;
    this.areas = areas;
    this.valueForegroundColor = valueForegroundColor;
    this.useThemeColor = useThemeColor;
  }
}

export interface GaugeOptions extends ControlOptions {
  minimum?: number;
  maximum?: number;
  signalId?: SignalIdentifier;
  areas?: Area[];
  valueForegroundColor?: string;
  useThemeColor?: boolean;
}
