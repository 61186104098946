import { Component, Input } from '@angular/core';

@Component({
  selector: 'devapp-cell-action-button',
  templateUrl: './cell-action-button.component.html',
  styleUrls: ['./cell-action.scss'],
})
export class CellActionButtonComponent {
  @Input()
  title: string;

  @Input()
  text: string;

  @Input()
  danger = false;
}
