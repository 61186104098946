export function parseOptionalBoolean(
  value: string | undefined,
  trueValue: string,
  falseValue: string,
): boolean | undefined;
export function parseOptionalBoolean(
  value: string | undefined,
  trueValue: string,
  falseValue: string,
  defaultValue: boolean,
): boolean;
export function parseOptionalBoolean(
  value: string | undefined,
  trueValue: string,
  falseValue: string,
  defaultValue?: boolean,
): boolean | undefined {
  if (value === trueValue) {
    return true;
  }

  if (value === falseValue) {
    return false;
  }

  return defaultValue;
}

export function serializeOptionalBoolean<T extends string, F extends string>(
  value: boolean | undefined,
  trueValue: T,
  falseValue: F,
): T | F | undefined {
  if (value === true) {
    return trueValue;
  }

  if (value === false) {
    return falseValue;
  }

  return undefined;
}

export function parseOptionalInt(value: string | undefined): number | undefined;
export function parseOptionalInt(value: string | undefined, defaultValue: number): number;
export function parseOptionalInt(value: string | undefined, defaultValue?: number): number | undefined {
  if (value == null) {
    return defaultValue;
  }

  return parseInt(value.toString(), 10);
}

export function cleanUndefinedProperties<T>(obj: T): T {
  Object.keys(obj).forEach((key) => {
    if (typeof (obj as any)[key] === 'undefined') {
      delete (obj as any)[key];
    }
  });
  return obj;
}

export function parseDimensions(value: string | undefined, signalName: string): [number, number] | undefined {
  if (value == null) {
    return undefined;
  }

  const match = value.match(/\[(\d+)-(\d+)]/);
  if (match == null) {
    throw new Error(`Unable to parse dimensions for signal: ${signalName}.`);
  }

  return [parseInt(match[1], 10), parseInt(match[2], 10)];
}

export function serializeDimensions(value: [number, number] | undefined, signalName: string): string | undefined {
  if (value == null) {
    return undefined;
  }

  return signalName.replace('[x]', `[${value[0]}-${value[1]}]`);
}

export function parseLength(value: string | undefined): number {
  if (value == null || !value?.startsWith('STRING')) {
    return undefined;
  }

  const match = value.match(/\[(\d+)]/);
  if (match == null) {
    throw new Error(`Unable to parse length for string signal: ${value}`);
  }
  return parseInt(match[1], 10);
}
