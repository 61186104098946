import { SignalSpecification } from '../../system-specification/system-specification';

export type ClientDeviceSignalType = 'Date' | 'Date/Time' | 'Time';
export class ClientDevice {
  private static _clientDeviceSignalSpecifications = [
    new SignalSpecification({
      name: 'Date',
      type: 'STRING[32]',
      writable: false,
      readAccessLevel: 0,
      writeAccessLevel: 0,
      options: [],
    }),
    new SignalSpecification({
      name: 'Date/Time',
      type: 'STRING[32]',
      writable: false,
      readAccessLevel: 0,
      writeAccessLevel: 0,
      options: [],
    }),
    new SignalSpecification({
      name: 'Time',
      type: 'STRING[32]',
      writable: false,
      readAccessLevel: 0,
      writeAccessLevel: 0,
      options: [],
    }),
  ];

  static get clientDeviceSignalSpecifications() {
    return ClientDevice._clientDeviceSignalSpecifications;
  }
}
