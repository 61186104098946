import { Area } from './area';
import { ControlOptions } from '../control';
import { SignalIdentifier } from '../signal-identifier';
import { SimpleControl } from '../simple-control';

export class EnumSignal extends SimpleControl {
  readonly: boolean;
  standalone: boolean;
  signalId: SignalIdentifier;
  areas: Area[];

  readonly minimumHeight = 2.5;

  get minimumWidth() {
    return this.readonly ? 2.5 : 7.5;
  }

  readonly humanReadableName = 'Enum Signal';

  constructor({
    readonly = false,
    standalone = true,
    signalId = new SignalIdentifier(),
    areas = [],
    ...options
  }: EnumSignalOptions) {
    super(options);

    this.readonly = readonly;
    this.standalone = standalone;
    this.signalId = signalId;
    this.areas = areas;
  }
}

export interface EnumSignalOptions extends ControlOptions {
  readonly?: boolean;
  standalone?: boolean;
  signalId?: SignalIdentifier;
  // Enum Signals does not have any areas, this is for smooth conversion between signal control types.
  areas?: Area[];
}
