import { ControlOptions } from '../control';
import { SignalIdentifier } from '../signal-identifier';
import { SimpleControl } from '../simple-control';

export class BarChart extends SimpleControl {
  minimum: number;
  maximum: number;
  direction: 'right' | 'left' | 'top' | 'bottom';
  bars: Bar[];
  valuesCount: number;

  readonly minimumWidth = 15;
  readonly minimumHeight = 10;
  override readonly intrinsicAspectRatio = 1.62; // see https://en.wikipedia.org/wiki/Golden_ratio

  get min() {
    return this.minimum >= this.maximum || this.minimum == null ? 0 : this.minimum;
  }

  get max() {
    return this.maximum == null || this.maximum <= this.minimum ? 100 : this.maximum;
  }

  readonly humanReadableName = 'Bar Chart';

  constructor({
    minimum = 0,
    maximum = 100,
    direction = 'left',
    bars = [],
    valuesCount = 5,
    ...options
  }: BarChartOptions) {
    super(options);

    this.minimum = minimum;
    this.maximum = maximum;
    this.direction = direction;
    this.bars = bars;
    this.valuesCount = valuesCount;
  }
}

export interface BarChartOptions extends ControlOptions {
  minimum?: number;
  maximum?: number;
  direction?: 'right' | 'left' | 'top' | 'bottom';
  bars?: Bar[];
  // Bar Chart does not have any values count, this is for smooth conversion between chart controls.
  valuesCount?: number;
}

export class Bar {
  label: string;
  color: string;
  signalId: SignalIdentifier;

  constructor(options: BarOptions) {
    this.label = options.label;
    this.color = options.color;
    this.signalId = options.signalId;
  }
}

export interface BarOptions {
  label: string;
  color: string;
  signalId: SignalIdentifier;
}
