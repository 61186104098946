<form [formGroup]="form" (devappSubmit)="submitForm()">
  <div class="modal-header">
    <h4 class="modal-title">New Group</h4>
    <button type="button" class="btn-close" (click)="hideModal()"></button>
  </div>
  <div class="modal-body">
    <p>Create a group and invite other users to build and share packages.</p>

    <devapp-form-errors [form]="form"></devapp-form-errors>

    <devapp-form-control-frame [control]="form.controls.name" [label]="'Name'" [required]="true">
      <devapp-form-control-text></devapp-form-control-text>
    </devapp-form-control-frame>
    <devapp-form-control-frame
      *ngIf="canCreateCommonGroup"
      [control]="form.controls.common"
      [label]="'Common Group'"
      [required]="false"
    >
      <devapp-form-control-checkbox></devapp-form-control-checkbox>
    </devapp-form-control-frame>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-link" (click)="hideModal()">Cancel</button>
    <button type="submit" class="btn btn-primary">Create</button>
  </div>
</form>
