import { isEqual } from 'lodash-es';
import { Page } from '../../../../application/page';
import { UsedSignalsVisitor } from '../../../../public-api';
import { Problem, Validatable, ValidationTrigger, Validator } from '../validator';

export class PageValidator extends Validator {
  isRelevant(node: Validatable, triggers: ValidationTrigger[]): boolean {
    return (
      node instanceof Page &&
      (triggers.includes('nodeProperties') ||
        triggers.includes('applicationWide') ||
        (triggers.includes('childrenProperties') && node.variations.length !== 0))
    );
  }

  validate(page: Page): Problem[] {
    const allProblems: Problem[] = [];

    const problem1 = this.validateHasControls(page);
    if (problem1 != null) {
      allProblems.push(problem1);
    }

    const problem2 = this.validateNameIsNotEmpty(page);
    if (problem2 != null) {
      allProblems.push(problem2);
    }

    const problem3 = this.validateScreenTypesHaveSameSignals(page);
    if (problem3 != null) {
      allProblems.push(problem3);
    }

    return allProblems;
  }

  private validateHasControls(page: Page): Problem | null {
    if (page.controlRoot.children.length > 0) {
      return null;
    }

    if (page.variations.length > 0) {
      return {
        severity: 'warning',
        shortDescription: 'Page has no default content.',
        longDescription:
          "Page has no default content in case no page variation match is found. Please add a control to the page's root.",
        subObjects: [],
      };
    } else {
      return {
        severity: 'warning',
        shortDescription: 'Page has no content.',
        longDescription: 'Page has no content. Consider removing empty page.',
        subObjects: [],
      };
    }
  }

  private validateNameIsNotEmpty(page: Page): Problem | null {
    if (page.name === '') {
      return {
        severity: 'error',
        shortDescription: 'Page name is empty.',
        longDescription: 'Page name is empty. Please specify a name for the page using Page Properties pane.',
        subObjects: ['name'],
      };
    }
    return null;
  }

  private validateScreenTypesHaveSameSignals(page: Page): Problem | null {
    if (page.variations.length === 0) {
      return null;
    }

    const allSignals = page.variations.map((variation) => {
      const visitor = new UsedSignalsVisitor();
      variation.accept(visitor);
      return visitor
        .getLinked()
        .map((signal) => signal.asString())
        .sort();
    });

    if (!allSignals.every((signals) => isEqual(allSignals[0], signals))) {
      return {
        severity: 'warning',
        shortDescription: 'Screen Types have differing signals assigned.',
        longDescription:
          'Screen Types have differing signals assigned. This might be an error or might be intentional. Please carefully check that the signals are correctly assigned.',
        subObjects: [],
      };
    }

    return null;
  }
}
