import { ControlVisitor } from './control-visitor';
import { Control } from '../application/control';
import { BarChart } from '../application/controls/bar-chart';
import { BooleanSignal } from '../application/controls/boolean-signal';
import { Button, SetPulseButtonAction } from '../application/controls/button';
import { EnumSignal } from '../application/controls/enum-signal';
import { Gauge } from '../application/controls/gauge';
import { LineChart } from '../application/controls/line-chart';
import { NumericSignal } from '../application/controls/numeric-signal';
import { StringSignal } from '../application/controls/string-signal';
import { Text } from '../application/controls/text';
import { SignalIdentifier } from '../application/signal-identifier';

/**
 * Visitor collects signals used by the controls tree.
 */
export class UsedSignalsVisitor extends ControlVisitor {
  private linkedSignals = new Map<string, SignalIdentifier>();
  private readSignals = new Map<string, SignalIdentifier>();

  visitControl(control: Control): void {
    if (control.visibility.conditions.length != 0) {
      control.visibility.conditions.forEach((condition) => {
        if (condition.reference instanceof SignalIdentifier) {
          this.addBoth(condition.reference);
        }
        if (condition.comparator instanceof SignalIdentifier) {
          this.addBoth(condition.comparator);
        }
      });
    }
    if (control instanceof LineChart) {
      control.lines.forEach((line) => this.addBoth(line.signalId));
    } else if (control instanceof BarChart) {
      control.bars.forEach((bar) => this.addBoth(bar.signalId));
    } else if (control instanceof BooleanSignal) {
      this.addBoth(control.signalId);
    } else if (control instanceof EnumSignal) {
      this.addBoth(control.signalId);
    } else if (control instanceof Gauge) {
      this.addBoth(control.signalId);
    } else if (control instanceof NumericSignal) {
      this.addBoth(control.signalId);
    } else if (control instanceof StringSignal) {
      this.addBoth(control.signalId);
    } else if (control instanceof Button && control.action instanceof SetPulseButtonAction) {
      this.addLinked(control.action.signalId);
    } else if (control instanceof Text) {
      for (const link of control.signals) {
        this.addBoth(link.signalId);
      }
    }
  }

  /**
   * All signals linked from the control tree.
   */
  getLinked() {
    return Array.from(this.linkedSignals.values());
  }

  /**
   * Signals, whose values should be continuously read from ECUs.
   */
  getRead() {
    return Array.from(this.readSignals.values());
  }

  private addBoth(signal: SignalIdentifier) {
    this.addRead(signal);
    this.addLinked(signal);
  }

  private addRead(signal: SignalIdentifier) {
    const stringSignal = signal.asString();
    if (stringSignal != null) {
      this.readSignals.set(stringSignal, signal);
    }
  }

  private addLinked(signal: SignalIdentifier) {
    const stringSignal = signal.asString();
    if (stringSignal != null) {
      this.linkedSignals.set(stringSignal, signal);
    }
  }
}
