<li
  class="ng-toast__message {{ toast.additionalClasses }}"
  [class.fade]="isAnimated"
  [class.show]="visible"
  (mouseenter)="onMouseEnter()"
  (mouseleave)="onMouseLeave()"
  (click)="onToastClick()"
>
  <div class="alert alert-{{ toast.className }}">
    <span class="d-flex flex-row align-items-center">
      <fa-icon [icon]="icon" class="me-2"></fa-icon>
      <span *ngIf="toast.count" class="message-count">{{ toast.count + 1 }}</span>
      <span class="content" [innerHTML]="toast.content"></span>
      <button type="button" class="btn btn-link ms-2" *ngIf="toast.dismissButton" (click)="onDismissClick($event)">
        OK
      </button>
    </span>
  </div>
</li>
