import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Group, GroupService } from './data/group.service';
import { DataError } from './http/error';
import { ModalComponent } from './modals/modal-component';
import { PermissionsService } from './security/permissions.service';
import { Session } from './security/session.service';
import { ToasterService } from './toaster/toaster.service';
import { defaultWriteRequestErrorHandlerFactory } from '../shared/error/utils';

@Component({
  selector: 'devapp-new-root-group-modal',
  templateUrl: './new-root-group-modal.component.html',
})
export class NewRootGroupModalComponent extends ModalComponent {
  public form: FormGroup<{ name: FormControl<string>; common: FormControl<boolean> }>;
  public name: FormControl<string>;
  public common: FormControl<boolean>;

  get canCreateCommonGroup() {
    return this.permissions.canCreateCommonGroup;
  }

  constructor(
    public activeModal: NgbActiveModal,
    private groupService: GroupService,
    private router: Router,
    private session: Session,
    private toaster: ToasterService,
    private permissions: PermissionsService,
  ) {
    super();

    this.name = new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(64)]);
    this.common = new FormControl(false);
    this.form = new FormGroup({
      name: this.name,
      common: this.common,
    });
  }

  submitForm() {
    this.groupService.create({ name: this.name.value, type: this.common.value ? 'COMMON' : 'REGULAR' }).subscribe({
      next: (createdGroup: Group) => {
        this.toaster.success({ content: 'Group was created.' });

        this.hideModal();

        this.session
          .setCurrentGroupId(createdGroup.id)
          .subscribe(() => this.router.navigate(['/groups', createdGroup.id]));
      },
      error: (error) => {
        if (error instanceof DataError && error.code === 'R000415') {
          this.toaster.danger(
            `Unable to create a new group. ` + `You don't have an available group ownership to create a new group.`,
          );
        } else {
          defaultWriteRequestErrorHandlerFactory(this.form, this.toaster, 'Failed creating group.')(error);
        }
      },
    });
  }
}
