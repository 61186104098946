import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { PageHeaderActionComponent } from './page-header/page-header-action.component';
import { PageHeaderComponent } from './page-header/page-header.component';

@NgModule({
  imports: [CommonModule, FontAwesomeModule, NgbDropdownModule],
  declarations: [PageHeaderComponent, PageHeaderActionComponent],
  exports: [PageHeaderComponent, PageHeaderActionComponent],
})
export class LayoutModule {}
