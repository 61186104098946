import { ControlValidator } from './control.validator';
import { EnumSignal } from '../../../../application/controls/enum-signal';
import { Problem, Validatable, ValidationTrigger } from '../validator';

export class EnumSignalValidator extends ControlValidator {
  public isRelevant(node: Validatable, triggers: ValidationTrigger[]): boolean {
    return (
      node instanceof EnumSignal &&
      (triggers.includes('nodeProperties') || triggers.includes('signals') || triggers.includes('applicationWide'))
    );
  }

  public validate(control: EnumSignal): Problem[] {
    const problems = [
      ...this.validateControl(control),
      ...this.validateSignalId(control.signalId, control.humanReadableName, 'signalId', this.systemIndex),
    ];

    if (problems.length === 0) {
      if (control.signalId.options.length === 0) {
        return [
          {
            severity: 'warning',
            shortDescription: 'Specified signal does not have any options to be displayed in the Enum Signal.',
            longDescription:
              'Specified signal does not have any options to be displayed in the Enum Signal. ' +
              'Please provide options using Configure button under Signal property in the Enum Signal Properties pane.',
            subObjects: ['signalId'],
          },
        ];
      }

      const range = control.signalId.getRange();
      if (!control.signalId.options.every((option) => option.value >= range[0] && option.value <= range[1])) {
        return [
          {
            severity: 'error',
            shortDescription: 'Enum Signal options are not within the available range of specified signal.',
            longDescription:
              'Enum Signal options are not within the available range of specified signal. ' +
              'Please provide options using Configure button under Signal property in the Enum Signal Properties pane.',
            subObjects: ['signalId'],
          },
        ];
      }
    }

    return problems;
  }
}
