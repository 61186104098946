import { ControlValidator } from './control.validator';
import { Hint } from '../../../../application/controls/hint';
import { Problem, Validatable, ValidationTrigger } from '../validator';

export class HintValidator extends ControlValidator {
  public isRelevant(node: Validatable, triggers: ValidationTrigger[]): boolean {
    return node instanceof Hint && (triggers.includes('nodeProperties') || triggers.includes('applicationWide'));
  }

  public validate(hint: Hint): Problem[] {
    const allProblems: Problem[] = super.validateControl(hint);

    const textProblem = this.validateText(hint);
    if (textProblem != null) {
      allProblems.push(textProblem);
    }
    return allProblems;
  }

  private validateText(hint: Hint): Problem | null {
    if (hint.text == null || hint.text.length === 0) {
      return {
        severity: 'warning',
        shortDescription: 'Hint has no text.',
        longDescription:
          "A Hint control without hint text doesn't provide any information. " +
          'Consider adding some content using Text property in the Hint Properties pane.',
        subObjects: ['text'],
      };
    }
    return null;
  }
}
