export const formatVersion = '0.2.15';

export interface ApplicationDeclaration {
  formatVersion: typeof formatVersion;
  pages: PageDeclaration[];
  themeOptions: ThemeDeclaration;
  screenTypes?: ScreenTypeDeclaration[];
}

export interface ThemeDeclaration {
  brandColor: {
    background: string;
  };
  primaryColor: {
    background: string;
  };
  pageColor: {
    background: string;
  };
  headerPresets: HeaderPresetDeclaration[];
}

export interface HeaderPresetDeclaration {
  id: string;
  label: string;
  fontSize: string;
  align: 'left' | 'center' | 'right' | 'justify';
}

export interface PageDeclaration {
  id: string;
  name: string;
  backgroundImage?: string;
  backgroundImageRepeat?: 'repeat' | 'repeat-x' | 'repeat-y' | 'no-repeat' | 'space' | 'round';
  backgroundImageSize?: 'auto' | 'cover' | 'contain';
  pages?: PageDeclaration[];
  controls?: AnyControlDeclaration[];
  variations?: PageVariationDeclaration[];
}

export interface ScreenTypeDeclaration {
  id: string;
  name: string;
  minWidth: string;
  orientation: 'landscape' | 'portrait' | 'any';
}

export interface PageVariationDeclaration {
  screenTypeId: string;
  controlRoot: RootControlDeclaration;
}

export interface ControlDeclaration {
  id: string;

  width?: string;
  height?: string;
  margin?: ControlDeclaration.Margins;
  visibility?: ControlDeclaration.VisibilityDeclaration;
}

export namespace ControlDeclaration {
  export interface Margins {
    top?: string;
    bottom?: string;
    right?: string;
    left?: string;
  }

  export interface VisibilityDeclaration {
    aggregator: 'or' | 'and';
    conditions: ControlDeclaration.VisibilityConditionDeclaration[];
  }

  export interface VisibilityConditionDeclaration {
    type: 'VisibilityShowCondition' | 'VisibilityHideCondition';
    operator:
      | 'none'
      | 'lessThan'
      | 'lessThanOrEqualTo'
      | 'equalTo'
      | 'notEqualTo'
      | 'greaterThanOrEqualTo'
      | 'greaterThan'
      | 'inSet'
      | 'notInSet'
      | 'contains'
      | 'doesNotContain';
    reference: AnySignalIdentifierDeclaration;
    comparator: AnySignalIdentifierDeclaration | string | number;
    comparatorType: 'Signal' | 'Value';
  }
}

export interface SignalFormatDeclaration {
  fractionDigits: number;
  fractionLimiting: 'round' | 'truncate';
}

export interface SignalScaleDeclaration {
  multiplier: number;
  offset: number;
}

export interface BaseSignalIdentifierDeclaration {
  signalType: 'BOOL' | 'U8' | 'U16' | 'U32' | 'S8' | 'S16' | 'S32' | `STRING[${number}]` | null;
  unit?: string;
  options?: SignalOptionDeclaration[];
  scale?: SignalScaleDeclaration;
  format?: SignalFormatDeclaration;
  source?: AnySignalSourceDeclaration;
}

export interface SignalSourceDeclaration {
  signalName: string | null;
}

export interface EcuSignalSourceDeclaration extends SignalSourceDeclaration {
  type: 'EcuSignalSource';
  ecuId: string | null;
}

export interface ClientDeviceSignalSourceDeclaration extends SignalSourceDeclaration {
  type: 'ClientDeviceSignalSource';
}

export interface SignalIdentifierDeclaration extends BaseSignalIdentifierDeclaration {
  type: 'SignalIdentifier';
}

export interface ArraySignalIdentifierDeclaration extends BaseSignalIdentifierDeclaration {
  type: 'ArraySignalIdentifier';
  index: number | null;
}

export interface SignalOptionDeclaration {
  name: string;
  value: number;
}

export interface Area {
  range: [number, number];
  color: string;
  warning?: boolean;
}

export type AnySignalSourceDeclaration = EcuSignalSourceDeclaration | ClientDeviceSignalSourceDeclaration;

export type AnySignalIdentifierDeclaration = SignalIdentifierDeclaration | ArraySignalIdentifierDeclaration;

export interface CompositeControlDeclaration extends ControlDeclaration {
  children: AnyControlDeclaration[];
}

export interface LabelControlDeclaration extends ControlDeclaration {
  type: 'Label';
  text: string;
  align: 'left' | 'center' | 'right';
}

export interface TextControlDeclaration extends ControlDeclaration {
  type: 'Text';
  text: string;
  align: 'left' | 'center' | 'right' | 'justify';
  signals: TextControlDeclaration.SignalBinding[];
}

export namespace TextControlDeclaration {
  export interface SignalBinding {
    signalID: AnySignalIdentifierDeclaration;
    variableName: string;
  }
}

export interface HintControlDeclaration extends ControlDeclaration {
  type: 'Hint';
  title: string;
  text: string;
  align: 'left' | 'center' | 'right' | 'justify';
}

export interface ButtonControlDeclaration extends ControlDeclaration {
  type: 'Button';
  text: string;
  action: AnyButtonActionDeclaration;
}

export interface WriteSignalsActionDeclaration {
  type: 'writeSignals';
}

export interface NavigatePageActionDeclaration {
  type: 'navigatePage';
  pageId: string | null;
}

export interface SetPulseActionDeclaration {
  type: 'setPulse';
  signalId: AnySignalIdentifierDeclaration;
}

export type AnyButtonActionDeclaration =
  | WriteSignalsActionDeclaration
  | NavigatePageActionDeclaration
  | SetPulseActionDeclaration;

export interface ImageControlDeclaration extends ControlDeclaration {
  type: 'Image';
  resource: string | null;
  intrinsicAspectRatio: number | null;
}

export interface StackLayoutControlDeclaration extends CompositeControlDeclaration {
  type: 'StackLayout';
  direction: 'topToBottom' | 'leftToRight';
  children: AnyControlDeclaration[];
}

export interface GridLayoutControlDeclaration extends CompositeControlDeclaration {
  type: 'GridLayout';
  children: GridLayoutRowControlDeclaration[];
  columns: number;
  spacing: string;
}

export interface GridLayoutRowControlDeclaration extends CompositeControlDeclaration {
  type: 'GridLayoutRow';
  children: GridLayoutCellControlDeclaration[];
}

export interface GridLayoutCellControlDeclaration extends CompositeControlDeclaration {
  type: 'GridLayoutCell';
}

export interface NumericSignalControlDeclaration extends ControlDeclaration {
  type: 'NumericSignal';
  readonly: boolean;
  standalone: boolean;
  signalID: AnySignalIdentifierDeclaration;
  areas: Area[];
}

export interface EnumSignalControlDeclaration extends ControlDeclaration {
  type: 'EnumSignal';
  readonly: boolean;
  standalone: boolean;
  signalID: AnySignalIdentifierDeclaration;
  areas: Area[];
}

export interface BooleanSignalControlDeclaration extends ControlDeclaration {
  type: 'BooleanSignal';
  readonly: boolean;
  standalone: boolean;
  signalID: AnySignalIdentifierDeclaration;
  areas: Area[];
}

export interface GaugeControlDeclaration extends ControlDeclaration {
  type: 'Gauge';
  minimum: number;
  maximum: number;
  areas: Area[];
  signalID: AnySignalIdentifierDeclaration;
  valueForegroundColor: string;
  useThemeColor: boolean;
}

export interface LineChartControlDeclaration extends ControlDeclaration {
  type: 'LineChart';
  minimum: number;
  maximum: number;
  valuesCount: number;
  lines: LineChartControlDeclaration.Line[];
  direction: 'right' | 'left' | 'top' | 'bottom';
}

export namespace LineChartControlDeclaration {
  export interface Line {
    label: string;
    color: string;
    signalID: AnySignalIdentifierDeclaration;
  }
}

export interface BarChartControlDeclaration extends ControlDeclaration {
  type: 'BarChart';
  minimum: number;
  maximum: number;
  direction: 'right' | 'left' | 'top' | 'bottom';
  bars: BarChartControlDeclaration.Bar[];
  valuesCount: number;
}

export namespace BarChartControlDeclaration {
  export interface Bar {
    label: string;
    color: string;
    signalID: AnySignalIdentifierDeclaration;
  }
}

export interface HeaderControlDeclaration extends ControlDeclaration {
  type: 'Header';
  text: string;
  preset: string;
}

export interface RootControlDeclaration extends CompositeControlDeclaration {
  type: 'Root';
}
export interface StringSignalControlDeclaration extends ControlDeclaration {
  type: 'StringSignal';
  readonly: boolean;
  standalone: boolean;
  signalID: AnySignalIdentifierDeclaration;
}

export type AnyControlDeclaration =
  | LabelControlDeclaration
  | TextControlDeclaration
  | HintControlDeclaration
  | ButtonControlDeclaration
  | ImageControlDeclaration
  | StackLayoutControlDeclaration
  | GridLayoutControlDeclaration
  | GridLayoutRowControlDeclaration
  | GridLayoutCellControlDeclaration
  | NumericSignalControlDeclaration
  | EnumSignalControlDeclaration
  | BooleanSignalControlDeclaration
  | GaugeControlDeclaration
  | LineChartControlDeclaration
  | BarChartControlDeclaration
  | HeaderControlDeclaration
  | RootControlDeclaration
  | StringSignalControlDeclaration;
