import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule, Optional, SkipSelf } from '@angular/core';
import { BrowserModule, EventManager } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbDropdownModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { lastValueFrom } from 'rxjs';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { CustomEventManager } from './custom-event-manager';
import { AuthInterceptor } from './http/auth.interceptor';
import { API_BASE_URL, BaseUrlInterceptor } from './http/base-url.interceptor';
import { ErrorInterceptor } from './http/error.interceptor';
import { InvitationsInterceptor } from './http/invitations.interceptor';
import { PendingRequestsInterceptor } from './http/pending-requests.interceptor';
import { LayoutComponent } from './layout/layout.component';
import { ListGroupsModalComponent } from './list-groups-modal.component';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { NewRootGroupModalComponent } from './new-root-group-modal.component';
import { ConfigurablePreloadingStrategy } from './preloading-strategy';
import { RedirectComponent } from './redirect.component';
import { DIPService } from './security/dip.service';
import { IdpService } from './security/idp.service';
import { PermissionGuard } from './security/permission.guard';
import { Session } from './security/session.service';
import { SpinnerComponent } from './spinner/spinner.component';
import { ToasterModule } from './toaster/toaster.module';
import { environment } from '../../environments/environment';
import { FormsModule } from '../shared/forms/forms.module';
import { SharedModule } from '../shared/shared.module';

export function initializeSessionFactory(session: Session): () => Promise<void> {
  return () => lastValueFrom(session.initialize());
}

@NgModule({
  imports: [
    BrowserModule,
    FontAwesomeModule,
    FormsModule,
    HttpClientModule,
    NgbModalModule,
    NgbDropdownModule,
    RouterModule,
    SharedModule,
  ],
  declarations: [
    LayoutComponent,
    SpinnerComponent,
    BreadcrumbComponent,
    RedirectComponent,
    NewRootGroupModalComponent,
    ListGroupsModalComponent,
  ],
  exports: [LayoutComponent, SpinnerComponent, ToasterModule],
  providers: [
    // Core
    { provide: API_BASE_URL, useValue: environment.sleipnerBaseUrl },
    PendingRequestsInterceptor,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: BaseUrlInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useExisting: PendingRequestsInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: InvitationsInterceptor, multi: true },
    ConfigurablePreloadingStrategy,
    { provide: EventManager, useClass: CustomEventManager },

    // Session and permissions
    { provide: IdpService, useClass: DIPService },
    Session,
    PermissionGuard,
    { provide: APP_INITIALIZER, useFactory: initializeSessionFactory, deps: [Session], multi: true },
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
