import { Property } from '../../../../application/property';
import { Command } from '../command';

export class UpdatePropertyCommand<T> implements Command {
  constructor(public data: Data<T>) {}

  apply(): void {
    this.data.property.set(this.data.newValue);
  }

  unapply(): void {
    this.data.property.set(this.data.oldValue);
  }
}

interface Data<T> {
  property: Property<T, unknown, unknown>;
  oldValue: T;
  newValue: T;
}
