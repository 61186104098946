import { Application } from '../../../../application/application';
import { ScreenType } from '../../../../application/screen-type';
import { Command } from '../command';

export class AddScreenTypeCommand implements Command {
  constructor(public data: Data) {}

  apply(): void {
    this.data.application.addScreenType(this.data.screenType);
  }

  unapply(): void {
    this.data.application.removeScreenType(this.data.screenType);
  }
}

interface Data {
  screenType: ScreenType;
  application: Application;
}
