import { Directive, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormGroup, FormGroupDirective } from '@angular/forms';
import { Subscription } from 'rxjs';

/**
 * This directive should be used as a drop-in replacement for `ngSubmit` event of FormGroupDirective.
 *
 * It wires up validation logic for our custom FormsModule.
 * @see processSubmit() for more details.
 */
@Directive({ selector: '[devappSubmit]' })
export class SubmitDirective implements OnInit, OnDestroy {
  @Output()
  readonly devappSubmit = new EventEmitter();

  private subscription = new Subscription();

  constructor(private directive: FormGroupDirective) {}

  ngOnInit(): void {
    this.subscription.add(
      this.directive.ngSubmit.subscribe(() => {
        processSubmit(this.directive.form);

        if (this.directive.form.valid) {
          this.devappSubmit.emit();
        }
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

/**
 * Common logic to be executed on form submission.
 *
 * This logic is responsible for two things:
 *
 * 1. Mark all ancestor controls as dirty, which reveals all errors to the user upon submission. The idea is that when
 * user opens a *create* form, it is initially invalid (missing values), but we don't want to stress user by showing it,
 * so they will reveal errors on by one as they fill out the form. However there is no guarantee that they will edit all
 * fields, hence we mark them as dirty on submit to reveal the errors.
 * 2. Reset externally added errors (usually by {@see AbstractControl.setErrors()} call). The idea is to prevent request
 * to the server if any local validators failed as request will definitely fail. However we also add external errors
 * when previous form submission returned errors ({@see addErrorsFromResponse}). These errors are usually intermittent
 * (e.g. not unique name of the package) and could have been fixed by user in a separate tab. Hence they should not
 * prevent a repeated form submission.
 *
 * @param control - Control to process.
 */
function processSubmit(control: AbstractControl) {
  if (control instanceof FormGroup) {
    for (const child of Object.values(control.controls)) {
      processSubmit(child);
    }
  } else if (control instanceof FormArray) {
    for (const child of control.controls) {
      processSubmit(child);
    }
  }

  control.markAsDirty({ onlySelf: true });
  control.updateValueAndValidity({ onlySelf: true });
}
