/**
 * Property is a data structure, which represents a property of the node
 * in the application tree which can be modified by the user.
 *
 * This allows to handle all properties in the same way and supports adding
 * different kinds of logic:
 * - fallback to default value if raw value is not valid
 * - custom logic to parse raw value into something more convenient to use
 */
import { Validatable } from '../host/shared/validation/validator';

export class Property<TRaw, TSafe = TRaw, TParsed = TSafe> {
  private rawValue: TRaw;
  private safeValue: TSafe;
  private parsedValue: TParsed;

  private _parentNode: Validatable | null = null;

  private guard?: (raw: TRaw) => TSafe;
  private parse?: (raw: TSafe) => TParsed;

  get raw(): TRaw {
    // TODO: Add assertion that set() was called at least once
    return this.rawValue;
  }

  get safe(): TSafe {
    // TODO: Add assertion that set() was called at least once
    return this.safeValue;
  }

  get parsed(): TParsed {
    // TODO: Add assertion that set() was called at least once
    return this.parsedValue;
  }

  get parentNode() {
    return this._parentNode;
  }

  constructor(parentNode: Validatable, { guard, parse }: PropertyOptions<TRaw, TSafe, TParsed> = {}) {
    this._parentNode = parentNode;
    Object.defineProperty(this, 'guard', { enumerable: false, value: guard });
    Object.defineProperty(this, 'parse', { enumerable: false, value: parse });
  }

  set(value: TRaw): void {
    this.rawValue = value;
    this.safeValue = this.guard != null ? this.guard(this.rawValue) : (this.rawValue as any as TSafe);
    this.parsedValue = this.parse != null ? this.parse(this.safeValue) : (this.safeValue as any as TParsed);
  }
}

export interface PropertyOptions<TRaw, TSafe, TParsed> {
  guard?: (raw: TRaw) => TSafe;
  parse?: (raw: TSafe) => TParsed;
}
