import { Command } from './command';
import { CommandManager } from './command-manager';
import { AddControlCommand } from './commands/add-control.command';
import { AddPageVariationCommand } from './commands/add-page-variation.command';
import { AddPageCommand } from './commands/add-page.command';
import { AddScreenTypeCommand } from './commands/add-screen-type.command';
import { EditApplicationCommand } from './commands/edit-application.command';
import { EditControlPropertyCommand, getValueForKeyPath } from './commands/edit-control-property.command';
import { EditPagePropertyCommand } from './commands/edit-page-property.command';
import { EditPageCommand } from './commands/edit-page.command';
import { EditSignalIdentifierCommand } from './commands/edit-signal-identifier.command';
import { RemoveControlCommand } from './commands/remove-control.command';
import { RemovePageVariationCommand } from './commands/remove-page-variation.command';
import { RemovePageCommand } from './commands/remove-page.command';
import { RemoveScreenTypeCommand } from './commands/remove-screen-type.command';
import { UpdatePropertyCommand } from './commands/update-property.command';
import { ApplicationAdapter } from '../../../application/adapter';
import { Application, ThemeOptions } from '../../../application/application';
import { CompositeControl } from '../../../application/composite-control';
import { Control } from '../../../application/control';
import { GridLayout, GridLayoutCell, GridLayoutRow } from '../../../application/controls/grid-layout';
import { Root } from '../../../application/controls/root';
import { Page } from '../../../application/page';
import { PageVariation } from '../../../application/page-variation';
import { Property } from '../../../application/property';
import { ScreenType } from '../../../application/screen-type';
import {
  FractionLimiting,
  SignalFormatter,
  SignalIdentifier,
  SignalOption,
  SignalScale,
} from '../../../application/signal-identifier';
import { array } from '../../../utils/utils';
import { AddedItemVisitor } from '../../../visitor/added-item-visitor';
import { UniqueIdVisitor } from '../../../visitor/unique-id-visitor';

class BaseChangesManager {
  constructor(
    protected commandManager: CommandManager,
    protected application: Application,
    protected adapter: ApplicationAdapter,
    protected ids: Map<string, number>,
  ) {}

  editApplication(app: Application, newThemeOptions: ThemeOptions) {
    const command = new EditApplicationCommand({ app, newThemeOptions, oldThemeOptions: app.themeOptions });

    this.scheduleCommands(command);
    return this;
  }

  addPage(parentPage: Page, page: Page, position: number) {
    const command = new AddPageCommand({ parentPage, page, position });

    this.scheduleCommands(command);
    return this;
  }

  clonePage(pageToBeCloned: Page) {
    if (pageToBeCloned.parent == null) {
      throw new Error('Page is not attached to a parent');
    }

    const screenTypesMap = new Map(this.application.screenTypes.map((item) => [item.id, item]));
    const clonedPage = this.adapter.createPage(this.adapter.serializePage(pageToBeCloned), { screenTypesMap });
    clonedPage.name = `${pageToBeCloned.safeName} copy`;
    const addedItemVisitor = new AddedItemVisitor((type) => this.getNewId(type));
    clonedPage.accept(addedItemVisitor);

    const command = new AddPageCommand({
      page: clonedPage,
      parentPage: pageToBeCloned.parent,
      position: pageToBeCloned.parent.children.indexOf(pageToBeCloned) + 1,
    });

    this.scheduleCommands(command);
    return this;
  }

  editPage(page: Page, newName: string) {
    const command = new EditPageCommand({ page, newName, oldName: page.name });

    this.scheduleCommands(command);
    return this;
  }

  editPageProperty(page: Page, newValue: any, keyPath: string) {
    const command = new EditPagePropertyCommand({
      page,
      keyPath,
      oldValue: getValueForKeyPath(page, keyPath),
      newValue,
    });

    this.scheduleCommands(command);
    return this;
  }

  movePage(page: Page, newParent: Page, newPosition: number) {
    if (page.parent == null) {
      throw new Error('Page to be moved is not attached to any parent');
    }

    const removePageCommand = new RemovePageCommand({
      parentPage: page.parent,
      position: page.parent.children.indexOf(page),
      removedPage: page,
    });

    const addPageCommand = new AddPageCommand({
      parentPage: newParent,
      position: newPosition,
      page,
    });

    this.scheduleCommands(removePageCommand, addPageCommand);
    return this;
  }

  removePage(page: Page) {
    if (page.parent == null) {
      throw new Error('Page is not attached to any parent');
    }

    const command = new RemovePageCommand({
      parentPage: page.parent,
      removedPage: page,
      position: page.parent.children.indexOf(page),
    });

    this.scheduleCommands(command);
    return this;
  }

  addPageVariation(parentPage: Page, variation: PageVariation, position: number) {
    return this.scheduleCommands(new AddPageVariationCommand({ parentPage, variation, position }));
  }

  clonePageVariation(variationToClone: PageVariation) {
    if (variationToClone.parentPage == null) {
      throw new Error('Page variation is not attached to a page.');
    }

    const controlRoot = new Root({
      id: variationToClone.controlRoot.id,
      children: variationToClone.controlRoot.children.map((item) =>
        this.adapter.createControl(this.adapter.serializeControl(item)),
      ),
    });
    controlRoot.accept(new AddedItemVisitor((type) => this.getNewId(type)));
    const variation = new PageVariation({ screenType: variationToClone.screenType.raw, controlRoot });

    const command = new AddPageVariationCommand({
      variation,
      parentPage: variationToClone.parentPage,
      position: variationToClone.parentPage.variations.indexOf(variationToClone) + 1,
    });

    this.scheduleCommands(command);
    return this;
  }

  movePageVariation(variation: PageVariation, newParent: Page, newPosition: number) {
    if (variation.parentPage == null) {
      throw new Error('Page variation is not attached to a page.');
    }

    const removePageVariationCommand = new RemovePageVariationCommand({
      parentPage: variation.parentPage,
      position: variation.parentPage.variations.indexOf(variation),
      removedVariation: variation,
    });

    const addPageVariationCommand = new AddPageVariationCommand({
      parentPage: newParent,
      position: newPosition,
      variation,
    });

    this.scheduleCommands(removePageVariationCommand, addPageVariationCommand);
    return this;
  }

  removePageVariation(variation: PageVariation) {
    if (variation.parentPage == null) {
      throw new Error('Page variation is not attached to a page.');
    }

    const command = new RemovePageVariationCommand({
      parentPage: variation.parentPage,
      removedVariation: variation,
      position: variation.parentPage.variations.indexOf(variation),
    });

    this.scheduleCommands(command);
    return this;
  }

  addControl(control: Control, parentControl: CompositeControl, position: number) {
    const command = new AddControlCommand({ parentControl, control, position });

    this.scheduleCommands(command);
    return this;
  }

  cloneControl(control: Control) {
    if (control.parent == null) {
      throw new Error('Control is not attached to a parent control');
    }

    const position = control.parent.indexOf(control) + 1;
    const parent = control.parent;
    const newControl = this.adapter.createControl(this.adapter.serializeControl(control));
    const addedItemVisitor = new AddedItemVisitor((type) => this.getNewId(type));
    newControl.accept(addedItemVisitor);

    return this.addControl(newControl, parent, position);
  }

  moveControl(control: Control, newParent: CompositeControl, newPosition: number) {
    if (control.parent == null) {
      throw new Error('Control is not attached to any parent');
    }

    const removeCommand = new RemoveControlCommand({
      parentControl: control.parent,
      position: control.parent.indexOf(control),
      removedControl: control,
    });

    const addCommand = new AddControlCommand({
      parentControl: newParent,
      position: newPosition,
      control,
    });

    this.scheduleCommands(removeCommand, addCommand);
    return this;
  }

  removeControl(control: Control) {
    if (control.parent == null) {
      throw new Error('Control is not attached to a parent control');
    }

    const command = new RemoveControlCommand({
      parentControl: control.parent,
      removedControl: control,
      position: control.parent.children.indexOf(control),
    });

    this.scheduleCommands(command);
    return this;
  }

  editControlProperty(control: Control, newValue: any, keyPath: string) {
    const command = new EditControlPropertyCommand({
      control,
      keyPath,
      oldValue: getValueForKeyPath(control, keyPath),
      newValue,
    });

    this.scheduleCommands(command);
    return this;
  }

  addScreenType(screenType: ScreenType) {
    return this.scheduleCommands(new AddScreenTypeCommand({ screenType, application: this.application }));
  }

  removeScreenType(screenType: ScreenType) {
    return this.scheduleCommands(new RemoveScreenTypeCommand({ screenType, application: this.application }));
  }

  updateProperty<T>(property: Property<T, unknown, unknown>, newValue: T) {
    return this.scheduleCommands(new UpdatePropertyCommand({ property, oldValue: property.raw, newValue }));
  }

  editSignalIdentifier(signal: SignalIdentifier, data: EditSignalRawData) {
    const command = new EditSignalIdentifierCommand({
      signalId: signal,
      newUnit: data.unit,
      newOptions: data.options,
      oldUnit: signal.unit,
      oldOptions: signal.options,
      newScale: new SignalScale({
        multiplier: data.multiplier,
        offset: data.offset,
      }),
      oldScale: signal.scale,
      newFormatter: new SignalFormatter({
        fractionDigits: data.fractionDigits,
        fractionLimiting: data.fractionLimiting,
      }),
      oldFormatter: signal.formatter,
    });

    this.scheduleCommands(command);
    return this;
  }

  updateRowProperty(control: GridLayout, newValue: number) {
    if (control.parent == null) {
      throw new Error('Control is not attached to a parent control');
    }

    const position = control.parent.indexOf(control);
    const newControl = this.adapter.createControl(this.adapter.serializeControl(control)) as GridLayout;
    const currentRows = newControl.safeRows;

    newControl.rows = newValue;
    if (newControl.safeRows < currentRows) {
      newControl.children.slice(newControl.safeRows, currentRows).forEach((row) => newControl.remove(row));
    } else if (newControl.safeRows > currentRows) {
      const newRows = array(newControl.safeRows - currentRows, () => {
        return new GridLayoutRow({
          id: this.getNewId('grid-layout-row'),
          children: array(newControl.safeColumns, () => {
            return new GridLayoutCell({ id: this.getNewId('grid-layout-cell'), children: [] });
          }),
        });
      });

      newRows.forEach((row) => newControl.add(row));
    }

    const removeCommand = new RemoveControlCommand({
      parentControl: control.parent,
      position,
      removedControl: control,
    });

    const addCommand = new AddControlCommand({
      parentControl: control.parent,
      position,
      control: newControl,
    });

    this.scheduleCommands(removeCommand, addCommand);
    return this;
  }

  updateColumnProperty(control: GridLayout, newValue: number) {
    if (control.parent == null) {
      throw new Error('Control is not attached to a parent control');
    }

    const position = control.parent.indexOf(control);
    const newControl = this.adapter.createControl(this.adapter.serializeControl(control)) as GridLayout;
    const currentColumns = newControl.safeColumns;

    newControl.columns = newValue;
    if (newControl.safeColumns < currentColumns) {
      newControl.children.forEach((row) => {
        row.children.slice(newControl.safeColumns, currentColumns).forEach((cell) => row.remove(cell));
      });
    } else if (newControl.safeColumns > currentColumns) {
      newControl.children.forEach((row) => {
        const newCells = array(newControl.safeColumns - currentColumns, () => {
          return new GridLayoutCell({ id: this.getNewId('grid-layout-cell'), children: [] });
        });
        newCells.forEach((cell) => row.add(cell));
      });
    }

    const removeCommand = new RemoveControlCommand({
      parentControl: control.parent,
      position,
      removedControl: control,
    });

    const addCommand = new AddControlCommand({
      parentControl: control.parent,
      position,
      control: newControl,
    });

    this.scheduleCommands(removeCommand, addCommand);
    return this;
  }

  getNewId(type: string) {
    const baseId = type.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();

    const count = this.ids.get(baseId);
    if (count == null) {
      this.ids.set(baseId, 0);
      return `${baseId}`;
    } else {
      this.ids.set(baseId, count + 1);
      return `${baseId}-${count + 1}`;
    }
  }

  protected scheduleCommands(...commands: Command[]): this {
    this.commandManager.applyCommands(commands);
    return this;
  }
}

export class ChangesManager extends BaseChangesManager {
  constructor(commandManager: CommandManager, application: Application, adapter: ApplicationAdapter) {
    const ids = new Map<string, number>();

    super(commandManager, application, adapter, ids);

    this.setupIdsMap();
  }

  batch() {
    return new BulkChangesManager(this.commandManager, this.application, this.adapter, this.ids);
  }

  private setupIdsMap() {
    const uniqueIdsVisitor = new UniqueIdVisitor();
    this.application.rootPage.accept(uniqueIdsVisitor);

    const ids = new Set([
      ...uniqueIdsVisitor.getAllIds(),
      ...new Set(
        this.application.themeOptions.headerPresets.map((preset) => {
          return preset.id;
        }),
      ),
    ]);
    for (const item of this.application.screenTypes) {
      ids.add(item.id);
    }
    for (const id of ids) {
      const match = id.match(/^(\D+)(?:-(\d+))?$/);
      if (match != null) {
        const [, base, value = '1'] = match;
        this.ids.set(base, Math.max(parseInt(value, 10), this.ids.has(base) ? this.ids.get(base) : 0));
      }
    }

    this.fixControlsWithNonUniqueIds(uniqueIdsVisitor);
  }

  private fixControlsWithNonUniqueIds(idsVisitor: UniqueIdVisitor) {
    const controlsWithNonUniqueIds = idsVisitor.getAllControlsWithNonUniqueIds();
    for (const control of controlsWithNonUniqueIds) {
      const controlType = control.humanReadableName.replace(/\s+/g, '-').toLowerCase();
      control.id = this.getNewId(controlType);
    }
  }
}

export class BulkChangesManager extends BaseChangesManager {
  private commands: Command[] = [];

  apply() {
    this.commandManager.applyCommands(this.commands);
  }

  protected override scheduleCommands(...commands: Command[]): this {
    this.commands.push(...commands);
    return this;
  }
}

export interface EditSignalRawData {
  unit: string;
  options: SignalOption[];
  multiplier: number;
  offset: number;
  fractionDigits: number;
  fractionLimiting?: FractionLimiting;
}
