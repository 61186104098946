import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { PermissionsService } from './core/security/permissions.service';

@Injectable()
export class TechnicianGuard {
  constructor(
    private router: Router,
    private permissions: PermissionsService,
  ) {}

  canActivate(_: ActivatedRouteSnapshot) {
    if (!this.permissions.canListPackages) {
      this.router.navigate(['mobile-applications']);
      return false;
    }
    return true;
  }
}
