import { ControlOptions } from '../control';
import { SignalIdentifier } from '../signal-identifier';
import { SimpleControl } from '../simple-control';

export class Button extends SimpleControl {
  text: string;
  action: ButtonAction;

  readonly minimumWidth = 2.5;
  readonly minimumHeight = 2.5;

  readonly humanReadableName = 'Button';

  constructor({ text = 'Button', action = new WriteSignalsButtonAction(), ...options }: ButtonOptions) {
    super(options);

    this.text = text;
    this.action = action;
  }
}

export interface ButtonOptions extends ControlOptions {
  text?: string;
  action?: ButtonAction;
}

export class WriteSignalsButtonAction {
  protected readonly type = 'writeSignals';
}

export class NavigatePageButtonAction {
  protected readonly type = 'navigatePage';

  pageId: string | null;

  constructor({ pageId = null }: { pageId: string | null }) {
    this.pageId = pageId;
  }
}

export class SetPulseButtonAction {
  protected readonly type = 'setPulse';

  signalId: SignalIdentifier;

  constructor({ signalId }: { signalId: SignalIdentifier }) {
    this.signalId = signalId;
  }
}

export type ButtonAction = WriteSignalsButtonAction | NavigatePageButtonAction | SetPulseButtonAction;
