import { ControlVisitor } from './control-visitor';
import { Control } from '../application/control';
import { Header } from '../application/controls/header';

export class HeaderInUseVisitor extends ControlVisitor {
  headersInUse = new Set<string>();

  visitControl(control: Control): void {
    if (control instanceof Header) {
      this.headersInUse.add(control.preset);
    }
  }
}
