import { FormGroup } from '@angular/forms';
import { DataError, ValidationError } from '../../core/http/error';
import { UNEXPECTED_ERROR_MESSAGE } from '../../core/http/error-code';
import { ToasterService } from '../../core/toaster/toaster.service';
import { addErrorsFromResponse } from '../forms/validation/utils';

export function defaultErrorFormatter(error: Error, prefix?: string) {
  const suffix = error instanceof DataError ? error.userMessage : null;

  if (prefix != null && suffix === UNEXPECTED_ERROR_MESSAGE) {
    return prefix;
  } else if (prefix != null && suffix != null) {
    return `${prefix} ${suffix}`;
  } else if (prefix != null) {
    return prefix;
  } else if (suffix != null) {
    return suffix;
  } else {
    return UNEXPECTED_ERROR_MESSAGE;
  }
}

/**
 * Factory, which creates default handler for the data writing request (POST
 * or PATCH). It usually involves filling data into form, so in case of
 * ValidationError it will add errors to the provided form if it's any other
 * error, it will show toast instead.
 *
 * Example:
 *
 * writeMyData()
 *   .catch(defaultWriteRequestErrorHandlerFactory(this.form, this.toaster);
 *
 * @param form - Form to add validation errors to.
 * @param toaster - Toaster service to be used for showing toast.
 * @param options - Extra options or prefix to add to the error message in the toast.
 */
export function defaultWriteRequestErrorHandlerFactory(
  form: FormGroup,
  toaster: ToasterService,
  options?: string | DefaultWriteRequestErrorHandlerFactoryOptions,
) {
  const prefix = typeof options === 'string' ? options : options?.prefix;
  const customFieldMapping = typeof options === 'string' ? null : options?.customFieldMapping;

  return (error: Error) => {
    if (error instanceof ValidationError) {
      addErrorsFromResponse(form, error, { customFieldMapping });
    } else {
      toaster.danger(defaultErrorFormatter(error, prefix));
    }
  };
}

export interface DefaultWriteRequestErrorHandlerFactoryOptions {
  /**
   * Optional prefix to add to the error message in the toast.
   */
  prefix?: string;

  /**
   * Customise how API field name is mapped into form field name.
   */
  customFieldMapping?: { [apiFieldName: string]: string | null };
}

/**
 * Factory, which creates default handler for the data delete request (DELETE).
 * In case request fails, it will show error toast with error message returned
 * from the server.
 *
 * Example:
 *
 * deleteMyData()
 *   .catch(defaultDeleteRequestErrorHandlerFactory(this.toaster);
 *
 * @param toaster - Toaster service to be used for showing toast.
 * @param prefix? - Optional prefix to add to the error message in the toast.
 */
export function defaultDeleteRequestErrorHandlerFactory(toaster: ToasterService, prefix?: string) {
  return (error: Error) => {
    toaster.danger(defaultErrorFormatter(error, prefix));
  };
}
