import { Page } from '../../../../application/page';
import { Command } from '../command';

export class RemovePageCommand implements Command {
  constructor(public data: RemovePageCommandData) {}

  apply(): void {
    this.data.parentPage.remove(this.data.removedPage);
  }

  unapply(): void {
    this.data.parentPage.insert(this.data.removedPage, this.data.position);
  }
}

export interface RemovePageCommandData {
  parentPage: Page;
  removedPage: Page;
  position: number;
}
