import { ControlOptions } from '../control';
import { SignalIdentifier } from '../signal-identifier';
import { SimpleControl } from '../simple-control';

export class LineChart extends SimpleControl {
  static readonly maxValuePoints = 1000;
  static readonly minValuePoints = 5;

  minimum: number;
  maximum: number;
  valuesCount: number;
  lines: Line[];
  direction: 'right' | 'left' | 'top' | 'bottom';

  readonly minimumWidth = 15;
  readonly minimumHeight = 10;
  override readonly intrinsicAspectRatio = 1.62; // see https://en.wikipedia.org/wiki/Golden_ratio

  get valuesCountSafe(): number {
    if (
      typeof this.valuesCount === 'number' &&
      this.valuesCount >= LineChart.minValuePoints &&
      this.valuesCount <= LineChart.maxValuePoints
    ) {
      return this.valuesCount;
    }
    return 5;
  }

  get min() {
    return this.minimum >= this.maximum || this.minimum == null ? 0 : this.minimum;
  }

  get max() {
    return this.maximum == null || this.maximum <= this.minimum ? 100 : this.maximum;
  }

  readonly humanReadableName = 'Line Chart';

  constructor({
    minimum = 0,
    maximum = 100,
    valuesCount = 5,
    lines = [],
    direction = 'left',
    ...options
  }: LineChartOptions) {
    super(options);

    this.minimum = minimum;
    this.maximum = maximum;
    this.valuesCount = valuesCount;
    this.lines = lines;
    this.direction = direction;
  }
}

export interface LineChartOptions extends ControlOptions {
  minimum?: number;
  maximum?: number;
  valuesCount?: number;
  lines?: Line[];
  // Line Chart does not have any direction, this is for smooth conversion between chart controls.
  direction?: 'right' | 'left' | 'top' | 'bottom';
}

export class Line {
  label: string;
  color: string;
  signalId: SignalIdentifier;

  constructor(options: LineOptions) {
    this.label = options.label;
    this.color = options.color;
    this.signalId = options.signalId;
  }
}

export interface LineOptions {
  label: string;
  color: string;
  signalId: SignalIdentifier;
}
