import { Component } from '@angular/core';
import { Toast } from '../toast';
import { GlobalOptions, ToasterService } from '../toaster.service';

@Component({
  selector: 'devapp-toast-container',
  templateUrl: './toast-container.component.html',
  styleUrls: ['./toast-container.component.scss'],
})
export class ToastContainerComponent {
  public get containerClass(): string {
    return `ng-toast ng-toast--${this.options.horizontalPosition} ng-toast--${this.options.verticalPosition}`;
  }

  public get itemClass(): string {
    return `ng-toast--${this.options.horizontalPosition} ng-toast--${this.options.verticalPosition}`;
  }

  public get options(): GlobalOptions {
    return this.service.options;
  }

  public get toasts(): Toast[] {
    return this.service.toasts;
  }

  public constructor(private service: ToasterService) {}
}
