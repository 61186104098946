import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import parseJSON from 'date-fns/parseJSON';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Page } from './shared/page';
import { BaseService } from './shared/service';

export interface Group {
  id: number;
  name: string;
  parentId: number | null;
  isArchived: boolean;
  created: Date;
  updated: Date;
  type: 'REGULAR' | 'COMMON';
}
@Injectable({ providedIn: 'root' })
export class GroupService extends BaseService<Group> {
  constructor(private client: HttpClient) {
    super();
  }

  create(data: Partial<Pick<Group, 'name' | 'parentId' | 'type'>>): Observable<Group> {
    return this.client.post('/v4/groups', data).pipe(map((result: any) => this.parse(result)));
  }

  list(params: ListOptions = {}): Observable<Page<Group>> {
    return this.client
      .get(`/v4/groups`, { params: this.buildParams(params) })
      .pipe(map((data: any) => this.parsePage(data)));
  }

  listByParentGroup(parentGroupId: number, params: ListOptions = {}): Observable<Page<Group>> {
    return this.client
      .get(`/v4/groups/${parentGroupId}/groups`, { params: this.buildParams(params) })
      .pipe(map((data: any) => this.parsePage(data)));
  }

  read(id: number): Observable<Group> {
    return this.client.get(`/v4/groups/${id}`).pipe(map((data: any) => this.parse(data)));
  }

  update(id: number, data: Partial<Pick<Group, 'name' | 'isArchived'>>): Observable<Group> {
    return this.client.patch(`/v4/groups/${id}`, data).pipe(map((result: any) => this.parse(result)));
  }

  delete(id: number): Observable<void> {
    return this.client.delete(`/v4/groups/${id}`).pipe(map(() => void 0));
  }

  protected parse(data: any): Group {
    return {
      id: data.id,
      name: data.name,
      parentId: data.parentId,
      isArchived: data.isArchived,
      created: parseJSON(data.created),
      updated: parseJSON(data.updated),
      type: data.type,
    };
  }
}

interface ListOptions {
  limit?: number;
  offset?: number;
  sort?: 'updatedDesc';
  search?: string;
}
