import { CompositeControl } from '../../../../application/composite-control';
import { Control } from '../../../../application/control';
import { Command } from '../command';

export class RemoveControlCommand implements Command {
  constructor(public data: RemoveControlCommandData) {}

  apply(): void {
    this.data.parentControl.remove(this.data.position);
  }

  unapply(): void {
    this.data.parentControl.insert(this.data.removedControl, this.data.position);
  }
}

export interface RemoveControlCommandData {
  parentControl: CompositeControl;
  removedControl: Control;
  position: number;
}
