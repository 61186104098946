import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
  BadRequestError,
  ForbiddenError,
  InternalServerError,
  NotFoundError,
  UnauthorizedError,
  ValidationError,
} from './error';
import { ErrorResponsePayload, ValidationResponsePayload } from './types';
import { Session } from '../security/session.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private get session() {
    return this.injector.get(Session);
  }

  constructor(private injector: Injector) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError((error) => this.handleErrorResponse(error)));
  }

  private handleErrorResponse(response: HttpErrorResponse): Observable<HttpEvent<any>> {
    let error: Error;
    const payload: ErrorResponsePayload = response.error;

    if (response.status === 500) {
      error = new InternalServerError(payload.code, payload.message);
    } else if (response.status === 400) {
      if (payload.code === 'R000005') {
        const validationPayload = payload as ValidationResponsePayload;
        error = new ValidationError(validationPayload.code, validationPayload.message, validationPayload.fieldErrors);
      } else {
        error = new BadRequestError(payload.code, payload.message);
      }
    } else if (response.status === 403) {
      if (payload.code === 'R000420') {
        const router = this.injector.get(Router);
        router.navigateByUrl('/licenses/invalid-license');
      } else if (payload.code === 'R000428') {
        const router = this.injector.get(Router);
        router.navigateByUrl('/licenses/group-above-limits');
      } else if (payload.code === 'R000430') {
        const router = this.injector.get(Router);
        router.navigateByUrl('/licenses/group-without-owner');
      } else if (payload.code === 'R000431') {
        const router = this.injector.get(Router);
        router.navigateByUrl('/licenses/group-is-archived');
      }

      error = new ForbiddenError(payload.code, payload.message);
    } else if (response.status === 404) {
      error = new NotFoundError(payload.code, payload.message);
    } else if (response.status === 401) {
      this.session.logout();

      error = new UnauthorizedError(payload.code, payload.message);
    } else {
      error = response;
    }

    return throwError(() => error);
  }
}
