import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BreadcrumbService {
  private invalidateSubject = new Subject<void>();
  invalidated = this.invalidateSubject.asObservable();

  invalidate() {
    this.invalidateSubject.next();
  }
}
