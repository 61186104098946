import { Control } from '../../../../application/control';
import { Command } from '../command';

export class EditControlPropertyCommand implements Command {
  constructor(public data: Data) {}

  apply(): void {
    setValueForKeyPath(this.data.control, this.data.keyPath, this.data.newValue);
  }

  unapply(): void {
    setValueForKeyPath(this.data.control, this.data.keyPath, this.data.oldValue);
  }
}

interface Data {
  control: Control;
  keyPath: string;
  oldValue: any;
  newValue: any;
}

export function setValueForKeyPath(object: any, keyPath: string, value: any): void {
  if (object == null || keyPath == null) {
    return;
  }

  if (!keyPath.includes('.')) {
    object[keyPath] = value;
    return;
  }

  const chain = keyPath.split('.');
  const firstKey = chain.shift();
  const shiftedKeyPath = chain.join('.');

  setValueForKeyPath(object[firstKey!], shiftedKeyPath, value);
}

export function getValueForKeyPath(object: any, keyPath: string): any {
  if (object == null || keyPath == null) {
    return null;
  }

  if (!keyPath.includes('.')) {
    return object[keyPath];
  }

  const chain = keyPath.split('.');
  const firstKey = chain.shift();
  const shiftedKeyPath = chain.join('.');

  return getValueForKeyPath(object[firstKey!], shiftedKeyPath);
}
