import { HttpClient, HttpEventType, HttpParams } from '@angular/common/http';
import { Injectable, Type } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { DataObject } from './object';
import { Page } from './page';
import { DataSerializer } from './serializer';

export interface ListOptions {
  search?: string;
  sort?: string;
  limit?: number;
  offset?: number;
  filters?: { [key: string]: string | number | boolean | Array<string | number | boolean> };
}

/**
 * Base class to implement data manipulation services.
 */
@Injectable()
export abstract class DataService<T extends DataObject> {
  protected abstract readonly URL: string;
  protected abstract readonly modelConstructor: Type<T>;

  protected readonly serializer = new DataSerializer<T>();

  public constructor(protected client: HttpClient) {}

  public getAll(options?: ListOptions): Observable<Page<T>> {
    return this.getList(`/${this.URL}`, options);
  }

  public getByID(id: number): Observable<T> {
    return this.client
      .get(`/${this.URL}/${id}`)
      .pipe(map((data) => this.serializer.fromJSON(data, new this.modelConstructor())));
  }

  public deleteByID(id: number): Observable<void> {
    return this.client.delete(`/${this.URL}/${id}`).pipe(map(() => void 0));
  }

  public save(model: T): Observable<T>;
  public save(model: T, reportProgress: boolean): Observable<T | number>;

  public save(model: T, reportProgress?: boolean): Observable<T> | Observable<T | number> {
    if (model.id != null) {
      return this.update(model, reportProgress);
    }
    return this.create(model, reportProgress);
  }

  protected getList(
    baseURL: string,
    { search = null, sort = null, limit = null, offset = null, filters = null }: ListOptions = {},
  ): Observable<Page<T>> {
    let params = new HttpParams();

    if (search != null) {
      params = params.set('search', search);
    }
    if (sort != null) {
      params = params.set('sort', sort);
    }
    if (limit != null) {
      params = params.set('limit', limit.toString());
    }
    if (offset != null) {
      params = params.set('offset', offset.toString());
    }
    if (filters != null && Object.keys(filters).length > 0) {
      Object.keys(filters).forEach((key) => {
        const value = filters[key];
        if (Array.isArray(value)) {
          value.forEach((v) => (params = params.append(key, v)));
        } else {
          params = params.set(key, value);
        }
      });
    }

    return this.client.get(baseURL, { params }).pipe(
      map((data: any) => {
        return {
          limit: data._metadata.limit,
          offset: data._metadata.offset,
          totalCount: data._metadata.totalCount,
          records: data.records.map((item: any) => this.serializer.fromJSON(item, new this.modelConstructor())),
        };
      }),
    );
  }

  protected create(model: T): Observable<T>;
  protected create(model: T, reportProgress: boolean): Observable<T | number>;

  protected create(model: T, reportProgress?: boolean): Observable<T> | Observable<T | number> {
    if (reportProgress === undefined || reportProgress === false) {
      return this.client
        .post(`/${this.URL}`, this.serializer.toJSON(model))
        .pipe(map((data) => this.serializer.fromJSON(data, new this.modelConstructor())));
    } else {
      return this.client
        .post(`/${this.URL}`, this.serializer.toJSON(model), { reportProgress: true, observe: 'events' })
        .pipe(
          filter((event) => {
            return event.type === HttpEventType.UploadProgress || event.type === HttpEventType.Response;
          }),
          map((event) => {
            switch (event.type) {
              case HttpEventType.UploadProgress:
                return Math.round((event.loaded / event.total) * 100);
              case HttpEventType.Response:
                return this.serializer.fromJSON(event.body, new this.modelConstructor());
              default:
                throw new Error('Got an unexpected HttpEven when creating.');
            }
          }),
        );
    }
  }

  protected update(model: T): Observable<T>;
  protected update(model: T, reportProgress: boolean): Observable<T | number>;

  protected update(model: T, reportProgress?: boolean): Observable<T> | Observable<T | number> {
    if (reportProgress === undefined || reportProgress === false) {
      return this.client
        .patch(`/${this.URL}/${model.id}`, this.serializer.toJSON(model))
        .pipe(map((data) => this.serializer.fromJSON(data, new this.modelConstructor())));
    } else {
      return this.client
        .patch(`/${this.URL}/${model.id}`, this.serializer.toJSON(model), { reportProgress: true, observe: 'events' })
        .pipe(
          filter((event) => {
            return event.type === HttpEventType.UploadProgress || event.type === HttpEventType.Response;
          }),
          map((event) => {
            switch (event.type) {
              case HttpEventType.UploadProgress:
                return Math.round((event.loaded / event.total) * 100);
              case HttpEventType.Response:
                return this.serializer.fromJSON(event.body, new this.modelConstructor());
              default:
                throw new Error('Got an unexpected HttpEven when updating.');
            }
          }),
        );
    }
  }
}

export abstract class BaseService<T> {
  protected abstract parse(data: any): T;

  protected parsePage(data: any): Page<T> {
    return {
      limit: data.limit,
      offset: data.offset,
      totalCount: data.totalCount,
      records: data.records.map((item: any) => this.parse(item)),
    };
  }

  protected buildParams(options: { [key: string]: any } = {}): HttpParams {
    return Object.keys(options).reduce((params, key) => {
      const value = options[key];
      if (value == null || value === '') {
        return params;
      }

      if (Array.isArray(value)) {
        return value.reduce((params, item) => params.append(key, item), params);
      }

      return params.set(key, value);
    }, new HttpParams());
  }
}
