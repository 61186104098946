import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, from, NEVER, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { IdpService } from './idp.service';
import { environment } from '../../../environments/environment';
import { loadAll } from '../../shared/utils';
import { License, LicenseService } from '../data/license.service';
import { Membership, MembershipService } from '../data/membership.service';
import { User, UserService } from '../data/user.service';

export const MAX_RECENT_GROUPS_COUNT = 5;

/**
 * Session contains information about current session. It exposes information
 * about the current user and selected group.
 *
 * Session is initialized by `Session.initialize()` method, which is called in
 * `APP_INITIALIZER` to ensure that user is authenticated and all necessary data
 * is available or redirects to SSO login/register page.
 */
@Injectable()
export class Session {
  private readonly recentGroupsKey = `recentGroups.${environment.environmentName}`;

  private userSubject: BehaviorSubject<User>;
  private licensesSubject: BehaviorSubject<License[]>;
  private membershipsSubject: BehaviorSubject<Membership[]>;
  private membershipSubject: BehaviorSubject<Membership | null>;

  /**
   * Access token to be used in API requests.
   *
   * @returns Access token.
   */
  get accessToken(): Observable<string> {
    return this.impl.getToken();
  }

  /**
   * User's membership in the current group.
   *
   * @returns user's membership in the current group
   */
  get membership(): Membership | null {
    return this.membershipSubject.getValue();
  }

  get membership$(): Observable<Membership | null> {
    return this.membershipSubject;
  }

  /**
   * Current user.
   *
   * @returns current user
   */
  get user(): User {
    return this.userSubject.getValue();
  }

  get user$(): Observable<User> {
    return this.userSubject;
  }

  /**
   * User's licenses.
   *
   * @returns user's licenses
   */
  get licenses(): License[] {
    return this.licensesSubject.getValue();
  }

  get licenses$(): Observable<License[]> {
    return this.licensesSubject;
  }

  /**
   * User's memberships
   *
   * @returns current user
   */
  get memberships(): Membership[] {
    return this.membershipsSubject.getValue();
  }

  get memberships$(): Observable<Membership[]> {
    return this.membershipsSubject;
  }

  constructor(
    private impl: IdpService,
    private membershipService: MembershipService,
    private userService: UserService,
    private licenseService: LicenseService,
    private injector: Injector,
  ) {}

  /**
   * Initializes session with data from localStorage.
   */
  initialize(): Observable<void> {
    let targetRoute: string | null = null;
    return this.impl.initialize().pipe(
      tap((target) => (targetRoute = target)),
      switchMap(() => this.getSelfUser()),
      tap((user) => (this.userSubject = new BehaviorSubject(user))),
      switchMap(() => this.getUsersLicenses()),
      tap((licenses) => (this.licensesSubject = new BehaviorSubject(licenses))),
      switchMap(() => this.getUsersMemberships()),
      tap((memberships) => (this.membershipsSubject = new BehaviorSubject(memberships))),
      switchMap(() => {
        const membership = this.getUsersMembership();
        this.membershipSubject = new BehaviorSubject(membership);
        this.persistRecentGroups();

        if (targetRoute != null) {
          return from(this.injector.get(Router).navigateByUrl(targetRoute)).pipe(map(() => null));
        } else {
          return of(null);
        }
      }),
    );
  }

  refreshLicenses() {
    return this.getUsersLicenses().pipe(
      tap((licenses) => this.licensesSubject.next(licenses)),
      switchMap(() => this.refreshMemberships()),
    );
  }

  refreshMemberships() {
    return this.getUsersMemberships().pipe(
      tap((memberships) => this.membershipsSubject.next(memberships)),
      map(() => {
        const membership = this.getUsersMembership();
        this.membershipSubject.next(membership);
        this.persistRecentGroups();
      }),
    );
  }

  setCurrentGroupId(currentGroupId: number | null) {
    this.addToRecentlyUsedGroups(currentGroupId);
    return this.refreshMemberships().pipe(
      map(() => {
        const membership = this.getUsersMembership();
        this.membershipSubject.next(membership);
        this.persistRecentGroups();
      }),
    );
  }

  /**
   * Terminates session and drops session data from localStorage.
   */
  logout(): void {
    this.impl.logout();
  }

  openProfile() {
    this.impl.openProfile();
  }

  addToRecentlyUsedGroups(groupId: number) {
    const recentGroups = this.loadRecentGroups();
    const removeIndex = recentGroups.indexOf(groupId);
    removeIndex > -1 ? recentGroups.splice(removeIndex, 1) : recentGroups.pop();
    recentGroups.unshift(groupId);
    localStorage.setItem(this.recentGroupsKey, JSON.stringify(recentGroups));
  }

  private loadRecentGroups(): number[] {
    const value = localStorage.getItem(this.recentGroupsKey);
    if (value != null) {
      try {
        return JSON.parse(value);
      } catch (error) {
        // remove current group if parsing failed
        localStorage.removeItem(this.recentGroupsKey);
      }
    }
    return [];
  }

  private persistRecentGroups(): void {
    if (this.membership != null) {
      localStorage.setItem(
        this.recentGroupsKey,
        JSON.stringify(this.memberships.slice(0, MAX_RECENT_GROUPS_COUNT).map((m) => m.groupId)),
      );
    } else {
      localStorage.removeItem(this.recentGroupsKey);
    }
  }

  getSelfUser() {
    return this.userService.readSelf().pipe(catchError((err) => this.handleError(err, 'fetching user')));
  }

  getUsersLicenses() {
    return this.licenseService
      .list({ userId: this.user.id })
      .pipe(catchError((err) => this.handleError(err, 'fetching user licences')))
      .pipe(map((result) => result.records));
  }

  getUsersMemberships() {
    return loadAll((offset, limit) => this.membershipService.list({ userId: this.user.id, offset, limit }))
      .pipe(catchError((err) => this.handleError(err, 'fetching memberships')))
      .pipe(
        map((memberships) => {
          const recentGroups = this.loadRecentGroups();
          recentGroups.reverse();
          for (const groupId of recentGroups) {
            const index = memberships.findIndex((r) => r.groupId === groupId);
            if (index !== -1) {
              memberships.unshift(...memberships.splice(index, 1));
            }
          }
          return memberships;
        }),
      );
  }

  private getUsersMembership(): Membership | null {
    return this.memberships.length > 0 ? this.memberships[0] : null;
  }

  public handleError(error: Error, operation = 'operation'): Observable<never> {
    const supportLink = 'https://www.danfoss.com/en/products/software/dps/plus1-software/#tab-support';

    // eslint-disable-next-line no-console
    console.error(error);

    const message = `While starting the application, something went wrong when <b>${operation}</b>.<br><br>
      The error returned was: <b>${
        error.message ?? 'Unknown Error'
      }</b>.<br><br>Please contact us at <a href=${supportLink}>our support hub</a> for further assistance.`;

    const spinner = document.querySelector('.spinner') as HTMLElement;
    spinner.style.textAlign = 'center';
    spinner.classList.remove('spinner');
    spinner.innerHTML = message;
    return NEVER;
  }
}
