import {
  AnyButtonActionDeclaration,
  AnyControlDeclaration,
  AnySignalIdentifierDeclaration,
  ApplicationDeclaration,
  BarChartControlDeclaration,
  BooleanSignalControlDeclaration,
  ButtonControlDeclaration,
  EnumSignalControlDeclaration,
  formatVersion,
  GaugeControlDeclaration,
  GridLayoutCellControlDeclaration,
  GridLayoutControlDeclaration,
  GridLayoutRowControlDeclaration,
  HeaderControlDeclaration,
  HintControlDeclaration,
  ImageControlDeclaration,
  LabelControlDeclaration,
  LineChartControlDeclaration,
  NumericSignalControlDeclaration,
  PageDeclaration,
  SignalFormatDeclaration,
  SignalScaleDeclaration,
  StackLayoutControlDeclaration,
  TextControlDeclaration,
} from './schema';
import { calculateHoverColor, calculateTextColor } from '../../../utils/colors';
import { Application } from '../../application';
import { Control } from '../../control';
import { Area } from '../../controls/area';
import { Bar, BarChart } from '../../controls/bar-chart';
import { BooleanSignal } from '../../controls/boolean-signal';
import {
  Button,
  ButtonAction,
  NavigatePageButtonAction,
  SetPulseButtonAction,
  WriteSignalsButtonAction,
} from '../../controls/button';
import { EnumSignal } from '../../controls/enum-signal';
import { Gauge } from '../../controls/gauge';
import { GridLayout, GridLayoutCell, GridLayoutRow } from '../../controls/grid-layout';
import { Header, HeaderPreset } from '../../controls/header';
import { Hint } from '../../controls/hint';
import { Image } from '../../controls/image';
import { Label } from '../../controls/label';
import { Line, LineChart } from '../../controls/line-chart';
import { NumericSignal } from '../../controls/numeric-signal';
import { StackLayout } from '../../controls/stack-layout';
import { SignalBinding, Text } from '../../controls/text';
import { Page } from '../../page';
import {
  ArraySignalIdentifier,
  EcuSignalSource,
  SignalFormatter,
  SignalIdentifier,
  SignalScale,
} from '../../signal-identifier';
import { AdapterContract } from '../contract';

export class ApplicationAdapter implements AdapterContract {
  createApplication(declaration: ApplicationDeclaration): Application {
    return new Application({
      pages: declaration.pages.map((item) => this.createPage(item)),
      themeOptions: {
        headerPresets: declaration.themeOptions.headerPresets.map((preset) => {
          return new HeaderPreset(preset);
        }),
        brandColor: {
          background: declaration.themeOptions.brandColor.background,
          hover: calculateHoverColor(declaration.themeOptions.brandColor.background),
          text: calculateTextColor(declaration.themeOptions.brandColor.background),
        },
        primaryColor: {
          ...declaration.themeOptions.primaryColor,
          hover: calculateHoverColor(declaration.themeOptions.primaryColor.background),
          text: calculateTextColor(declaration.themeOptions.primaryColor.background),
        },
        pageColor: {
          ...declaration.themeOptions.pageColor,
          hover: calculateHoverColor(declaration.themeOptions.pageColor.background),
          text: calculateTextColor(declaration.themeOptions.pageColor.background),
        },
      },
      formatVersion: formatVersion,
    });
  }

  createPage(declaration: PageDeclaration): Page {
    const pages = declaration.pages != null ? declaration.pages.map((child) => this.createPage(child)) : [];

    const controls = declaration.controls != null ? declaration.controls.map((item) => this.createControl(item)) : [];

    return new Page({
      id: declaration.id,
      name: declaration.name,
      pages,
      controls,
    });
  }

  createControl(data: AnyControlDeclaration): Control {
    switch (data.type) {
      case 'BarChart':
        return this.createBarChartControl(data);
      case 'BooleanSignal':
        return this.createBooleanSignalControl(data);
      case 'Button':
        return this.createButtonControl(data);
      case 'EnumSignal':
        return this.createEnumSignalControl(data);
      case 'Gauge':
        return this.createGaugeControl(data);
      case 'GridLayout':
        return this.createGridLayoutControl(data);
      case 'GridLayoutRow':
        return this.createGridLayoutRowControl(data);
      case 'GridLayoutCell':
        return this.createGridLayoutCellControl(data);
      case 'Hint':
        return this.createHintControl(data);
      case 'Image':
        return this.createImageControl(data);
      case 'Label':
        return this.createLabelControl(data);
      case 'LineChart':
        return this.createLineChartControl(data);
      case 'NumericSignal':
        return this.createNumericSignalControl(data);
      case 'StackLayout':
        return this.createStackLayoutControl(data);
      case 'Text':
        return this.createTextControl(data);
      case 'Header':
        return this.createHeaderControl(data);
      default:
        throw new Error(`Unsupported control type: ${(data as any).type}.`);
    }
  }

  createSignalIdentifier(data: AnySignalIdentifierDeclaration): SignalIdentifier {
    switch (data.type) {
      case 'SignalIdentifier':
        return new SignalIdentifier({
          signalType: data.signalType,
          unit: data.unit,
          options: data.options,
          scale: data.scale != null ? this.createSignalScale(data.scale) : undefined,
          formatter: data.format != null ? this.createSignalFormat(data.format) : undefined,
          source:
            data.ecuId != null && data.signalName != null
              ? new EcuSignalSource({ ecuId: data.ecuId, signalName: data.signalName })
              : null,
        });
      case 'ArraySignalIdentifier':
        return new ArraySignalIdentifier({
          signalType: data.signalType,
          index: data.index,
          unit: data.unit,
          options: data.options,
          scale: data.scale != null ? this.createSignalScale(data.scale) : undefined,
          formatter: data.format != null ? this.createSignalFormat(data.format) : undefined,
          source:
            data.ecuId != null && data.signalName != null
              ? new EcuSignalSource({ ecuId: data.ecuId, signalName: data.signalName })
              : null,
        });
      default:
        throw new Error(`Unsupported signal identifier type: ${(data as any).type}.`);
    }
  }

  createSignalScale(data: SignalScaleDeclaration) {
    return new SignalScale(data);
  }

  createSignalFormat(data: SignalFormatDeclaration) {
    return new SignalFormatter(data);
  }

  createLabelControl(declaration: LabelControlDeclaration): Label {
    return new Label(declaration);
  }

  createSignalBinding({ signalID, ...declaration }: TextControlDeclaration.SignalBinding): SignalBinding {
    return new SignalBinding({ ...declaration, signalId: this.createSignalIdentifier(signalID) });
  }

  createTextControl({ type: _, ...declaration }: TextControlDeclaration): Text {
    return new Text({
      ...declaration,
      signals: declaration.signals.map((signal) => this.createSignalBinding(signal)),
    });
  }

  createHintControl(declaration: HintControlDeclaration): Hint {
    return new Hint(declaration);
  }

  createLine({ signalID, ...declaration }: LineChartControlDeclaration.Line): Line {
    return new Line({ ...declaration, signalId: this.createSignalIdentifier(signalID) });
  }

  createLineChartControl({ type: _, ...declaration }: LineChartControlDeclaration): LineChart {
    return new LineChart({
      ...declaration,
      lines: declaration.lines.map((line) => this.createLine(line)),
    });
  }

  createArea(declaration: Area): Area {
    return new Area(declaration);
  }

  createGaugeControl({ type: _, signalID, ...declaration }: GaugeControlDeclaration): Gauge {
    return new Gauge({
      ...declaration,
      signalId: this.createSignalIdentifier(signalID),
      areas: declaration.areas.map((area) => this.createArea(area)),
    });
  }

  createNumericSignalControl({ signalID, type: _, ...rest }: NumericSignalControlDeclaration): NumericSignal {
    return new NumericSignal({
      signalId: this.createSignalIdentifier(signalID),
      ...rest,
      areas: rest.areas.map((area) => this.createArea(area)),
    });
  }

  createEnumSignalControl({ signalID, type: _, ...rest }: EnumSignalControlDeclaration): EnumSignal {
    return new EnumSignal({ signalId: this.createSignalIdentifier(signalID), ...rest });
  }

  createBooleanSignalControl({ signalID, type: _, ...rest }: BooleanSignalControlDeclaration): BooleanSignal {
    return new BooleanSignal({ signalId: this.createSignalIdentifier(signalID), ...rest });
  }

  createBar({ signalID, ...declaration }: BarChartControlDeclaration.Bar): Bar {
    return new Bar({ ...declaration, signalId: this.createSignalIdentifier(signalID) });
  }

  createBarChartControl({ type: _, ...declaration }: BarChartControlDeclaration): BarChart {
    return new BarChart({
      ...declaration,
      bars: declaration.bars.map((bar) => this.createBar(bar)),
    });
  }

  createButtonControl({ type: _, action, ...options }: ButtonControlDeclaration): Button {
    return new Button({ action: this.createButtonAction(action), ...options });
  }

  createButtonAction(declaration: AnyButtonActionDeclaration): ButtonAction {
    switch (declaration.type) {
      case 'navigatePage':
        return new NavigatePageButtonAction({ pageId: declaration.pageId });
      case 'writeSignals':
        return new WriteSignalsButtonAction();
      case 'setPulse':
        return new SetPulseButtonAction({ signalId: this.createSignalIdentifier(declaration.signalId) });
    }
  }

  createImageControl(declaration: ImageControlDeclaration): Image {
    return new Image(declaration);
  }

  createStackLayoutControl({ type: _, children, ...options }: StackLayoutControlDeclaration): StackLayout {
    return new StackLayout({
      ...options,
      children: children.map((item) => this.createControl(item)),
    });
  }

  createGridLayoutControl({ type: _, children, ...options }: GridLayoutControlDeclaration): GridLayout {
    return new GridLayout({
      ...options,
      children: children.map((item) => this.createControl(item) as GridLayoutRow),
    });
  }

  createGridLayoutRowControl({ type: _, children, ...options }: GridLayoutRowControlDeclaration): GridLayoutRow {
    return new GridLayoutRow({
      ...options,
      children: children.map((item) => this.createControl(item) as GridLayoutCell),
    });
  }

  createGridLayoutCellControl({ type: _, children, ...options }: GridLayoutCellControlDeclaration) {
    return new GridLayoutCell({
      ...options,
      children: children.map((item) => this.createControl(item)),
    });
  }

  createHeaderControl(declaration: HeaderControlDeclaration): Header {
    return new Header(declaration);
  }

  serializeControl(control: Control): AnyControlDeclaration {
    let json;

    if (control instanceof BarChart) {
      json = this.serializeBarChart(control);
    } else if (control instanceof BooleanSignal) {
      json = this.serializeBooleanSignal(control);
    } else if (control instanceof Button) {
      json = this.serializeButton(control);
    } else if (control instanceof EnumSignal) {
      json = this.serializeEnumSignal(control);
    } else if (control instanceof Gauge) {
      json = this.serializeGauge(control);
    } else if (control instanceof GridLayout) {
      json = this.serializeGridLayout(control);
    } else if (control instanceof GridLayoutRow) {
      json = this.serializeGridLayoutRow(control);
    } else if (control instanceof GridLayoutCell) {
      json = this.serializeGridLayoutCell(control);
    } else if (control instanceof Hint) {
      json = this.serializeHint(control);
    } else if (control instanceof Image) {
      json = this.serializeImage(control);
    } else if (control instanceof Label) {
      json = this.serializeLabel(control);
    } else if (control instanceof LineChart) {
      json = this.serializeLineChart(control);
    } else if (control instanceof NumericSignal) {
      json = this.serializeNumericSignal(control);
    } else if (control instanceof StackLayout) {
      json = this.serializeStackLayout(control);
    } else if (control instanceof Text) {
      json = this.serializeText(control);
    } else if (control instanceof Header) {
      json = this.serializeHeader(control);
    } else {
      throw new Error(`Unknown control type: ${control.constructor.name}.`);
    }

    return {
      ...json,
      ...(control.width !== control.fallbackWidth ? { width: control.width } : {}),
      ...(control.height !== control.fallbackHeight ? { height: control.height } : {}),
      ...(control.margin.top !== '0du' ||
      control.margin.bottom !== '0du' ||
      control.margin.right !== '0du' ||
      control.margin.left !== '0du'
        ? {
            margin: {
              top: control.margin.top,
              bottom: control.margin.bottom,
              right: control.margin.right,
              left: control.margin.left,
            },
          }
        : {}),
    };
  }

  serializeSignalIdentifier(signal: SignalIdentifier): AnySignalIdentifierDeclaration {
    if (signal instanceof ArraySignalIdentifier) {
      return {
        type: 'ArraySignalIdentifier',
        ecuId: signal.source instanceof EcuSignalSource ? signal.source.ecuId : null,
        signalName: signal.source instanceof EcuSignalSource ? signal.source.signalName : null,
        signalType: signal.signalType,
        index: signal.index,
        ...(signal.unit === '' ? {} : { unit: signal.unit }),
        ...(signal.options.length === 0 ? {} : { options: signal.options }),
        ...(signal.scale.multiplier !== 1 || signal.scale.offset !== 0
          ? { scale: this.serializeSignalScale(signal.scale) }
          : {}),
        ...(signal.formatter.fractionDigits !== 0 || signal.formatter.fractionLimiting !== 'round'
          ? { format: this.serializeSignalFormat(signal.formatter) }
          : {}),
      };
    } else {
      return {
        type: 'SignalIdentifier',
        ecuId: signal.source instanceof EcuSignalSource ? signal.source.ecuId : null,
        signalName: signal.source instanceof EcuSignalSource ? signal.source.signalName : null,
        signalType: signal.signalType,
        ...(signal.unit === '' ? {} : { unit: signal.unit }),
        ...(signal.options.length === 0 ? {} : { options: signal.options }),
        ...(signal.scale.multiplier !== 1 || signal.scale.offset !== 0
          ? { scale: this.serializeSignalScale(signal.scale) }
          : {}),
        ...(signal.formatter.fractionDigits !== 0 || signal.formatter.fractionLimiting !== 'round'
          ? { format: this.serializeSignalFormat(signal.formatter) }
          : {}),
      };
    }
  }

  serializeSignalScale(scale: SignalScale): SignalScaleDeclaration {
    return { multiplier: scale.multiplier, offset: scale.offset };
  }

  serializeSignalFormat(format: SignalFormatter): SignalFormatDeclaration {
    return { fractionDigits: format.fractionDigits, fractionLimiting: format.fractionLimiting };
  }

  serializeSignalBinding(signal: SignalBinding): TextControlDeclaration.SignalBinding {
    return {
      signalID: this.serializeSignalIdentifier(signal.signalId),
      variableName: signal.variableName,
    };
  }

  serializeText(control: Text): TextControlDeclaration {
    return {
      id: control.id,
      type: 'Text',
      text: control.text,
      align: control.align,
      signals: control.signals.map((signal) => this.serializeSignalBinding(signal)),
    };
  }

  serializeLabel(label: Label): LabelControlDeclaration {
    return {
      id: label.id,
      type: 'Label',
      text: label.text,
      align: label.align,
    };
  }

  serializeHint(control: Hint): HintControlDeclaration {
    return {
      id: control.id,
      type: 'Hint',
      title: control.title,
      text: control.text,
      align: control.align,
    };
  }

  serializeNumericSignal(control: NumericSignal): NumericSignalControlDeclaration {
    return {
      id: control.id,
      type: 'NumericSignal',
      readonly: control.readonly,
      standalone: control.standalone,
      areas: control.areas.map((area) => this.serializeArea(area)),
      signalID: this.serializeSignalIdentifier(control.signalId),
    };
  }

  serializeLine(line: Line): LineChartControlDeclaration.Line {
    return {
      label: line.label,
      color: line.color,
      signalID: this.serializeSignalIdentifier(line.signalId),
    };
  }

  serializeLineChart(control: LineChart): LineChartControlDeclaration {
    return {
      id: control.id,
      type: 'LineChart',
      minimum: control.minimum,
      maximum: control.maximum,
      valuesCount: control.valuesCount,
      lines: control.lines.map((line) => this.serializeLine(line)),
    };
  }

  serializeEnumSignal(control: EnumSignal): EnumSignalControlDeclaration {
    return {
      id: control.id,
      type: 'EnumSignal',
      readonly: control.readonly,
      standalone: control.standalone,
      signalID: this.serializeSignalIdentifier(control.signalId),
    };
  }

  serializeBar(bar: Bar): BarChartControlDeclaration.Bar {
    return {
      label: bar.label,
      color: bar.color,
      signalID: this.serializeSignalIdentifier(bar.signalId),
    };
  }

  serializeBarChart(control: BarChart): BarChartControlDeclaration {
    return {
      id: control.id,
      type: 'BarChart',
      minimum: control.minimum,
      maximum: control.maximum,
      direction: control.direction,
      bars: control.bars.map((bar) => this.serializeBar(bar)),
    };
  }

  serializeBooleanSignal(control: BooleanSignal): BooleanSignalControlDeclaration {
    return {
      id: control.id,
      type: 'BooleanSignal',
      readonly: control.readonly,
      standalone: control.standalone,
      signalID: this.serializeSignalIdentifier(control.signalId),
    };
  }

  serializeApplication(app: Application): ApplicationDeclaration {
    const pages = app.rootPage.children.map((page) => this.serializePage(page));
    const themeOptions = {
      brandColor: { background: app.themeOptions.brandColor.background },
      primaryColor: { background: app.themeOptions.primaryColor.background },
      pageColor: { background: app.themeOptions.pageColor.background },
      headerPresets: app.themeOptions.headerPresets.map((preset) => {
        return { align: preset.align, fontSize: preset.fontSize, id: preset.id, label: preset.label };
      }),
    };

    return {
      formatVersion,
      pages,
      themeOptions,
    };
  }

  serializePage(page: Page): PageDeclaration {
    const declaration: PageDeclaration = {
      id: page.id,
      name: page.name,
    };

    if (page.controlRoot.children.length !== 0) {
      declaration.controls = page.controlRoot.children.map((child) => this.serializeControl(child));
    }

    if (page.children.length !== 0) {
      declaration.pages = page.children.map((pge) => this.serializePage(pge));
    }

    return declaration;
  }

  serializeStackLayout(stackLayout: StackLayout): StackLayoutControlDeclaration {
    const children = stackLayout.children.map((child) => this.serializeControl(child));

    return {
      id: stackLayout.id,
      type: 'StackLayout',
      direction: stackLayout.direction,
      children,
    };
  }

  serializeGridLayout(control: GridLayout): GridLayoutControlDeclaration {
    return {
      id: control.id,
      type: 'GridLayout',
      columns: control.columns,
      spacing: control.spacing,
      children: control.children.map((item) => this.serializeControl(item) as GridLayoutRowControlDeclaration),
    };
  }

  serializeGridLayoutRow(control: GridLayoutRow): GridLayoutRowControlDeclaration {
    return {
      id: control.id,
      type: 'GridLayoutRow',
      children: control.children.map((item) => this.serializeControl(item) as GridLayoutCellControlDeclaration),
    };
  }

  serializeGridLayoutCell(control: GridLayoutCell): GridLayoutCellControlDeclaration {
    return {
      id: control.id,
      type: 'GridLayoutCell',
      children: control.children.map((item) => this.serializeControl(item)),
    };
  }

  serializeImage(control: Image): ImageControlDeclaration {
    return {
      id: control.id,
      type: 'Image',
      resource: control.resource,
      intrinsicAspectRatio: control.intrinsicAspectRatio,
    };
  }

  serializeArea(area: Area): Area {
    const declaration: Area = { range: area.range, color: area.color };

    if (area.warning != null) {
      declaration.warning = area.warning;
    }

    return declaration;
  }

  serializeGauge(control: Gauge): GaugeControlDeclaration {
    return {
      id: control.id,
      type: 'Gauge',
      minimum: control.minimum,
      maximum: control.maximum,
      areas: control.areas.map((area) => this.serializeArea(area)),
      signalID: this.serializeSignalIdentifier(control.signalId),
      valueForegroundColor: control.valueForegroundColor,
      useThemeColor: control.useThemeColor,
    };
  }

  serializeButton(control: Button): ButtonControlDeclaration {
    return {
      id: control.id,
      type: 'Button',
      text: control.text,
      action: this.serializeButtonAction(control.action),
    };
  }

  serializeButtonAction(action: ButtonAction): AnyButtonActionDeclaration {
    if (action instanceof WriteSignalsButtonAction) {
      return {
        type: 'writeSignals',
      };
    } else if (action instanceof NavigatePageButtonAction) {
      return {
        type: 'navigatePage',
        pageId: action.pageId,
      };
    } else if (action instanceof SetPulseButtonAction) {
      return {
        type: 'setPulse',
        signalId: this.serializeSignalIdentifier(action.signalId),
      };
    } else {
      throw new Error(`Unexpected button action: ${action}.`);
    }
  }

  serializeHeader(control: Header): HeaderControlDeclaration {
    return {
      id: control.id,
      type: 'Header',
      text: control.text,
      preset: control.preset,
    };
  }
}
