import {
  EcuMatchingCriteria,
  PropertyCriterion,
  PropertyName,
  SystemMatchingCriteria,
} from './system-matching-criteria';
import { EcuSpecification, SystemSpecification } from '../system-specification/system-specification';

const propertiesEnabledByDefault = new Set<PropertyName>([
  'Net Address',
  'Node Address',
  'Protocol',
  'System ID',
  'Application ID',
]);

function pushIf(value: string | number | undefined, name: PropertyName, ecuCriterion: EcuMatchingCriteria) {
  if (value != null && propertiesEnabledByDefault.has(name)) {
    ecuCriterion.criteria.push(new PropertyCriterion({ name, toMatch: value.toString() }));
  }
}

export function createSystemCriteriaFromSpecification(spec: SystemSpecification): SystemMatchingCriteria {
  const criteria = new SystemMatchingCriteria({
    nextEcuId: 1,
  });

  criteria.ecus = spec.ecus.map((ecu) => createEcuCriteriaFromSpecification(ecu, criteria.getNewEcuId()));

  return criteria;
}

export function createEcuCriteriaFromSpecification(ecu: EcuSpecification, ecuId: string) {
  const criteria = new EcuMatchingCriteria({ ecuId });

  // Only some properties are valid to be used in the SMC.
  pushIf(ecu.netAddress, 'Net Address', criteria);
  pushIf(ecu.nodeAddress, 'Node Address', criteria);
  pushIf(ecu.protocol, 'Protocol', criteria);
  pushIf(ecu.systemID, 'System ID', criteria);

  // Allow Application ID only for PLUS+1 protocol as it is not available for other protocols
  if (ecu.protocol === 'PLUS+1') {
    pushIf(ecu.applicationID, 'Application ID', criteria);
  }

  if (ecu.diagnosticDataId != null && ecu.protocol === 'UDS') {
    criteria.criteria.push(new PropertyCriterion({ name: 'Diagnostic Data ID', toMatch: ecu.diagnosticDataId }));
  }

  pushIf(ecu.applicationType, 'Application Type', criteria);
  pushIf(ecu.applicationVersion, 'Application Version', criteria);
  pushIf(ecu.toolVersion, 'Tool Version', criteria);
  pushIf(ecu.os, 'OS', criteria);
  pushIf(ecu.compileTime, 'Compile Time', criteria);
  pushIf(ecu.manufacturingDate, 'Manufacturing Date', criteria);
  pushIf(ecu.bootloaderVersion, 'Bootloader Version', criteria);
  pushIf(ecu.serialNumber, 'Serial Number', criteria);
  pushIf(ecu.partNumber0, 'Part Number 0', criteria);
  pushIf(ecu.partNumberRevision0, 'Part Number Revision 0', criteria);
  pushIf(ecu.partNumber1, 'Part Number 1', criteria);
  pushIf(ecu.partNumberRevision1, 'Part Number Revision 1', criteria);
  pushIf(ecu.partNumber2, 'Part Number 2', criteria);
  pushIf(ecu.ean, 'EAN', criteria);

  return criteria;
}

export function getDefaultEcuLabel(ecu: EcuMatchingCriteria): string {
  let netAddress: string | null = null;
  let nodeAddress: string | null = null;
  let applicationId: string | null = null;

  for (const criterion of ecu.criteria) {
    switch (criterion.name) {
      case 'Net Address':
        netAddress = criterion.toMatch;
        break;
      case 'Node Address':
        nodeAddress = criterion.toMatch;
        break;
      case 'Application ID':
        applicationId = criterion.toMatch;
        break;
    }
  }

  if (netAddress != null && nodeAddress != null && applicationId != null) {
    return `${netAddress},${nodeAddress} - ${applicationId}`;
  } else if (applicationId != null) {
    return applicationId;
  } else if (netAddress != null && nodeAddress != null) {
    return `${netAddress},${nodeAddress}`;
  } else {
    return `ECU ${ecu.ecuId.substr(3)}`;
  }
}
