import { Control, ControlOptions } from './control';
import { ControlVisitor } from '../visitor/control-visitor';

/**
 * Base class for any control, which can be a container for other controls.
 */
export abstract class CompositeControl extends Control {
  children: Control[] = [];

  /**
   * Default width for composite controls is different than for simple
   * controls.
   */
  override readonly fallbackWidth = 'matchParent';

  constructor({ children = [], width = 'matchParent', ...options }: CompositeControlOptions) {
    super({ width, ...options });

    children.forEach((child) => this.add(child));
  }

  accept<T extends ControlVisitor>(visitor: T): T {
    visitor.visitControl(this);
    this.children.forEach((child) => child.accept(visitor));
    return visitor;
  }

  /**
   * Add a control as child to the control.
   * See Control.insert()
   */
  public add(child: Control) {
    this.insert(child, this.children.length);
  }

  /**
   * Inserts a child into the control at a specific position.
   *
   * If the control is already a child of the parent, then the control will be
   * moved to the specified position.
   *
   * If the control is already a child of another control then the ownership
   * will be transferred to this control.
   *
   * @param child - A control to insert as a child.
   * @param index - An index specifying at which position the child
   * should be inserted.
   */
  public insert(child: Control, index: number) {
    if (index < 0) {
      throw new Error(`Incorrect child index: ${index}.`);
    }

    if (child.parent != null) {
      child.parent.remove(child);
    }

    child.parent = this;

    this.children.splice(index, 0, child);
  }

  public indexOf(child: Control): number {
    return this.children.indexOf(child);
  }

  public remove(controlOrIndex: Control | number) {
    const index = controlOrIndex instanceof Control ? this.indexOf(controlOrIndex) : controlOrIndex;
    const control = controlOrIndex instanceof Control ? controlOrIndex : this.children[controlOrIndex];

    this.children.splice(index, 1);
    control.parent = null;
  }

  public childAt(index: number): Control {
    return this.children[index];
  }
}

export interface CompositeControlOptions extends ControlOptions {
  children?: Control[];
}
