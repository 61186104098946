import { ControlOptions } from '../control';
import { SimpleControl } from '../simple-control';

export class Label extends SimpleControl {
  text: string;
  align: LabelAlign;

  readonly minimumWidth = 1;
  readonly minimumHeight = 2.5;

  readonly humanReadableName = 'Label';

  constructor({ text = 'Label', align = 'left', ...options }: LabelOptions) {
    super(options);

    this.text = text;
    this.align = align;
  }
}

export interface LabelOptions extends ControlOptions {
  text?: string;
  align?: LabelAlign;
}

export type LabelAlign = 'left' | 'center' | 'right';
