import { ControlVisitor } from './control-visitor';
import { Control } from '../application/control';
import { Page } from '../application/page';

export class AddedItemVisitor extends ControlVisitor {
  oldToNewIdMap = new Map<string, string>();

  constructor(private idGenerator: (type: string) => string) {
    super();
  }

  override visitPage(page: Page): void {
    const oldPageId = page.id;
    page.id = this.idGenerator('page');
    this.oldToNewIdMap.set(oldPageId, page.id);

    super.visitPage(page);
  }

  visitControl(control: Control): void {
    const oldControlId = control.id;
    const type = control.humanReadableName.replace(/\s+/g, '-').toLowerCase();
    control.id = this.idGenerator(type);
    this.oldToNewIdMap.set(oldControlId, control.id);
  }

  getOldToNewIdMap(): Map<string, string> {
    return this.oldToNewIdMap;
  }
}
