import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faSpinner } from '@fortawesome/pro-light-svg-icons/faSpinner';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Page } from '../../core/data/shared/page';
import { AsyncData } from '../async-data';

export interface DataRequestParams {
  page: number;
  itemsPerPage: number;
  search: string;
}

export const DEFAULT_LISTING_ITEMS_PER_PAGE = 10;

/**
 * Component to render table listing of items together with pagination.
 *
 * It wraps TableComponent, adds opinionated error handling and pagination at
 * the bottom. See *Visible methods for details what information is displayed
 * depending on the data state.
 *
 * It is designed in way that it requires minimal extra state and logic in the
 * parent component. Parent component is required to provide one input: data
 * and handle one output: dataRequest, which should load new data and set it
 * to the input property.
 */
@Component({
  selector: 'devapp-listing',
  templateUrl: './listing.component.html',
  styleUrls: ['./listing.component.scss'],
})
export class ListingComponent implements AfterViewInit, AfterViewChecked, OnInit, OnChanges {
  icons = { faSpinner };

  /**
   * Data to visualize as a listing table.
   *
   * It uses special data structure, which contains either value (successful
   * load) or error (failed load).
   */
  @Input()
  data: AsyncData<Page<any>>;

  /**
   * Allows to customize entity name used in messages displayed by the component.
   */
  @Input()
  entityName = 'items';

  /**
   * Sets how many items should be displayed per page.
   */
  @Input()
  itemsPerPage: number = DEFAULT_LISTING_ITEMS_PER_PAGE;

  /**
   * Whether list can be searched.
   */
  @Input()
  searchable = true;

  /**
   * sometimes like for example, when we have modals, we don't want
   *  this component to automatically navigate to another link i.e. page
   * but rather just refresh the table with the clicked page data
   */
  @Input()
  shouldNavigate = true;

  /**
   * Emits on every page change.
   */
  @Output()
  readonly pageChange = new EventEmitter<number>();

  /**
   * Emits data refresh requests.
   *
   * Every emit by this output should trigger data refresh with parameters
   * provided by the emit and ends by parent component setting new value to the
   * data property of this component.
   */
  @Output()
  readonly dataRequest = new EventEmitter<DataRequestParams>();

  columns: number;

  totalItems: number;

  activePage = 1;

  /**
   * search term string value as <Observable>
   * contains a  string stream to subscribe to
   */
  searchTermSubscriber = new Subject<string>();

  /**
   * search term value
   */
  searchTerm = '';

  /**
   * Indicates if pagination should be displayed.
   *
   * Pagination is always visible after initial value for totalItems was set
   * from first successful data.
   */
  get paginationVisible(): boolean {
    return this.totalItems != null;
  }

  /**
   * Indicates if main contents should be displayed.
   *
   * Data is displayed when we have successfully loaded data.
   */
  get rowsVisible(): boolean {
    return this.data != null && this.data.value != null && this.data.value.records.length !== 0;
  }

  /**
   * Indicates if loading indicator should be displayed.
   *
   * Loading indicator is displayed, when we don't have other data to
   * display.
   */
  get loadingVisible(): boolean {
    return this.data == null;
  }

  /**
   * Indicates if message about empty list should be displayed.
   *
   * It happens, when data was loaded successfully, but list is empty.
   */
  get emptyVisible(): boolean {
    return this.data != null && this.data.value != null && this.data.value.records.length === 0;
  }

  /**
   * Indicates if error message should be displayed.
   *
   * Error message should be displayed, when data was loaded, but it returned
   * error.
   */
  get errorVisible(): boolean {
    return this.data != null && this.data.error != null;
  }

  constructor(
    private elementRef: ElementRef,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private router: Router,
  ) {}

  navigatePage(page: number) {
    if (this.shouldNavigate) {
      const tree = this.router.parseUrl(this.router.url);
      tree.queryParams['page'] = page.toString();
      this.router.navigateByUrl(tree);
    } else {
      this.dataRequest.emit({ page, itemsPerPage: this.itemsPerPage, search: this.searchTerm });
    }
  }

  requestRefresh(reset = false) {
    if (reset) {
      this.activePage = 1;
      this.searchTerm = '';
    }
    this.dataRequest.emit({ page: this.activePage, itemsPerPage: this.itemsPerPage, search: this.searchTerm });
  }

  retry(event: MouseEvent) {
    event.preventDefault();
    this.data = null;
    this.requestRefresh();
  }

  /**
   * subscribe to the input value after one min change
   * set the searchTerm final value to be used later
   * reset the current page to the first one
   * refresh the current request
   */
  registerSearch(): void {
    this.searchTermSubscriber.pipe(debounceTime(600), distinctUntilChanged()).subscribe((term) => {
      this.searchTerm = term;
      this.activePage = 1;
      this.requestRefresh();
    });
  }

  /**
   * push the current input search value to the subscriber
   * @param searchTerm
   */
  search(searchTerm: string): void {
    this.searchTermSubscriber.next(searchTerm);
  }

  ngOnInit() {
    if (!this.shouldNavigate) {
      this.activePage = 1;
      this.pageChange.emit(1);
      this.requestRefresh();
    } else {
      this.route.queryParams.subscribe(({ page = 1 }) => {
        this.activePage = +page;
        this.pageChange.emit(page);
        this.requestRefresh();
      });
    }

    // register input value as subscriber
    this.registerSearch();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('data' in changes) {
      if (
        changes['data'].currentValue != null &&
        changes['data'].currentValue.error != null &&
        changes['data'].currentValue.error.code === 'R000007'
      ) {
        this.data = changes['data'].previousValue;
        return this.navigatePage(1);
      }

      if (this.data != null && this.data.value != null) {
        this.totalItems = this.data.value.totalCount;
      }
    }
  }

  ngAfterViewInit() {
    // TODO: Refactor once https://github.com/angular/angular/issues/8563 is implemented
    this.columns = (this.elementRef.nativeElement as HTMLElement).querySelectorAll('th').length;
    this.cd.detectChanges();
  }

  ngAfterViewChecked() {
    Array.from(this.elementRef.nativeElement.querySelectorAll('td')).forEach((td: HTMLTableCellElement) => {
      if (td.innerText !== '') {
        td.title = td.innerText;
      }
    });
  }
}
