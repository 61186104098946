import { SystemSpecification } from './system-specification';
import { SystemSpecificationAdapterV0_1_0 } from './versions/adapter-v0-1-0';
import { SystemSpecificationAdapterV0_2_0 } from './versions/adapter-v0-2-0';
import { SystemSpecificationAdapterV0_3_0 } from './versions/adapter-v0-3-0';
import { AdapterContract } from './versions/contract';
import { SystemSpecificationAdapter as SystemSpecificationAdapterLatest } from './versions/latest/adapter';
import { formatVersion as versionLatest } from './versions/latest/schema';

export type FormatVersion = '0.1.0' | '0.2.0' | '0.3.0' | typeof versionLatest;

export const latestSystemSpecificationVersion = versionLatest;

export class SystemSpecificationAdapter {
  private versions = new Map<FormatVersion, AdapterContract>();

  constructor() {
    this.versions.set('0.1.0', new SystemSpecificationAdapterV0_1_0());
    this.versions.set('0.2.0', new SystemSpecificationAdapterV0_2_0());
    this.versions.set('0.3.0', new SystemSpecificationAdapterV0_3_0());
    this.versions.set('0.4.0', new SystemSpecificationAdapterLatest());
  }

  parse(json: string): SystemSpecification {
    const data = JSON.parse(json);

    if (data.formatVersion == null) {
      throw new Error('System Specification version is not set. Unable to load.');
    }

    return this.getAdapter(data.formatVersion).parse(data);
  }

  serialize(specification: SystemSpecification, format: FormatVersion = specification.formatVersion): string {
    return JSON.stringify(this.getAdapter(format).serialize(specification));
  }

  private getAdapter(format: FormatVersion) {
    const adapter = this.versions.get(format);
    if (adapter == null) {
      throw new Error(
        'System Specification format version is not supported. ' +
          `Specification version is ${format}. Latest supported version is ${versionLatest}.`,
      );
    }
    return adapter;
  }
}
