import { Root } from './controls/root';
import { Page } from './page';
import { Property } from './property';
import { ScreenType } from './screen-type';
import { PageVariationVisitor } from '../visitor/page-variation-visitor';

export class PageVariation {
  readonly screenType = new Property<ScreenType | null>(this);
  readonly controlRoot: Root;

  backgroundImage: string;
  backgroundImageRepeat: 'repeat' | 'repeat-x' | 'repeat-y' | 'no-repeat' | 'space' | 'round';
  backgroundImageSize: 'auto' | 'cover' | 'contain';

  get id() {
    return this.controlRoot.id;
  }

  parentPage: Page | null = null;

  get parent() {
    return this.parentPage;
  }

  constructor({
    screenType = null,
    backgroundImage = null,
    backgroundImageRepeat = 'repeat',
    backgroundImageSize = 'auto',
    controlRoot,
  }: PageVariationOptions) {
    this.screenType.set(screenType);
    this.backgroundImage = backgroundImage;
    this.backgroundImageRepeat = backgroundImageRepeat;
    this.backgroundImageSize = backgroundImageSize;
    this.controlRoot = controlRoot;
    controlRoot.parentContainer = this;
  }

  accept<T extends PageVariationVisitor>(visitor: T): T {
    visitor.visitPageVariation(this);
    return visitor;
  }
}

export interface PageVariationOptions {
  screenType?: ScreenType | null;
  backgroundImage?: string;
  backgroundImageRepeat?: 'repeat' | 'repeat-x' | 'repeat-y' | 'no-repeat' | 'space' | 'round';
  backgroundImageSize?: 'auto' | 'cover' | 'contain';
  controlRoot: Root;
}
