import { Routes } from '@angular/router';
import { preloadModule } from './core/preloading-strategy';
import { RedirectComponent } from './core/redirect.component';
import { TechnicianGuard } from './technician.guard';

export const routes: Routes = [
  {
    path: 'users',
    loadChildren: () => import('./users/users.module').then((m) => m.UsersModule),
    data: { breadcrumb: 'Users' },
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then((m) => m.ProfileModule),
  },
  // Preload PackageModule as this is the module, which will be used first
  // after logging in. It also ensures that login form will disappear right
  // away instead of staying on screen while module is being loaded and parsed.
  {
    path: 'packages',
    loadChildren: () => import('./packages/packages.module').then((m) => m.PackagesModule),
    data: { preload: preloadModule, breadcrumb: 'Packages' },
    canActivate: [TechnicianGuard],
  },
  {
    path: 'systems',
    loadChildren: () => import('./systems/systems.module').then((m) => m.SystemsModule),
    data: { breadcrumb: 'Systems' },
  },
  {
    path: 'devices',
    loadChildren: () => import('./devices/devices.module').then((m) => m.DevicesModule),
    data: { breadcrumb: 'Devices' },
  },
  {
    path: 'groups',
    loadChildren: () => import('./groups/groups.module').then((m) => m.GroupsModule),
    data: { breadcrumb: 'Groups' },
  },
  { path: 'licenses', loadChildren: () => import('./licenses/licenses.module').then((m) => m.LicensesModule) },
  {
    path: 'onboarding',
    loadChildren: () => import('./onboarding/onboarding.module').then((m) => m.OnboardingModule),
  },
  {
    path: 'mobile-applications',
    loadChildren: () =>
      import('./mobile-applications/mobile-applications.module').then((m) => m.MobileApplicationsModule),
    data: { breadcrumb: 'Mobile Applications' },
  },
  {
    path: 'what-is-new',
    loadChildren: () => import('./changelog/changelog.module').then((m) => m.ChangelogModule),
    data: { breadcrumb: "What's New" },
  },
  {
    path: 'redirect',
    component: RedirectComponent,
  },
  // Redirect to home page, when user, who is already authenticated clicks
  // on the Sign Up button on the landing page.
  {
    path: 'sign-up',
    redirectTo: '/',
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/packages',
  },
  {
    path: '**',
    loadChildren: () => import('./error/error.module').then((m) => m.ErrorModule),
    pathMatch: 'prefix',
  },
];
