import { ResponseErrorCode, responseErrorMessages, UNEXPECTED_ERROR_MESSAGE } from './error-code';
import { FieldErrors } from './types';

export abstract class DataError extends Error {
  public abstract override readonly name: string;
  public userMessage: string;
  public override message: string;
  public code: ResponseErrorCode;

  public constructor(code: ResponseErrorCode, message?: string) {
    super(message);
    Object.setPrototypeOf(this, DataError.prototype);

    this.code = code;
    this.userMessage = responseErrorMessages.get(code);
    if (this.userMessage == null) {
      this.userMessage = UNEXPECTED_ERROR_MESSAGE;
    }
    this.message = message;
  }
}

export class NotFoundError extends DataError {
  public readonly name = 'NotFoundError';

  public constructor(code: ResponseErrorCode, message: string) {
    super(code, message);
    Object.setPrototypeOf(this, NotFoundError.prototype);
  }
}

export class InternalServerError extends DataError {
  public readonly name = 'InternalServerError';

  public constructor(code: ResponseErrorCode, message: string) {
    super(code, message);
    Object.setPrototypeOf(this, InternalServerError.prototype);
  }
}

export class UnauthorizedError extends DataError {
  public readonly name = 'UnauthorizedError';

  public constructor(code: ResponseErrorCode, message: string) {
    super(code, message);
    Object.setPrototypeOf(this, UnauthorizedError.prototype);
  }
}

export class BadRequestError extends DataError {
  public readonly name: string = 'BadRequestError';

  public constructor(code: ResponseErrorCode, message: string) {
    super(code, message);
    Object.setPrototypeOf(this, BadRequestError.prototype);
  }
}

export class ForbiddenError extends DataError {
  public readonly name: string = 'ForbiddenError';

  public constructor(code: ResponseErrorCode, message: string) {
    super(code, message);
    Object.setPrototypeOf(this, ForbiddenError.prototype);
  }
}

export class ValidationError extends BadRequestError {
  public override readonly name = 'ValidationError';
  public fieldErrors: FieldErrors;

  public constructor(code: ResponseErrorCode, message: string, fieldErrors: FieldErrors) {
    super(code, message);
    Object.setPrototypeOf(this, ValidationError.prototype);

    this.fieldErrors = fieldErrors;
  }
}
