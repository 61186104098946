import { ApplicationAdapterV0_1_0 } from './adapter-v0-1-0';
import { AdapterContract } from './contract';
import { A0_2_0 } from './v0-2-0';
import { Application } from '../application';
import { Control } from '../control';
import { Button, ButtonAction, NavigatePageButtonAction, WriteSignalsButtonAction } from '../controls/button';
import { GridLayout, GridLayoutCell, GridLayoutRow } from '../controls/grid-layout';
import { Image } from '../controls/image';
import { StackLayout } from '../controls/stack-layout';
import { Page } from '../page';

/**
 * This is the only place, where different versions of ST application format
 * are converted into same set of entities, which should be used by ST
 * applications consumers.
 */
export class ApplicationAdapterV0_2_0 implements AdapterContract {
  private baseAdapter = new ApplicationAdapterV0_1_0();

  createApplication(declaration: A0_2_0.ApplicationDeclaration): Application {
    return new Application({
      pages: declaration.pages.map((item) => this.createPage(item, declaration.system)),
      // Intentionally using a different format because we don't support
      // serialisation for this format anymore.
      formatVersion: '0.2.1',
    });
  }

  createPage(declaration: A0_2_0.PageDeclaration, system: A0_2_0.SystemDeclaration = { ecus: [] }): Page {
    const pages = declaration.pages != null ? declaration.pages.map((child) => this.createPage(child, system)) : [];

    const controls =
      declaration.controls != null ? declaration.controls.map((item) => this.createControl(item, system)) : [];

    return new Page({
      id: declaration.id,
      name: declaration.name,
      pages,
      controls,
    });
  }

  createControl(data: A0_2_0.AnyControlDeclaration, system: A0_2_0.SystemDeclaration = { ecus: [] }): Control {
    switch (data.type) {
      case 'BarChart':
        return this.baseAdapter.createBarChartControl(data, system);
      case 'BooleanSignal':
        return this.baseAdapter.createBooleanSignalControl(data, system);
      case 'Button':
        return this.createButtonControl(data);
      case 'EnumSignal':
        return this.baseAdapter.createEnumSignalControl(data, system);
      case 'Gauge':
        return this.baseAdapter.createGaugeControl(data, system);
      case 'GridLayout':
        return this.createGridLayoutControl(data, system);
      case 'GridLayoutRow':
        return this.createGridLayoutRowControl(data, system);
      case 'GridLayoutCell':
        return this.createGridLayoutCellControl(data, system);
      case 'Hint':
        return this.baseAdapter.createHintControl(data);
      case 'Image':
        return this.createImageControl(data);
      case 'Label':
        return this.baseAdapter.createLabelControl(data);
      case 'LineChart':
        return this.baseAdapter.createLineChartControl(data, system);
      case 'NumericSignal':
        return this.baseAdapter.createNumericSignalControl(data, system);
      case 'StackLayout':
        return this.createStackLayoutControl(data, system);
      case 'Text':
        return this.baseAdapter.createTextControl(data);
      default:
        throw new Error(`Unsupported control type: ${(data as any).type}.`);
    }
  }

  createButtonControl({
    type: _,
    action,
    actionArgs,
    margin,
    ...declaration
  }: A0_2_0.ButtonControlDeclaration): Button {
    return new Button({
      ...declaration,
      margin: this.createMargins(margin),
      action: this.createButtonAction(declaration.id, action, actionArgs),
    });
  }

  createButtonAction(controlId: string, action: 'writeSignals' | 'navigatePage', actionArgs: any[]): ButtonAction {
    if (action === 'navigatePage' && actionArgs.length !== 1) {
      throw new Error(
        `Button with ID '${controlId}' specifies action 'navigatePage'. ` +
          `'actionArgs' property should contain exactly one element - page ID to navigate, ` +
          `but ${actionArgs.length} elements was provided.`,
      );
    }

    switch (action) {
      case 'writeSignals':
        return new WriteSignalsButtonAction();
      case 'navigatePage':
        return new NavigatePageButtonAction({ pageId: actionArgs[0] });
    }
  }

  createImageControl({ margin, ...declaration }: A0_2_0.ImageControlDeclaration): Image {
    return new Image({ margin: this.createMargins(margin), ...declaration });
  }

  createStackLayoutControl(
    { type: _, children, margin, ...options }: A0_2_0.StackLayoutControlDeclaration,
    system: A0_2_0.SystemDeclaration,
  ): StackLayout {
    return new StackLayout({
      ...options,
      margin: this.createMargins(margin),
      children: children.map((item) => this.createControl(item, system)),
    });
  }

  createGridLayoutControl(
    { type: _, children, spacing, margin, ...options }: A0_2_0.GridLayoutControlDeclaration,
    system: A0_2_0.SystemDeclaration,
  ): GridLayout {
    return new GridLayout({
      ...options,
      margin: this.createMargins(margin),
      spacing: `${spacing}du`,
      children: children.map((item) => this.createControl(item, system) as GridLayoutRow),
    });
  }

  createGridLayoutRowControl(
    { type: _, children, margin, ...options }: A0_2_0.GridLayoutRowControlDeclaration,
    system: A0_2_0.SystemDeclaration,
  ): GridLayoutRow {
    return new GridLayoutRow({
      ...options,
      margin: this.createMargins(margin),
      children: children.map((item) => this.createControl(item, system) as GridLayoutCell),
    });
  }

  createGridLayoutCellControl(
    { type: _, children, margin, ...options }: A0_2_0.GridLayoutCellControlDeclaration,
    system: A0_2_0.SystemDeclaration,
  ) {
    return new GridLayoutCell({
      ...options,
      margin: this.createMargins(margin),
      children: children.map((item) => this.createControl(item, system)),
    });
  }

  createMargins(value: A0_2_0.ControlDeclaration.Margins = {}) {
    const { top = '0du', bottom = '0du', left = '0du', right = '0du' } = value;

    return {
      top: top === '0' ? `${top}du` : top,
      bottom: bottom === '0' ? `${bottom}du` : bottom,
      left: left === '0' ? `${left}du` : left,
      right: right === '0' ? `${right}du` : right,
    };
  }

  serializeControl(_: Control): A0_2_0.AnyControlDeclaration {
    throw new Error('Serializing to this format version is not supported.');
  }

  serializeApplication(_: Application): A0_2_0.ApplicationDeclaration {
    throw new Error('Serializing to this format version is not supported.');
  }

  serializePage(_: Page): A0_2_0.PageDeclaration {
    throw new Error('Serializing to this format version is not supported.');
  }
}
