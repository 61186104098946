import { Injectable } from '@angular/core';
import { EventManager } from '@angular/platform-browser';

/**
 * Custom event manager, which allows to add event listeners outside of Angular
 * zone.
 *
 * See https://stackoverflow.com/a/45229997/1377864
 */
@Injectable()
export class CustomEventManager extends EventManager {
  override addEventListener(element: HTMLElement, eventName: string, handler: Function): Function {
    if (eventName.endsWith(NO_ZONE)) {
      eventName = eventName.slice(0, -NO_ZONE.length);
      return this.getZone().runOutsideAngular(() => super.addEventListener(element, eventName, handler));
    }

    return super.addEventListener(element, eventName, handler);
  }
}

/**
 * Suffix, which will cause event listener to be added outside of Angular zone
 * and therefore change detection won't be triggered after event fires. It is
 * useful, when event should not trigger change detection run every time and
 * event is fired very often thus causing performance issues.
 *
 * This should be appended as suffix to event name and is supported for all
 * event listeners managed by Angular.
 *
 * @example
 *
 * component MyComponent {
 *   background = 'white';
 *
 *   @HostListener('mouseover' + NO_ZONE)
 *   onMouseOver() {
 *      if (someCondition) {
 *        this.background = 'red';
 *        this.changeDetector.detectChanges();
 *      }
 *   }
 * }
 */
export const NO_ZONE = '.no-zone';
