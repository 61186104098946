import { Application } from '../../../application/application';
import { Control } from '../../../application/control';
import { Page } from '../../../application/page';
import { PageVariation } from '../../../application/page-variation';

export type ProblemSeverity = 'error' | 'warning';
export type ValidationTrigger =
  | 'nodeProperties' /* Triggered when one or multiple properties of a validateable node was changed. Also
                            includes direct children being added or removed. */
  | 'childrenProperties' /* Triggered when a (direct) child property was changed. */
  | 'parentProperties' /* Triggered when a (direct) parent property was changed. */
  | 'controlHierarchy' /* Triggered when the control hierarchy of a page was changed. */
  | 'dashboardHierarchy' /* Triggered when the page hierarchy was updated i.e. a page was created,
                            deleted, moved or when its name was updated. */
  | 'signals' /* Triggered when one or multiple signals was changed. */
  | 'applicationWide'; /* Triggered when a change has the possibility to affects every other control in the app. */

export interface Problem {
  /**
   * The severity of the problem.
   *
   * 'Error' is for problems that _will_ result in the application not
   * functioning correctly and 'warning' for problems that _might_ result in
   * the application not functioning correctly.
   */
  severity: ProblemSeverity;

  /**
   * Short description of the problem. Typically displayed in the problems list.
   */
  shortDescription: string;

  /**
   * Longer, more detailed description, possibly providing guidance to the user.
   */
  longDescription: string;

  /**
   * The sub-object of the node that gives the problem, typically the property
   * name.
   */
  subObjects: string[];
}

export type Validatable = Control | Page | PageVariation;

export abstract class Validator {
  /**
   * Called for every node that was affected by a specific trigger.
   * For performance reasons no heavy operations should be done in this function.
   *
   * @param node The node that is affected.
   * @param triggers A list of triggers that triggered the call.
   * @return True if the validator is relevant, otherwise false.
   */
  abstract isRelevant(node: Validatable, triggers: ValidationTrigger[]): boolean;

  /**
   * Called if the associated isRelevant() call returned true.
   * In this function, the implementor should do the actual verification and possibly return a list of problems.
   *
   * @param node The node that should be validated.
   * @param application The Application to be used for verification, if needed.
   * @return A list of problems, or an empty list if no problems could be found.
   */
  abstract validate(node: Validatable, application?: Application): Problem[];
}
