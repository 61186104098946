import { rgb } from 'd3-color';

/**
 * Returns black or white (#262626 or #ffffff) according to the which one receives the highest
 * score metric when used as a foreground color to the inputted background color.
 *
 * The algorithm used is based on the one documented by the World Wide Web Consortium (W3C) in
 * {@link https://www.w3.org/TR/WCAG20-TECHS/G18.html|WCAG 2.0 §G18}. However, it has been
 * altered to skew slightly towards choosing white, to align with the design documents of
 * Danfoss.
 *
 * @param backgroundColor - The color of the background in hex form (#xxxxxx).
 */
export function calculateTextColor(backgroundColor: string): string {
  if (backgroundColor[0] === '#' && backgroundColor.length === 7) {
    const lBlack = 0.01938236095; // #262626
    const lWhite = 1; // #ffffff

    let r = parseInt(backgroundColor.substring(1, 3), 16) / 255;
    let g = parseInt(backgroundColor.substring(3, 5), 16) / 255;
    let b = parseInt(backgroundColor.substring(5, 7), 16) / 255;

    if (r <= 0.03928) {
      r = r / 12.92;
    } else {
      r = Math.pow((r + 0.055) / 1.055, 2.4);
    }

    if (g <= 0.03928) {
      g = g / 12.92;
    } else {
      g = Math.pow((g + 0.055) / 1.055, 2.4);
    }

    if (b <= 0.03928) {
      b = b / 12.92;
    } else {
      b = Math.pow((b + 0.055) / 1.055, 2.4);
    }

    let l = 0.2126 * r + 0.7152 * g + 0.0722 * b;

    if ((l + 0.05) / (lBlack + 0.05) > (lWhite + 0.05) / (l + 0.05) + 2) {
      return '#262626';
    } else {
      return '#ffffff';
    }
  } else {
    throw new Error('Color is not formatted correctly');
  }
}

/**
 * Returns a slightly brighter shade of the color inputted, unless the color
 * is already very bright. In this case the color returned is slightly darker.
 *
 * @param backgroundColor - The color of the background in hex form (#xxxxxx).
 */
export function calculateHoverColor(backgroundColor: string, k?: number): string {
  // TODO: Possibly replace this with a logarithmic lightening function
  if (backgroundColor[0] === '#' && backgroundColor.length === 7) {
    let r = parseInt(backgroundColor.substring(1, 3), 16);
    let g = parseInt(backgroundColor.substring(3, 5), 16);
    let b = parseInt(backgroundColor.substring(5, 7), 16);

    let c = rgb(r, g, b);
    return c.brighter(k).formatHex() === '#ffffff' ? c.darker(k).formatHex() : c.brighter(k).formatHex();
  } else {
    throw new Error('Color is not formatted correctly');
  }
}

/**
 * Returns a slightly darker shade of the color inputted, unless the color
 * is already very dark. In this case the color returned is slightly brighter.
 *
 * @param backgroundColor - The color of the background in hex form (#xxxxxx).
 */
export function calculateBorderColor(backgroundColor: string, k?: number): string {
  if (backgroundColor[0] === '#' && backgroundColor.length === 7) {
    let r = parseInt(backgroundColor.substring(1, 3), 16);
    let g = parseInt(backgroundColor.substring(3, 5), 16);
    let b = parseInt(backgroundColor.substring(5, 7), 16);

    let c = rgb(r, g, b);
    return c.darker(k).formatHex() === '#000000' ? c.brighter(k).formatHex() : c.darker(k).formatHex();
  } else {
    throw new Error('Color is not formatted correctly');
  }
}
