import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Group } from './data/group.service';
import { Membership, MembershipService } from './data/membership.service';
import { Page } from './data/shared/page';
import { ModalComponent } from './modals/modal-component';
import { Session } from './security/session.service';
import { AsyncData, asyncError, asyncSuccess } from '../shared/async-data';

@Component({
  selector: 'devapp-list-groups-modal',
  templateUrl: './list-groups-modal.component.html',
  styleUrls: ['./list-groups-modal.component.scss'],
})
export class ListGroupsModalComponent extends ModalComponent {
  data: AsyncData<Page<Membership>>;
  selectedGroupId: number;

  constructor(
    public activeModal: NgbActiveModal,
    private session: Session,
    private router: Router,
    private membershipService: MembershipService,
  ) {
    super();
    this.selectedGroupId = this.session.membership.groupId;
  }

  reloadGroups(page: number, itemsPerPage: number, searchTerm: string) {
    this.membershipService
      .list({
        limit: itemsPerPage,
        offset: itemsPerPage * (page - 1),
        search: searchTerm,
        userId: this.session.user.id,
      })
      .subscribe({
        next: (value) => (this.data = asyncSuccess(value)),
        error: (error) => (this.data = asyncError(error)),
      });
  }

  selectGroup(group: Group) {
    if (this.session.membership.groupId !== group.id) {
      this.session.setCurrentGroupId(group.id).subscribe(() =>
        this.router.navigate(['/redirect'], {
          queryParams: { to: this.router.url },
          skipLocationChange: true,
        }),
      );
    }
  }
}
