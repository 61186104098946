import {
  SignalFormatter,
  SignalIdentifier,
  SignalOption,
  SignalScale,
} from '../../../../application/signal-identifier';
import { Command } from '../command';

export class EditSignalIdentifierCommand implements Command {
  constructor(public data: Data) {}

  apply(): void {
    this.data.signalId.unit = this.data.newUnit;
    this.data.signalId.options = this.data.newOptions;
    this.data.signalId.scale = this.data.newScale;
    this.data.signalId.formatter = this.data.newFormatter;
  }

  unapply(): void {
    this.data.signalId.unit = this.data.oldUnit;
    this.data.signalId.options = this.data.oldOptions;
    this.data.signalId.scale = this.data.oldScale;
    this.data.signalId.formatter = this.data.oldFormatter;
  }
}

interface Data {
  signalId: SignalIdentifier;
  newUnit: string;
  newOptions: SignalOption[];
  newScale: SignalScale;
  newFormatter: SignalFormatter;
  oldUnit: string;
  oldOptions: SignalOption[];
  oldScale: SignalScale;
  oldFormatter: SignalFormatter;
}
