import { InjectionToken } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

export const MODAL_OPTIONS = new InjectionToken('modal.options');

export abstract class ModalComponent<_T = undefined> {
  abstract activeModal: NgbActiveModal;

  hideModal() {
    this.activeModal.dismiss();
  }
}
