export interface AsyncData<T> {
  value: T;
  error: Error;
}

export function asyncSuccess<T>(value: T): AsyncData<T> {
  return { value, error: null };
}

export function asyncError<T = any>(error: Error): AsyncData<T> {
  return { value: null, error };
}
