import { SignalIdentifier } from '../signal-identifier';

export type VisibilityOperators =
  | 'none'
  | 'lessThan'
  | 'lessThanOrEqualTo'
  | 'equalTo'
  | 'notEqualTo'
  | 'greaterThanOrEqualTo'
  | 'greaterThan'
  | 'inSet'
  | 'notInSet'
  | 'contains'
  | 'doesNotContain';

export abstract class VisibilityCondition {
  operator: VisibilityOperators;
  reference: SignalIdentifier;
  comparator: SignalIdentifier | number | string;
}

export class VisibilityShowCondition implements VisibilityCondition {
  operator: VisibilityOperators;
  reference: SignalIdentifier;
  comparator: SignalIdentifier | number | string;

  constructor(options: VisibilityConditionOptions) {
    this.operator = options.operator;
    this.reference = options.reference;
    this.comparator = options.comparator;
  }
}

export class VisibilityHideCondition implements VisibilityCondition {
  operator: VisibilityOperators;
  reference: SignalIdentifier;
  comparator: SignalIdentifier | number | string;

  constructor(options: VisibilityConditionOptions) {
    this.operator = options.operator;
    this.reference = options.reference;
    this.comparator = options.comparator;
  }
}

export interface VisibilityConditionOptions {
  operator: VisibilityOperators;
  reference: SignalIdentifier;
  comparator: SignalIdentifier | number | string;
}
