<ngb-pagination
  *ngIf="itemsPerPage < totalItems"
  class="d-flex justify-content-center"
  [pageSize]="itemsPerPage"
  [collectionSize]="totalItems"
  [boundaryLinks]="true"
  [directionLinks]="false"
  [maxSize]="5"
  [rotate]="true"
  [page]="activePage"
  (pageChange)="pageChanged.emit($event)"
>
  <ng-template ngbPaginationFirst>First</ng-template>
  <ng-template ngbPaginationLast>Last</ng-template>
</ngb-pagination>
