import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Session } from '../security/session.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private get session() {
    return this.injector.get(Session);
  }

  constructor(private injector: Injector) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.session.accessToken.pipe(
      mergeMap((token) => {
        request = request.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
        return next.handle(request);
      }),
    );
  }
}
