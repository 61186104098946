import { ClientDevice } from './client-device';
import { ClientDeviceSignalSource, EcuSignalSource, SignalIdentifier } from '../../application/signal-identifier';
import { SystemMatch, systemMatches } from '../../system-matching-criteria/system-matcher';
import { EcuMatchingCriteria, SystemMatchingCriteria } from '../../system-matching-criteria/system-matching-criteria';
import {
  EcuSpecification,
  SignalSpecification,
  SystemSpecification,
} from '../../system-specification/system-specification';

export class SystemIndex {
  private matchResult: SystemMatch;
  private signalsMap = new Map<string, Map<string, SignalSpecification>>();

  constructor(
    private specification: SystemSpecification,
    private criteria: SystemMatchingCriteria,
  ) {
    const matchResult = systemMatches(this.specification, this.criteria);

    if (!matchResult.matches) {
      throw new Error('Reference system specification does not match system matching criteria.');
    }

    this.matchResult = matchResult;

    for (const [ecuId, ecuSpecification] of this.matchResult.idToSpecification) {
      const ecuSignalsMap = new Map<string, SignalSpecification>();
      for (const signalSpecification of ecuSpecification.signals) {
        ecuSignalsMap.set(signalSpecification.name, signalSpecification);
      }
      this.signalsMap.set(ecuId, ecuSignalsMap);
    }
  }

  getEcuSpecification(ecuId: string): EcuSpecification | null {
    const specification = this.matchResult.idToSpecification.get(ecuId);
    return specification != null ? specification : null;
  }

  getEcuCriteria(ecuId: string): EcuMatchingCriteria | null {
    const criteria = this.matchResult.idToCriteria.get(ecuId);
    return criteria != null ? criteria : null;
  }

  getSignalSpecification(signalId: SignalIdentifier): SignalSpecification | null {
    if (signalId.source instanceof EcuSignalSource) {
      if (signalId.source.ecuId != null) {
        const ecuSignalsMap = this.signalsMap.get(signalId.source.ecuId);
        if (ecuSignalsMap != null && signalId.source.signalName != null) {
          const specification = ecuSignalsMap.get(signalId.source.signalName);
          if (specification != null) {
            return specification;
          }
        }
      }
    } else if (signalId.source instanceof ClientDeviceSignalSource) {
      return ClientDevice.clientDeviceSignalSpecifications.find((spec) => spec.name === signalId.source.signalName);
    }
    return null;
  }

  getEcuId(ecuFullAddress: number | null): string | null {
    if (ecuFullAddress == null) {
      return null;
    }

    const ecuId = this.matchResult.fullAddressToId.get(ecuFullAddress);
    return ecuId != null ? ecuId : null;
  }
}
