import { PageVariationVisitor } from './page-variation-visitor';
import { Page } from '../application/page';
import { PageVariation } from '../application/page-variation';
import { ScreenType } from '../application/screen-type';

export class ScreenTypeIsInUseVisitor extends PageVariationVisitor {
  private variations: PageVariation[] = [];
  private pages = new Set<Page>();

  constructor(public screenType: ScreenType) {
    super();
  }

  visitPageVariation(variation: PageVariation) {
    if (variation.screenType.raw === this.screenType) {
      this.variations.push(variation);
      this.pages.add(variation.parentPage);
    }
  }

  screenTypeIsInUse(): boolean {
    return this.variations.length > 0;
  }

  get variationsThatUseScreenType(): PageVariation[] {
    return this.variations;
  }

  get pagesThatUseScreenType(): Page[] {
    return Array.from(this.pages);
  }
}
