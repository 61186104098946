import { ControlOptions } from '../control';
import { SimpleControl } from '../simple-control';

export class Hint extends SimpleControl {
  title: string;
  text: string;
  align: HintAlign;

  readonly minimumWidth = 2.5;
  readonly minimumHeight = 2.5;

  readonly humanReadableName = 'Hint';

  constructor({ text = 'Hint Text', title = 'Hint Title', align = 'left', ...options }: HintOptions) {
    super(options);

    this.text = text;
    this.title = title;
    this.align = align;
  }
}

export interface HintOptions extends ControlOptions {
  title?: string;
  text?: string;
  align?: HintAlign;
}

export type HintAlign = 'left' | 'center' | 'right' | 'justify';
