import parseJSON from 'date-fns/parseJSON';

/**
 * Basic serializer to handle conversion between model instance and JSON.
 *
 * It provides two methods for the purpose and can be customized by providing
 * options with customization logic. By default it converts all created and
 * updated fields to/from Date/ISO-8601 string objects.
 */
export class DataSerializer<T extends PlainObject> {
  public DEFAULT_OPTIONS: SerializerOptions<T> = {
    onDeserialize: () => null,
    onSerialize: () => null,
    ignoreProperties: [],
  };

  public options: SerializerOptions<T>;

  public constructor(options?: SerializerOptions<T>) {
    this.options = { ...this.DEFAULT_OPTIONS, ...options };
  }

  /**
   * Populates instance with data from the plain object.
   *
   * @example
   * let serializer = new DataSerializer();
   * let instance = new MyClass();
   * serializer.fromJSON({myVar: 42}, instance);
   * console.log(instance.myVar); // outputs 42
   *
   * @param json - Data to populate instance.
   * @param instance - Instance to be populated with data.
   * @returns populated instance
   */
  public fromJSON(json: PlainObject, instance: T): T {
    for (const key of Object.keys(json)) {
      if (!this.options.ignoreProperties.includes(key)) {
        instance[key as keyof T] = this.isTimestampProperty(key) ? parseJSON(json[key]) : json[key];
      }
    }

    this.options.onDeserialize(this, json, instance);

    return instance;
  }

  /**
   * Serializes instance to plain object.
   *
   * @example
   * let serializer = new DataSerializer();
   * let instance = new MyClass();
   * instance.myVar = 42;
   * let json = serializer.toJSON(instance);
   * console.log(json); // outputs {myVar: 42}
   *
   * @param instance - Instance to be serialized.
   * @returns populated plain object
   */
  public toJSON(instance: T): PlainObject {
    let json: PlainObject = {};
    for (const key of Object.keys(instance)) {
      if (!this.options.ignoreProperties.includes(key) && key !== 'serializer') {
        json[key] = this.isTimestampProperty(key) ? (instance[key] as Date).toISOString() : instance[key];
      }
    }

    this.options.onSerialize(this, instance, json);

    return json;
  }

  private isTimestampProperty(key: string) {
    return key === 'created' || key === 'updated';
  }
}

/**
 * Serializer customization options.
 */
export interface SerializerOptions<T> {
  /**
   * Customize instance after it was populated from JSON.
   */
  onDeserialize?: (serializer: DataSerializer<T>, json: PlainObject, instance: T) => void;

  /**
   * Customize JSON after it was populated from the instance.
   */
  onSerialize?: (serializer: DataSerializer<T>, instance: T, json: PlainObject) => void;

  /**
   * List of properties defined on the model, but not present in the JSON.
   * These properties won't be set by deserialization logic and won't be
   * included in serialized JSON.
   */
  ignoreProperties?: Array<keyof T>;
}

export interface PlainObject {
  [key: string]: any;
}
